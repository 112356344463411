import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchSinglePoll } from '../../../../redux/features/singlePages/newPolls/newPollSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Loader from '../../../common/Loader/loader';
import CommentSection from '../Listing/comment-section';
import SimilarProduct from '../Listing/similar-product';
import PromotedPost from '../Topic/promoted-post';
import PollCard from './poll-card';

const PollsPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
    console.log(id);
    if (id) {
      dispatch(fetchSinglePoll(id));
    }
  }, [id]);
  const { postData, loading } = useAppSelector((state) => state.pollPost);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section>
          <PollCard info={postData} />
          <PromotedPost />
          <CommentSection comments={postData} />
        </section>
      )}
    </>
  );
};

export default PollsPage;
