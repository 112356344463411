import clsx from "clsx";

const Card = ({
  noMt,
  className,
  children,
  style
}: {
  noMt?: any;
  className?: string;
  style?: any;
  children?: React.ReactNode;
}) => {
  return (
    <div
      style={style}
      className={clsx(
        className,
        "border-[0px] border-solid w-full border-accentGray-600 rounded-lg ",
        !className?.includes("p-") && "p-[10px]",
        !noMt && "mt-3 "
      )}
    >
      {children}
    </div>
  );
};

export default Card;
