import mixpanel from "mixpanel-browser";
import { Link, useNavigate } from "react-router-dom";
import useAuthUser from "../../../hooks/getAuthUser";

export default function CommunityItems({ value }: { value: Array<any> }) {
  return (
    <section className="grid grid-cols-1 gap-5 p-4 bg-white border rounded-lg border-slate-200 md:grid-cols-2">
      {value?.map((_, idx) => (
        <Card props={_} idx={idx + 1} key={idx} />
      ))}
    </section>
  );
}

const Card = ({ props, idx }: { idx: number; props: any }) => {
  const user = useAuthUser();

  return (
    <Link
      to={`/browse/s/${props.name}/posts`}
      onClick={() =>
        mixpanel.track("Community card clicked", {
          community_name: props.name,
          email: user ? user["email"] : "",
        })
      }
      className="cursor-pointer border-orange-300 border-b border-r-2 hover:border-t-4 hover:border-orange-300 rounded-md"
    >
      <div className="flex items-center gap-2.5 text-black ">
        <p className="w-5 text-sm font-medium">{idx}.</p>
        <img
          alt=""
          className="w-[44px] h-[44px] rounded-[108.49px] shadow"
          src={`/assets/vectors/navbar/${props.genre}.svg`}
        />
        <div>
          <p className="text-sm font-semibold ">c/{props.name}</p>
          <p className="w-[229px]  text-xs font-medium">{props.genre}</p>
          <p className="text-xs font-medium text-neutral-400 ">
            {props.subscriberCount + 4} members
          </p>
        </div>
      </div>
      <div className="flex mt-2">
        <div className="basis-[5.5em] " />
        <div className="flex justify-between grow"></div>
      </div>
    </Link>
  );
};
