import { Icon } from "@iconify/react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import clsx from "clsx";

import { ComponentProps, useState } from "react";
import { cn } from "../../helpers/utils";
import { FormMessage } from "../ui/form";

type PrivacyProps = ComponentProps<"div"> & {
  level?: any[];
  value?: any;
  onOptionSelect: (val: any) => void;
};

export function RadioCard({ className, onOptionSelect, value, ...props }: PrivacyProps) {
  const [selector, setSelector] = useState(value);
  const onSelect = (el: any) => {
    if (onOptionSelect) {
      onOptionSelect(el);
      setSelector(el);
    }
  };
  return (
    <div
      className={cn(
        " mt-1 md:mt-4 grid max-w-3xl gap-x-4 gap-y-3 grid-cols-2 md:grid-cols-4 md:mb-8 mb-2",
        className
      )}
    >
      {(props.level || level).map((el) => (
        <div
          id={el.label}
          className="relative shadow-lg"
          onClick={() => {
            onSelect(el.label);
          }}
        >
          <label
            className={clsx(
              selector === el.label
                ? "flex h-full cursor-pointer flex-col rounded-lg p-2 md:p-4 shadow-lg shadow-slate-100 bg-emerald-600 text-white"
                : "flex h-full cursor-pointer flex-col rounded-lg p-2 md:p-4 shadow-lg shadow-slate-100 "
            )}
            htmlFor={el.label}
          >
            <Icon
              icon={el.icon}
              className={clsx(
                selector === el.label
                  ? "text-white text-base mx-auto"
                  : "text-orange-500 text-base mx-auto"
              )}
            />
            <span className="mt-2- text-sm font-medium text-center">{el.label}</span>
            <span className="text-xs uppercase text-center">
              {el.text} items
            </span>
          </label>
        </div>
      ))}
    </div>
  );
}

const level = [
  {
    icon: "mingcute:user-2-fill",
    label: "Public",
    text: "Anyone can view, post, and comment to this community",
  },
  {
    icon: "fluent:eye-12-filled",
    label: "Restricted",
    text: "Anyone can view this community, but only approved users can post",
  },
  {
    icon: "teenyicons:lock-small-solid",
    label: "Private",
    text: "Only approved users can view and submit to this community",
  },
];
