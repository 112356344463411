import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface FeedState {
  loading: boolean;
  error: string | null;
  deleteLoading: boolean;
  deleteError: string | null;
}

// Define the initial state using that type
const initialState: FeedState = {
  loading: true,
  error: null,
  deleteLoading: false,
  deleteError: null,
};

export const deletePost = createAsyncThunk(
  "post/remove",
  async ({ id }: any) => {
    const response = await axios.delete(
      `https://webservice.pairrit.com/pairrit-ws/api/posts/${id}`
    );
    return response.status;
  }
);

export const reportPost = createAsyncThunk(
  "home/reportPost",
  async ({
    postId,
    userId,
    report,
  }: {
    postId: string;
    userId: string;
    report: string;
  }) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/posts/report`,
      {
        postId: postId,
        userId: userId,
        report: report,
      }
    );
    return response.status;
  }
);

export const reportPostSlice = createSlice({
  name: "reportPost",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(reportPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(reportPost.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(reportPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(deletePost.pending, (state) => {
        state.deleteLoading = true;
        state.deleteError = null;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.deleteLoading = false;
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.deleteLoading = false;
        state.deleteError = action.error.message || "An error occurred";
      });
  },
});

export const {} = reportPostSlice.actions;

export default reportPostSlice.reducer;
