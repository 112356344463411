import { Icon } from "@iconify/react";
import GradientBtn from "../../../buttons/GradientBtn";

const Reviews = () => {
  return (
    <div>
      <div>
        <img
          className="w-full h-full mx-auto object-cover rounded-lg"
          src="/assets/imgs/home/banner-4.png"
          alt="ad"
        />
      </div>
    </div>
  );
};

export default Reviews;
