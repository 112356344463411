import { Icon } from "@iconify/react";
import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import DialogWrapper from "../../../common/dialog-wrapper";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";

export default function Share({

  postId,

}: {
  postId: any;

}) {
  


  return (
    <section className="flex select-none w-fit">
  
   
      <DialogWrapper
        wrapperClassName="md:min-w-[500px]"
        className="w-fit"
        content={<CopyLink type="listing" id={postId} />}
      >
        <button
          onClick={() => mixpanel.track("Listing share button clicked")}
          className="ml-2 flex items-baseline gap-2 rounded-lg bg-green-500 px-1 py-1 text-base font-bold text-white hover:opacity-75 border-2 border-white"
        >
          <Icon icon={"carbon:share"} className="text-lg text-white mx-auto" />
        </button>
      </DialogWrapper>
    </section>
  );
}
