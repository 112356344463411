import "./Post.css";
import Listing from "./Listing/listing";
import NewGear from "./New-Gear/new-gear";
import Poll from "./Poll/poll";
import Topic from "./Topic/topic";
import TopicImg from "./Topic/topicImg";

const Post = ({ el, idx }: { el: any; idx: number }) => {
  if (el.type === "PROD_GEAR") return <NewGear el={el} idx={idx} key={idx} />;
  if (el.type === "PROD_SALE") return <Listing el={el} idx={idx} key={idx} />;
  if (el.type === "PROD_POLL") return <Poll el={el} idx={idx} key={idx} />;
  if (el.type === "PROD_TOPIC") return <Topic el={el} idx={idx} key={idx} />;
  if (el.type === "PROD_TOPIC_IMG") return <TopicImg el={el} idx={idx} key={idx} />;
  return null;
};

export default Post;
