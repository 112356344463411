import { Icon } from '@iconify/react';
import React from 'react'
import { Link } from 'react-router-dom';
import Flags from '../Home/Flags/Flags';
import Flags2 from '../Home/Flags/Flags2';
import GearList from '../Single-Page/New-Gear/gear-list';
import CardContainer from './CardContainer';
import { list2, prodList } from './MarketHome';
import ProductList from './product-list';

const WomenShop = () => {
  return (
    <div>
      <Flags
        m1="Smell Like A Queen"
        m2="Save over 25% on all popular scents"
        m3="Shop All Women"
        m4={3}
        m5="?gender=FEMALE"
      />
      <Flags2
        m1="Smell Like A Queen"
        m2="Save over 25% on all popular scents"
        m3="Shop All Women"
        m4={3}
        m5="?gender=FEMALE"
      />
      <div className="lg:mt-10 mt-4">
        <Link to={"/market?gender=FEMALE"}>
          <p className="text-sm md:text-base font-bold flex mb-1">
            Shop Top Brands{" "}
            <Icon
              icon="ph:arrow-right-duotone"
              className="my-auto ml-1 text-xl"
            />
          </p>
        </Link>

        <div className="h-0 mb-5  border-t border-slate-400/20" />
        <CardContainer />
      </div>
      <div className="lg:mt-18 mt-4">
        <p className="text-sm md:text-base font-bold flex">
          Top Women{" "}
          <Icon
            icon="ph:arrow-right-duotone"
            className="my-auto ml-1 text-xl"
          />
        </p>
        <div className="h-0  border-t border-slate-400/20" />
        <ProductList
          value={list2}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>

      <img
        className="w-full h-full mx-auto object-cover mt-12 rounded-lg"
        src="/assets/imgs/home/banner-8.png"
        alt="ad"
      />
      <div className="lg:mt-20 mt-4">
        <p className="text-sm md:text-base font-bold flex ">
          Most Popular Scents
          <Icon
            icon="ph:arrow-right-duotone"
            className="my-auto ml-1 text-xl"
          />
        </p>
        <div className="h-0  border-t border-slate-400/20" />
        <GearList
          value={prodList}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>
    </div>
  );
}

export default WomenShop