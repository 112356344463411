// @ts-nocheck
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import useSwiperRef from "../../../../hooks/useSwiperRef";
import useAuthUser from "../../../../hooks/getAuthUser";
import Card from "../../../common/Card";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { sizesData2 } from "../../Home/Categories/categoriesData";
import mixpanel from "mixpanel-browser";

const OilsMarketCategories = ({
  value,
  setValue,
  selection,
}: {
  value: any;
  selection?: any;
  setValue: (value: any) => void;
}) => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const [nextEl, nextRef] = useSwiperRef();
  const [prevEl, prevRef] = useSwiperRef();
  const [selector, setSelector] = useState(value);

  useEffect(() => {

  }, [selector]);
  return (
    <Card noMt className="category-card bg-slate-700 from-stone-400 to-zinc-300  shadow-lg rounded px-3 py-1 w-fit mx-auto ">
      <div className="flex justify-between ">
        <p className="text-xs ">Select a size</p>

        <div className="md:hidden flex gap-1 self-end ">
          <Icon
            icon="ph:arrow-right-duotone"
            className="text-xl text-black animate-pulse"
          />
        </div>
      </div>

      <div className="md:mt-[5px]  ">
        <div className="">
          <Swiper
            modules={[Navigation]}
            navigation={{
              prevEl,
              nextEl,
            }}
            spaceBetween={10}
            breakpoints={{
              0: {
                slidesPerView: selection.length,
              },
              400: {
                slidesPerView: selection.length,
              },
              640: {
                slidesPerView: selection.length,
              },
              1480: {
                slidesPerView: selection.length,
              },
            }}
          >
            {selection.map((el, idx) => {
              return (
                <SwiperSlide key={"home-category-slider-item " + idx}>
                  <div
                    className={clsx(
                      value === el.label
                        ? "cursor-pointer flex flex-col items-center border-b-4 border-orange-300 bg-zinc-200  shadow-lg rounded-b"
                        : "cursor-pointer flex flex-col items-center hover:border-b-4 hover:border-orange-300 shadow shadow-neutral-500/50 rounded-b bg-slate-100"
                    )}
                    onClick={() => {
                      setValue(el.label);
                      mixpanel.track("Market Category Selected", {
                        category_name: el.label,
                        email: user ? user["email"] : "",
                      });
                    }}
                  >
                    <div className="mt-1 text-center font-semibold  text-[10px] lg:text-[10px]">
                      {el.label}
                    </div>

                    {/* <Icon
                      icon="solar:bottle-bold-duotone"
                      className=" text-xl"
                    /> */}
                    <div className="mt-1 text-center  text-[11px] lg:text-[12px] font-bold">
                      ${el.price}
                    </div>

                    <div className="mt-1 text-center  text-[11px] lg:text-[12px]">
                      {el.size}ml
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Card>
  );
};

export default OilsMarketCategories;
