import { useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import Card from "../../../common/Card/Card";
import Carousel from "../Listing/carousel";
import Author from "./author";
import PostFooter from "./post-footer";
import Vote from "./vote";

export default function TopicCard({ info }: { info: any }) {
  const { userId } = useAppSelector((state) => state.userProfile);
  const [likePressed, setLikePressed] = useState(
    info.isLiked?.includes(userId) ? true : false
  );
  return (
    <Card className="flex gap-2 bg-white">
      <Vote likes={info.likes} isLiked={likePressed} postId={info.postId} />
      <div className="grow p-3">
        <Author info={info} />
        <div>
          <p className="text-lg font-medium">{info.title}</p>
          {!info.topicType && (
            <div className="bg-orange-400  w-36  rounded-lg border-orange-400/20 p-1 shadow-lg shadow">
              <div className="bg-neutral-100 rounded-sm border backdrop-blur-[20px]">
                <img
                  className="object-bottom w-full h-full "
                  src="/assets/imgs/home/username.png"
                  alt=""
                />
              </div>

              {/* <p className="text-xs  font-semibold text-center pt-1 ">{props.title}</p> */}
            </div>
          )}
          <p className="text-sm font-medium tracking-normal">{info.text}</p>
          <PostFooter comments={info.comments} />
        </div>
      </div>
    </Card>
  );
}
