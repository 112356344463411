export const notifications = [
  {
    
    time: "14h",
    type: "Shipped",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been,",
  },
  {
    
    time: "14h",
    type: "Delievered",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been,",
  },
  {
    
    time: "14h",
    type: "New Message",
    highlited: true,
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been,",
    reply: true,
  },
  {
    
    time: "14h",
    type: "New Message",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been,",
    reply: true,
  },
  {
    
    time: "14h",
    type: "Item Sold",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been,",
  },
  {
    
    time: "1day",
    type: "Welcome",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been,",
  },
  {
    
    time: "3 days",
    type: "New Offer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been,",
  },

];