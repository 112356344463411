import { Icon } from "@iconify/react";
import { useOktaAuth } from "@okta/okta-react";
import { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { cn } from "../../../../../../helpers/utils";
import { useAppSelector } from "../../../../../../redux/hooks";
import GradientBtn from "../../../../../buttons/GradientBtn";
import { Privacy } from "../../../../../common/privacy";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch } from "react-redux";
import { notifySelect } from "../../../../../../redux/features/notify/notifySlice";
import { AppDispatch } from "../../../../../../redux/store";
import { Form, FormControl, FormField, FormItem } from "../../../../../ui/form";
import { reportPost } from "../../../../../../redux/features/homeFeed/reportSlice";

const formSchema = z.object({
  userId: z.string(),
  postId: z.string(),
  report: z.string(),
});

interface CreateCommunityModalProps extends ComponentProps<"form"> {
  id: any;
}

export default function ReportModal({
  className,
  id,
  ...props
}: CreateCommunityModalProps) {
  const { userId } = useAppSelector((state) => state.userProfile);
  const { authState } = useOktaAuth();
  const dispatch = useDispatch<AppDispatch>();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const { loading, error } = useAppSelector((state) => state.reportPost);

  function onSubmit(values: any) {
    if (authState?.isAuthenticated) {
      dispatch(reportPost(values));
      dispatch(
        notifySelect({
          title: "Report Sent!",
          type: 0,
          open: true,
        })
      );
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
    }
  }
  useEffect(() => {
    form.setValue("userId", userId);
    form.setValue("postId", id);
    if (error) {
      dispatch(
        notifySelect({
          title: "Error Sending",
          type: 1,
          open: true,
        })
      );
    }
  }, [error]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn("z-50 h-", className, {})}
        {...props}
      >
        <GradientBtn
          short
          label={"Submit"}
          className="!rounded-full !font-bold !text-sm !px-[17px] mx-auto"
        />
        <div className="h-px bg-neutral-500/30 my-4" />
        <FormField
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Privacy level={level} onOptionSelect={field.onChange} />
              </FormControl>
            </FormItem>
          )}
          name={"report"}
          control={form.control}
        />
        <GradientBtn
          short
          label={"Submit"}
          className="!rounded-full !font-bold !text-sm !px-[17px]"
        />
      </form>
    </Form>
  );
}

const level = [
  {
    label: "Spam",
  },
  {
    label: "Misinformation",
  },
  {
    label: "Abusive",
  },
  {
    label: "Inappropriate",
  },
];
