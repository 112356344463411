// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-item:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.93) 0%,
      rgba(255, 255, 255, 0.93) 100%
    ),
    #fbbc05;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Notifications/Notifications.css"],"names":[],"mappings":"AAAA;EACE;;;;;WAKS;AACX","sourcesContent":[".notification-item:hover {\n  background: linear-gradient(\n      0deg,\n      rgba(255, 255, 255, 0.93) 0%,\n      rgba(255, 255, 255, 0.93) 100%\n    ),\n    #fbbc05;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
