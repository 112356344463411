import { HTMLAttributes } from "react";
import { cn } from "../../../helpers/utils";

export const Stat = ({
  className,
  user,
  isUser,
}: {
  className?: string;
  user: any;
  isUser?: any;
} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn("gap-2 flex", className, {})}>
      <div
        style={{
          backgroundImage: `url(/assets/imgs/home/fragVerify1.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundBlendMode: "overlay",
        }}
        className="w-full md:p-1 border flex flex-col justify-center rounded-lg bg-slate-200 border-amber-200  shadow-md"
        key={1}
      >
        <p className="text-base font-semibold text-center">{user.noOfSold}</p>
        <p className=" text-xs font-medium text-center">{"# Sold"}</p>
      </div>
      {!isUser && (
        <div
          style={{
            backgroundImage: `url(/assets/imgs/home/fragVerify1.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundBlendMode: "overlay",
          }}
          className="w-full md:p-1 border flex flex-col justify-center rounded-lg bg-slate-200 border-amber-200  shadow-md"
          key={1}
        >
          <p className="text-base font-semibold text-center">
            ${user.balance /100}
          </p>
          <p className=" text-xs font-medium text-center">{"# Balance"}</p>
        </div>
      )}
      <div
        style={{
          backgroundImage: `url(/assets/imgs/home/fragVerify1.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundBlendMode: "overlay",
        }}
        className="w-full md:p-1 border flex flex-col justify-center rounded-lg bg-slate-200 border-amber-200  shadow-md"
        key={2}
      >
        <p className=" text-base font-semibold text-center">{user.noOfSales}</p>
        <p className=" text-xs font-medium text-center">{"For Sale"}</p>
      </div>
      <div
        style={{
          backgroundImage: `url(/assets/imgs/home/fragVerify1.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundBlendMode: "overlay",
        }}
        className="w-full md:p-1 border flex flex-col justify-center rounded-lg bg-slate-200 border-amber-200  shadow-md"
        key={3}
      >
        <p className="text-base font-semibold text-center">{user.noOfBuys}</p>
        <p className=" text-xs font-medium text-center">{"# Buys"}</p>
      </div>
    </div>
  );
};
