import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useAuthUser from "../../../../hooks/getAuthUser";
import Feedback from "../../../../interfaces/Feedback";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { addBuyerFeedback } from "../../../../redux/features/userTransactions/orders/userOrdersSlice";
import { addSellerFeedback } from "../../../../redux/features/userTransactions/purchases/userPurchasesSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import GradientBtn from "../../../buttons/GradientBtn";
import DialogHeader from "../../../common/dialog-header";
import { Loading } from "../../../common/Widget/Loading";
import { Form } from "../../../ui/form";

const formSchema = z.object({
  userId: z.string(),
  purchaseId: z.string(),
  feedbackRate: z.coerce.number(),
});

interface FeedbackProps {
  inputAssets: Feedback[];
  id: any;
  order?: any;
}

const FeedbackForm: React.FC<FeedbackProps> = ({ inputAssets, id, order }) => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { feedbackError, feedbackLoading } = useAppSelector(
    (state) => state.userPurchases
  );
  const { feedbackOError, feedbackOLoading } = useAppSelector(
    (state) => state.userOrders
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const [q1, setQ1] = React.useState(4);
  const [q2, setQ2] = React.useState(4);
  const [q3, setQ3] = React.useState(4);

  function onSubmit(values: any) {
    {
      order
        ? dispatch(addBuyerFeedback(values))
            .then(() => {
              dispatch(
                notifySelect({
                  title: "Feedback Sent!",
                  type: 0,
                  open: true,
                })
              );
              mixpanel.track("Leave review (Order)", {
                ...values,
                email: user ? user["email"] : "",
              });
            })
            .finally(() => window.location.reload())
        : dispatch(addSellerFeedback(values))
            .then(() => {
              dispatch(
                notifySelect({
                  title: "Feedback Sent!",
                  type: 0,
                  open: true,
                })
              );
              mixpanel.track("Leave review (Purchase)", {
                ...values,
                email: user ? user["email"] : "",
              });
            })
            .finally(() => window.location.reload());
    }
  }

  useEffect(() => {
    const score = Math.round(((q1 + q2 + q3) / 3) * 10) / 10 + 1;
    form.setValue("userId", userId);
    form.setValue("purchaseId", id);
    form.setValue("feedbackRate", score);
    if (feedbackError || feedbackOError) {
      dispatch(
        notifySelect({
          title: "Error Sending",
          type: 1,
          open: true,
        })
      );
    }
  }, [feedbackError, q1, q2, q3, feedbackOError, id]);
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogHeader />
        <div className="mt-3 ">
          <h1 className="text-xl mb-4 text-center xs:w-fit m-auto">
            {order ? "Seller Feedback" : "Buyer Feedback"}
          </h1>
          <div>
            <div className="my-2">
              <h1 className="text-base mb-1  xs:w-fit m-auto">
                {inputAssets[0].placeholder}
              </h1>
              <div className="flex gap-2 ">
                {Array.from(Array(5).keys()).map((_) => (
                  <div
                    className={clsx(
                      q1 == _
                        ? "w-[22px] h-[22px] bg-orange-400 text-white grid place-content-center font-semibold rounded-full"
                        : "cursor-pointer w-[22px] h-[22px] bg-gray-800 text-white grid place-content-center font-semibold rounded-full"
                    )}
                    key={_}
                    onClick={() => setQ1(_)}
                  >
                    {_ + 1}
                  </div>
                ))}
              </div>
            </div>
            <div className="my-2">
              <h1 className="text-base mb-1  xs:w-fit m-auto">
                {inputAssets[1].placeholder}
              </h1>
              <div className="flex gap-2 ">
                {Array.from(Array(5).keys()).map((_) => (
                  <div
                    className={clsx(
                      q2 == _
                        ? "w-[22px] h-[22px] bg-orange-400 text-white grid place-content-center font-semibold rounded-full"
                        : "cursor-pointer w-[22px] h-[22px] bg-gray-800 text-white grid place-content-center font-semibold rounded-full"
                    )}
                    key={_}
                    onClick={() => setQ2(_)}
                  >
                    {_ + 1}
                  </div>
                ))}
              </div>
            </div>
            <div className="my-2">
              <h1 className="text-base mb-1  xs:w-fit m-auto">
                {inputAssets[2].placeholder}
              </h1>
              <div className="flex gap-2 ">
                {Array.from(Array(5).keys()).map((_) => (
                  <div
                    className={clsx(
                      q3 == _
                        ? "w-[22px] h-[22px] bg-orange-400 text-white grid place-content-center font-semibold rounded-full"
                        : "cursor-pointer w-[22px] h-[22px] bg-gray-800 text-white grid place-content-center font-semibold rounded-full"
                    )}
                    key={_}
                    onClick={() => setQ3(_)}
                  >
                    {_ + 1}
                  </div>
                ))}
              </div>
            </div>
            {feedbackLoading || feedbackOLoading ? (
              <Loading />
            ) : (
              <GradientBtn
                type="submit"
                label={"Submit Review"}
                className={"mx-auto mt-5"}
              />
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

export default FeedbackForm;
