import { HTMLAttributes, useEffect, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Chip } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import {
  FreeMode,
  Navigation,
  Thumbs,
  Zoom,
  EffectFade,
  Pagination,
} from "swiper";
import { cn } from "../../../../helpers/utils";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Icon } from "@iconify/react";

const OilCarousel = ({
  className,
  imgs,
  item,
  md,
  lg,
  ...props
}: {
  className?: string;
  imgs?: any;
  item?: boolean;
  md?: any;
  lg?: any;
} & HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch();

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();

  const images = [
    imgs,
    "/assets/imgs/home/pic1.png",
    "/assets/imgs/home/pic2.png",
    "/assets/imgs/home/pic5.png",
    "/assets/imgs/home/pic4.png",
    "/assets/imgs/home/pic6.png",
    "/assets/imgs/home/pic3.png",
  ];

  function getRandomNumber() {
    return Math.floor(Math.random() * (35 - 18 + 1)) + 18;
  }

  return (
    <>
      <div className="hidden lg:flex h-full">
        <Swiper
          //@ts-ignore
          onSwiper={setThumbsSwiper}
          spaceBetween={5}
          direction={"vertical"}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="h-full"
        >
          {images?.map((_: any) => (
            <SwiperSlide
              tabIndex={0}
              className="ring-accentYellow-500 !h-[5em] !w-[5em] !mb-2 [&:where(.swiper-slide-thumb-active)]:ring m-0.5 rounded-lg"
              key={_}
            >
              <img
                alt={_}
                className={cn(
                  "h-[5em] !w-[5em] cover  rounded-lg shadow-lg cursor-pointer"
                )}
                src={`${_}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div
        {...props}
        className={cn(
          "w-full  overflow-hidden border-none  select-none rounded-lg bg-white shadow-lg shadow-slate-100/50 max-md:mb-2",
          {}
        )}
      >
        <Swiper
          loop={true}
          effect={"fade"}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, FreeMode, Thumbs, Zoom]}
          spaceBetween={10}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          zoom={true}
          className={`overflow-hidden w-full h-full `}
        >
          {images?.map((_: any) => (
            <SwiperSlide
              className="rounded swiper-zoom-container  p-1 w-full"
              key={_}
            >
              <Chip
                icon={
                  <Icon className={"text-2xs"} icon={"hugeicons:sale-tag-01"} />
                }
                sx={{
                  bgcolor: "background.paper",
                  fontSize: "10px",
                }}
                className="text-2xs absolute top-1 left-4"
                color="error"
                size="small"
                label={`${getRandomNumber()}+ Sold`}
                variant="outlined"
              />
              <Icon
                icon={"solar:heart-linear"}
                className="text-3xl absolute top-1 right-4"
              />
              <img
                alt=""
                className="object-cover rounded h-[20em] lg:h-[45em]"
                src={`${_}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default OilCarousel;
