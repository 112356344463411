import { HTMLAttributes } from "react";
import { cn } from "../../../../helpers/utils";
import { Icon } from "@iconify/react";
import friendlyTime from "../../../../helpers/friendlyTime";
import { Link } from "react-router-dom";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import DialogWrapper from "../../../common/dialog-wrapper";
import mixpanel from "mixpanel-browser";

interface AuthorProps extends HTMLAttributes<HTMLDivElement> {
  isPromoted?: boolean;
  info: any;
}

export default function Author({ info, className, ...props }: AuthorProps) {
  return (
    <section
      className={cn(
        "flex items-center gap-1 max-sm:border-b border-zinc-100",
        className,
        {}
      )}
      {...props}
    >
      <Link to={`/user/${info.author.id}/gear`}>
        <img
          src={`/assets/imgs/avatar/${info.author.avatar}.svg`}
          alt=""
          height={26}
          width={26}
          className="rounded-full"
        />
      </Link>
      <div className="flex gap-1 max-sm:-space-y-1 sm:items-center grow max-sm:flex-col">
        <Link to={`/user/${info.author.id}/gear`}>
          <div className="flex items-center gap-2">
            <p className="text-sm font-semibold leading-tight">
              {info.author.username}
            </p>
          </div>
        </Link>
        <div className="flex items-center gap-1 text-xs font-medium text-neutral-400">
          <Icon icon="lucide:dot" className="max-sm:hidden" />
          <p>
            {props.isPromoted ? (
              "Promoted"
            ) : (
              <div className="flex items-center">
                <img
                  className="w-3 h-3"
                  src="/assets/vectors/home/flower.svg"
                  alt="flower"
                />
                <p className="ml-1 text-2xs">{info.author.muzPoints}</p>
              </div>
            )}
          </p>
          {props.isPromoted ? null : (
            <>
              <Icon icon="lucide:dot" />
              <p>{friendlyTime(info.createdDateTime)}</p>
            </>
          )}
        </div>
      </div>
      {props.isPromoted ? null : (
        <DialogWrapper
          wrapperClassName="md:min-w-[500px]"
          className="w-fit"
          content={<CopyLink type="new" id={info.id} />}
        >
          <button
            onClick={() => mixpanel.track("Topic share button clicked")}
            className="ml-2 flex items-baseline gap-2 rounded-lg bg-green-500 px-3 py-1.5 text-base font-bold text-white hover:opacity-75 border-2 border-white"
          >
            <Icon
              icon={"bi:share-fill"}
              className="text-lg text-white mx-auto"
            />
          </button>
        </DialogWrapper>
      )}
    </section>
  );
}
