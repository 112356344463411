import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { taxes } from "../../../data/states";
import { useAppSelector } from "../../../redux/hooks";
import Card from "../../common/Card";
import EmptyCart from "../../common/Error/EmptyCart";

import { OrderSummaryMob } from "../Cart/order-summary-mob";

const CheckoutLayout = () => {
  const { cartData, cartTotal } = useAppSelector((state) => state.cart);

  const [page, setPage] = useState(
    window.location.href.indexOf("confirmation") != -1 ? true : false
  );
  const [total, setTotal] = useState(
    cartData.length < 1
      ? 0
      : cartData
          .map((i: any) => i.price * i.total)
          .reduce((a: any, b: any) => a + b)
  );

  const totPrice = total + 4;

  return (
    <div className="lg:mt-10 mb-[8em]">
      <div className="grid md:grid-cols-12 gap-5">
        <Card className="col-span-full px-3.5 py-3.5 md:px-8 md:py-5 md:col-span-8 bg-white">
          {!page ? <OrderSummaryMob /> : null}
          {cartData.length < 1 ? <EmptyCart /> : <Outlet />} <br />
          <div className="container-mini border-t my-8 py-3.5 border-border flex flex-wrap gap-5">
            {links.map((el) => (
              <Link
                to={el.href}
                className="text-sky-700 text-[10px] md:text-xs "
                key={el.label}
              >
                {el.label}
              </Link>
            ))}
          </div>
        </Card>
        {!page ? (
          <Card className="max-h-96 md:col-span-4 max-md:hidden flex flex-col justify-between bg-white">
            <div className="">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-[18px] font-medium">Summary</h3>
              </div>

              <div className="text-accentGray-200 text-xs font-medium">
                {cartData.length} items
              </div>

              <div>
                {cartData.map((el: any) => (
                  <div className="flex justify-between font-medium gap-3 my-3">
                    <div>
                      {el.total} x {el.name}
                    </div>
                    <div className="flex-shrink-0">$ {el.price * el.total}</div>
                  </div>
                ))}
              </div>
              <hr className="border-accentGray-600 my-4" />
              <div>
                <div className="flex justify-between gap-3 my-3 font-medium">
                  <div>Shipping</div>
                  <div className="flex-shrink-0">$ 4.00</div>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center my-2">
              <div className="font-medium text-base">Total</div>
              <div className="font-medium text-base">${totPrice}</div>
            </div>
          </Card>
        ) : null}
      </div>
    </div>
  );
};

export default CheckoutLayout;

const links = [
  { label: "Refund Policy", href: "" },
  { label: "Shipping Policy", href: "" },
  { label: "Privacy Policy", href: "" },
];
