import { Icon } from "@iconify/react";

import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import { useEffect, useState } from "react";
import Select from "../../common/select";
import DialogWrapper from "../../common/dialog-wrapper";
import CreateCommunityModal from "./create-community-modal";
import { categoriesData } from "../Home/Categories/categoriesData";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Plus } from "phosphor-react";
import GreyBtn from "../../buttons/GreyBtn";
import GreyBtn2 from "../../buttons/GreyBtn2";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";

export default function Filter() {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const category = useAppSelector((state) => state.communities);
  const [selectedInstrument, setSelectedInstrument] = useState(
    category.communityCategory
  );

  return (
    <section className=" jusitfy-between my-2 md:my-4 ">
      <DialogWrapper content={<CreateCommunityModal />} className="justify-end">
        <GradientBtn
          label={
            <p className=" flex items-center gap-2.5 hover:opacity-75 h-12">
              {" "}
              <Plus className="w-5 h-6 text-bold" />
              <span className="text-white text-xs capitalize">
                Create Shopping Community
              </span>
            </p>
          }
          onClick={() =>
            mixpanel.track("Create community button clicked", {
              email: user ? user["email"] : "",
            })
          }
          className="flex items-center md:h-full h-12 gap-1 w-full rounded-lg shadow"
        />
      </DialogWrapper>
    </section>
  );
}

const data = [
  {
    label: "Categories",
    options: categoriesData,
  },
  {
    label: "Sub-Communities",
    options: categoriesData,
  },
  {
    label: "Type",
    options: [
      { label: "Private", icon: "/assets/imgs/home/lock.png" },
      { label: "Public", icon: "/assets/vectors/navbar/SOCIAL.svg" },
      { label: "Restrict", icon: "/assets/vectors/navbar/SOCIAL.svg" },
    ],
  },
];
