import React from 'react'
import GradientBtn from '../../buttons/GradientBtn';
import Widget from '../Widget';

const NotAdded = ({m1, m2, m3}: any) => {
  return (
    <div className="max-w-screen-xl mx-auto px-4 flex mt-3 justify-start h-60 md:px-8">
      <div className="max-w-lg mx-auto space-y-3 text-center">
        <h3 className="text-orange-600 font-semibold">{m1}</h3>
        <p className="text-gray-800 text-4xl font-semibold sm:text-5xl">
         {m2}
        </p>
        <p className="text-gray-600">
          {m3}
        </p>
      </div>
   
    </div>
  );
}

export default NotAdded