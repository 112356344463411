import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { removeWishlist } from "../../../../redux/features/userWishlist/wishlistSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import GradientBtn from "../../../buttons/GradientBtn/GradientBtn";
import { Loading } from "../../../common/Widget/Loading";
import { Form } from "../../../ui/form";


type DeleteGearProps = ComponentProps<"div"> & { index: any };

const formSchema = z.object({
  userId: z.string(),
  prodId: z.string(),
});

export function RemoveFromWatchlist({ className, index, ...props }: DeleteGearProps) {
  const { userId } = useAppSelector((state) => state.userProfile);
  const { addLoading, addError } = useAppSelector(
    (state) => state.userWishlist
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
const dispatch = useAppDispatch();
  function onSubmit(values: any) {
    dispatch(removeWishlist(values))
      .then(() =>
        dispatch(
          notifySelect({
            title: "Frag Removed!",
            type: 0,
            open: true,
          })
        )
      )
      .finally(() => window.location.reload());
  }
  useEffect(() => {
    form.setValue("userId", userId);
    form.setValue("prodId", index);
    if (addError) {
      dispatch(
        notifySelect({
          title: "Error Removing",
          type: 1,
          open: true,
        })
      );
    }
  }, [addError, userId]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-3 font-medium">Remove Item</p>
        <hr className="my-6" />
        <div className="text-zinc-900 font-medium">
          Are you sure you want to remove this item?
        </div>{" "}
        <div className="-mx-6 p-3.5 mt-10 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
          <button className="text-zinc-600 !text-sm border border-current py-2 px-[17px]  rounded-full font-bold ">
            Cancel
          </button>
          {addLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              type="submit"
              label={"Remove Item"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}
