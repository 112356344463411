import { Icon } from "@iconify/react";
import * as Tabs from "@radix-ui/react-tabs";
import {  useEffect, useState } from "react";
import GreyBtn2 from "../../../buttons/GreyBtn2";
import YellowBtn from "../../../buttons/YellowBtn";
import Photos from "./description";
import Pricing from "./pricing";
import { ProductInfo } from "./product-info";
import Review from "./review";
import Information from "./shipping";
import { useOktaAuth } from "@okta/okta-react";
import SellerFlags from "../../Home/Flags/SellerFlag";
import { useAppSelector } from "../../../../redux/hooks";
import Card from "../../../common/Card";
import Skeleton from "@mui/material/Skeleton";

const Listing = () => {
  const { authState } = useOktaAuth();
  const { profileData, loading } = useAppSelector((state) => state.userProfile);
  const [value, setValue] = useState("Choose Fragrance");
  const [sizes, setSizes] = useState([
    {
      label: "SMPL",
      size: 0.07,
    },
    {
      label: "TRAVEL",
      size: 1.7,
    },
    {
      label: "FULL",
      size: 3.4,
    },
  ]);


  
  
   if (value === "Choose Fragrance" && !authState?.isAuthenticated) {
     return <SellerFlags shop={true} />;
   }
  return (
    <Tabs.Root value={value} onValueChange={setValue}>
      <div className="grid grid-cols-4">
        <div className="col-span-3 max-md:col-span-full pr-2.5">
          <Tabs.Content value="Choose Fragrance">
            <ProductInfo setValue={setValue} setSizes={setSizes} />
          </Tabs.Content>
          <Tabs.Content value="Information" asChild>
            <>
              <Information setValue={setValue} sizes={sizes} />
            </>
          </Tabs.Content>
          <div className="flex flex-col h-full">
            <div className="grow">
              <Tabs.Content value="Photos & Desc">
                <Photos setValue={setValue} />
              </Tabs.Content>

              <Tabs.Content value="Pricing" asChild>
                <>
                  <Pricing setValue={setValue} />
                </>
              </Tabs.Content>
              <Tabs.Content value="Review" asChild>
                <>
                  <Review setValue={setValue} />
                </>
              </Tabs.Content>
            </div>
          </div>
        </div>

        <Tabs.List className="border-l max-md:hidden pl-1.5 flex flex-col gap-20 relative items-start h-fit border-slate-400/20 lg:mt-[6em]">
          <div className="absolute w-px h-full bg-accentYellow-500 left-[18px]" />
          {steps.map((_) => (
            <Tabs.Trigger
              key={_}
              value={_}
              disabled
              className="relative z-0 flex items-center gap-3 mx-1 group"
            >
              <div className=" w-[18px] grid place-content-center h-[18px] group-aria-selected:bg-white bg-accentYellow-500 rounded-full border border-yellow-500">
                <Icon
                  icon={"material-symbols:check"}
                  className="text-sm text-white"
                />
              </div>
              <p className="text-sm font-medium leading-[18px]">{_}</p>
            </Tabs.Trigger>
          ))}
        </Tabs.List>
      </div>
    </Tabs.Root>
  );
};

export default Listing;

const steps = [
  "Choose Fragrance",
  "Information",
  "Photos & Desc",

  "Pricing",
  "Review",
];
