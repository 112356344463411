import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { RemoveListing } from "./RemoveListing";
import DialogWrapper from "../../../common/dialog-wrapper";
import { BoostListing } from "./BoostListing";
import { Link } from "react-router-dom";
import Loader from "../../../common/Loader/loader";
import { OfferListing } from "./OffersListing";
import { EditListing } from "./EditListing";

export const Listing = ({ info }: { info: any }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (imgs: any) => {
      try {
        const response = await axios.get(
          `https://webservice.pairrit.com/pairrit-ws/api/getsignedurl?filenames=${imgs}`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData(info.images.toString());
  }, [window]);
  return (
    <div
      className="border rounded-md border-solid bg-accentGray-30 border-accentGray-950 mb-2"
      key={"listing-"}
    >
      <div className="p-4 rounded-md text-sm">
        <div className="flex items-end sm:items-start justify-between gap-6">
          <Link to={`/single/listing/${info.postId}`}>
            <div className="flex flex-col sm:flex-row gap-4 items-start text-accentGray-60">
              {loading ? (
                <Loader />
              ) : (
                <img
                  width={128}
                  className="rounded-lg max-md:h-[84px] object-cover aspect-square"
                  src={data[0]}
                  alt=""
                />
              )}
              <div>
                <div className="font-bold underline">{info.name}</div>
                <div className="text-accentGray-700 text-xs sm:text-xs font-medium">
                  Order Number: {info.id}
                </div>
                <div className="text-accentGray-700 text-xs sm:text-xs font-medium">
                  Quantity: {info.quantity}
                </div>
                <div className="text-accentGray-700 text-xs sm:text-xs font-medium">
                  Condition: {info.condition}
                </div>
              </div>
            </div>
          </Link>
          <div className="text-end flex-shrink-0 text-xs ">
            <div className="text-lg font-bold">${info.price}</div>
            <div>{info.category}</div>
            <div>{info.watchers} Watchers</div>
            <div className="underline">2 Offers</div>
            <div className="flex gap-1 ">
              Boosted
              {info.is3PercentFromFinalSellingPrice ? (
                <Icon
                  icon={"lets-icons:check-fill"}
                  className="text-green-500 text-lg"
                />
              ) : (
                <Icon
                  icon={"ep:warning-filled"}
                  className="text-red-500 text-base"
                />
              )}
            </div>
            <div className="flex gap-1">
              Offers
              {info.isAcceptOffers ? (
                <Icon
                  icon={"lets-icons:check-fill"}
                  className="text-green-500 text-lg"
                />
              ) : (
                <Icon
                  icon={"ep:warning-filled"}
                  className="text-red-500 text-base"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-solid border-accentGray-150 py-3 px-4 flex gap-5 flex-wrap justify-between items-center">
        {/* <div className="text-accentBlue-300 font-bold md:text-sm text-xs">
          LIVE
        </div> */}
        <div className="flex flex-wrap flex-nowrap gap-1 md:gap-4 md:text-xs text-2xs">
          <DialogWrapper
            wrapperClassName="md:min-w-[500px]"
            className="w-fit"
            content={<EditListing info={info} />}
          >
            <button className="flex gap-2 hover:bg-slate-100 items-center bg-white border-[2px] border-solid border-accentGray-950 h-10 px-4 md:px-6 rounded-full font-bold">
              Edit
            </button>
          </DialogWrapper>
          <DialogWrapper
            wrapperClassName="md:min-w-[500px]"
            className="w-fit"
            content={<RemoveListing id={info.id} />}
          >
            <button className="flex gap-2 hover:bg-slate-100 items-center bg-white border-[2px] border-solid border-accentGray-950 h-10 px-4 md:px-6 rounded-full font-bold">
              Unlist
            </button>
          </DialogWrapper>
          <DialogWrapper
            wrapperClassName="md:min-w-[500px]"
            className="w-fit"
            content={<OfferListing id={info.id} type={info.isAcceptOffers} />}
          >
            <button className="flex gap-2 hover:bg-slate-100 items-center bg-white border-[2px] border-solid border-accentGray-950 h-10 px-4 md:px-6 rounded-full font-bold">
              {false ? "Remove Offers" : "Accept Offers"}
            </button>
          </DialogWrapper>
          <DialogWrapper
            wrapperClassName="md:min-w-[500px]"
            className="w-fit"
            content={
              <BoostListing
                id={info.id}
                type={info.is3PercentFromFinalSellingPrice}
              />
            }
          >
            <button className="flex gap-2 hover:bg-slate-100 items-center bg-white border-[2px] border-solid border-accentGray-950 h-10 px-4 md:px-6 rounded-full font-bold">
              {info.is3PercentFromFinalSellingPrice
                ? "Remove Boost"
                : "Add Boost"}
            </button>
          </DialogWrapper>
        </div>
      </div>
    </div>
  );
};
