import * as Switch from "@radix-ui/react-toggle-group";
import { ComponentProps } from "react";
import { cn } from "../../../helpers/utils";
import GreyBtn from "../../buttons/GreyBtn";
import { Checkbox } from "../Create-Post/Listing/shipping";
import { Action } from "./action";
import { BillingExt } from "./billing-ext";
import { Breadcrumb } from "./breadcrumb";
import { DataTable } from "./data-table";
import { Input } from "./input";
import { Methods } from "./methods";
import { PaymentExt } from "./payment-ext";

type PaymentProps = ComponentProps<"div">;

export function Payment({ className, ...props }: PaymentProps) {
  return (
    <div className={cn(className)} {...props}>
      <Breadcrumb current="payment" />

      <PaymentExt info={content} />
      <br />
      <BillingExt />
      <br />
      {/* <h4 className="text-[17px] font-semibold my-2.5">Remember me</h4>
      <div className="rounded-md border container-mini border-border p-2.5">
        <Checkbox label="Save my information for a faster checkout" />
      </div> */}
      <br />
      <Action
        to="confirmation"
        from="Info"
        fromLink="/checkout/shipping"
        toLink="/checkout/confirmation"
      />
    </div>
  );
}

const content = [
  {
    icon: "ic:baseline-gpp-good",
    title: "Seller's",
    desc: "Verified",
  },
  {
    icon: "bi:send-check",
    title: "Free Shipping",
    desc: "Over $149+",
  },
  {
    icon: "healthicons:eco-care",
    title: "Fast Delivery",
    desc: "2-3 Days",
  },
];


