import { Icon } from "@iconify/react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { useState } from "react";
import { cn } from "../../../helpers/utils";
import GradientBtn from "../../buttons/GradientBtn";
import { Input } from "./input";

type PaymentExtProps = {};
export function PaymentExt({ ...props }: PaymentExtProps) {
  const [state, setState] = useState("credit");
  return (
    <>
      <div className="grid grid-cols-3 md:lg:xl:grid-cols-3 group bg-slate-100 rounded-lg shadow-xl shadow-neutral-100 border ">
        {content.map((_, idx) => (
          <div className="p-5 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
            <Icon
              icon={_.icon}
              className="text-green-500 text-3xl md:text-4xl shadow-xl shadow-neutral-100"
            />
            <p className=" text-sm md:text-md font-medium text-slate-700 mt-3">{_.title}</p>
            <p className="mt-2 text-xs text-slate-500">{_.desc}</p>
          </div>
        ))}
      </div>
    </>
  );
}




const content = [
  { icon: "bi:send-check", title: "We Authenticate", desc: "100%" },
  {
    icon: "mdi:battery-charging-100",
    title: "We Verify",
    desc: "Listing Match 5/5",
  },
  { icon: "ic:baseline-gpp-good", title: "No Fake's", desc: "Unless dupes, No Defaults" },
  {
    icon: "healthicons:eco-care",
    title: "PackageCare",
    desc: "Repackaged with care",
  },
  {
    icon: "fluent-emoji-high-contrast:feather",
    title: "Community",
    desc: "Shopping Communities",
  },
  {
    icon: "ph:trend-up-duotone",
    title: "Earn Points",
    desc: "Get FlexPoints",
  },
];