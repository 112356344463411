import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface FeedState {
  loading: boolean;
  error: string | null;
}

const initialState: FeedState = {
  loading: false,
  error: null,
};

export const createTopicPost = createAsyncThunk(
  "post/topic",
  async ({
    authorId,
    community,
    title,
    postCategory,
    topicType,
    description,
    createdDateTime,
    images,
  }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/topics`,
      {
        authorId: authorId,
        title: title,
        community: community,
        images: images,
        postCategory: postCategory,
        topicType: topicType,
        description: description,
        isEnablePostReplyNotification: true,
        reviewed: true,
        isDraft: false,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.status;
  }
);

const topicSlice = createSlice({
  name: "post/topic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTopicPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTopicPost.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createTopicPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const {} = topicSlice.actions;

export const topicReducer = topicSlice.reducer;
