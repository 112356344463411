import { zodResolver } from "@hookform/resolvers/zod";
import mixpanel from "mixpanel-browser";
import { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import useAuthUser from "../../../../hooks/getAuthUser";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { removeProduct } from "../../../../redux/features/userStore/userStoreSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { AppDispatch } from "../../../../redux/store";
import GradientBtn from "../../../buttons/GradientBtn";
import { Loading } from "../../../common/Widget/Loading";
import { Form } from "../../../ui/form";

const formSchema = z.object({
  postId: z.string(),
  
});

interface DeleteProdModalProps extends ComponentProps<"form"> {
  id: any;
}
export function RemoveListing({
  className,
  id,
  ...props
}: DeleteProdModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const user = useAuthUser();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const { deleteError, deleteLoading, deleteSuccess } = useAppSelector(
    (state) => state.userStore
  );

  function onSubmit(values: any) {
    dispatch(removeProduct(values))
      .then(() =>
      {
        dispatch(
          notifySelect({
            title: "Product Removed!",
            type: 0,
            open: true,
          })
        );
        mixpanel.track("Unlist product", {
          product_id: id,
          email: user ? user["email"] : "",
        });
}
      )
      .finally(() => window.location.reload());
  }
  useEffect(() => {
    form.setValue("postId", id);
    if (deleteError) {
      dispatch(
        notifySelect({
          title: "Error Removing",
          type: 1,
          open: true,
        })
      );
    }
    if (deleteSuccess) {
      dispatch(
        notifySelect({
          title: "Product Removed!",
          type: 0,
          open: true,
        })
      );
    }
  }, [deleteError, deleteSuccess, id]);
  return (
    <Form {...form}>
      <form className="z-50 " onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-3 font-bold text-lg">
          Remove this listing
        </p>
        <hr className="my-6" />
        <div className="text-zinc-900 font-medium">
          Are you sure you want to remove this listing?
        </div>{" "}
        <div className="-mx-6 p-3.5 mt-10 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
          <button className="text-zinc-600 !text-sm border border-current py-2 px-[17px]  rounded-full font-bold ">
            Cancel
          </button>
          {deleteLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              type="submit"
              label={"Remove"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}
