import * as Dialog from "@radix-ui/react-dialog";
import { HTMLAttributes, useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import { cn } from "../../../../helpers/utils";
import GradientBtn from "../../../buttons/GradientBtn";
import { Link } from "react-router-dom";

interface DialogWrapperProps extends HTMLAttributes<HTMLDivElement> {
  wrapperClassName?: string;
}

export default function GuestOnBoardWrapper({
  className,
  content,
  wrapperClassName,
  children,
  ...props
}: DialogWrapperProps) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 200000);
  }, []);

  return (
    <Dialog.Root onOpenChange={setOpen}>
      <Dialog.Trigger className={cn("block w-full", className)}>
        {children}
      </Dialog.Trigger>{" "}
      <Dialog.Overlay className="fixed z-[9999] inset-0 " />
      <AnimatePresence>
        {open && (
          <Dialog.Content
            forceMount
            asChild
            className={cn(
              "fixed z-[10000] inset-0 m-auto h-fit md:w-fit p-2 bg-transparent",
              wrapperClassName,
              {}
            )}
            {...props}
          >
            <motion.div
              initial={{ y: 100, scale: 0.5, opacity: 0 }}
              exit={{ y: 0, scale: 0, opacity: 0 }}
              animate={{ y: 0, scale: 1, opacity: 1 }}
            >
              <div className="grid  w-full place-items-center">
                <div className="absolute left-1/2 top-1/2 h-96 w-80 -translate-x-1/2 -translate-y-1/2 rotate-6 rounded-2xl bg-orange-400"></div>

                <div className="absolute left-1/2 top-1/2  w-80 -translate-x-1/2 -translate-y-1/2 rotate-0 space-y-6 rounded-2xl mobile-post-btn p-3 transition duration-300 shadow-lg">
                  <div className="flex justify-end">
                    <Dialog.Close
                      asChild
                      className="absolute right-3 cursor-pointer  top-3"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 27 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.4814 13.8174L22.878 6.4195L20.9016 4.44336L13.5039 11.8402L6.10625 4.44336L4.12988 6.4195L11.5276 13.8185L4.12988 21.2154L6.10625 23.1915L13.5039 15.7947L20.9016 23.1915L22.878 21.2154L15.4814 13.8174Z"
                          fill="#878A8C"
                        />
                      </svg>
                    </Dialog.Close>
                  </div>
                  <div>
                    <img
                      src="/assets/imgs/common/logo.png"
                      className="w-16 mx-auto"
                      alt=""
                    />
                    <h4 className="text-lg font-medium text-center text-white my-0">
                     #1 Fragrance Marketplace!
                    </h4>
                  </div>

                  <div className="grid grid-cols-12 items-center justify-items-center md:gap-5">
                    <div className="col-span-6 order-1 flex flex-col pl-2">
                      <p className="text-3xl font-bold  bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-700 ">
                        Join Sellers Waitlist
                      </p>
                      <p className="text-xl text-white font-semibold md:text-2xl">
                        We are a Fragrance Marketplace
                      </p>
                    </div>
                    <div className="order-2 col-span-6">
                      <img
                        className=" h-[150px] w-[250px] object-cover bg-transparent h-[100px] "
                        src="/assets/imgs/home/shopit.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="mt-1">
                    <Link className="my-2" to="/create/sell">
                      <GradientBtn
                        className={" mx-auto w-full"}
                        label={"Join Now And Start Selling!"}
                      />
                    </Link>

                    <div className="text-xs text-center pl-1 mt-1 font-semibold text-stone-100">
                      Earn 50% Off Seller Fees For LIFE!
                    </div>
                  </div>

                  <div className=" gap-2 w-full mx-auto">
                    {options.map((el, index) => (
                      <div className="bg-zinc-200 border border-solid border-accentGray-600  px-1 text-center rounded-lg">
                        <div className="text-xs font-semibold ">{el.label}</div>
                        {/* <hr className="v2 my-1" />
                        <div className="mt-1 text-[10px]">
                          <div className="font-medium my-2">{el.desc2}</div>
                        </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          </Dialog.Content>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
}

const options = [
  {
    label: "Discover Fragrances, Share The Experience",
    desc: "Protection ",
    desc2: "We Protect: No Scams, fakes or knockoffs",
  },
];
