import { Icon } from "@iconify/react";
import * as RdxSelect from "@radix-ui/react-select";
import { HtmlHTMLAttributes, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { addExistingParamsValue } from "../../pages/Search/filter-mob-full";

export default function SortbySelect({
  options,
  onChange,
  ...props
}: {
  onChange?: (value: any) => void;
  options: {
    label: string;
    action?: string;
  }[];
  wrapperClass?: string;
  placeholder?: string;
  } & HtmlHTMLAttributes<HTMLDivElement>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort");
  const [value, setValue] = useState(sort ? sort : "Most Popular");
  const onSelect = (value: any) => {
    setValue(value.label);
     setSearchParams(addExistingParamsValue(searchParams, "sort", value.value));
  };
  return (
    <div className={cn("", props.wrapperClass)}>
      <RdxSelect.Root onValueChange={onSelect}>
        <RdxSelect.Trigger
          className={cn(
            "outline-none h-10 w-24 bg-gradient-to-l from-red-500 to-yellow-500 text-white  rounded-full border-2 border-white shadow gap-4 px-3.5 flex capitalize items-center whitespace-nowrap justify-between text-xs font-medium leading-[18px]",
            props.className
          )}
        >
          <RdxSelect.Value className="whitespace-nowrap">
            {value}
          </RdxSelect.Value>
          <RdxSelect.Icon>
            <Icon
              icon="icon-park-outline:sort-three"
              className="text-xl text-white"
            />
          </RdxSelect.Icon>
        </RdxSelect.Trigger>

        <RdxSelect.Portal>
          <RdxSelect.Content
            position="popper"
            className="rounded-lg border border-slate-200 px-2 bg-white md:w-36 lg:w-40 justify-center py-2"
          >
            <RdxSelect.ScrollUpButton />
            <RdxSelect.Viewport className="space-y-3  capitalize ">
              {options.map((_: any) => (
                <RdxSelect.Item
                  className="font-medium cursor-pointer text-xs z-50 hover:bg-slate-100"
                  value={_}
                >
                  <RdxSelect.ItemText className="text-xs">
                    {_.label}
                  </RdxSelect.ItemText>
                </RdxSelect.Item>
              ))}
            </RdxSelect.Viewport>
            <RdxSelect.ScrollDownButton />
          </RdxSelect.Content>
        </RdxSelect.Portal>
      </RdxSelect.Root>
    </div>
  );
}
