import { useAppSelector } from "../../../../redux/hooks";
import GradientBtn from "../../../buttons/GradientBtn";
import DialogWrapper from "../../../common/dialog-wrapper";
import ComingSoon from "../../../common/Error/ComingSoon";
import Loader from "../../../common/Loader/loader";
import ReturnForm from "../Orders/ReturnModal";
import { Icon } from "@iconify/react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import { useState } from "react";
import { Loading } from "../../../common/Widget/Loading";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectPayouts,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import Card from "../../../common/Card/Card";

const MyBill = () => {
  const { authState } = useOktaAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { profileData, loading } = useAppSelector((state) => state.userProfile);
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await axios.post(
        `https://webservice.pairrit.com/pairrit-ws/api/payment/getSession/${profileData.stripeId}`
      );

      const clientSecret = await response.data;
      return clientSecret;
    };
    return loadConnectAndInitialize({
      publishableKey: `pk_live_51OUNPXEmm19RxbbmY01wHSuAqL1Y2eQppKIk71QvthJ3fGnahVrl0CTHmLVVchUtWCL79vwIC9UUxb9CWEz5IXim00iDVN9ttU`,
      fetchClientSecret: fetchClientSecret,
    });
  });
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://webservice.pairrit.com/pairrit-ws/api/payment/getAccountLink/${profileData.stripeId}`
      );
      window.location.replace(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  const updateData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://webservice.pairrit.com/pairrit-ws/api/payment/getUpdateLink/${profileData.stripeId}`
      );
      window.location.replace(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  return (
    <div className="mt-5">
      {!authState?.isAuthenticated ? (
        <ComingSoon />
      ) : loading ? (
        <Loader />
      ) : (
        <div className="lg:grid grid-cols-12 justify-between text-[#212121] text-xs py-6 border-t border-b border-solid border-l-accentGray-950">
          <div className="col-span-4">
            <div className="text-bold text-sm font-bold">
              Payout Method (via Stripe)
            </div>
            <div className="flex gap-3 mt-3">
              {profileData.detailsSubmitted ? (
                <Icon
                  icon={"lets-icons:check-fill"}
                  className="text-green-500 text-lg"
                />
              ) : (
                <Icon
                  icon={"ep:warning-filled"}
                  className="text-red-500 text-lg"
                />
              )}
              <div className="font-bold text-xs">OnBoarding</div>
            </div>
            <div className="flex gap-3 mt-1">
              {profileData.transfersEnabled ? (
                <Icon
                  icon={"lets-icons:check-fill"}
                  className="text-green-500 text-lg"
                />
              ) : (
                <Icon
                  icon={"ep:warning-filled"}
                  className="text-red-500 text-lg"
                />
              )}
              <div className="font-bold text-xs">Payouts</div>
            </div>
            <div className="flex gap-3 mt-1">
              <div className="font-bold text-xs">
                ---- Payouts Every Monday ----
              </div>
            </div>
          </div>
          <div className="col-span-8 mt-5 lg:mt-0">
            <div className="border boder-solid border-l-accentGray-950 rounded-md p-3">
              {profileData.detailsSubmitted ? (
                <div className="flex justify-between gap-2">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <GradientBtn
                      className="w-full"
                      label="Update"
                      onClick={() => updateData()}
                    />
                  )}
                </div>
              ) : (
                <GradientBtn
                  className="w-full"
                  label="Open Shop"
                  onClick={() => fetchData()}
                />
              )}
            </div>
          </div>
        </div>
      )}
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <Card className="bg-white">
          <ConnectPayouts />
        </Card>
      </ConnectComponentsProvider>
    </div>
  );
};

export default MyBill;
