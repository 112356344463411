import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchUserCommunities } from "../../../../../redux/features/userCommunites/userCommunitiesSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Loader from "../../../../common/Loader/loader";
import ForSaleTopBar from "../../../Market/for-sale-topbar";
import GearRoomProfile from "../../../My-Gear-Room/profile";
import { CommunityCard } from "../Communities";

const UserOwnedCommunities = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { userCommunities, loading } = useAppSelector(
    (state) => state.userCommunities
  );
   useEffect(() => {
     if (id) {
       dispatch(fetchUserCommunities(id));
     }
   }, [id]);
  return (
    <div>
      <ForSaleTopBar user={id} communities />
      {loading ? (
        <Loader />
      ) : (
        <section className="grid grid-cols-1 gap-5 p-4 mt-4  bg-white border rounded-lg border-slate-200 lg:grid-cols-2">
          {userCommunities?.map((_:any, idx:any) => (
            <CommunityCard props={_} idx={idx + 1} />
          ))}
        </section>
      )}
    </div>
  );
};

export default UserOwnedCommunities;
