import { ComponentProps, Fragment } from "react";
import { Link } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { useMediaQuery } from "../../../hooks/useMediaQueries";
import { useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import Card from "../../common/Card/Card";
import DialogWrapper from "../../common/dialog-wrapper";
import Loader from "../../common/Loader/loader";
import { InviteMod } from "./invite-mod";
import { RemoveMod } from "./remove-mod";

type ModeratorsProps = ComponentProps<"div">;

export function Moderators() {
  const { communityData, loading } = useAppSelector((state) => state.community);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <DialogWrapper
            wrapperClassName="md:min-w-[500px]"
            content={<InviteMod />}
          >
            <GradientBtn
              label={"Invite user as mod"}
              className={"ml-auto my-3"}
            />
          </DialogWrapper>
          {/* <section className=" mt-6 space-y-5 ">
        {mods.map((_, idx) => (
          <ModeratorCard user={_} idx={idx + 1} />
        ))}
      </section> */}
          {communityData.moderators?.map((_: any, idx: any) => (
            <ModeratorCard
              minimal
              img={_.profilePicUri}
              points={_.muzPoints}
              name={_.userName}
              idx={idx + 1}
              key={idx}
            />
          ))}
        </div>
      )}
    </>
  );
}

export const ModeratorCard = ({
  idx,
  minimal,
  name,
  img,
  link,
  points,
}: {
  idx: number;
  minimal?: boolean;
  link?: string;
  name: any;
  img: any;
  points: any;
}) => {
  const md = useMediaQuery("md");
  const Wrapper = md ? Fragment : "div";
  return (
    <Card className="border bg-white px-4  py-6 rounded-lg">
      <div className="md:flex items-center gap-2.5 text-black ">
        <Link className="flex items-center gap-2.5" to={`/user/${link}/shop`}>
          <Wrapper className="flex items-center gap-2.5">
            <p className="w-5 text-sm font-medium">0{idx}</p>
            <img
              alt=""
              className="w-[54px] h-[54px] rounded-[108.49px] shadow"
              src={`/assets/imgs/avatar/default.svg`}
            />
            <div className="flex-1">
              <p className="text-base font-semibold ">{name}</p>

              <div className="flex items-center gap-1 mt-1">
                <img src="/assets/vectors/common/flower.svg" alt="" />
                <div className="text-[11px] font-medium ">
                  {points.toLocaleString("en-US")}
                </div>
              </div>
              {/* <p className=" text-xs font-semibold">Admin</p> */}
            </div>
          </Wrapper>
        </Link>
        {!minimal && (
          <Wrapper className="flex items-center flex-wrap gap-1 ml-8 mt-3">
            <DialogWrapper
              wrapperClassName="md:min-w-[500px]"
              className="w-fit"
              content={<RemoveMod />}
            >
              <button className=" h-8 min-w-[114px] border-current px-4 border rounded-md inline-flex gap-1 md:text-sm font-semibold leading-tight text-white capitalizew-full bg-red-600 items-center text-xs">
                Remove as Mod
              </button>
            </DialogWrapper>
            <br />
            <DialogWrapper
              wrapperClassName="md:min-w-[500px]"
              className="w-fit"
              content={<InviteMod edit />}
            >
              <GradientBtn label={"Edit"} className={"h-8 min-w-[114px]"} />
            </DialogWrapper>
          </Wrapper>
        )}
      </div>
    </Card>
  );
};
