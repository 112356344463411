import React from "react";
import { Link } from "react-router-dom";
import Card from "../../../common/Card/Card";
import GradientBtn from "../../../buttons/GradientBtn";

const Flags2 = ({ m1, m2, m3, m4, m5 }: any) => {
  return (
    <Card
      noMt
      className="hidden lg:block bg-gradient-to-r from-zinc-100 to-slate-200 md:p-2 rounded-xl shadow-lg  p-0 h-[12em] overflow-hidden"
    >
      <div className=" items-center flex flex-col md:mt-0 -pt-4">
        <p className="md:text-4xl text-2xl pb-1 font-bold bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-700 ">
          {m1}
        </p>
        <div className="text-sm font-semibold text-slate-700">{m2}</div>

        <Link className="my-2" to={`/market${m5}`}>
          <button className="w-full px-10 rounded-full bg-gradient-to-l from-red-600 gap-1.5 to-orange-400 text-white  h-10 flex items-center justify-center hover:opacity-75 shadow  border-2 border-white shadow ">
            <p className="md:text-xs font-bold leading-tight text-white capitalize text-xs w-full">
              {m3}
            </p>
          </button>
        </Link>
      </div>
      <div className="flex justify-between md:-mt-[7em] h-full">
        <img
          className=" w-[450px] object-cover bg-transparent  h-full "
          src={`/assets/imgs/home/shopit${m4}.png`}
          alt="banner-pic-1"
        />
        <img
          className=" w-[250px] object-cover bg-blend-normal mt-20 z-10  justify-center h-[180px] "
          src="/assets/imgs/home/shopit5.png"
          alt="banner-pic-2"
        />
        <img
          className=" w-[450px] object-cover bg-transparent h-full "
          src={`/assets/imgs/home/shopit${m4}.png`}
          alt="banner-pic-3"
        />
      </div>
    </Card>
  );
};

export default Flags2;
