import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// const initialState = {
//   shippingAddress: {
//     email: "",
//     firstName: "",
//     lastName: "",
//     phone: "",
//     address: "",
//     city: "",
//     state: "",
//     zip: null,
//     deliveryMethod: "",
//   },

//   payment: {
//     type:"",
//     name: "",
//     cardNumber: "",
//     expDate: "",
//     zipCode: null,
//   },
//   billingAddress: {
//     phone: "",
//     address: "",
//     city: "",
//     state: "",
//     zip: null,
//   },
// };

// Define a type for the slice state
interface InitialStateType {
  fetchLoading: boolean;
  fetchError: string | null;
  orderData: any;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  orderData: null,
  fetchLoading: true,
  fetchError: null,
};

export const fetchConfirmation = createAsyncThunk(
  "checkout/Confirmation",
  async (id: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/payment/confirmation/${id}`
    );
    return response.data;
  }
);

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfirmation.pending, (state) => {
        state.fetchLoading = true;
        state.fetchError = null;
      })
      .addCase(fetchConfirmation.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.orderData = action.payload;
      })
      .addCase(fetchConfirmation.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message || "An error occurred";
      });
  },
});

export const {} = checkoutSlice.actions;

export default checkoutSlice.reducer;
