import { Icon } from '@iconify/react';
import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section className="bg-gradient-to-r from-blueGray-200 to-slate-400 pt-8 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-xl fonat-semibold text-blueGray-700">
              Let's keep in touch!
            </h4>
            <h5 className="text-sm mt-0 mb-2 text-blueGray-600">
              The fragrance marketplace for shoppers and retailers.
            </h5>
            <div className="mt-6 lg:mb-0 mb-6 flex">
              {icons.map((_, index) => (
                <Link
                  className="pt-2 text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 bg-slate-200"
                  type="button"
                  to={_.link}
                >
                  <Icon
                    icon={_.icon}
                    className="text-2xl align-items-center  mx-auto"
                  />
                </Link>
              ))}
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                  Useful Links
                </span>
                <ul className="list-unstyled">
                  {data.map((_, index) => (
                    <li>
                      <Link
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-xs"
                        to={_.link}
                      >
                        {_.icon}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                  Other Resources
                </span>
                <ul className="list-unstyled">
                  {data1.map((_, index) => (
                    <li>
                      <Link
                        className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-xs"
                        to={_.link}
                      >
                        {_.icon}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-blueGray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-blueGray-500 font-semibold py-1">
              Copyright © 2025 Pairrit Shop.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;

const icons = [
  { icon: "line-md:email-twotone", link: "/twitter" },
  { icon: "iconoir:tiktok", link: "/twitter" },
  { icon: "icon-park-twotone:instagram", link: "/twitter" },
  { icon: "icon-park-twotone:phone-call", link: "/twitter" },
];

const data = [
  { icon: "Help Center", link: "/help" },
  { icon: "About Us", link: "/about" },
  { icon: "Protection", link: "/premium" },
  { icon: "Donate", link: "/donate" },
  { icon: "Contact Us", link: "/contact" },
];

const data1 = [
  { icon: "Terms Agreement", link: "/policy/agreement" },
  { icon: "FAQs", link: "/help" },
  { icon: "Privacy Policy", link: "/policy/privacy" },
  { icon: "Content Policy", link: "/policy/privacy" },
];