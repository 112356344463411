export const feedbackInputAssets = [
  {
    type: 1,
    placeholder: "How would you rate the overall quailty of this delivery?",
  },
  {
    type: 2,
    placeholder: "How closely did this delievery meet your expectations?",
  },
  {
    type: 3,
    placeholder: "How reliable was the seller?",
  },
];

export const feedbackInputAssets2 = [
  {
    type: 1,
    placeholder: "How would you rate the overall quailty of this transaction?",
  },
  {
    type: 2,
    placeholder: "How closely did this delievery meet your expectations?",
  },
  {
    type: 3,
    placeholder: "How reliable was the buyer?",
  },
];