import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface GearState {
  loading: boolean;
  error: string | null;
  offerLoading: boolean;
  inCollection: boolean;
  offerError: string | null;
  productList: any;
  offerList: any;
  partialList: any;
  postData: any;
  condition: Array<any> | any;
  size: Array<any> | any;
  price: Array<any> | any;
}

// Define the initial state using that type
const initialState: GearState = {
  loading: true,
  error: null,
  offerLoading: true,
  offerError: null,
  productList: null,
  offerList: null,
  partialList: null,
  postData: null,
  size: [],
  condition: [],
  price: [0, 2000],
  inCollection: false,
};

export const fetchSingleGear = createAsyncThunk(
  "single/Gear",
  async ({ id, user }: { id: string; user: string }) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/gears/${id}/${user}`
    );
    return response.data;
  }
);

export const fetchGearProducts = createAsyncThunk(
  "products/offers",
  async ({ id }: { id: string }) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/products/fragProducts/${id}`
    );
    return response.data;
  }
);

export const newGearSlice = createSlice({
  name: "newGear",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleGear.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleGear.fulfilled, (state, action) => {
        state.postData = action.payload.gear;
        state.productList = action.payload.products;
        state.inCollection = action.payload.collection;
        state.loading = false;
      })
      .addCase(fetchSingleGear.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchGearProducts.pending, (state) => {
        state.offerLoading = true;
        state.offerError = null;
      })
      .addCase(fetchGearProducts.fulfilled, (state, action) => {
        state.partialList = action.payload.partials;
        state.offerLoading = false;
      })
      .addCase(fetchGearProducts.rejected, (state, action) => {
        state.offerLoading = false;
        state.offerError = action.error.message || "An error occurred";
      });
  },
});

export const {} = newGearSlice.actions;

export default newGearSlice.reducer;
