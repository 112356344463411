import { Icon } from "@iconify/react";
import { ComponentProps, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { fetchConfirmation } from "../../../redux/features/checkout/checkoutSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";
import Card from "../../common/Card";
import Loader from "../../common/Loader/loader";
import { Checkbox } from "../Create-Post/Listing/shipping";
import PurchaseCard from "../Transactions/Purchases/PurchaseCard";

type ConfirmationProps = ComponentProps<"div">;

export function Confirmation({ className, ...props }: ConfirmationProps) {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { fetchError, fetchLoading, orderData } = useAppSelector(
    (state) => state.checkout
  );

  useEffect(() => {
    setTimeout(() => {
      if (id) {
        dispatch(fetchConfirmation(id));
      }
    }, 5000);
  }, []);

  return (
    <>
      {fetchLoading ? (
        <Loader />
      ) : (
        <div
          className={cn("container-mini lg:mt-10 mb-[7em]", className)}
          {...props}
        >
          <br />
          <div className="flex items-center gap-3.5">
            <Icon
              icon={"carbon:checkmark-outline"}
              className="text-4xl md:hidden text-sky-700"
            />
            <div>
              <p className="text-[15px] md:text-[13px]">
                Confirmation #{orderData.orderId}
              </p>
              <h4 className="text-zinc-800 max-md:text-sm font-semibold">
                Thank you {orderData.buyerFullAddress.name}!
              </h4>
            </div>
          </div>
          <br />
          <div className="border border-border rounded-md bg-white">
            <img
              className="w-24 h-24 cursor-pointer mx-auto "
              src="/assets/imgs/home/badge.png"
              alt="google logo"
            />
            <div className="p-3">
              <h4 className="text-zinc-800 max-md:text-sm font-semibold">
                Your order is confirmed on{" "}
                {orderData.purchaseDate.split("T")[0]}
              </h4>
              <p className="text-[13px] max-md:text-xs  leading-5 mt-1">
                We’ve accepted your order, and we’re getting it ready. Come back
                to this page for updates on your shipment status.
              </p>
              <Link className="mr-2" to="/transactions/purchases">
                <button className="text-xs rounded-md py-3 mt-3 text-blue-700 hover:bg-black/5 px-4 border border-border">
                  See Purchases
                </button>
              </Link>
              <Link to={orderData.paymentMethod}>
                <button className="text-xs rounded-md py-3 mt-3 text-blue-700 hover:bg-black/5 px-4 border border-border">
                  Receipt Link
                </button>
              </Link>
            </div>
          </div>
          <br />
          <Card className="overflow-hidden bg-white">
            <h3 className="text-zinc-800 font-semibold">Order Summary</h3>
            <div className="grid [&_*]:text-[13px] max-md:[&_*]:text-[11px] [&_h4]:mt-3 [&_*]:leading-relaxed  grid-cols-1 md:grid-cols-2">
              <div>
                <h4 className="font-bold underline">Email</h4>
                <p>{orderData.buyerEmail}</p>

                <h4 className="font-bold underline">Shipping Address</h4>
                <p className="font-normal">
                  {orderData.buyerFullAddress.name} <br />
                  {orderData.buyerFullAddress.address.line1} <br />
                  {orderData.buyerFullAddress.address.city},{" "}
                  {orderData.buyerFullAddress.address.state},{" "}
                  {orderData.buyerFullAddress.address.country} <br />
                  {orderData.buyerFullAddress.address.postalCode}
                </p>
                <h4 className="font-semibold underline">Shipping method</h4>
                <p>USPS</p>
              </div>
              <div>
                <h4 className="font-semibold underline">Receipt Link</h4>
                <Link
                  to={orderData.paymentMethod}
                  className="flex items-center gap-2"
                >
                  <p className="text-blue-500 ">
                    See Reciept -{" "}
                    <span className="font-semibold">
                      $ {orderData.orderAmount.toFixed(2)}
                    </span>
                  </p>
                </Link>
              </div>
              <div className="w-full col-span-full">
                <h4 className="font-semibold underline mb-2">Order Item(s)</h4>
                {orderData.purchaseList.map((el: any, idx: any) => {
                  return <PurchaseCard confirm el={el} idx={idx} />;
                })}
              </div>
            </div>
          </Card>
          <div
            className={cn(
              "flex items-center justify-between gap-5 max-md:flex-col max-md:mt-10 my-8 ",
              className
            )}
            {...props}
          >
            <Link
              to={"/contact"}
              className="text-sky-700 max-md:order-last text-[10.97px] font-medium "
            >
              Need help?
            </Link>
            <Link to={"/"}>
              <GradientBtn label="Continue Shopping" />
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
