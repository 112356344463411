import { Icon } from "@iconify/react";
import { CircularProgress } from "@nextui-org/progress";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  add2Wishlist,
  removeWishlist,
} from "../../../../redux/features/userWishlist/wishlistSlice";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import DialogWrapper from "../../../common/dialog-wrapper";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";
import { AnyAaaaRecord } from "dns";

export default function Quantity({
  max,
  value,
  postId,
  setValue,

}: {
  postId: any;
  max: number;
  value: any;
  setValue: (value: any) => void;
}) {

  return (
    <section className="flex my-2 select-none w-fit">
      <div
        onClick={() => {
          if (value > 1) {
            setValue((p:any) => --p);
          }
        }}
        className="grid text-lg font-semibold border rounded-tl rounded-bl  cursor-pointer bg-zinc-100 place-content-center w-9 h-8"
      >
        -
      </div>

      <div className="grid text-base font-semibold bg-white place-content-center w-9 h-8 border">
        {value}
      </div>

      <div
        onClick={() => {
          if (value < max) {
            setValue((p:any) => ++p);
          }
        }}
        className="grid text-lg font-semibold rounded-tr rounded-br  cursor-pointer place-content-center bg-accentYellow-500 w-9 h-8"
      >
        +
      </div>

      <DialogWrapper
        wrapperClassName="md:min-w-[500px]"
        className="w-fit"
        content={<CopyLink type="listing" id={postId} />}
      >
        <button
          onClick={() => mixpanel.track("Listing share button clicked")}
          className="ml-2 flex items-baseline gap-2 rounded-lg bg-green-500 px-1 py-1 text-base font-bold text-white hover:opacity-75 border-2 border-white"
        >
          <Icon icon={"carbon:share"} className="text-lg text-white mx-auto" />
        </button>
      </DialogWrapper>
    </section>
  );
}
