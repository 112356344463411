import * as Dialog from "@radix-ui/react-dialog";
import { HTMLAttributes, useEffect, useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "../../../../helpers/utils";
import OnBoardingIntro from "./OnBoardingIntro";
import OnBoardingP1 from "./OnBoardingP1";
import OnBoardingP2 from "./OnBoardingP2";
import { useAppSelector } from "../../../../redux/hooks";
import { Loading } from "../../Widget/Loading";
import OnBoardingP3 from "./OnBoardingP3";

interface DialogWrapperProps extends HTMLAttributes<HTMLDivElement> {
  wrapperClassName?: string;
}

export default function OnBoardWrapper({
  className,
  content,
  wrapperClassName,
  children,
  ...props
}: DialogWrapperProps) {
  const { profileData, loading } = useAppSelector(
    (state) => state.userProfile
  );
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("First");

  useEffect(() => {
   
  if (value === 'Last') {
    setOpen(false);
  }  
 }, [value]);

  return (
    <>
      {loading === true ? null : (
        <Dialog.Root onOpenChange={setOpen} modal={false}>
          <Dialog.Trigger className={cn("block w-full", className)}>
            {children}
          </Dialog.Trigger>{" "}
          <Dialog.Overlay className="fixed z-[9999] inset-0 bg-black/40" />
          <AnimatePresence>
            {profileData.userStatus == "NEW" && (
              <Dialog.Content
                forceMount
                asChild
                className={cn(
                  "fixed z-[10000] inset-0 m-auto h-fit md:w-fit  p-2 ",
                  wrapperClassName,
                  {}
                )}
                onInteractOutside={(e) => e.preventDefault()}
                {...props}
              >
                <motion.div
                  initial={{ y: 100, scale: 0.5, opacity: 0 }}
                  exit={{ y: 0, scale: 0, opacity: 0 }}
                  animate={{ y: 0, scale: 1, opacity: 1 }}
                >
                  <div className="grid min-h-screen w-full place-items-center">
                    <div className="absolute left-1/2 top-1/2 h-96 w-80 -translate-x-1/2 -translate-y-1/2 rotate-6 rounded-2xl bg-orange-500"></div>

                    <div className="absolute left-1/2 top-1/2 h-96 w-80 -translate-x-1/2 -translate-y-1/2 rotate-0 space-y-6 rounded-2xl mobile-post-btn p-6 transition duration-300 hover:rotate-0 shadow-lg">
                      <Tabs.Root value={value} onValueChange={setValue}>
                        <Tabs.Content value="First">
                          <OnBoardingIntro setValue={setValue} />
                        </Tabs.Content>
                        <Tabs.Content value="Second">
                          <OnBoardingP1 setValue={setValue} />
                        </Tabs.Content>
                        <Tabs.Content value="Third">
                          <OnBoardingP2 setValue={setValue} />
                        </Tabs.Content>
                        <Tabs.Content value="Last">
                          <OnBoardingP3 setValue={setValue} />
                        </Tabs.Content>
                      </Tabs.Root>
                    </div>
                  </div>
                </motion.div>
              </Dialog.Content>
            )}
          </AnimatePresence>
        </Dialog.Root>
      )}
    </>
  );
}
