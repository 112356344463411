import React, { useState, useEffect } from "react";
import Register from "../../interfaces/Register";
import { useOktaAuth } from "@okta/okta-react";
import { signup } from "../../redux/features/auth/authSignupSlice";
import { notifySelect } from "../../redux/features/notify/notifySlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import DialogHeader from "../common/dialog-header";
import Loader from "../common/Loader/loader";
import AuthBtns from "./AuthBtns";
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Loading } from "../common/Widget/Loading";
import axios from "axios";
import { Icon } from "@iconify/react";

interface RegisterProps {
  inputAssets: Register[];
  title: String;
}

interface FormErrors {
  username?: string;
  email?: string;
  password?: string;
  firstName?: string;
}

const SignupB: React.FC<RegisterProps> = ({
  inputAssets,
  title,
}: RegisterProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { oktaAuth } = useOktaAuth();
  const loggingIn = async () => {
    oktaAuth.signInWithRedirect({ originalUri: location.pathname });
  };
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    firstName: "",
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let errors: FormErrors = {};

    // Username validation
    if (
      !formData.username ||
      formData.username.length < 4 ||
      formData.username.length > 12 ||
      !/^[a-zA-Z0-9_\.]+$/.test(formData.username)
    ) {
      errors.username = "Must be 4-12 characters";
    }

    if (
      !formData.firstName ||
      formData.firstName.length < 3 ||
      formData.firstName.length > 12 ||
      !/^[a-zA-Z\-]+$/.test(formData.firstName)
    ) {
      errors.firstName = "Must be 3-12 characters, No Numbers or Characters";
    }

    // if (
    //   !formData.lastName ||
    //   formData.lastName.length < 3 ||
    //   formData.lastName.length > 12 ||
    //   /\s/.test(formData.lastName)
    // ) {
    //   errors.lastName = "Username must be 3-12 characters long with no spaces.";
    // }

    // Email validation
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    // Password validation
    if (
      !formData.password ||
      !/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,16}$/.test(
        formData.password
      )
    ) {
      errors.password = "Must be 7-15 characters, one number and character.";
    }

    setFormErrors(errors);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    validateForm();
    if (
      Object.keys(formErrors).length === 0 &&
      Object.values(formData).every((field) => field)
    ) {
      event.preventDefault();
      try {
        setLoading(true);
        const response = await axios.post(
          `https://webservice.pairrit.com/pairrit-ws/api/auth/signup`,
          {
            firstName: formData.firstName,
            email: formData.email,
            login: formData.email,
            displayName: formData.username,
            password: formData.password,
          }
        );
        setLoading(false);
        dispatch(
          notifySelect({
            title: "Success, Please Login!",
            type: 0,
            open: true,
          })
        );
        mixpanel.alias(formData.email);
        loggingIn();
      } catch (err: any) {
        setLoading(false);
        if (!err?.response) {
          dispatch(
            notifySelect({
              title: "No Server Response",
              type: 1,
              open: true,
            })
          );
        } else if (err.response?.status === 409) {
          dispatch(
            notifySelect({
              title: "Username or Email Taken",
              type: 1,
              open: true,
            })
          );
        } else {
          dispatch(
            notifySelect({
              title: "Registration Failed",
              type: 1,
              open: true,
            })
          );
        }
      }
    } else {
      event.preventDefault();
      dispatch(
        notifySelect({
          title: "Check Inputs",
          type: 1,
          open: true,
        })
      );
    }
  };

  return (
    <div className="tablet:hidden  md:mt-24 md:border border-solid border-zinc-300 rounded-lg p-4">
      <DialogHeader />
      <section>
        <div className="mt-8">
          <h1 className="text-xl mb-4 text-center">{title}</h1>
          <p className="text-xs mb-4 text-center">
            Welcome Back, We missed you!
          </p>
        </div>
      </section>

      <button
        type="button"
        className="border border-solid border-zinc-300 flex text-black w-full p-2 rounded-2xl place-content-center gap-2 px-3 py-1.5"
      >
        <Icon icon="devicon:google" className="text-2xl h-6 justify-center" />
        <span className="justify-center font-semibold">
          Continue with Google
        </span>
      </button>
      <p className="text-center mt-2">Or</p>
      <form
        className="mt-2 relative z-0 md:w-full"
        onKeyDown={validateForm}
        onSubmit={handleSubmit}
      >
        {inputAssets.map((details, index) => (
          <div className="relative mb-8" key={details.name}>
            <input
              name={details.name}
              className="w-full bg-slate-200 rounded-2xl pb-2 pl-9 p-3 mt-1 border-amber-200"
              type={details.type}
              placeholder={details.placeholder}
              onChange={handleInputChange}
            />
            <img
              className="absolute top-4 left-3 w-4 h-4"
              src={details.logo}
              alt="logo"
            />
            <img
              className={`${
                details.logoTwo ? details.logoTwo : "hidden"
              } absolute right-2 bottom-4 cursor-pointer`}
              src={details.logoTwo}
              alt=""
            />
            {formErrors[details.name as keyof FormErrors] && (
              <p className="text-red-500 text-2xs ml-3">
                *{formErrors[details.name as keyof FormErrors]}
              </p>
            )}
          </div>
        ))}
        {loading ? (
          <Loading />
        ) : (
          <button
            type="submit"
            className="mobile-post-btn text-white w-full p-2 rounded-2xl"
          >
            Continue
          </button>
        )}
        {/* <p className="text-[#B5B5B5] my-5 text-center">or continue with</p>
          <AuthBtns /> */}
        <div className="xs:w-full sm:w-full max-max-w-96 text-center m-auto mt-6">
          <p className="text-xs md:sm">
            By continuing, you are setting up a Pairrit account and agree to our
            <span className="text-[#0079D3] cursor-pointer mx-1">
              User Agreement
            </span>
            and
            <span className="text-[#0079D3] cursor-pointer mx-1">
              Privacy Policy.
            </span>
          </p>
        </div>
      </form>
    </div>
  );
};

export default SignupB;
