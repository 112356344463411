import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  loading: boolean;
  error: string | null;
  userCommunities: any;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  loading: true,
  error: null,
  userCommunities: null,
};

export const fetchUserCommunities = createAsyncThunk(
  "user/fetchUserCommunities",
  async (data: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/user/${data}`
    );
    return response.data;
  }
);

export const userCommunitiesSlice = createSlice({
  name: "userCommunities",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    removeMod: (state, action: PayloadAction<string>) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserCommunities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserCommunities.fulfilled, (state, action) => {
        state.loading = false;
        state.userCommunities = action.payload;
      })
      .addCase(fetchUserCommunities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const {} = userCommunitiesSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userCommunitiesSlice.reducer;
