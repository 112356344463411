import {  useAppSelector } from "../../../../redux/hooks";
import Loader from "../../../common/Loader/loader";
import { Mods } from "./Mods";

export const CommunityMods = () => {
  const { communityData, loading } = useAppSelector((state) => state.community);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
            <Mods value={communityData.moderators}/>
        </div>
      )}
    </div>
  );
};
