import { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch } from "react-redux";
import { Loading } from "../../common/Widget/Loading";
import { removeProduct } from "../../../redux/features/userStore/userStoreSlice";
import { Form } from "../../ui/form";
import { AppDispatch } from "../../../redux/store";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import { useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";

const formSchema = z.object({
  postId: z.string(),
});

interface DeleteProdModalProps extends ComponentProps<"form"> {
  id: any;
}

export default function DeleteProductModal({
  className,
  id,
  ...props
}: DeleteProdModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const { deleteError, deleteLoading, deleteSuccess } = useAppSelector(
    (state) => state.userStore
  );

  function onSubmit(values: any) {
    dispatch(removeProduct(values))
  }
  useEffect(() => {
    form.setValue("postId", id);
    if (deleteError) {
      dispatch(
        notifySelect({
          title: "Error Removing",
          type: 1,
          open: true,
        })
      );
    }
     if (deleteSuccess) {
       dispatch(
         notifySelect({
           title: "Product Removed!",
           type: 0,
           open: true,
         })
       );
     }
    
  }, [deleteError, deleteSuccess, id]);

  return (
    <Form {...form}>
      <form className="z-50 " onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-1 font-bold">Confirm</p>
        <hr className="my-2" />
        <div className="text-zinc-900 font-medium">Remove Product?</div>{" "}
        <div className="-mx-6 p-3.5 mt-2 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
          {deleteLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              type="submit"
              label={"Remove"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}
