import { useEffect, useState } from "react";
import ShopFlags from "../Flags/ShopFlags";
import Post from "./Post/Post";

const Posts = ({ posts }: { posts: Array<any> }) => {
  const [todos, setTodos] = useState([""]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  return (
    <div className="-z-0 mb-24">
      {posts
        ? posts?.map((el, idx) => (
            <>
              {idx % 6 == 0 && idx != 0 ? <ShopFlags /> : null}
              {/* {idx % 5 == 0 ? (
                <img
                  className="w-full h-full mx-auto object-cover mt-2 rounded-lg"
                  src="/assets/imgs/home/banner-3.png"
                  alt="ad"
                />
              ) : null} */}
              {idx % 3 == 0 && idx != 0 ? (
                <img
                  className="w-full h-full mx-auto object-cover mt-2 rounded-lg"
                  src="/assets/imgs/home/banner-2.png"
                  alt="ad"
                />
              ) : null}
              {/* {idx % 2 == 0 && idx % 3 != 0 ? (
                <img
                  className="w-full h-full mx-auto object-cover mt-2 rounded-lg"
                  src="/assets/imgs/home/banner-6.png"
                  alt="ad"
                />
              ) : null} */}
              {idx % 8 == 0 && idx % 3 != 0 ? (
                <img
                  className="w-full h-full mx-auto object-cover mt-2 rounded-lg"
                  src="/assets/imgs/home/banner-8.png"
                  alt="ad"
                />
              ) : null}
              <Post key={idx} el={el} idx={idx} />
            </>
          ))
        : ""}
    </div>
  );
};

export default Posts;
