import { Icon } from "@iconify/react";
import clsx from "clsx";
import { ComponentProps } from "react";
import { cn } from "../../../helpers/utils";


const GreyBtn2 = ({
  className,
  short,
  label,
  iconify,
  iconClassName,
  ...props
}: Partial<{
  className: string;
  short: boolean;
  label: any;
  iconify: any;
  iconClassName: any;
}> &
  ComponentProps<"button">) => {
  return (
    <button
      className={cn(
        "font-medium flex items-center justify-center rounded-md bg-accentGray-400 md:hover:bg-accentGray-500 text-accentGray-700 gap-1.5 transition-colors text-xl border border-solid border-[rgba(134, 145, 168, 0.20)]",
        short ? "h-[30px] text-xl" : "h-[38px]",
        !className?.includes("px-") && (short ? "px-3" : "px-5"),
        className
      )}
      {...props}
    >
      {iconify && <Icon className={clsx(iconClassName)} icon={iconify} />}
      {label}
    </button>
  );
};

export default GreyBtn2;
