import { HTMLAttributes } from "react";
import { cn } from "../../../../helpers/utils";
import Card from "../../../common/Card/Card";
import { CircularProgress } from "@nextui-org/progress";
import { Link } from "react-router-dom";
import { RatingStars } from "./details";

interface PaymentSectionProps extends HTMLAttributes<HTMLDivElement> { info: any; }

export default function PaymentSection({
  className,
  info,
  ...props
}: PaymentSectionProps) {
  return (
    <section
      className={cn("grid grid-cols-1 gap-1 md:grid-cols-1", className, {})}
      {...props}
    >
      <Card className="[&>div]:flex mt-2 space-y-3 !p-4  font-medium [&_aside]:min-w-[87px] bg-white">
        <div>
          <aside className="font-bold text-black text-xs">Shipping:</aside>
          <div className="text-xs">
            <p>
              <span className="font-semibold">
                Standard $4.00 | Express $6.50
              </span>
            </p>
          </div>
        </div>

        <div>
          <aside className="font-bold text-black text-xs">Delivery:</aside>
          <div className="text-xs">
            Estimated between{" "}
            <span className="font-semibold">4-6 Business Days </span>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <p className="font-bold text-xs text-red-500">
            This item will be Authenticated ❤️
          </p>
          <CircularProgress
            classNames={{
              svg: "w-10 h-10 drop-shadow-md ",
              indicator: "stroke-black",
              track: "stroke-white/10",
              value: "text-xs font-semibold text-black",
            }}
            size="sm"
            value={100}
            showValueLabel={true}
          />
        </div>
      </Card>
      <hr className=" my-1" />
      <div className="">
        <div className="flex overflow-hidden rounded-lg">
          <div className="px-3.5 pb-2 grow bg-white shadow space-y-1">
            <div>
              {" "}
              <p className="font-bold text-sm underline mb-2">Seller Info</p>
              <Link to={`/user/${info.author.id}/shop`}>
                <div className="text-xs flex items-center font-semibold ">
                  {/* User avatar */}
                  <img
                    src={`/assets/imgs/avatar/${info.author.avatar}.svg`}
                    alt=""
                    className="w-7 rounded-full aspect-square"
                  />
                  {/* Seller's username */}
                  <div className="ml-2">{info.author.username}</div>
                  {/* Seller's ratings */}
                  <div className=" ml-2">
                    <RatingStars
                      numberOfReviews={info.author.sellerRating.toFixed(2)}
                    />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
