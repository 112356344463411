import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import GreyBtn from "../../buttons/GreyBtn/GreyBtn";
import Loader from "../../common/Loader/loader";
import { useOktaAuth } from "@okta/okta-react";
import { RatingStars } from "../Single-Page/Listing/details";

export default function Profile() {
  const { profileData, loading } = useAppSelector((state) => state.userProfile);
  const { authState } = useOktaAuth();
  return (
    <div className="overflow-hidden bg-white border rounded-lg shadow-md border-slate-400/20">
      {!authState?.isAuthenticated ? (
        <img
          className="w-full h-full mx-auto object-cover rounded"
          src="/assets/imgs/home/banner-7.png"
          alt="ad"
        />
      ) : loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-3 p-4">
          <img
            alt="user"
            src={`/assets/imgs/avatar/${profileData?.profileUrl}.svg`}
            className="w-28 h-24 border-2 border-white rounded-lg shadow-md"
          />
          <div className="">
            <p className="col-span-3 text-sm font-semibold">
              {profileData?.userName}
            </p>
            <div className="grid grid-cols-3 my-1 w-full gap-2">
              <div className="col-span-1 text-xs font-semibold">
                <div className="flex items-center gap-1">
                  <img src="/assets/vectors/common/flower.svg" alt="" />
                  <div className="text-[11px] font-medium ">
                    {profileData?.muzPoints.toLocaleString("en-US")}
                  </div>
                </div>
              </div>
              {/* <div className="col-span-1 text-xs font-semibold">
                <div className="flex items-center gap-1">
                  <img src="/assets/vectors/common/calendar.svg" alt="" />
                  <div className="text-[11px] font-medium ">{11}</div>
                </div>
              </div> */}
              <div className="col-span-1 text-xs font-semibold">
                <RatingStars
                  numberOfReviews={profileData.sellerRatings.toFixed(1)}
                />
              </div>
            </div>
          </div>
          <div className="my-3">
            <hr />
          </div>
          <div className="">
            <Link to="/my/shop">
              <button className="text-sm px-2 flex items-center justify-center w-full h-10 font-semibold text-white rounded-lg bg-gradient-to-l from-red-500 to-yellow-500">
                My Shop
                <Icon className="w-10 text-2xl" icon={"mingcute:right-line"} />
              </button>
            </Link>
            {/* <GreyBtn label={'+ Edit Avatar'} className={'text-blue-700 mt-2.5'} /> */}
            <div className="my-3">
              <hr />
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(/assets/imgs/home/fragVerify1.png)`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundBlendMode: "overlay",
            }}
            className="w-full md:p-1 border flex flex-col justify-center rounded-lg bg-slate-200 border-amber-200  shadow-md"
            key={1}
          >
            <p className="text-base font-semibold text-center">
              ${profileData.balance / 100}
            </p>
            <p className=" text-xs font-medium text-center">{"# Balance"}</p>
          </div>
          <div
            style={{
              backgroundImage: `url(/assets/imgs/home/fragVerify1.png)`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundBlendMode: "overlay",
            }}
            className="w-full md:p-1 border flex flex-col justify-center rounded-lg bg-slate-200 border-amber-200  shadow-md"
            key={2}
          >
            <p className=" text-base font-semibold text-center">
              {profileData.noOfSales}
            </p>
            <p className=" text-xs font-medium text-center">{"For Sale"}</p>
          </div>
        </div>
      )}
    </div>
  );
}
