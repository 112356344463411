import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import GradientBtn from "../../../buttons/GradientBtn/GradientBtn";
import GreyBtn from "../../../buttons/GreyBtn/GreyBtn";
import Card from "../../../common/Card/Card";

import { useOktaAuth } from "@okta/okta-react";
import Share from "./share";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import DialogWrapper from "../../../common/dialog-wrapper";
import NewGear from "../../Create-Post/NewGear";




export default function GearDetails({
  info,
  listing
}: {
  info?: any;
  listing?: any;
}) {

 const {
  inCollection
 } = useAppSelector((state) => state.newGearPost);


  // Make sure its a listing
  return (
    <Card className="bg-white  flex flex-col max-lg:py-4 shadow p-3">
      <div className="text-xl font-extrabold uppercase text-left">
        {info.model}{" "}
        <span className="font-light text-zinc-500 italic">{info.brandAbr}</span>
      </div>
      <div className="justify-center ">
        <div className="text-2xs md:text-xs flex items-center font-semibold ">
          {/* <img
              src={`/assets/imgs/avatar/${info.author.avatar}.svg`}
              alt=""
              className="w-7 rounded-full aspect-square"
            /> */}
          {/* Seller's username */}
          {/* <div className="ml-2">{info.author.username}</div> */}
          {/* Seller's ratings */}
          <div className=" mx-1">
            <RatingStars numberOfReviews={info.rate} />
          </div>
          • {info.gender} • {info.author} •
          <Link
            to={info.fragUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-1 text-blue-700"
          >
            Fragrantica Page
          </Link>
          <Share
            postId={info.id}
          />
        </div>

        <div className="max-md:hidden text-xs font-medium text-neutral-400  [&>b]:pr-1 mt-1">
          Brand: <b>{info.brandAbr}</b>
          Gender: <b>{info.gender} </b>
          Formula: <b>{info.formula} </b>
          Year: <b>{info.year} </b>
        </div>

        <div className="flex  gap-3 [&_*]:text-black items-center mt-2  w-full ">
          {listing ? (
            <GreyBtn
              to={`/single/new-gear/${info.id}`}
              label={"See All"}
              className={"w-full [&_*]:!text-black rounded-lg"}
            />
          ) : null}
          {inCollection == true ? (
            <GreyBtn
              label={"Added, See Collection"}
              className={"w-full [&_*]:!text-black "}
            />
          ) : (
            <DialogWrapper className="z-50 w-full" content={<NewGear />}>
              <GradientBtn
                label={"Add to Collection"}
                iconClassName="text-xl"
                iconify="flat-color-icons:plus"
                className={"w-full [&_*]:!text-white "}
              />
            </DialogWrapper>
          )}
          {/* {!authState?.isAuthenticated && (
            <NotifyButton
              className={"w-full max-w-xs"}
              placeholder="Signin or signup to comment and like"
            />
          )} */}
        </div>
      </div>
    </Card>
  );
}
export function RatingStars({ numberOfReviews }: { numberOfReviews?: string }) {
  return (
    <div className="flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 text-yellow-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
      {numberOfReviews && (
        <p className="text-gray-600 font-bold text-xs ml-">
          {`${numberOfReviews}`}
        </p>
      )}
    </div>
  );
}
