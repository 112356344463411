import axios from "axios";
import { Icon } from "@iconify/react";
import clsx from "clsx";
import { FC, memo, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  ChatType,
  getAllChat,
  getUnreadCountPerUser,
} from "../../../../helpers/firestore";
import { useUser } from "../../../../hooks/useUser";
import { useAppSelector } from "../../../../redux/hooks";
interface Props {
  query: string;
}

const ChatPeople = ({ query }: Props) => {
  const { userId } = useAppSelector((state) => state.userProfile);
  const [chats, setAllChat] = useState<(ChatType & { userName: string })[]>([]);
  useEffect(() => {
    getAllChat(userId, async (res) => {
      const finalChat = await Promise.all(
        res.map(async (user) => {
          const secondaryUserId = user.users[1 - user.users.indexOf(userId)];
          return await axios
            .get(
              `https://webservice.pairrit.com/pairrit-ws/api/profile/${secondaryUserId}`
            )
            .then((res) => {
              return { ...user, userName: res.data?.userName };
            });
        })
      );
      setAllChat(finalChat);
    });
  }, [userId]);

  return (
    <div className="p-[10px] pt-0">
      {chats
        .filter((chat) =>
          chat.userName.toLowerCase().includes(query.toLowerCase())
        )
        ?.map((el, idx) => {
          return <ChatItem {...el} key={idx} />;
        })}
    </div>
  );
};

const ChatItem: FC<ChatType> = memo(
  (props) => {
    const [searchParams] = useSearchParams();
    const { userId } = useAppSelector((state) => state.userProfile);
    const secondaryUserId = props.users[1 - props.users.indexOf(userId)];
    const [unreadCount, setUnreadCount] = useState(0);
    const id = searchParams.get("user");
    const active = id === secondaryUserId;
    const op = useUser(secondaryUserId);
    useEffect(() => {
      const unsub = getUnreadCountPerUser(
        { userId, otherUserId: secondaryUserId },
        (c) => {
          setUnreadCount(c);
        }
      );
      return () => unsub();
    }, [userId]);
    if (op)
      return (
        <Link
          to={`/messages?user=${secondaryUserId}`}
          className={clsx(
            "flex justify-between border-b-2 rounded-lg items-center p-2 border-solid border-accentGray-600 hover:bg-accentGray-400 transition-colors cursor-pointer",
            active && "bg-accentGray-400"
          )}
        >
          <div className="flex items-center gap-2">
            <img
              className="w-[34px] h-[34px] rounded-[50%] flex-shrink-0"
              src={`/assets/imgs/avatar/${op?.profileUrl}.svg`}
              alt={op?.userName}
            />
            <div>
              <div className="restrict-one-line font-semibold text-sm">
                {op?.userName}
              </div>
              <div className="line-clamp-1 overflow-hidden w-full text-accentGray-700">
                {props.messages.slice(-1)[0].message.startsWith("file:///") ? (
                  <img
                    className="cursor-pointer size-4"
                    src="/assets/vectors/messages/img-selector.svg"
                    alt="img-selector"
                  />
                ) : (
                  props.messages.slice(-1)[0].message
                )}
              </div>
            </div>
          </div>

          {!!unreadCount && (
            <Icon
              icon="iconamoon:comment-dots-fill"
              className="text-xl text-red-500 h-4 justify-center "
            />
          )}
        </Link>
      );
    return null;
  },
  function arePropsEqual(oldProps, newProps) {
    return oldProps.messages.length === newProps.messages.length;
  }
);

export default ChatPeople;
