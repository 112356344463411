export const categoriesData = [
  {
    icon: "/assets/vectors/navbar/GENERAL.svg",
    label: "General",
    value: "General",
    to: "/general",
  },
  {
    icon: "/assets/vectors/navbar/SHOPPING.svg",
    label: "Shopping",
    value: "Shopping",
    to: "/shopping",
  },
  // {
  //   icon: "/assets/vectors/navbar/COLLECTORS.svg",
  //   label: "Collectors",
  //   value: "Collectors",
  //   to: "/collectors",
  // },
  // {
  //   icon: "/assets/vectors/navbar/SOCIAL.svg",
  //   label: "Social",
  //   value: "Social",
  //   to: "/social",
  // },
  {
    icon: "/assets/vectors/navbar/DISCOVERY.svg",
    label: "Discovery",
    value: "Discovery",
    to: "/discovery",
  },
];

export const categoriesData2 = [
  {
    icon: "/assets/imgs/categories/comm.svg",
    label: "General",
    to: "/general",
  },
  {
    icon: "/assets/imgs/categories/blooming.svg",
    label: "Floral",
    to: "/floral",
  },
  {
    icon: "/assets/imgs/categories/water.svg",
    label: "Fresh",
    to: "/fresh",
  },
  {
    icon: "/assets/imgs/categories/fruit.svg",
    label: "Fruity",
    to: "/fruity",
  },
  {
    icon: "/assets/imgs/categories/aroma.svg",
    label: "Aromatic",
    to: "/aromatic",
  },
  {
    icon: "/assets/imgs/categories/oriental.svg",
    label: "Oriental",
    to: "/oriental",
  },
  {
    icon: "/assets/imgs/categories/wood.svg",
    label: "Woody",
    to: "/woody",
  },
  {
    icon: "/assets/imgs/categories/gour.svg",
    label: "Gourmond",
    to: "/gourmond",
  },
  {
    icon: "/assets/imgs/categories/leather.svg",
    label: "Leather",
    to: "/leather",
  },
  {
    icon: "/assets/imgs/categories/niche.svg",
    label: "Niche",
    to: "/niche",
  },
];

export const sizesData2 = [
  {
    price: 10.99,
    label: "Sample",
    to: 0.07,
  },
  { label: "Travel", price: 30.05, to: 0.27 },
  { label: "Mini", price: 5.15, to: 1.7 },
  { label: "Full ", price: 200.25, to: 3.4 },
  { label: "Extra ", price: 230.25, to: 4.2 },
];