import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import { inviteUsers } from "../../../redux/features/post/inviteSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";
import { Form, FormControl, FormField, FormItem } from "../../ui/form";
import DialogHeader from "../dialog-header";
import { Input } from "../input";
import Loader from "../Loader/loader";
import { useOktaAuth } from "@okta/okta-react";
import { Loading } from "../Widget/Loading";
import Card from "../Card/Card";
import { Icon } from "@iconify/react";

const formSchema = z.object({
  senderId: z.string().optional(),
  recipientEmail: z.string(),
});

const Invite = () => {
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { loading, error, sent } = useAppSelector((state) => state.inviteUser);
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
    if (authState?.isAuthenticated) {
      dispatch(inviteUsers(values));
      dispatch(
        notifySelect({
          title: "Invite Sent!",
          type: 0,
          open: true,
        })
      );
    } else {
      dispatch(
        notifySelect({
          title: "Login to Earn Points 😅",
          type: 1,
          open: true,
        })
      );
    }
  }

  useEffect(() => {
    form.setValue("senderId", userId);
    if (error) {
      dispatch(
        notifySelect({
          title: "Error Sending 😅",
          type: 1,
          open: true,
        })
      );
    }
  }, [userId, error]);

  return (
    <>
      {sent ? (
        <Card>
          <Icon
            icon={"icon-park-twotone:success"}
            className="h-12 w-12 text-green-400 mx-auto my-3"
          />
          <h1 className="text-center">
            Success Your Invite has been sent, thanks!
          </h1>
        </Card>
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader />
            <h2 className="text-center mb-3 text-black text-3xl font-semibold">
              Invite Friends
            </h2>
            <h4 className="text-center text-black text-base font-bold">
              Earn Free Flex Points
            </h4>
            <p className="text-center text-black text-base font-normal">
              100 per New Member
            </p>
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoFocus
                      noLabel
                      {...field}
                      placeholder="Enter Email"
                      type="email"
                      className="w-full md:w-[460px] h-12 px-3 mt-4 bg-zinc-100 rounded-[11px] border outline-accentYellow-500 border-slate-400/20"
                    />
                  </FormControl>
                </FormItem>
              )}
              name={"recipientEmail"}
              key={"email"}
              control={form.control}
            />
            {loading ? (
              <Loading />
            ) : (
              <GradientBtn
                type="submit"
                label={"Invite Friend"}
                className="mx-auto block mt-14 mb-10"
              />
            )}
            <p className="text-center md:w-[460px] text-zinc-600 text-sm font-medium">
              Signin to earn muzPoints. muzPoints can be used towards purchases,
              and selling products at a discount.
            </p>
          </form>
        </Form>
      )}
    </>
  );
};

export default Invite;
