import { createSlice } from "@reduxjs/toolkit";

// Define a type for the slice state
interface InitialStateType {
  messagerList: Array<any>;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  messagerList: [],
};

export const messagesSlice = createSlice({
  name: "messages",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
});

export const {} = messagesSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default messagesSlice.reducer;
