import { useEffect, useState, HTMLAttributes } from "react";
import { Icon } from "@iconify/react";
import { useAppSelector } from "../../../redux/hooks";
import DialogWrapper from "../../common/dialog-wrapper";
import { DeleteGear } from "./delete-gear";
import { Link } from "react-router-dom";


interface GearProps extends HTMLAttributes<HTMLDivElement> {
  data?: Array<any>;
}

const Gears = ({ data }: GearProps) => {
  const isEdit = useAppSelector((state) => state.userCollection.isDelete);
  const [isDelete, setisDelete] = useState(isEdit);

   useEffect(() => {
     setisDelete(isEdit);
   }, [isEdit]);
  return (
    <div className="grid grid-cols-4 gap-3 my-4  md:grid-cols-8 lg:grid-cols-12 ">
      {data?.map((_, idx: any) => (
        <div className="justify-center mx-auto ">
          <div className=" relative bg-gradient-to-r w-fit rounded-full p-1.5 from-[#FBBC05] to-[#FF6F00]">
            {isDelete === true ? (
              <DialogWrapper
                wrapperClassName="md:min-w-[500px]"
                className="absolute top-0 w-6 drop-shadow aspect-square right-0"
                content={<DeleteGear index={idx} />}
              >
                <button className="hover:animate-spin w-7 aspect-square grid place-content-center bg-red-500 rounded-full">
                  <Icon icon={"lucide:x"} className="text-white text-lg" />
                </button>
              </DialogWrapper>
            ) : (
              ""
            )}
            <Link to={`/single/new-gear/${_.brandId}`}>
              {" "}
              <img
                className="w-16 rounded-full aspect-square object-cover ring-4 ring-white"
                src={_.img}
                alt={_.name}
              />
            </Link>

            {_.status === "Signature" ? (
              <img
                className="absolute bottom-0 w-6 md:w-6 drop-shadow aspect-square right-0"
                src="/assets/vectors/common/blue-tick.svg"
                alt=""
              />
            ) : (
              <img
                className="absolute bottom-0 right-0"
                src="/assets/vectors/common/record.svg"
                alt=""
              />
            )}
          </div>
          <p className="text-[9px] mt-1 text-center">
            {_.name.slice(0, 14)}
            {_.name.length > 14 && <>... </>}
          </p>
          <p className="cursor-pointer text-red-500 text-center text-2xs">
            {((_.prate + _.vrate) / 2).toFixed(1)}
          </p>
        </div>
      ))}
    </div>
  );
}

export default Gears

