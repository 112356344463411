import { useState, useEffect } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { fetchCommunityByName } from "../../../redux/features/communityFeed/communitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ComingSoon from "../../common/Error/ComingSoon";
import Jumbo from "../../common/Jumbotron/Jumbo";
import { useOktaAuth } from "@okta/okta-react";
import Tabs from "../../common/Tabs/Tabs";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";

const tabs = (id: any) => [
  {
    to: `/que/${id}/settings`,
    icon: "/assets/vectors/transactions/purchases.svg",
    label: "Settings",
  },
  {
    to: `/que/${id}/rules`,
    icon: "/assets/vectors/transactions/orders.svg",
    label: "Rules",
  },
  // {
  //   to: "/que/posts",
  //   icon: "/assets/vectors/transactions/offers.svg",
  //   label: "Posts",
  // },
  {
    to: `/que/${id}/insights`,
    icon: "/assets/vectors/transactions/earnings.svg",
    label: "Insights",
  },
  {
    to: `/que/${id}/mods`,
    icon: "/assets/vectors/transactions/my-bill.svg",
    label: "Moderators",
  },
  {
    to: `/que/${id}/ban`,
    icon: "/assets/vectors/transactions/manage-listings.svg",
    label: "Banned",
  },
  //   {
  //     to: "/que/approve",
  //     icon: "/assets/vectors/transactions/manage-listings.svg",
  //     label: "Approved",
  //   },
];

const ModLayout = () => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { authState } = useOktaAuth();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fetchCommunityByName(id));
    }
    mixpanel.track("Community Que page view", {
      community_name: id,
      email: user ? user["email"] : "",
    });
  }, [id]);
  if (!authState?.isAuthenticated) return <ComingSoon />;
  return (
    <div className="mb-[9em] lg:mt-10">
      {/* <Navigate replace to="/policy/terms" /> */}
      <Jumbo
        title={`Mod Que:C/${id}`}
        des="Control and manage your community"
      />
      <Tabs tabsData={tabs(id)} />

      <Outlet />
    </div>
  );
};

export default ModLayout;
