import { Icon } from "@iconify/react";
import YellowBtn from "../../../buttons/YellowBtn";
import Select from "../../../common/Selectors/select";
import Moderators from "./moderators";
import RelatedCommunities from "./related-communities";
import RulesAccordion from "./rules-accordion";
import Stats from "./stats";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Loader from "../../../common/Loader/loader";
import AppDownload from "../../../common/Explore/AppDownload";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import {
  joinCommunity,
  unJoinCommunity,
} from "../../../../redux/features/communityFeed/communitySlice";
import { useParams } from "react-router-dom";
import DialogWrapper from "../../../common/dialog-wrapper";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import Card from "../../../common/Card";
import JoinBtn from "../../../buttons/ActiveBtn/JoinBtn";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";
import Invite from "../../../common/Sidebar/Invite";

export default function ExploreMenu() {
  const { communityData, loading, joinLoading, joinError } = useAppSelector(
    (state) => state.community
  );
  const { userId } = useAppSelector((state) => state.userProfile);
  const user = useAuthUser();
  const { id } = useParams();
  const { authState } = useOktaAuth();
  const [isPressed, setIsPressed] = useState(true);
  const dispatch = useAppDispatch();
  const onTap = () => {
    if (authState?.isAuthenticated) {
      const data = { communityName: id, userId: userId };
      dispatch(joinCommunity(data)).then(() => window.location.reload());
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
    }
  };
  const onUnTap = () => {
    if (authState?.isAuthenticated) {
      const data = { communityName: id, userId: userId };
      setIsPressed(false);
      dispatch(unJoinCommunity(data)).then(() => window.location.reload());
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
    }
  };
  useEffect(() => {
    if (loading == false) {
      setIsPressed(
        communityData.subscriberIds?.includes(userId) ? true : false
      );
    }
  });
  return (
    <Card className="bg-white">
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <section className="">
          <div className="flex justify-between">
            <p className="text-lg font-semibold ">c/{communityData.name}</p>
            <Icon
              icon="pepicons-pencil:dots-x"
              className="text-2xl"
              role="button"
            />
          </div>

          <p className="text-sm font-normal ">{communityData.description}</p>
          <div className="flex items-center gap-1 py-3">
            <Icon icon="bx:calendar" className="text-rose-600" />
            <p className="text-xs font-medium text-black">
              {communityData.createdDateTime.split("T")[0]}
            </p>
            <p className="text-xs font-medium text-green-500">
              ({communityData.genre})
            </p>
          </div>
          <Stats
            count={communityData.subscriberIds.length}
            rank={communityData.rankedSize + 1}
          />
          <div className="flex gap-2 my-6">
            <JoinBtn />
            <DialogWrapper
              wrapperClassName="md:min-w-[500px]"
              className="w-fit"
              content={<CopyLink type="comm" id={id} />}
            >
              <button
                onClick={() => mixpanel.track("Community share button clicked")}
                className="gap-2 rounded-lg bg-green-500  px-3 py-1.5 text-base font-bold text-white hover:opacity-75 border-2 border-white"
              >
                <Icon
                  icon={"carbon:share"}
                  className="text-xl text-white mx-auto"
                />
              </button>
            </DialogWrapper>
          </div>
          <div className="my-3">
            <hr />
          </div>
          {/* <RelatedCommunities relatedCommunities={relatedCommunities} /> */}
          {/* <Ruler /> */}
          <RulesAccordion rules={communityData.rules} />
          <div className="my-3">
            <hr />
          </div>
          <Moderators
            mods={communityData.moderators}
            name={communityData.name}
          />

          <div className="mb-[5px]">
            <DialogWrapper className="z-50 w-full" content={<Invite />}>
              <button
                onClick={() => {
                  mixpanel.track("Invite user button click", {
                    email: user ? user["email"] : "",
                  });
                }}
                className="bg-transparent rounded-lg border border-solid border-orange-700 hover:bg-accentYellow-100 transition-colors w-full flex justify-center items-center gap-[10px] h-[40px]"
              >
                <Icon
                  icon="carbon:add-filled"
                  className="text-xl  text-slate-700 "
                />
                <div className="text-xs text-slate-700">Invite Friends</div>
              </button>
            </DialogWrapper>
          </div>
        </section>
      )}
    </Card>
  );
}
