

  export const brandData: Array<{ title: string; value: string }> = [
    { title: "Christian Dior", value: "16" },
    { title: "Gucci", value: "8" },
    { title: "Hermes", value: "8" },
    {
      title: "Maison Francis Kurkdjian",
      value: "12",
    },
    { title: "Prada", value: "10" },
    { title: "Paco Rabannne", value: "8" },
    { title: "Chanel", value: "16" },
    {
      title: "Estee Lauder",
      value: "10",
    },
    { title: "Ralph Lauren", value: "8" },
    { title: "Burberry", value: "14" },
    { title: "Lancome", value: "15" },
    {
      title: "Yves Saint Laurent",
      value: "16",
    },
    { title: "Armani", value: "19" },
    { title: "Versace", value: "21" },
    { title: "Dolce Gabbana", value: "22" },
    { title: "Tom Ford", value: "27" },
    { title: "Montblanc", value: "28" },
    { title: "Calvin Klein", value: "29" },
    { title: "Givenchy", value: "30" },
  
    { title: "Theirry Mugler", value: "6" },
    { title: "Marc Jacobs", value: "3" },
    { title: "Parfums De Marly", value: "15" },
    { title: "Byredo", value: "8" },
    { title: "Jimmy Choo", value: "6" },
    { title: "Chloe", value: "6" },
    { title: "Guerlain", value: "6" },
    { title: "Initio", value: "5" },
    { title: "Elizabeth Arden", value: "5" },
    { title: "Elizabeth Taylor", value: "5" },

    { title: "Diptyque", value: "46" },

    { title: "Frederic Malle", value: "48" },
    { title: "Viktor Rolf", value: "49" },
    { title: "Acqua di Parma", value: "50" },
    {
      title: "Jean-Paul Gaultier",
      value: "52",
    },
    {
      title: "Juliette Has a Gun",
      value: "54",
    },
    { title: "Le Labo", value: "63" },
    { title: "Kilian", value: "65" },
    { title: "Creed", value: "69" },
    { title: "Valentino", value: "70" },
    { title: "Kayali", value: "71" },
    { title: "Davidoff", value: "72" },
    { title: "Amouage", value: "73" },
    { title: "Issey Miyake", value: "79" },
    { title: "Carolina Herrera", value: "80" },
    { title: "Azzaro", value: "86" },
    { title: "Mancera", value: "91" },
    { title: "Xerjoff", value: "99" },
    { title: "Bond No.9", value: "102" },
    { title: "Roja Parfums", value: "104" },
    { title: "Ariana Grande", value: "109" },
    { title: "Moschino", value: "100" },
    { title: "Nishane", value: "114" },
    { title: "Maison Margiela", value: "122" },
    { title: "Armaf", value: "156" },
    { title: "Afnan", value: "157" },
    { title: "Lattafa", value: "158" },
    { title: "Al Haramain", value: "159" },
    { title: "Montale", value: "161" },
  ];

  



  export const brandData2: Array<{ title: string; value: string }> = [
    { title: "Other", value: "0" },
    { title: "Christian Dior", value: "1" },
    { title: "LOréal", value: "2" },
    { title: "Gucci", value: "3" },
    { title: "Hermes", value: "4" },
    {
      title: "Maison Francis Kurkdjian",
      value: "5",
    },
    { title: "Prada", value: "6" },
    { title: "Paco Rabannne", value: "7" },
    { title: "Chanel", value: "8" },
    { title: "Louis Vuitton", value: "9" },
    {
      title: "Estee Lauder Perfume",
      value: "10",
    },
    { title: "Ralph Lauren", value: "11" },
    { title: "Tommy Hilfiger", value: "12" },
    { title: "Hugo Boss", value: "13" },
    { title: "Burberry", value: "14" },
    { title: "Lancome", value: "15" },
    {
      title: "Yves Saint Laurent",
      value: "16",
    },
    { title: "Coach", value: "17" },
    { title: "Guess?", value: "18" },
    { title: "Armani", value: "19" },
    { title: "Bulgari", value: "20" },
    { title: "Versace", value: "21" },
    { title: "Dolce & Gabbana", value: "22" },
    { title: "Kate Spade", value: "23" },
    { title: "Michael Kors", value: "24" },
    { title: "Fendi", value: "25" },
    { title: "Balenciaga", value: "26" },
    { title: "Tom Ford", value: "27" },
    { title: "Montblanc", value: "28" },
    { title: "Calvin Klein", value: "29" },
    { title: "Givenchy", value: "30" },
    { title: "Tumi", value: "31" },
    { title: "Theirry Mugler", value: "32" },
    { title: "Marc Jacobs", value: "33" },
    { title: "PINK", value: "34" },
    { title: "Parfums De Marly", value: "35" },
    { title: "Byredo", value: "36" },
    { title: "Jimmy Choo", value: "37" },
    { title: "Nabeel Perfume", value: "38" },
    { title: "Loewe", value: "39" },
    { title: "Chloe", value: "40" },
    { title: "Guerlain", value: "41" },
    { title: "Initio", value: "42" },
    { title: "Elizabeth Arden", value: "43" },
    { title: "Elizabeth Taylor", value: "44" },
    { title: "Jo Malone", value: "45" },
    { title: "Diptyque", value: "46" },
    { title: "D.S. & Durga", value: "47" },
    { title: "Frederic Malle", value: "48" },
    { title: "Viktor & Rolf", value: "49" },
    { title: "Acqua di Parma", value: "50" },
    { title: "Good Chemistry", value: "51" },
    {
      title: "Jean-Paul Gaultier",
      value: "52",
    },
    { title: "Sephora", value: "53" },
    {
      title: "Juliette Has a Gun",
      value: "54",
    },
    { title: "Atelier", value: "55" },
    { title: "Roberto Cavalli", value: "56" },
    { title: "Stella McCartney", value: "57" },
    { title: "Clinique", value: "58" },
    { title: "Sol de Janeiro", value: "59" },
    { title: "Ellis Brooklyn", value: "60" },
    { title: "Krigler", value: "61" },
    { title: "Philosophy", value: "62" },
    { title: "Le Labo", value: "63" },
    { title: "Indie", value: "64" },
    { title: "Kilian Paris", value: "65" },
    { title: "Boy Smells", value: "66" },
    {
      title: "Escentric Molecules",
      value: "67",
    },
    { title: "Clean Reserve", value: "68" },
    { title: "Creed", value: "69" },
    { title: "Valentino", value: "70" },
    { title: "Lacoste", value: "71" },
    { title: "Davidoff", value: "72" },
    { title: "Amouage", value: "73" },
    { title: "Dunhill", value: "74" },
    { title: "Penhallgons", value: "75" },
    { title: "Floris", value: "76" },
    { title: "London", value: "77" },
    { title: "Miller Harris", value: "78" },
    { title: "Issey Miyake", value: "79" },
    { title: "Carolina Herrera", value: "80" },
    { title: "Glossier", value: "81" },
    { title: "Dossier", value: "82" },
    {
      title: "Bath  & Body Works",
      value: "83",
    },
    { title: "Avon", value: "84" },
    {
      title: "Salvatore Ferragamo",
      value: "85",
    },
    { title: "Azzaro", value: "86" },
    { title: "John Varvatos", value: "87" },
    {
      title: "Abercrombie & Fitch",
      value: "88",
    },
    { title: "Cartier", value: "89" },
    { title: "Kenneth Cole NY", value: "90" },
    { title: "Mancera", value: "91" },
    { title: "Mirage", value: "92" },
    { title: "Perry Ellis", value: "93" },
    { title: "Adidas", value: "94" },
    { title: "Mary Kay", value: "95" },
    { title: "Aramis", value: "97" },
    { title: "Victoria Sercret", value: "98" },
    { title: "Xerjoff", value: "99" },
    { title: "Tommy Bahama", value: "101" },
    { title: "Bond No.9", value: "102" },
    { title: "Axe", value: "103" },
    { title: "Roja Parfums", value: "104" },
    { title: "Jafra", value: "105" },
    { title: "Hollister", value: "106" },
    { title: "ZARA", value: "107" },
    { title: "Liz Claiborne", value: "108" },
    { title: "Ariana Grande", value: "109" },
    { title: "Stetson", value: "109" },
    { title: "Moschino", value: "100" },
    { title: "Mercedes Benz", value: "111" },
    {
      title: "Beverly Hills Polo Club",
      value: "112",
    },
    { title: "Banana Republic", value: "113" },
    { title: "Nishane", value: "114" },
    { title: "Juicy Couture", value: "115" },
    { title: "Huda Beauty", value: "116" },
    { title: "Lush", value: "117" },
    {
      title: "Urban Outfitters",
      value: "118",
    },
    { title: "Curve", value: "119" },
    { title: "Joop!", value: "120" },
    { title: "DIESEL", value: "121" },
    { title: "Maison Margiela", value: "122" },
    { title: "Old Spice", value: "123" },
    { title: "Vince Camuto", value: "124" },
    { title: "English Laundry", value: "125" },
    {
      title: "Independent (3rd Party)",
      value: "126",
    },
    { title: "Ed Hardy", value: "127" },
    { title: "Lalique", value: "128" },
    { title: "Rue21", value: "129" },
    { title: "Alhambra", value: "130" },
    { title: "Macys", value: "131" },
    { title: "American Eagle", value: "132" },
    {
      title: "Ermenegildo Zegna",
      value: "133",
    },
    { title: "Drakkar Noir", value: "134" },
    { title: "Kim Kardashian", value: "135" },
    {
      title: "Narciso Rodriguez",
      value: "136",
    },
    { title: "NEST Fragrances", value: "137" },
    { title: "Britney Spears", value: "138" },
    { title: "Tiffany & Co.", value: "139" },
    { title: "Tory Burch", value: "140" },
    { title: "Commodity", value: "141" },
    { title: "AERIN", value: "142" },
    { title: "DKNY", value: "143" },
    {
      title: "Oscar de la Renta",
      value: "144",
    },
    { title: "Escada", value: "145" },
    { title: "Coty", value: "146" },
    { title: "TOCCA", value: "147" },
    { title: "Vince Wang", value: "148" },
    { title: "Donna Karan", value: "149" },
    { title: "Ulta", value: "150" },
    { title: "Paris Hilton", value: "151" },
    { title: "Target", value: "152" },
    { title: "Taylor Swift", value: "153" },
    {
      title: "House of Sillage",
      value: "154",
    },
    { title: "Nina Ricci", value: "155" },
    { title: "Armaf", value: "156" },
    { title: "Afnan", value: "157" },
    { title: "Lattafa", value: "158" },
    { title: "Al Haramain", value: "159" },
    { title: "Zimaya", value: "160" },
    { title: "Juliette Has A Gun", value: "160" },
    { title: "Montale", value: "161" },
  ];

   