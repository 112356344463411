import { HTMLAttributes } from 'react';
import { cn } from '../../../../helpers/utils';
import { Icon } from '@iconify/react';

interface PostFooterProps extends HTMLAttributes<HTMLDivElement> {
  comments: any;
}

export default function PostFooter({comments, className, ...props }: PostFooterProps) {
  return (
    <section
      className={cn("flex items-center justify-between mt-2.5", className, {})}
      {...props}
    >
      <div className="flex items-center gap-5">
        <div
        
          className="text-xs flex items-center gap-1.5 font-medium text-neutral-400"
        >
          <Icon icon="ri:chat-4-line" className="text-xl" />
          <p className="max-sm:hidden">{comments} comments</p>
        </div>
      </div>
      <div className="flex items-center gap-2.5">
        <p className="text-xs font-medium text-neutral-400">5 people here</p>
      </div>
    </section>
  );
}

const data = [
  {
    icon: "ri:chat-4-line",
    label: "ri:chat-4-line",
  },
];
