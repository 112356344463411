import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface InitialStateType {
  loading: boolean;
  error: string | null;
  orderList: any;
  trackingLoading: boolean;
  trackingError: string | null;
  feedbackOLoading: boolean;
  feedbackOError: string | null;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  loading: true,
  error: null,
  orderList: null,
  trackingLoading: false,
  trackingError: null,
  feedbackOLoading: false,
  feedbackOError: null,
};

export const fetchOrders = createAsyncThunk(
  "user/fetchOrders",
  async (userId: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/purchases/seller/${userId}`
    );
    return response.data;
  }
);

export const addTracking = createAsyncThunk(
  "user/addTracking",
  async ({ trackNum, id }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/purchases/${id}/tracking/${trackNum}`
    );
    return response.data;
  }
);

export const addBuyerFeedback = createAsyncThunk(
  "user/addBuyerFeedback",
  async ({ purchaseId, feedbackRate, userId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/purchases/seller/feedback`,
      {
        userId: userId,
        purchaseId: purchaseId,
        feedbackRate: feedbackRate,
      }
    );
    return response.data;
  }
);

export const userOrdersSlice = createSlice({
  name: "userOrders",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orderList = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(addTracking.pending, (state) => {
        state.trackingLoading = true;
        state.trackingError = null;
      })
      .addCase(addTracking.fulfilled, (state, action) => {
        state.trackingLoading = false;
      })
      .addCase(addTracking.rejected, (state, action) => {
        state.trackingLoading = false;
        state.trackingError = action.error.message || "An error occurred";
      })
      .addCase(addBuyerFeedback.pending, (state) => {
        state.feedbackOLoading = true;
        state.feedbackOError = null;
      })
      .addCase(addBuyerFeedback.fulfilled, (state, action) => {
        state.feedbackOLoading = false;
      })
      .addCase(addBuyerFeedback.rejected, (state, action) => {
        state.feedbackOLoading = false;
        state.feedbackOError = action.error.message || "An error occurred";
      });
  },
});

export const {} = userOrdersSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userOrdersSlice.reducer;
