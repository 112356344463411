// @ts-nocheck
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import useSwiperRef from "../../../../hooks/useSwiperRef";
import useAuthUser from "../../../../hooks/getAuthUser";
import Card from "../../../common/Card";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import { fetchCommunitiesByGenre } from "../../../../redux/features/communties/communtiesSlice";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { categoriesData } from "../../Home/Categories/categoriesData";
import mixpanel from "mixpanel-browser";

const Categories = ({ value, select }: { value: string; select?: Number }) => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const [nextEl, nextRef] = useSwiperRef();
  const [prevEl, prevRef] = useSwiperRef();
  const [selector, setSelector] = useState(value);

  useEffect(() => {
    dispatch(fetchCommunitiesByGenre(selector.toUpperCase()));
  }, [selector]);
  return (
    <Card className="category-card bg-gradient-to-r from-zinc-200 to-slate-300 shadow-lg">
      <div className="flex justify-between">
        <p className="text-xs">
          Categories
          <Link to="/">
            <span className="font-semibold text-red-600 text-[11px] relative bottom-1 ml-1">
              Shop here
            </span>
          </Link>
        </p>
      </div>

      <div className="mt-[10px] ">
        <div className="">
          <Swiper
            modules={[Navigation]}
            navigation={{
              prevEl,
              nextEl,
            }}
            spaceBetween={10}
            breakpoints={{
              0: {
                slidesPerView: 3,
              },
            }}
          >
            {categoriesData.map((el, idx) => {
              return (
                <SwiperSlide key={"home-category-slider-item " + idx}>
                  <div
                    className={clsx(
                      selector === el.label
                        ? "cursor-pointer flex flex-col items-center border-b-4 border-orange-300  shadow-lg "
                        : "cursor-pointer flex flex-col items-center hover:border-b-4 hover:border-orange-300 shadow shadow-neutral-500/50"
                    )}
                    onClick={() => {
                      setSelector(el.label);
                      mixpanel.track("Community Category Selected", {
                        category_name: el.label,
                        email: user ? user["email"] : "",
                      });
                    }}
                  >
                    <div
                      className={clsx(
                        selector === el.label
                          ? "rounded-[50%] overflow-hidden w-[30px] h-[30px] relative bg-orange-100 "
                          : "rounded-[50%] overflow-hidden w-[30px] h-[30px] relative hover:bg-orange-100 bg-slate-100 "
                      )}
                    >
                      <img
                        className="absolute w-[76x] h-76x]"
                        src="/assets/vectors/home/slider-border.svg"
                        alt="border"
                      />
                      <img
                        className="rounded-[50%] w-[48px] h-[48px] absolute left-2/4 -translate-x-2/4 top-2/4 -translate-y-2/4"
                        src={el.icon}
                        alt="slider"
                      />
                    </div>
                    <div className="mt-1 text-center font-semibold text-[11px]">
                      {el.label}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Card>
  );
};

export default Categories;
