import React from 'react'
import GradientBtn from '../../../buttons/GradientBtn';
import GreyBtn from '../../../buttons/GreyBtn';
import DialogHeader from '../../../common/dialog-header';
import CompHeader from '../../Comp/CompHeader';

const PricingFees = () => {
  return (
    <div>
      <DialogHeader />
      <h2 className="text-center mb-3 text-black text-3xl font-semibold">
        Seller Fees
      </h2>
      <div className="bg-white rounded-lg border border-slate-400/20">
        <CompHeader fee />
      </div>
    </div>
  );
}

export default PricingFees;
