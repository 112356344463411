import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { searchUsers } from "../../../redux/features/search/searchSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Card from "../../common/Card";
import NotAdded from "../../common/Error/NotAdded";
import Loader from "../../common/Loader/loader";

export const SearchPeople = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { usersList, loading, error } = useAppSelector((state) => state.search);
  useEffect(() => {
    dispatch(searchUsers(searchParams.get("q") || ""));
  }, [searchParams]);
  return (
    <Card className="divide-y p-0 bg-white">
      {loading ? (
        <Loader />
      ) : usersList ? (
        usersList?.map((el, idx) => {
          return (
            <Link to={`/user/${el.id}/gear`}>
              <div
                key={"notification" + idx}
                className={cn(
                  "flex p-4 items-center justify-between gap-3 border-solid border-accentGray-400 transtion-all bg-transparent notification-item"
                )}
              >
                <div className="flex-shrink-0 relative">
                  <img
                    className="w-11 h-11 rounded-[50%]"
                    src={`/assets/imgs/avatar/${el.profilePicUri}.svg`}
                    alt={el.userName}
                  />
                </div>
                <div className="flex-grow pt-1">
                  <h4 className="text-sm font-semibold flex items-center gap-3">
                    {el.userName} ({el.firstName})
                    <div className="text-accentGray-700"> · </div>
                    <div className="flex items-center">
                      <img
                        className="w-3 h-3"
                        src="/assets/vectors/home/flower.svg"
                        alt="flower"
                      />
                      <p className="ml-1 text-2xs">{el.muzPoints}</p>
                    </div>
                    <div className="text-accentGray-700"> · </div>
                    <p className="ml-1 text-2xs">{el.background}</p>
                  </h4>
                </div>
                <div className="flex-shrink-0 max-sm:hidden ">
                  <button className="h-8 px-8 py-[5px] bg-neutral-100 rounded-full text-sky-600 text-sm font-bold">
                    Follow
                  </button>
                </div>
              </div>
            </Link>
          );
        })
      ) : (
        <NotAdded
          m1="Nice Try"
          m2="No Results"
          m3="No matches to your request."
        />
      )}
    </Card>
  );
};
