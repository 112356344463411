import { Icon } from "@iconify/react";
import * as Accordion from "@radix-ui/react-accordion";
import { Variants, motion } from "framer-motion";
import { ElementRef, useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {
  fetchProducts,
  fetchMarket,
} from "../../../redux/features/market/marketplaceSlice";
import { useAppDispatch } from "../../../redux/hooks";
import {  useSearchParams } from "react-router-dom";
import SearchSelectMulti from "../../common/search-select-multi";
import { Checkbox } from "../Create-Post/Listing/shipping";
import { searchFilterData } from "./search-filter.data";
import { addExistingParamsArrayValue, removeExistingParamsArrayValue } from "./filter-mob-full";

export const SearchFilterMob = ({
  setFilterVisible,
}: {
  setFilterVisible: (value: React.SetStateAction<boolean>) => void;
}) => {
  const dispatch = useAppDispatch();
  const [selectedPerson, setSelectedPerson] = useState(["Gender", "Brands"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.getAll("category");
  const formula = searchParams.getAll("formula");
  const gender = searchParams.getAll("gender");
  const brands = searchParams.getAll("brands");
  const filterContainerRef = useRef<ElementRef<typeof motion.nav>>(null);
  const close = () => setFilterVisible(false);

  useOnClickOutside(filterContainerRef, close);

  return (
    <motion.nav
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{ type: "keyframes" }}
      variants={filterVariants}
      ref={filterContainerRef}
      className="max-w-[85%] flex flex-col z-50 shadow-2xl bg-white fixed inset-0 ml-auto"
    >
      <div className="py-1.5 flex items-center justify-between pl-4 pr-6 bg-gradient-to-l from-red-500 to-yellow-500 rounded-t  w-full">
        <h4 className="text-white text-[15px] font-semibold flex gap-4">
          Filter Search
        </h4>
        {/* <Icon
          role="button"
          onClick={close}
          className="text-black"
          icon={"ph:x-bold"}
        /> */}
      </div>
      <Accordion.Root type="multiple" defaultValue={selectedPerson} >
        {searchFilterData.map((_, idx) => (
          <Accordion.Item
            value={_.label}
            role="button"
            className="py-0.5 text-sm font-semibold text-left "
            key={_.label}
          >
            <Accordion.Trigger asChild className="group px-4 py-3 bg-gray-100">
              <div className="flex items-center justify-between">
                {_.label}
                <Icon
                  icon={"mdi:plus"}
                  className="w-8 text-xl transition-all rotate-180 group-aria-expanded:hidden"
                />
                <Icon
                  icon={"mdi:minus"}
                  className="w-8 text-xl transition-all rotate-180 group-aria-expanded:block hidden"
                />
              </div>
            </Accordion.Trigger>
            <Accordion.Content className="font-normal cursor-default space-y-2.5 px-4 py-1.5">
              {_.extra || null}
              {_.options.map((el: any, idx) => (
                <div key={idx} role="button">
               {_.label == "Category" && el.label && (
                    <Checkbox
                      className="[&_.label]:text-neutral-700 [&_.label]:text-xs [&_.icon-container]:rounded-none"
                      label={el.label}
                      checked={formula.includes(el.value) ? true : false}
                      defaultValue={category.includes(el.value) ? true : false}
                      onChange={(value: any) =>
                        category.includes(el.value)
                          ? setSearchParams(
                              removeExistingParamsArrayValue(
                                searchParams,
                                "category",
                                el.value
                              )
                            )
                          : setSearchParams(
                              addExistingParamsArrayValue(
                                searchParams,
                                "category",
                                el.value
                              )
                            )
                      }
                    />
                  )}
               {_.label == "Formula" && el.label && (
                    <Checkbox
                      className="[&_.label]:text-neutral-700 [&_.label]:text-xs [&_.icon-container]:rounded-none"
                      label={el.label}
                      checked={formula.includes(el.value) ? true : false}
                      defaultValue={formula.includes(el.value) ? true : false}
                      onChange={(value: any) =>
                        formula.includes(el.value)
                          ? setSearchParams(
                              removeExistingParamsArrayValue(
                                searchParams,
                                "formula",
                                el.value
                              )
                            )
                          : setSearchParams(
                              addExistingParamsArrayValue(
                                searchParams,
                                "formula",
                                el.value
                              )
                            )
                      }
                    />
                  )}
                   {_.label == "Gender" && el.label && (
                    <Checkbox
                      className="[&_.label]:text-neutral-700 [&_.label]:text-xs [&_.icon-container]:rounded-none"
                      label={el.label}
                      checked={formula.includes(el.value) ? true : false}
                      defaultValue={gender.includes(el.value) ? true : false}
                      onChange={(value: any) =>
                        gender.includes(el.value)
                          ? setSearchParams(
                              removeExistingParamsArrayValue(
                                searchParams,
                                "gender",
                                el.value
                              )
                            )
                          : setSearchParams(
                              addExistingParamsArrayValue(
                                searchParams,
                                "gender",
                                el.value
                              )
                            )
                      }
                    />
                  )}
           
                </div>
              ))}

              {_.label == "Brands" && <SearchSelectMulti />}
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
      <div className="grow" />
      <div className="p-4 flex [&>*]:grow gap-4 grid grid-cols-2">
        <button
          className="bg-[#2d2d2d] h-[35px] text-white text-xs font-semibold rounded"
          onClick={(val) =>
            dispatch(
              fetchMarket({
                page: 1,
                category,
                formula,
                gender,
                brands,
              })
            )
          }
        >
          View Items
        </button>
        <button
          className="bg-[#fff] h-[35px]  text-xs font-semibold rounded border-2 border-slate-800/50"
          onClick={(val) => {  dispatch(fetchProducts(0));
            setSearchParams({});
            setSelectedPerson([]);
}}
        >
          Clear all
        </button>
      </div>
    </motion.nav>
  );
};

const filterVariants: Variants = {
  hidden: {
    x: "-100%",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};
