import { CartList } from "./cart-list";
import { HowPay } from "./how-pay";
import { Save4Later } from "./save-for-later";
import { useOktaAuth } from "@okta/okta-react";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useEffect } from "react";
import { fetchCart, fetchCartSession } from "../../../redux/features/cart/cartSlice";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";

const Cart = () => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const userToken = Cookies.get("cartId");
  useEffect(() => {
    // if (userToken) { dispatch(fetchCartSession(userToken)) } else {
    //   dispatch(fetchCartSession(""));
    // }
   
    mixpanel.track("Cart page view", { email: user ? user["email"] : "" });
  }, [userId]);

  return (
    <div className="lg:mt-10 mb-[10em]">
      <HowPay />
      <CartList />
      {/* <Save4Later /> */}
    </div>
  );
};

export default Cart;
