import { Icon } from "@iconify/react";
import * as Accordion from "@radix-ui/react-accordion";
import { HTMLAttributes, useEffect, useState } from "react";

interface HelpProps extends HTMLAttributes<HTMLDivElement> {
  type: string;
}

export default function FaqAccordion({ type }: HelpProps) {
  const action = () => {
    switch (type) {
      case "buy":
        return buyData;
        break;
      case "sell":
        return sellData;
        break;
      case "comm":
        return commData;
        break;
      default:
        return buyData;
    }
  };
  return (
    <section>
      <p className="text-xl font-semibold text-center">
        Frequently Asked Questions
      </p>
      <Accordion.Root
        type="single"
        className="lg:space-y-4 space-y-2 my-6"
        collapsible
      >
        {action().map((_, idx) => (
          <Accordion.Item
            value={idx.toString()}
            role="button"
            className="lg:p-4 p-2 bg-white rounded-lg text-sm text-left border-b last:border-0 border-slate-200"
            key={_.ques}
          >
            <Accordion.Trigger asChild className="group">
              <div className="flex items-center justify-between">
                {_.ques}
                <Icon
                  icon={"iconamoon:arrow-up-2-light"}
                  className="w-8 text-xl transition-all rotate-180 group-aria-expanded:rotate-0"
                />
              </div>
            </Accordion.Trigger>
            <Accordion.Content className="pt-3 mt-3 border-t border-t-black/5">
              {_.ans}
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </section>
  );
}

const buyData = [
  {
    ques: "What is my protection as a buyer?",
    ans: "Every item is Pairrit Authenticated. Shop on Pairrit with confidence knowing every purchase is Pairrit Verified. You are free to return within 3 days if you are unsatified.",
  },
  {
    ques: "How to make an offer?",
    ans: "Select the 'Make an Offer' button on the listing. Enter your offer amount. Include a message to the seller if you would like. Select Submit Offer.",
  },
  {
    ques: "How are returns handled?",
    ans: "You have 3 days to request a return from the date you received the item(s), we only accept returns on New Products. We do not issue refunds or accept returns or exchanges on any used(Non-New) items sold.",
  },
  {
    ques: "Do used products qualitfy for a refund/return?",
    ans: "Only New(Non-Used) products are eligible for returns. ",
  },
  {
    ques: "What are the steps of getting a refund/return started?",
    ans: "Request a refund on the order. Wait 24 Hours. Once approved, ship the item back us. Once the item is verified by us, your refund should be initiated in 48 hours. ",
  },
  {
    ques: "Who is responsible for return shipping costs?",
    ans: "Only New(Non-Used) products are eligible for returns. Item(s) must include the return label and packinng slip provided by us, we will provide a prepaid label.",
  },
  {
    ques: "How do I cancel an order?",
    ans: "You may cancel your order within 3 hours by heading to your Purchases/Orders tab, locating the specific order, and following the steps. First Contact the seller via the messages before canceling the order. ",
  },
  {
    ques: "How to contact a seller?",
    ans: "Select the Message Seller button on the individual listing to start a message thread between you and the seller. The majority of sellers on Pairrit respond to messages within 24 hours.",
  },
  {
    ques: "Shipping Costs?",
    ans: "The cost of shipping is $5 to the 48 contiguous states and $7.25 to Hawaii and Alaska. We offer next day shipping at an additional $4.",
  },
  {
    ques: "Expected shipping time?",
    ans: "All items are shipped to us first for authentication before being delievered to you. It will typically take 6-8 busniess days (M-F) for orders to be delievered to buyer located in the US.",
  },
  {
    ques: "Shipping outside United States?",
    ans: "We only currently ship to United States at the moment, as we grow we will serve other countries. Thank You",
  },
];

const sellData = [
  {
    ques: "How do I list my item on Pairrit?",
    ans: "After setting up shop, Go to 'Create Post', then select the appropriate selections. Fill out the information according to your fragrance description, details and photo's.",
  },
  {
    ques: "How do returns work for sellers on Pairrit?",
    ans: "All used products sales are all final, only new products can be returned. If returned product passes verification, we will handle the returning process.",
  },
  {
    ques: "What fees will I pay for selling on Pairrit?",
    ans: "There is a 8.5% commission fee for every item sold on Pairrit. There are no fees for creating a new listing or removing a listing if it has not sold. This fee is assessed off of the total sale amount (including shipping).",
  },
  {
    ques: "I sold an item, what's next?",
    ans: "We will send you a prepaid, pre-addressed shipping label to your email. Drop it off with the courier listed, once the item has shipped, and passes our verification will initiate your payout. ",
  },
  {
    ques: "What happens if my product was rejected?",
    ans: "If you send items to us doesnt match the description you provided, you will have the option to have the product sent back to you or consigned on your behalf with us.",
  },
  {
    ques: "What happens if my product is found fake?",
    ans: "If you send items to us that we deem inauthentic or unsellable, the buyer will be refunded the full amount they paid, and you will have to option to have the products sent back or disposed.",
  },
  {
    ques: "Why Am I supposed to send my items to Pairrit?",
    ans: "To ensure all items sold on Pairrit are authentic, sellers are required to send their items to us for verification by our specialists.",
  },
  {
    ques: "How to connect a bank account with Pairrit Payments?",
    ans: "You can link your bank in the Billing Tab. Then, connect your bank account with manual verification.",
  },
  {
    ques: "What should I do if a buyer reports issues on an order?",
    ans: "If the buyer has reached out within 3 days, you can redirect them to Pairrit. If the buyer has requested a return outside of the allotted time to do so, let them know and politely decline their request.",
  },
  {
    ques: "Can I share a seller account?",
    ans: "On Pairrit, users may not share seller accounts. Users who are found to be sharing seller accounts may be suspended from selling.",
  },
  {
    ques: "Can I sell from outside the US?",
    ans: "Sorry, currently our marketplace is operating in the US at the moment.",
  },
  {
    ques: "Can I cancel my sale?",
    ans: "If you must cancel, you may do so in the app under the order's tab. Cancellations will lead to a higher commission fee. ",
  },
];

const commData = [
  {
    ques: "Can I change my username?",
    ans: "No, once a username is finalized, it can’t be changed in any way, including its capitalization.",
  },
  {
    ques: "How do I post?",
    ans: "Find the 'New Post' button and select the type of thread you want to create, with the correct type of category.",
  },
  {
    ques: "How do I create a community?",
    ans: "Find the Communities page and select the 'Create Community', select the type of category and name.",
  },
  {
    ques: "Recovering a lost or forgotten username?",
    ans: "If you’ve added an email address to your Pairrit account, you can request an email to recover your username and log in. Unfortunately, if you haven’t added an email address to your account, you can’t look up your username. ",
  },
  {
    ques: "How do I log in to Pairrit I forgot my password?",
    ans: "If you’ve added an email address to your Pairrit account, you can request an email to reset your password and log in. Unfortunately, if you haven’t added an email address to your account, you’re locked out until you can remember your password.",
  },
  {
    ques: "What are muzPoints?",
    ans: "On Pairrit, your karma is a reflection of how much your contributions mean to the community. How much muzPoints someone has is publicly displayed on their profile. ",
  },
  {
    ques: "What is a Pairrit Premium subscription?",
    ans: "Pairrit Premium is a subscription that auto-renews each month or year. Pairrit Premium members use Pairrit ad-free, exclusive gear reviews, custom gear rooms, and access to all gear rooms. ",
  },
  {
    ques: "How can I support Pairrit?",
    ans: "Pairrit Premium is a subscription that auto-renews each month or year. Pairrit Premium members use Pairrit ad-free, exclusive gear reviews, custom gear rooms, and access to all gear rooms. ",
  },
  {
    ques: "Can I report a post?",
    ans: "Pairrit Premium is a subscription that auto-renews each month or year. Pairrit Premium members use Pairrit ad-free, exclusive gear reviews, custom gear rooms, and access to all gear rooms. ",
  },
];
