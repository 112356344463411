import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface FeedState {
  loading: boolean;
  sent: boolean;
  error: string | null;
  inquiryLoading: boolean;
  inquirySent: boolean;
  inquiryError: string | null;
  waitLoading: boolean;
  waitSent: boolean;
  waitError: string | null;
}

const initialState: FeedState = {
  loading: false,
  error: null,
  inquiryLoading: false,
  inquiryError: null,
  inquirySent: false,
  sent: false,
  waitLoading: false,
  waitError: null,
  waitSent: false,
};

export const inviteUsers = createAsyncThunk(
  "invite/users",
  async ({ senderId, recipientEmail }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/users/invite`,
      {
        senderId: senderId,
        recipientEmail: recipientEmail,
      }
    );
    return response.status;
  }
);

export const joinWaitlist = createAsyncThunk(
  "join/waitlist",
  async ({ senderId, recipientEmail, firstName, lastName, number, type }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/users/waitlist`,
      {
        senderId: senderId,
        recipientEmail: recipientEmail,
        firstName: firstName,
        lastName: lastName,
        number: number,
        type: type
      }
    );
    return response.status;
  }
);

export const sendInquiry = createAsyncThunk(
  "inquiry/send",
  async ({ inquiry, email, message, number }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/inquiry/send`,
      {
        inquiry: inquiry,
        email: email,
        number: number,
        message: message,
      }
    );
    return response.status;
  }
);

const inviteSlice = createSlice({
  name: "invite/user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inviteUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(inviteUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.sent = true;
      })
      .addCase(inviteUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(sendInquiry.pending, (state) => {
        state.inquiryLoading = true;
        state.inquiryError = null;
      })
      .addCase(sendInquiry.fulfilled, (state, action) => {
        state.inquiryLoading = false;
        state.inquirySent = true;
      })
      .addCase(sendInquiry.rejected, (state, action) => {
        state.inquiryLoading = false;
        state.inquiryError = action.error.message || "An error occurred";
      })
      .addCase(joinWaitlist.pending, (state) => {
        state.waitLoading = true;
        state.waitError = null;
      })
      .addCase(joinWaitlist.fulfilled, (state, action) => {
        state.waitLoading = false;
        state.waitSent = true;
      })
      .addCase(joinWaitlist.rejected, (state, action) => {
        state.waitLoading = false;
        state.waitError = action.error.message || "An error occurred";
      });
  },
});

export const {} = inviteSlice.actions;

export const inviteReducer = inviteSlice.reducer;
