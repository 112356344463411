import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import { useOktaAuth } from "@okta/okta-react";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import { ComponentProps, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";
import { cn } from "../../../helpers/utils";
import useAuthUser from "../../../hooks/getAuthUser";
import { createCommunity } from "../../../redux/features/communties/communtiesSlice";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import GreyBtn from "../../buttons/GreyBtn";
import Card from "../../common/Card";
import { Input } from "../../common/input";
import Select from "../../common/select";
import { Loading } from "../../common/Widget/Loading";
import { Form, FormControl, FormField, FormItem } from "../../ui/form";
import { categoriesData } from "../Home/Categories/categoriesData";
const formSchema = z.object({
  creatorId: z.string(),
  name: z
    .string()
    .min(1, { message: "Title is required😅" })
    .max(25, { message: "25 Characters Max😅" }),
  genre: z.string().toUpperCase(),
  type: z.string(),
});
interface CreateCommunityModalProps extends ComponentProps<"div"> {}

const CreateCommunityModal = ({
  className,
  ...props
}: CreateCommunityModalProps) => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { createError, createLoading } = useAppSelector(
    (state) => state.communities
  );
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { authState } = useOktaAuth();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: any) => {
    if (authState?.isAuthenticated) {
      try {
        setLoading(true);
        const response = await axios.post(
          `https://webservice.pairrit.com/pairrit-ws/api/communities/create`,
          values
        );
        setLoading(false);
        setSuccess(true);
        dispatch(
          notifySelect({
            title: "Success, Community is Created",
            type: 0,
            open: true,
          })
        );
        mixpanel.track("Community Created completed", {
          ...values,
          email: user ? user["email"] : "",
        });
      } catch (err: any) {
        setLoading(false);
        setSuccess(false);
        if (!err?.response) {
          dispatch(
            notifySelect({
              title: "No Server Response",
              type: 1,
              open: true,
            })
          );
        } else if (err.response?.status === 409) {
          dispatch(
            notifySelect({
              title: "Community Taken",
              type: 1,
              open: true,
            })
          );
        } else {
          dispatch(
            notifySelect({
              title: "Create Failed",
              type: 1,
              open: true,
            })
          );
        }
      }
      // Submit form data
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
      // Handle form invalidation
    }
  };

  useEffect(() => {
    if (createError) {
      dispatch(
        notifySelect({
          title: `Name Taken`,
          type: 1,
          open: true,
        })
      );
    }
    form.setValue("creatorId", userId);
  }, [userId, createError]);

  return (
    <Form {...form}>
      <div className={cn("", className, {})} {...props}>
        <p className="text-zinc-900 font-medium">Create a community</p>
        <div className="h-px bg-neutral-500/30 my-3" />
        {success ? (
          <Card>
            <Icon
              icon={"icon-park-twotone:success"}
              className="h-12 w-12 text-green-400 mx-auto my-3"
            />
            <h1 className="text-center">
              Your New Shopping Communinty has been Created!
            </h1>
            <div className="flex flex-col gap-2 [&_*]:text-black items-center mt-2 lg:mt-4 w-full ">
              <Link
                className={"w-full [&_*]:!text-white mb-2 mx-auto"}
                to={"/my/communities/own"}
              >
                <GradientBtn
                  className={"w-full [&_*]:!text-white mb-2"}
                  label={"My Created Communities"}
                />
              </Link>
              <div className="w-full flex [&_a]:w-full gap-3">
                <GreyBtn
                  to={`/my/communities`}
                  label={`My Communities`}
                  className={"border w-full p-0"}
                />
                <GreyBtn
                  to={`/browse/all`}
                  label={`All Communities`}
                  className={"border w-full p-0"}
                />
              </div>
            </div>
          </Card>
        ) : (
          <form
            className="mt- relative z-0 md:w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="grid grid-cols-2 mb-8  gap-2 overscroll">
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        uniqueKey="category-selector"
                        onOptionSelect={field.onChange}
                        defaultValue={field.value}
                        options={categoriesData}
                        label="Category"
                        className="overscroll bg-zinc-100 mb-2"
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"genre"}
                control={form.control}
              />
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        uniqueKey="type-selector"
                        onOptionSelect={field.onChange}
                        defaultValue={field.value}
                        options={level}
                        label="Type"
                        className="overscroll-none bg-zinc-100 mb-2"
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"type"}
                control={form.control}
              />
            </div>
            <p className="text-zinc-900  font-medium mb-2 ml-1">Name</p>
            <div className="text-neutral-500 text-xs font-normal flex gap-1 mb-2 ml-1 items-center leading-none">
              Community names including capitalization cannot be changed.
              <Icon icon={"material-symbols:info-outline"} />
            </div>
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Community Name"
                      className="w-[496px] max-md:w-full h-10 px-3 text-neutral-400 outline-gray-500 bg-white rounded border border-gray-100"
                    />
                  </FormControl>
                </FormItem>
              )}
              name={"name"}
              key={"name"}
              control={form.control}
            />
            <p className="text-neutral-500 text-xs font-normal mb-8 ml-1">
              21 Characters remaining
            </p>{" "}
            <div className="-mx-6 p-3.5 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
              {createLoading ? (
                <Loading />
              ) : (
                <GradientBtn
                  short
                  type="submit"
                  label={"Create Community"}
                  className="!rounded-full !font-bold !text-sm !px-[17px]"
                />
              )}
            </div>{" "}
          </form>
        )}
      </div>
    </Form>
  );
};

export default CreateCommunityModal;

const level = [
  {
    icon: "mingcute:user-2-fill",
    label: "Public",
    value: "Public",
    text: "Anyone can view, post, and comment to this community",
  },
  {
    icon: "teenyicons:lock-small-solid",
    label: "Private",
    value: "Private",
    text: "Only approved users can view and submit to this community",
  },
];
