import { Combobox } from "@headlessui/react";
import { Icon } from "@iconify/react";
import clsx from "clsx";
import React, {  useState } from "react";
import { useSearchParams } from "react-router-dom";
import { brandData } from "../../data/brands";
import { cn } from "../../helpers/utils";
import {
  removeExistingParamsArrayValue,
  addExistingParamsArrayValue,
} from "../pages/Search/filter-mob-full";



const SearchSelectMulti = () => {
  
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const brands = searchParams.getAll("brand");
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? brandData
      : brandData.filter((item) => {
          return item.title.toLowerCase().includes(query.toLowerCase());
      });

  return (
    <Combobox
      multiple
      value={selectedPerson}
      onChange={(value) =>
        setSearchParams(
          addExistingParamsArrayValue(searchParams, "brand", value)
        )
      }
    >
      <Combobox.Button>
        {brands.length > 0 ? (
          <ul>
            {brands.map((person: any, idx: any) => (
              <li className="justify-bewteen gap-3 flex" key={idx}>
                {person}{" "}
                <Icon
                  icon={"charm:circle-cross"}
                  className="h-4 w-4 text-red-500"
                  aria-hidden="true"
                  onClick={() =>
                    setSearchParams(
                      removeExistingParamsArrayValue(
                        searchParams,
                        "brand",
                        person
                      )
                    )
                  }
                />
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-xs font-semibold">Select Brands</p>
        )}

        <Combobox.Input
          onChange={(event) => setQuery(event.target.value)}
          className={cn(
            "bg-white p-3 lg:w-[20em] w-full text-sm border border-solid border-accentGray-600 rounded-lg cursor-pointer relative shadow-lg"
          )}
        />
      </Combobox.Button>
      <Combobox.Options
        className={clsx(
          "w-[var(--input-width)] rounded-xl border border-white/5 bg-white p-1 [--anchor-gap:var(--spacing-1)] empty:invisible",
          "bg-white transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
        )}
      >
        {filteredPeople.slice(0, 10).map((item) => (
          <Combobox.Option
            key={item.value}
            value={item.title}
            className="flex md:gap-2 gap-1 items-center p-3 border-b border-solid border-accentGray-600 hover:bg-accentGray-30 transition-colors"
          >
            {item.title}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};

export default SearchSelectMulti;

