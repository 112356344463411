import Router from "./Router";
import { Security } from "@okta/okta-react";
import { oidc } from "./okta/config";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// Import Swiper styles
import "swiper/css";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";

// Near entry of your product, init Mixpanel
mixpanel.init("d5d1837601ac90edb8f78dfb1f7634dd", {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

function App() {
  const navigate = useNavigate();

  const customAuthHandler = () => {
    navigate('/login');
  };

  const oktaAuth = new OktaAuth(oidc);
  const restoreOriginalUri = (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
      <Router />
    </Security>

  );
}

export default App;
