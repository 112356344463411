import { useEffect } from "react";
import { fetchOffersSent } from "../../../../redux/features/userTransactions/offers/userOffersSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import NotAdded from "../../../common/Error/NotAdded";
import Loader from "../../../common/Loader/loader";
import OfferCard from "./OfferCard";

const OfferSent = () => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { buyOfferList, loading } = useAppSelector((state) => state.userOffers);
  useEffect(() => {
    dispatch(fetchOffersSent(userId));
  }, [userId]);

  return (
    <div className="mt-2">
      {loading ? (
        <Loader />
      ) : (
        <div className="mt-4">
          {buyOfferList?.length != 0 ? (
            buyOfferList?.map((el: any, idx: any) => {
              return <OfferCard el={el} idx={idx} buyer />;
            })
          ) : (
            <NotAdded
              m1="Nothing to Find"
              m2="No Offers Sent"
              m3="Make offers to earn FlexPoints"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OfferSent;
