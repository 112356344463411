import React from 'react'
import GradientBtn from '../../buttons/GradientBtn';
import Widget from '../Widget';

const EmptyCart = () => {
  return (
    <div className="col-span-full max-w-screen-xl mx-auto px-4 flex mt-3 justify-start h-60 md:px-8">
      <div className="max-w-lg mx-auto space-y-3 text-center">
        <h3 className="text-orange-600 font-semibold">Nothing to find</h3>
        <p className="text-gray-800 text-4xl font-semibold smtext-5xl">Empty Cart</p>
        <p className="text-gray-600">Start Shopping</p>
      </div>
    </div>
  );
}

export default EmptyCart