import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import { Car } from "phosphor-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { sendInquiry } from "../../../redux/features/post/inviteSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";
import Card from "../../common/Card";
import { Input } from "../../common/input";
import Select from "../../common/select";
import { Loading } from "../../common/Widget/Loading";
import { TextField } from "../../text-field";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../ui/form";

const formSchema = z.object({
  inquiry: z.string(),
  email: z.string(),
  number: z.string(),
  message: z.string(),
});

export default function Feedback() {
  const dispatch = useAppDispatch();
  const { inquiryError, inquiryLoading, inquirySent} = useAppSelector((state) => state.inviteUser);
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
     dispatch(sendInquiry(values));
  }

  
  return !inquirySent ? (
    <Card className="justify-center mt-6 w-40 bg-gray-100 border-2 p-2 rounded-xl shadow-xl text-xl bg-white">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <p className="text-xl">Feedback & Queries</p>
          <div>
            <label className="text-sm">Select Issue*</label>
            <br></br>
            <FormField
              render={({ field }) => (
                <FormItem className="md:w-[50%]">
                  <FormControl>
                    <Select
                      uniqueKey="feedback-selector"
                      onOptionSelect={field.onChange}
                      defaultValue={field.value}
                      options={instrumentOptions}
                      label="-- Select Your Query --"
                      className=" bg-zinc-100  mb-2"
                    />
                  </FormControl>
                </FormItem>
              )}
              name={"inquiry"}
              control={form.control}
            />
            <label className="text-sm">Email Address*</label>
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      type="email"
                      noLabel
                      placeholder="abc@geeksforgeeks.org"
                      className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:border-blue-500 w-full p-2.5"
                    />
                  </FormControl>
                </FormItem>
              )}
              name={"email"}
              key={"Email"}
              control={form.control}
            />
            <label className="text-sm">Contact No.</label>
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      {...field}
                      noLabel
                      type="tel"
                      placeholder="212-234-6898"
                      className="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:border-blue-500 w-full p-2.5"
                    />
                  </FormControl>
                </FormItem>
              )}
              name={"number"}
              key={"Phone"}
              control={form.control}
            />
            <br></br>

            <label className="text-sm">Drop Your Query</label>
            <br></br>
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <TextField {...field} onOptionSelect={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
              name={"message"}
              control={form.control}
            />
            <br></br>
            {inquiryLoading ? (
              <Loading />
            ) : (
              <GradientBtn
                type="submit"
                className={"w-full"}
                label={"Submit"}
              />
            )}
          </div>
        </form>
      </Form>
    </Card>
  ) : (
    <Card>
      <Icon
        icon={"icon-park-twotone:success"}
        className="h-12 w-12 text-green-400 mx-auto my-3"
      />
      <h1 className="text-center">
        Success Your Inquiry has been sent, thanks!
      </h1>
    </Card>
  );
}
 const instrumentOptions = [
   {
     label: "Feedback", value:"Feedback"
   },
   {
     label: "Community Related Queries", value:"Community"
   },
   {
     label: "Payment Related Issue", value:"Payment"
   },
   {
     label: "Profile Related Queries", value:"Profile"
   },
   {
     label: "Advertise With Us", value:"Ads"
   },
 ];