import { Fade } from "react-awesome-reveal";
import Card from "../../common/Card";
import Steps from "./Steps";
import CompHeader from "../Comp/CompHeader";

const Payout = () => {
  return (
    <div className="wrapper ">
      <Card className="containBig bg-[#d1d8e0] py-10 px-5 rounded justify-start items-center flex-col">
        <div className="flex justify-start items-center flex-col text-center">
          <Fade triggerOnce cascade>
            <p className="text-orange-600 text-base md:text-xl font-medium">
              HOW TO SALE?
            </p>
            <h2 className="text-black text-opacity-[0.87] font-semibold text-2xl sm:leading-[1.4] sm:text-[30px] xl:text-[35px]">
              LISTING A PRODUCT
            </h2>
          </Fade>
        </div>
        <div className="flex mt-5">
          <Steps data={data} />
          <div className="w-[3/4]">
            <img
            className=" h-24 w-full cursor-pointer mx-auto object-cover md:block hidden rounded-md my-5"
            src="/assets/imgs/home/fragInfo4.png"
            alt="google logo"
            />
             <img
            className=" h-48 w-full cursor-pointer  object-cover md:block hidden rounded-md my-5"
            src="/assets/imgs/home/fragInfo3.png"
            alt="google logo"
            />
           
          </div>        
        </div>
      </Card>
      <PayoutBox/>
    </div>
  );
};

export default Payout;

export const PayoutBox = () => {
  return (
    <Card className="containBig  py-10 px-5 rounded justify-start items-center flex-col">
      <div className="flex justify-start items-center flex-col text-center">
          <Fade triggerOnce cascade>
            <p className="text-orange-600 text-base md:text-xl font-medium">
              No unexpected charges
            </p>
            <h2 className="text-black text-opacity-[0.87] font-semibold text-2xl sm:leading-[1.4] sm:text-[30px] xl:text-[35px]">
              FEES
            </h2>
          </Fade>
        </div>
        <CompHeader fee/>
     </Card>
  );
};
const data = [
  {
    title: "Open Shop",
    desc: "Setup payout method through stripe",
    subTitle: "5 mins ~",
  },
  {
    title: "Product Selection",
    desc: "Select the 'Category' and 'Type' regarding your post.",
    subTitle: "1 mins ~",
  },
  {
    title: "Description",
    desc: "Fill out the items descriptions to match the listing",
    subTitle: "1 mins ~",
  },
  {
    title: "Shipping",
    desc: "Prepaid? ",
    subTitle: "1 mins ~",
  },
  {
    title: "Price",
    desc: "Experience worry-free transactions with our robust and secure platform.",
    subTitle: "1 mins ~",
  },
  {
    title: "Live Listing",
    desc: "Elevate your music career with our unwavering live musician support.",
    subTitle: "Live",
  },
];
