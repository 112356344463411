import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  isloggedIn: boolean;
  cartTotal: number;
  userId: string;
  email: string;
  loading: boolean;
  loadingProfile: boolean;
  loadingUpdate: boolean;
  loadingPerferences: boolean;
  error: string | null;
  updateError: string | null;
  profileData: any;
  otherProfileData: any;
  preferencesData: any;
  isSearchable: boolean;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  isloggedIn: false,
  userId: "Guest",
  email: "",
  loading: true,
  loadingProfile: true,
  loadingUpdate: false,
  loadingPerferences: true,
  error: null,
  updateError: null,
  profileData: null,
  otherProfileData: null,
  preferencesData: null,
  isSearchable: true,
  cartTotal: 0,
};

export const fetchProfile = createAsyncThunk(
  "user/fetchProfile ",
  async (data: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/profile/my/${data}`
    );
    return response.data;
  }
);

export const fetchAProfile = createAsyncThunk(
  "user/fetchAProfile ",
  async (data: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/profile/${data}`
    );
    return response.data;
  }
);

export const fetchPerferences = createAsyncThunk(
  "user/fetchPerferences",
  async (data: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/notification/settings/${data}`
    );
    return response.data;
  }
);

export const userProfileSlice = createSlice({
  name: "userProfile",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profileData = action.payload;
        state.userId = action.payload.userId;
        state.email = action.payload.email;
        state.cartTotal = action.payload.cartTotal;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchAProfile.pending, (state) => {
        state.loadingProfile = true;
        state.error = null;
      })
      .addCase(fetchAProfile.fulfilled, (state, action) => {
        state.loadingProfile = false;
        state.otherProfileData = action.payload;
      })
      .addCase(fetchAProfile.rejected, (state, action) => {
        state.loadingProfile = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchPerferences.pending, (state) => {
        state.loadingPerferences = true;
        state.error = null;
      })
      .addCase(fetchPerferences.fulfilled, (state, action) => {
        state.loadingPerferences = false;
        state.preferencesData = action.payload;
      })
      .addCase(fetchPerferences.rejected, (state, action) => {
        state.loadingPerferences = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const {} = userProfileSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userProfileSlice.reducer;
