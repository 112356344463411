import React from "react";
import { Link, useLocation } from "react-router-dom";
import Card from "../../../common/Card/Card";
import GradientBtn from "../../../buttons/GradientBtn";
import RegisterForm from "../../../auth/RegisterForm";
import DialogWrapper from "../../../common/dialog-wrapper";
import mixpanel from "mixpanel-browser";
import JoinWaitList from "../../../common/Sidebar/JoinWaitlist";
import Explore2 from "../../../common/Explore/Explore2";
import { PaymentExt } from "../../Checkout/payment-ext";
import { PayoutBox } from "../../How-To/Payout";

const SellerFlags = ({ shop }: any) => {
  
  return (
    <section>
      <Card
        noMt
        className="bg-gradient-to-r from-slate-200 to-slate-300 rounded-xl shadow-lg flags p-0 md:h-[13em] h-[15em] mb-5"
      >
        <div className="grid grid-cols-12 md:gap-2">
          <div className="md:order-1 lg:col-span-3 hidden md:block">
            <img
              className="h-[12em] w-full object-cover "
              src="/assets/imgs/home/fragVerify3.png"
              alt=""
            />
          </div>
          <div className="lg:col-span-6 md:col-span-6 col-span-12 items-center  order-2 flex flex-col mx-auto pt-4">
            <p className="md:text-4xl text-4xl font-bold pb-2  bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-red-700 ">
              Sell on Pairrit
            </p>
            <p className="md:text-sm font-medium text-center">
              Millions of fragrances shoppers are waiting to discover your shop.
            </p>
            <p className="text-sm font-medium mt-2 text-center">
              Join the Pairrit community and start selling today.
            </p>

            <div className="flex gap-3 md:mt-3 mt-5">
              <DialogWrapper content={<JoinWaitList />}>
                <GradientBtn
                  className={"w-full rounded-lg"}
                  label={"Get Verified"}
                  onClick={() => {
                    mixpanel.track("Join waitlist clicked", {
                      screen: "create listing",
                    });
                  }}
                />
              </DialogWrapper>
            </div>
          </div>
          <div className="order-2 lg:col-span-3  max-md:hidden start-end">
            <img
              className=" h-[12em] w-full object-cover bg-transparent  "
              src="/assets/imgs/home/fragVerify1.png"
              alt=""
            />
          </div>
        </div>
      </Card>
      <PaymentExt info={content} />
      <Explore2 />
      <PayoutBox/>
    </section>
  );
};

export default SellerFlags;


const content = [
  {
    icon: "ic:baseline-gpp-good",
    title: "Secure payments",
    desc: "We handle payments",
  },
  {
    icon: "bi:send-check",
    title: "Easy Setup",
    desc: "Leave it to us (24hrs)",
  },
  {
    icon: "healthicons:eco-care",
    title: "Low Fee",
    desc: "7% + 2.9% Commisson",
  },
];