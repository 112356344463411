import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchProductsByCommunity } from "../../../../redux/features/communityFeed/commMarketSlice";
import {  useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import NotAdded from "../../../common/Error/NotAdded";
import Loader from "../../../common/Loader/loader";
import ProductList from "../../Market/product-list";
import { Mods } from "./Mods";

export const CommunityStore = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
      if (id) {
        dispatch(fetchProductsByCommunity(id));
      }
    }, [id]);
  const { productList, loading } = useAppSelector((state) => state.commMarket);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : productList ? (
        <div className="mb-10">
          <ProductList
            value={productList}
            className="sm:grid-cols-3 lg:grid-cols-3"
          />
        </div>
      ) : (
        <NotAdded
          m1="Nothing to Find"
          m2="No Products Added"
          m3="Start selling "
        />
      )}
    </div>
  );
};
