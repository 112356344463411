import { HTMLAttributes, useState } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { getAge } from "../../../helpers/getAge";
import { cn } from "../../../helpers/utils";
import { useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";
import YellowBtn from "../../buttons/YellowBtn";
import Card from "../../common/Card/Card";
import { RatingStars } from "../Single-Page/Listing/details";
import About from "./about";
import { Stat } from "./stat";
import UserAbout from "./user-about";
import { useOktaAuth } from "@okta/okta-react";
import Skeleton from "@mui/material/Skeleton";
import DialogWrapper from "../../common/dialog-wrapper";
import { CopyLink } from "./copy-link";
import { Icon } from "@iconify/react";
import { UserInfo } from "./profile";
import * as RadixTabs from "@radix-ui/react-tabs";

export default function GearRoomProfileUser({
  store,
  user,
  communities,
  thread,
  data,
}: {
  user?: boolean;
  store?: boolean;
  data: any;
  communities?: boolean;
  thread?: boolean;
}) {
  const { pathname } = useLocation();
  const { authState } = useOktaAuth();
  const { loading, loadingProfile } = useAppSelector(
    (state) => state.userProfile
  );
  let params = useParams();
  const [currentTab, setCurrentTab] = useState(
    pathname.split(`/user/${params.id}/`)[1]
  );

  return (
    <>
      <RadixTabs.Root
        className="rounded-xl bg-gradient-to-r from-zinc-400 to-slate-500"
        defaultValue={currentTab}
      >
        <RadixTabs.List className="grid justify-center grid-cols-2 gap-1 mb-2">
          {action.map((_, i) => (
            <RadixTabs.Trigger
              asChild
              key={_.label}
              value={_.link}
              className="flex items-center justify-center  gap-1 lg:gap-3  lg:px-6 px-3 max-lg:rounded-md md:whitespace-nowrap md:py-3 py-1  lg:py-4 text-sm font-semibold group rounded-xl from-red-500 to-yellow-500 text-neutral-100 aria-selected:underline  decoration-red-500 decoration-2 underline-offset-4 lg:underline-offset-8 "
            >
              <NavLink
                to={`/user/${params.id}/${_.link}`}
                className="hover:bg-zinc-400 capitalize  aria-selected:border-y aria-selected:bg-zinc-300 aria-selected:text-red-500"
              >
                <Icon
                  icon={_.icon}
                  className=" text-lg hidden md:block aria-selected:text-red-500"
                />
                {_.label}
              </NavLink>
            </RadixTabs.Trigger>
          ))}
        </RadixTabs.List>
      </RadixTabs.Root>
      <Card className=" bg-gradient-to-r from-zinc-200 to-slate-300 rounded shadow-lg">
        <div className="flex max-md:flex-col md:gap-4">
          {(
            !authState?.isAuthenticated
              ? loadingProfile
              : loading || loadingProfile
          ) ? (
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={350}
              height={78}
            />
          ) : (
            <div className="relative flex gap-4 mt-1">
              <img
                className="max-md:w-[90px] md:w-[170px]  rounded-lg aspect-square md:mt-3  shadow-lg"
                src={`/assets/imgs/avatar/${data.profileUrl}.svg`}
                alt=""
              />

              <UserInfo className="md:hidden flex-1" user={data} isUser />
            </div>
          )}
          {(
            !authState?.isAuthenticated
              ? loadingProfile
              : loading || loadingProfile
          ) ? (
            <Skeleton
              className=" mx-auto"
              variant="rounded"
              width={330}
              height={48}
            />
          ) : (
            <div className="flex flex-wrap max-md:flex-col justify-between md:items-center grow">
              <UserInfo className="max-md:hidden md:ml-4" user={data} isUser />
                <Stat className="px-3 mt-3 basis-full" user={data} isUser />
                 <UserAbout store={store} desc={data} />
            </div>
          )}
        </div>
      </Card>
    </>
  );
}



const action = [
  {
    icon: "tdesign:shop",
    label: "Shop",
    link: "shop",
  },
  {
    icon: "carbon:storage-pool",
    label: "Collection",
    link: "gear",
  },
];