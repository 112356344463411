import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import Skeleton from "@mui/material/Skeleton";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { cn } from "../../../../helpers/utils";
import useAuthUser from "../../../../hooks/getAuthUser";
import { fetchFragsByBrand } from "../../../../redux/features/market/marketplaceSlice";
import { addNewListingProperties } from "../../../../redux/features/post/postSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import YellowBtn from "../../../buttons/YellowBtn";
import Card from "../../../common/Card";
import { Input } from "../../../common/input";
import SearchSelect from "../../../common/search-select";
import Select from "../../../common/select";
import { Form, FormControl, FormField, FormItem } from "../../../ui/form";



export function ProductInfo({
  setValue,
  setSizes,
}: {
  setValue: (value: string) => void;
  setSizes: (value: any) => void;
  }) {
  const post = useAppSelector((state) => state.post.newListing);
  const { searchList, searchLoading } = useAppSelector((state) => state.marketplace);
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const formSchema = z.object({
    brandId: z.string().default(post.brandId),
    model: z.string().default(post.model),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  function onSubmit(values: any) {
    dispatch(addNewListingProperties(values));
    mixpanel.track("Sell product (Step 1 Completed)", {
      ...values,
      email: user ? user["email"] : "",
    });
    setValue("Information");
  }
  const [isSelected, setIsSelected] = useState(post.brandId);
  const [isModel, setIsModel] = useState(post.model);

  useEffect(() => {
    form.setValue("brandId", isSelected);
    form.setValue("model", isModel);
  }, [isSelected]);
  
  return (
    <Form {...form}>
      <p className="font-semibold leading-tight">Sell Your Fragrance</p>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className=" text-[11px] font-semibold mt-3">
          Find Your Brand
          <span className="text-red-600">*</span>
        </p>
        <div className="grid grid-cols-2 gap-7">
          <div className="w-full mb-5 ">
            <SearchSelect />
          </div>
          <YellowBtn
            type="submit"
            className="px-4 mx-auto w-1/2 text-sm shadow-lg"
            label={"Continue"}
          />
        </div>
        {searchLoading ? (
          <Card className="bg-white mt-4">
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={350}
              height={48}
            />
          </Card>
        ) : (
          <div className="z-9 grid grid-cols-3 md:lg:xl:grid-cols-4 group bg-white rounded-lg shadow-xl shadow-neutral-100 border ">
            {searchList.map((_: any, idx: any) => (
              <div
                key={idx}
                onClick={() => { setIsSelected(_.id);  setIsModel(_.name); setSizes(_.sizes);}}
                className={cn(
                  "p-3 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer",
                  { "bg-slate-100 pt-0": isSelected == _.id }
                )}
              >
                {isSelected === _.id ? (
                  <img
                    className="w-8 md:w-6 drop-shadow aspect-square right-0"
                    src="/assets/vectors/common/blue-tick.svg"
                    alt=""
                  />
                ) : null}
                <img
                  src={_.img}
                  className="text-green-500 text-3xl md:text-4xl shadow-xl shadow-neutral-100"
                />
                <p className=" text-sm font-medium text-slate-700 mt-3 px-0!">
                  {_.name}
                </p>
                <p className=" text-2xs text-slate-700">
                  {_.formula} • {_.gender}
                </p>
              </div>
            ))}
          </div>
        )}
        <YellowBtn
          type="submit"
          className="px-4 my-6 w-max text-sm"
          label={"Continue"}
        />
      </form>
    </Form>
  );
}


