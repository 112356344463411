import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  marketCategory: string;
  loading: boolean;
  searchLoading: boolean;
  error: string | null;
  productList: any;
  searchList: any;
  sizes: Array<object>;
  brands: Array<any> | any;
  gender: Array<any> | any;
  category: Array<any> | any;
  size: Array<any> | any;
  formula: Array<any> | any;
  price: Array<any> | any;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  marketCategory: "General",
  sizes: [
    {
      size: "SMPL",
      label: 0.07,
    },
    {
      size: "TRAVEL",
      label: 1,
    },
    {
      size: "MINI",
      label: 2,
    },
    {
      size: "FULL",
      label: 3.4,
    },
    {
      size: "EXTRA",
      label: 6.8,
    },
  ],
  loading: true,
  error: null,
  productList: null,
  searchList: null,
  brands: [],
  gender: [],
  category: [],
  size: [],
  formula: [],
  price: [0, 2000],
  searchLoading: true,
};

export const fetchMarket = createAsyncThunk(
  "market/fetchMarket",
  async ({
    sort,
    category,
    formula,
    gender,
    brands,
    maxPrice,
    minPrice,
    maxYear,
    minYear,
  }: any) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/gears/gears/${
        sort ? sort : "POPULAR"
      }?category=${category}&brandName=${brands}&gender=${gender}&formula=${formula}&minRange=${
        minPrice ? minPrice : 0
      }&maxRange=${maxPrice ? maxPrice : 2024}&minYear=${
        minYear ? minYear : 0
      }&maxYear=${maxYear ? maxYear : 2024}`
    );
    return response.data;
  }
);

export const fetchProducts = createAsyncThunk(
  "market/fetchProducts",
  async (page: number) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/gears/all/${page}`
    );
    return response.data;
  }
);

export const fetchFragsByBrand = createAsyncThunk(
  "market/fetchFragsByBrand",
  async (data: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/gears/byBrand/${data}`
    );
    return response.data;
  }
);

export const marketplaceSlice = createSlice({
  name: "marketplace",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetFilter: (state) => {
      state.category = [];
      state.gender = [];
      state.formula = [];
      state.brands = [];
      state.size = [];
    },
    categorySelect: (state, action: PayloadAction<string>) => {
      state.marketCategory = action.payload;
    },
    adjustPrice: (state, action: PayloadAction<any>) => {
      state.price = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchMarket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMarket.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload;
      })
      .addCase(fetchMarket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchFragsByBrand.pending, (state) => {
        state.searchLoading = true;
        state.error = null;
      })
      .addCase(fetchFragsByBrand.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.searchList = action.payload;
        state.sizes = action.payload.sizes;
      })
      .addCase(fetchFragsByBrand.rejected, (state, action) => {
        state.searchLoading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});
export const {
  categorySelect,
  adjustPrice,
  resetFilter,
} = marketplaceSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default marketplaceSlice.reducer;
