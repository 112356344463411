import { HTMLAttributes, useEffect, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import {
  FreeMode,
  Navigation,
  Thumbs,
  Zoom,
  EffectFade,
  Pagination,
} from "swiper";
import { cn } from "../../../../helpers/utils";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { fetchImgs } from "../../../../redux/features/singlePages/getImgs/getImgsSlice";
import Loader from "../../../common/Loader/loader";
import Skeleton from "@mui/material/Skeleton";
import Card from "../../../common/Card";

const GearGallery = ({
  className,
  imgs,
  item,
  md,
  lg,
  ...props
}: {
  className?: string;
  imgs?: any;
  item?: boolean;
  md?: any;
  lg?: any;
} & HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch();

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
 
  return (
    <>
      <div
        {...props}
        className={cn(
          "w-full overflow-hidden border-none  select-none rounded-lg bg-white shadow-lg shadow-slate-100/50 max-md:mb-2",
          {}
        )}
      >
        <Swiper
          loop={true}
          effect={"fade"}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, FreeMode, Thumbs, Zoom]}
          spaceBetween={10}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          zoom={true}
          className={`overflow-hidden w-full h-[15em]  md:h-[30em]`}
        >
          {imgs.map((_: any) => (
            <SwiperSlide
              className="rounded swiper-zoom-container h-full p-1 w-full"
              key={_}
            >
              <img
                alt=""
                className="object-cover rounded"
                src={`${_}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default GearGallery;
