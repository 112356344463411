import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Select from "../../components/common/select";
import  CommSelect  from "../../components/common/comm-select";
import Tabs from "../../components/common/tabs";
import { useOktaAuth } from "@okta/okta-react";
import { Checkbox } from "../../components/pages/Create-Post/Listing/shipping";
import {
  categoriesData,
} from "../../components/pages/Home/Categories/categoriesData";
import {
  categorySelect,
  commSelect,
} from "../../redux/features/post/postSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useTour } from "@reactour/tour";

export default function TabsWrapper() {
  const dispatch = useAppDispatch();
  const { setIsOpen, setCurrentStep } = useTour();
  const { authState } = useOktaAuth();
 
  const { profileData, loading } = useAppSelector((state) => state.userProfile);
  const [selectedInstrument, setSelectedInstrument] = useState("General");
  const [selectedComm, setSelectedComm] = useState("None");
  useEffect(() => {
    dispatch(categorySelect(selectedInstrument));
    dispatch(commSelect(selectedComm));
  });
  useEffect(() => {
    setIsOpen(false);
  }, []);
  return (
    <div className=" ">
      {/* <div className="grid grid-cols-2 md:gap-3 my-3 gap-1">
        <Select
          className="first-step"
          uniqueKey="instrument-selector"
          label="Category"
          defaultValue={selectedInstrument}
          options={categoriesData}
          onOptionSelect={(val) => setSelectedInstrument(val)}
          required
        />
        {loading ? null : (
          <CommSelect
            className="second-step"
            uniqueKey="instrument-selector2"
            label="Community"
            defaultValue={selectedComm}
            options={
              authState?.isAuthenticated && profileData.myCommunities
                ? profileData.myCommunities
                : []
            }
            onOptionSelect={(val) => setSelectedComm(val)}
            optional
          />
        )}
      </div> */}
      <div className="bg-white border rounded-lg  border-slate-400 border-opacity-20 p-2.5">
        {/* <Tabs /> */}
        <Outlet />
        <div className=" flex items-center p-2.5 bg-zinc-100 rounded-lg justify-between mt-3">
          {authState?.isAuthenticated && (
            <Link
              to="/settings/payment"
              className="flex items-center gap-2 text-sm font-medium text-right text-blue-700"
            >
              Update Seller Payment Here
              <img src="/assets/vectors/common/info.svg" alt="" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
