import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentProps, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { useOktaAuth } from "@okta/okta-react";
import { updatePost } from "../../../../../../redux/features/userThreads/userThreadsSlice";
import { notifySelect } from "../../../../../../redux/features/notify/notifySlice";
import { useAppSelector } from "../../../../../../redux/hooks";
import { AppDispatch } from "../../../../../../redux/store";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../../../../ui/form";
import CommSelect from "../../../../../common/comm-select";
import GradientBtn from "../../../../../buttons/GradientBtn";
import { Loading } from "../../../../../common/Widget/Loading";
import { TextField } from "../../../../../text-field";
import { categoriesData } from "../../../Categories/categoriesData";
import Select from "../../../../../common/select";
import { Link } from "react-router-dom";


interface BoostListingProps extends ComponentProps<"form"> {
    info: any;
}

export function UpdatePost({
  className,
    info,
  ...props
}: BoostListingProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { authState } = useOktaAuth();
  const { profileData, loading } = useAppSelector((state) => state.userProfile);
  const { updateError, updateLoading } = useAppSelector((state) => state.userThreads);  
  const [selectedComm, setSelectedComm] = useState(info.community);
  const [selectedCategory, setSelectedCategory] = useState(info.category);
  const formSchema = z.object({
    id: z.string().default(info.id),
    postCategory: z.string().toUpperCase().default(info.postCategory),
    community: z.string().default(info.community),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
    dispatch(updatePost(values))
      .then(() =>
        dispatch(
          notifySelect({
            title: "Your Listing is Updated",
            type: 0,
            open: true,
          })
        )
      )
      .finally(() => window.location.reload());
  }
  useEffect(() => {
    if (updateError) {
      dispatch(
        notifySelect({
          title: `Error`,
          type: 1,
          open: true,
        })
      );
    }
    form.setValue("community", selectedComm);
    form.setValue("postCategory", selectedCategory.toUpperCase());
  }, [info, updateError, selectedComm, selectedCategory]);
  return (
    <Form {...form}>
      <form className="z-50 " onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-3 font-semibold text-lg">
          Edit {info.postContent.title} {info.postContent.brandName}
          {info.postContent.model}
        </p>
        <hr className="my-6" />
        <div className="grid grid-cols-2 md:gap-3 my-3 gap-1">
          <Select
            className="first-step"
            uniqueKey="instrument-selector"
            label="Category"
            defaultValue={selectedCategory}
            options={categoriesData}
            onOptionSelect={(val) => setSelectedCategory(val)}
            required
          />
          {loading ? null : (
            <CommSelect
              className="second-step"
              uniqueKey="instrument-selector2"
              label="Select Community"
              defaultValue={selectedComm}
              options={
                authState?.isAuthenticated && profileData.myCommunities
                  ? profileData.myCommunities
                  : []
              }
              onOptionSelect={(val) => setSelectedComm(val)}
              optional
            />
          )}
        </div>
        {/* <p className=" text-[11px] font-semibold my-1">
          Description
          <span className="text-red-600 text-2xs ml-1">(Only Effects Post Description)</span>
        </p>
        <FormField
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <TextField
                  defaultValue={
                    info.type === "PROD_TOPIC"
                      ? info.postContent.text
                      : info.postContent.description
                  }
                  {...field}
                  onOptionSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
          name={"description"}
          control={form.control}
        /> */}
        {info.type === "PROD_SALE" && (
          <Link to={"/transactions/manage-listings"}>
            <GradientBtn
              short
              type="submit"
              label={"See Manage Listings to edit Product"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          </Link>
        )}

        <div className="-mx-6 p-3.5 mt-24 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
          <button className="text-zinc-600 !text-sm border border-current py-2 px-[17px]  rounded-full font-bold ">
            Cancel
          </button>
          {updateLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              type="submit"
              label={"Submit"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}

