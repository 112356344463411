import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DialogWrapper from "../../../common/dialog-wrapper";
import Loader from "../../../common/Loader/loader";
import { AcceptOffer } from "./AcceptOffer";
import { DeclineOffer } from "./DeclineOffer";

const OfferCard = ({
  el,
  idx,
  buyer,
}: {
  el: any;
  idx: number;
  buyer?: any;
}) => {
  let currentdate = new Date();
  let date = new Date(el.expireDateTime);
  let hours = Math.abs((date as any) - (currentdate as any)) / 36e5;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (imgs: any) => {
      try {
        const response = await axios.get(
          `https://webservice.pairrit.com/pairrit-ws/api/getsignedurl?filenames=${imgs}`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
       if (el.product.condition !== "NEW") {
        fetchData(el.images.toString());
       } else {
         setData(el.images);
         setLoading(false);
       }
    
  }, []);
  return (
    <div
      className="border rounded-lg border-solid bg-white border-accentGray-600 mb-4"
      key={"purchase-" + idx}
    >
      <div className="p-[10px]">
        <div className="flex flex-wrap gap-4 justify-between items-center pb-[10px]">
          <div className="hidden sm:flex gap-4">
            {el.status === "EXPIRED" && (
              <div className="text-sm">Expired Offer</div>
            )}
            {el.status === "DECLINED" && (
              <div className="text-sm text-red-600">Declined Offer</div>
            )}
            {el.status === "ACCEPTED" && (
              <div className="text-sm text-accentGreen-500">Accepted Offer</div>
            )}
            {el.status === "SENT" && (
              <div className="text-sm text-blue-700">Active Offer</div>
            )}
          </div>
          {buyer ? (
            <div className="flex gap-2 sm:w-auto ">
              <div className="text-sm font-semibold">Offer To:</div>
              <div className="flex cursor-pointer text-accentBlue-500 gap-2 items-center text-xs font-semibold">
                <img src="/assets/vectors/purchases/shop.svg" alt="shop" />
                {el.sellerUsername}
              </div>
            </div>
          ) : (
            <div className="flex gap-2 sm:w-auto ">
              <div className="text-sm font-semibold">Offer From:</div>
              <div className="flex cursor-pointer text-accentBlue-500 gap-2 items-center text-xs font-semibold">
                <img src="/assets/vectors/purchases/shop.svg" alt="shop" />
                {el.senderUsername}
              </div>
            </div>
          )}
          <div className="block start-end sm:hidden text-xs text-[10px] text-accentGray-700">
            {hours.toFixed(0)} hours left
          </div>
        </div>

        <div className="flex justify-between bg-accentGray-40 p-[10px] rounded-md border border-solid border-accentGray-600">
          <Link
            className="flex gap-2 sm:gap-3 col-span-12 md:col-span-6"
            to={`/single/listing/${el.listingId}`}
          >
            <div className="w-[100px] text-accentBlue-500 font-semibold text-sm">
              {loading ? (
                <Loader />
              ) : (
                <img
                  width={128}
                  className="rounded-lg max-md:h-[84px]  aspect-square"
                  src={data}
                  alt=""
                />
              )}
            </div>
            <div className="">
              <div className="font-semibold  text-xs sm:text-base">
                {el.title}
              </div>
              <div className="text-accentGray-700 text-xs font-medium">
                Listing #: {el.listingId}
              </div>
              <div className="text-blue-500 text-xs font-medium">
                Message: {el.comment}
              </div>
              {el.status === "SENT" && (
                <div className="text-red-500 text-xs font-medium">
                  {hours.toFixed(0)} hours left
                </div>
              )}
            </div>
          </Link>
          <div className="text-end flex-shrink-0">
            <div className="font-semibold mb-5">${el.price}</div>
            <div className="text-accentBlue-500">
              Offered: ${el.offerAmount}
            </div>
          </div>
        </div>

        <div className="py-[10px] flex justify-between items-center">
          <div className="flex cursor-pointer gap-2 items-center text-xs font-semibold">
            <img src="/assets/vectors/purchases/shop.svg" alt="shop" />
            <div>
              <span className="text-accentBlue-500">{el.senderUsername}</span>{" "}
              offered ${el.offerAmount}
            </div>
          </div>

          <div className="flex sm:hidden gap-4 flex-shrink-0">
            {el.status === "EXPIRED" && (
              <div className="text-xs">Expired Offer</div>
            )}
            {el.status === "DECLINED" && (
              <div className="text-xs text-red-600">Declined Offer</div>
            )}
            {el.status === "ACCEPTED" && (
              <div className="text-xs text-accentGreen-500">Accepted Offer</div>
            )}
            {el.status === "SENT" && (
              <div className="text-xs text-blue-700">Active Offer</div>
            )}
          </div>
          {el.status === "SENT" && (
            <div className="hidden sm:block text-xs text-[10px] text-accentGray-700">
              {hours.toFixed(0)} hours left
            </div>
          )}
        </div>

        {buyer && (
          <div className="pt-[10px] flex justify-start items-center border-t border-solid border-accentgray-600 gap-4 flex-wrap md:flex-nowrap">
            {el.status === "ACCEPTED" && (
              <button className="border border-solid bg-accentGray-400 border-accentGray-600 rounded-md h-9 px-2 sm:px-4 hover:-translate-y-1 transition-transform text-accentGray-200 text-xs">
                See My Cart
              </button>
            )}

            <button className="border border-solid bg-accentGray-400 border-accentGray-600 rounded-md h-9 px-2 sm:px-4 hover:-translate-y-1 transition-transform text-accentGray-200 text-xs">
              Message Seller
            </button>
          </div>
        )}
        {!buyer && (
          <div className="pt-[10px] flex justify-start items-center border-t border-solid border-accentgray-600 gap-4 flex-wrap md:flex-nowrap">
            {el.status === "ACCEPTED" && (
              <button className="border border-solid bg-accentGray-400 border-accentGray-600 rounded-md h-9 px-2 sm:px-4 hover:-translate-y-1 transition-transform text-accentGray-200 text-xs">
                Ship Item
              </button>
            )}
            {el.status === "SENT" && (
              <div className=" flex gap-2">
                {" "}
                <DialogWrapper
                  wrapperClassName="md:min-w-[500px]"
                  className="w-fit"
                  content={<AcceptOffer id={el.id} />}
                >
                  {" "}
                  <button className="border border-solid bg-accentGray-400 border-green-600 rounded-md h-9 px-2 sm:px-4 hover:-translate-y-1 transition-transform text-accentGray-200 text-xs">
                    Accept Offer
                  </button>
                </DialogWrapper>
                <DialogWrapper
                  wrapperClassName="md:min-w-[500px]"
                  className="w-fit"
                  content={<DeclineOffer id={el.id} />}
                >
                  {" "}
                  <button className="border border-solid bg-accentGray-400 border-red-600 rounded-md h-9 px-2 sm:px-4 hover:-translate-y-1 transition-transform text-accentGray-200 text-xs">
                    Decline Offer
                  </button>
                </DialogWrapper>
              </div>
            )}
            <button className="border border-solid bg-accentGray-400 border-accentGray-600 rounded-md h-9 px-2 sm:px-4 hover:-translate-y-1 transition-transform text-accentGray-200 text-xs">
              Message Seller
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferCard;
