// @ts-nocheck
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import useSwiperRef from "../../../../hooks/useSwiperRef";
import useAuthUser from "../../../../hooks/getAuthUser";
import Card from "../../../common/Card";
import { categoriesData} from "./categoriesData";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  categorySelect as homeSelector,
  fetchPostsByType,
  fetchPosts,
} from "../../../../redux/features/homeFeed/homeFeedSlice";
import clsx from 'clsx';
import mixpanel from "mixpanel-browser";

const Categories = ({ value, select }: { value: string; select?: Number; }) => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const [nextEl, nextRef] = useSwiperRef();
  const [prevEl, prevRef] = useSwiperRef();
  const [selector, setSelector] = useState(value);

  useEffect(() => {
    if (selector == 'General') {
      dispatch(fetchPosts());
    } else {
      dispatch(fetchPostsByType(selector.toUpperCase()));
    }
    
  }, [selector]);

  return (
    <Card
      noMt
      className={clsx(
        select === 2
          ? "category-card bg-gradient-to-r from-stone-200 to-zinc-300  shadow-lg rounded px-3 py-1 mb-2 hidden md:block "
          : "category-card bg-gradient-to-r from-stone-200 to-zinc-300  shadow-lg rounded px-3 py-1 mb-2  md:hidden"
      )}
    >
      <div className=" ">
        <div className="">
          <Swiper
            modules={[Navigation]}
            navigation={{
              prevEl,
              nextEl,
            }}
            spaceBetween={10}
            breakpoints={{
              0: {
                slidesPerView: 3,
              },
            }}
          >
            {categoriesData.map((el, idx) => {
              return (
                <SwiperSlide key={"home-category-slider-item " + idx}>
                  <div
                    className={clsx(
                      selector === el.label
                        ? "cursor-pointer flex flex-col items-center border-b-4 border-orange-400  shadow-lg rounded-b"
                        : "cursor-pointer border-x-r flex flex-col items-center hover:border-b-4 hover:border-orange-300 shadow shadow-neutral-500/50 rounded-b"
                    )}
                    onClick={() => {
                      setSelector(el.label);
                      mixpanel.track("Feed Category Selected", {
                        category_name: el.label,
                        email: user ? user["email"] : "",
                      });
                    }}
                  >
                    <div
                      className={clsx(
                        selector === el.label
                          ? "rounded-[50%] overflow-hidden w-[30px] h-[30px] relative bg-orange-300 "
                          : "rounded-[50%] overflow-hidden w-[30px] h-[30px] relative hover:bg-orange-100 bg-slate-100 "
                      )}
                    >
                      <img
                        className="absolute w-[76x] h-[76x]"
                        src="/assets/vectors/home/slider-border.svg"
                        alt="border"
                      />
                      <img
                        className="rounded-[50%] w-[48px] h-[48px] absolute left-2/4 -translate-x-2/4 top-2/4 -translate-y-2/4"
                        src={el.icon}
                        alt="slider"
                      />
                    </div>
                    <div className="mt-1 text-center font-semibold text-[11px]">
                      {el.label}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Card>
  );
};

export default Categories;
