import { zodResolver } from "@hookform/resolvers/zod";
import mixpanel from "mixpanel-browser";
import { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import useAuthUser from "../../../../hooks/getAuthUser";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import {
  boostProduct,
  unBoostProduct,
} from "../../../../redux/features/userStore/userStoreSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { AppDispatch } from "../../../../redux/store";
import GradientBtn from "../../../buttons/GradientBtn";
import { Loading } from "../../../common/Widget/Loading";
import { Form } from "../../../ui/form";

const formSchema = z.object({
  postId: z.string(),
});

interface BoostListingProps extends ComponentProps<"form"> {
  type: any;
  id: any;
}

export function BoostListing({
  className,
  id,
  type,
  ...props
}: BoostListingProps) {
  const dispatch = useDispatch<AppDispatch>();
  const user = useAuthUser();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const { boostError, boostLoading, boostSuccess } = useAppSelector(
    (state) => state.userStore
  );

  function onSubmit(values: any) {
    {
      type
        ? dispatch(unBoostProduct(values))  
        : dispatch(boostProduct(values))       
    }
  }
  useEffect(() => {
    form.setValue("postId", id);
    if (boostError) {
      dispatch(
        notifySelect({
          title: "Error Boosting",
          type: 1,
          open: true,
        })
      );
    }
    if (boostSuccess) {
        dispatch(
          notifySelect({
            title: `Boost ${type ? "Removed" : "Added"}`,
            type: 0,
            open: true,
          })
        );
      mixpanel.track(`Boost ${type ? "Removed" : "Added"}`, {
        product_id: id,
        email: user ? user["email"] : "",
      });
      }
  }, [boostError,boostSuccess,id]);
  return (
    <Form {...form}>
      <form className="z-50 " onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-3 font-bold text-lg">
          {type ? "Remove Boost" : "Add Boost"}
        </p>
        <hr className="my-6" />
        {type ? (
          <div className="text-zinc-900 font-medium">
            Are you sure you want to unboost this listing?
          </div>
        ) : (
          <div className="text-zinc-900 font-medium">
            Are you sure you want to boost exposure this listing? (2%
            Additional)
          </div>
        )}
        <div className="-mx-6 p-3.5 mt-10 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
          <button className="text-zinc-600 !text-sm border border-current py-2 px-[17px]  rounded-full font-bold ">
            Cancel
          </button>
          {boostLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              type="submit"
              label={"Submit"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}
