import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import friendlyTime from "../../../../helpers/friendlyTime";
import GradientBtn from "../../../buttons/GradientBtn/GradientBtn";
import GreyBtn from "../../../buttons/GreyBtn/GreyBtn";
import Card from "../../../common/Card/Card";
import DialogWrapper from "../../../common/dialog-wrapper";
import { Progress } from "@nextui-org/progress";
import { useOktaAuth } from "@okta/okta-react";
import MakeAnOffer from "./make-an-offer";
import Quantity from "./quantity";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import {
  add2Cart,
  add2CartSession,
  addProd2Cart,
} from "../../../../redux/features/cart/cartSlice";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";
import { Info } from "../../Checkout/info";
import Cookies from "js-cookie";
import { generateUUID } from "../../../../helpers/getUUID";

interface CartType {
  name: string;
  id: string;
  postId: string;
  category: string;
  price: number;
  total: number;
  quantity: number;
  size: number;
  formula: string;
  condition: string;
  images: string;
}

export default function Details({
  info,
  isListing,
  offersMade,
}: {
  info?: any;
  offersMade?: any;
    isListing?: boolean;
  
}) {
  const { authState } = useOktaAuth();
  const { cartData, fetchLoading } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const [isWishlist, setIsWishlist] = useState(
    isListing && (info.watchersIds?.includes(userId) ? true : false)
  );
  const [likePressed, setLikePressed] = useState(
    isListing && (info.isLiked?.includes(userId) ? true : false)
  );
 
  const [amount, setAmount] = useState(1);
  const exists = cartData.some((e: any) => e.id == info.productId);
  const [inCart, setInCart] = useState(exists);
  const [cartDetails, setCartDetails] = useState<CartType>({
    name: info.title,
    id: info.productId,
    postId: info.id,
    category: info.type,
    price: info.price,
    total: amount,
    quantity: info.quantity,
    size: info.size,
    formula: info.formula,
    condition: info.condition,
    images: `${info.condition === "NEW" ? info.gear.img : info.images}`,
  });
  useEffect(() => {
    if (isListing) {
      mixpanel.track("Product detail page view", {
        product_name: info.title,
        product_id: info.productId,
        listing_id: info.id,
        product_amount: info.price,
        email: user ? user["email"] : "",
      });
    }
    setCartDetails({
      name: info.title,
      id: info.productId,
      postId: info.id,
      category: info.type,
      price: info.price,
      total: amount,
      quantity: info.quantity,
      size: info.size,
      formula: info.formula,
      condition: info.condition,
      images: `${info.condition === "NEW" ? info.gear.img : info.images}`,
    });
  }, [cartData,amount]);

  const onSubmit = () => {
    if (!cartData.some((e: any) => e.id == info.productId)) {
      if (authState?.isAuthenticated) {
        dispatch(
          add2Cart({
            userId: userId,
            productId: info.productId,
            qty: amount,
          })
        ).then(() =>
          dispatch(
            notifySelect({
              title: "Item Added!",
              type: 0,
              open: true,
            })
          )
        );
        dispatch(addProd2Cart(cartDetails));
        mixpanel.track("Add product to cart", {
          product_name: info.title,
          product_id: info.productId,
          listing_id: info.id,
          product_amount: info.price,
          email: user ? user["email"] : "",
        });
      } else {
        if (Cookies.get("cartId")) {
          dispatch(
            add2CartSession({
              userId: Cookies.get("cartId"),
              productId: info.productId,
              qty: amount,
            })
          ).then(() =>
            dispatch(
              notifySelect({
                title: "Item Added!",
                type: 0,
                open: true,
              })
            )
          );
        } else {
          const uuid = generateUUID();
          Cookies.set("cartId", uuid, {
            expires: 3,
            path: "/",
            secure: true,
            sameSite: "None",
          });
          dispatch(
            add2CartSession({
              userId: uuid,
              productId: info.productId,
              qty: amount,
            })
          ).then(() =>
            dispatch(
              notifySelect({
                title: "Item Added!",
                type: 0,
                open: true,
              })
            )
          );
        }
        dispatch(addProd2Cart(cartDetails));
      }
    } else {
      dispatch(
        notifySelect({
          title: "Check Your Cart",
          type: 2,
          open: true,
        })
      );
    }
  };

  useEffect(() => {
    setIsWishlist(true);
    if (info.isLiked?.includes(userId)) {
      setLikePressed(true);
    }
    if (exists) {
      setInCart(true);
    }
  });

  // Make sure its a listing
  return (
    <Card noMt className="bg-white flex flex-col max-lg:py-4 shadow-inner  p-4">
      <div className="text-2xl font-extrabold uppercase text-left">
        {info.brandName} {info.model}
        {info.status === "SOLD" ? (
          <Alert severity="error">Item Has Been Sold</Alert>
        ) : info.status === "INACTIVE" ? (
          <Alert severity="error">Item is not available</Alert>
        ) : null}
      </div>
      <div className="justify-center ">
        <div className="flex items-center gap-4 my-4">
          <p className="text-[35px] font-semibold">$ {info.price.toFixed(2)}</p>
          <p className="text-[35px] text-gray-400 font-medium line-through">
            {(info.price + info.price * 0.1).toFixed(2)}
          </p>

          {/* <p className="text-lg line-through text-neutral-400">$ 918</p> */}
        </div>

        <Quantity
          max={info.quantity}
          setValue={setAmount}
          postId={info.id}
          value={amount}
        />

        <div className="text-xs font-medium text-neutral-400 [&>b]:pl-1 [&>b]:pr-2 mt-3 lg:mt-5">
          <b className="text-red-600">{info.quantity} Left </b> Condition:{" "}
          <b>{info.condition} </b>
          Size: <b>{info.size < 0.1 ? "SMPL" : `${info.size}oz`} </b>
          Formula: <b>{info.formula} </b>
        </div>

        {info.condition == "NEW" ? (
          <Progress
            size="sm"
            radius="sm"
            classNames={{
              base: "max-w-md mt-3",
              track: "drop-shadow-md border border-default",
              indicator: "bg-gradient-to-r from-pink-500 to-yellow-500",
              label: "tracking-wider font-medium text-default-600",
              value: "text-foreground/60",
            }}
            label="Juice Remaining"
            value={info.usage}
            showValueLabel={true}
          />
        ) : null}

        <div className="flex flex-col gap-3 [&_*]:text-black items-center mt-2 lg:mt-8 w-full ">
          {info.status === "ACTIVE" ? (
            inCart ? (
              <GradientBtn
                label={"Already in cart"}
                onClick={() =>
                  dispatch(
                    notifySelect({
                      title: "Already in cart",
                      type: 2,
                      open: true,
                    })
                  )
                }
                className={"w-full [&_*]:!text-white"}
              />
            ) : (
              <GradientBtn
                label={"Add to Cart"}
                onClick={() => onSubmit()}
                className={"w-full [&_*]:!text-white "}
              />
            )
          ) : (
            <GradientBtn
              label={"Sold Out"}
              className={"w-full [&_*]:!text-white opacity-25 hover:opacity-25"}
            />
          )}

          <div className={"w-full flex [&_a]:w-full gap-3 "}>
            {info.isAcceptOffers === true ? (
              info.status === "ACTIVE" ? (
                authState?.isAuthenticated ? (
                  <DialogWrapper
                    content={
                      <MakeAnOffer
                        id={info.id}
                        price={info.price}
                        offersLeft={3 - offersMade}
                      />
                    }
                    onClick={() =>
                      mixpanel.track("Make offer clicked", {
                        product_name: info.title,
                        product_id: info.productId,
                        listing_id: info.id,
                        product_amount: info.price,
                        email: user ? user["email"] : "",
                      })
                    }
                    className="w-full"
                  >
                    <GreyBtn
                      label={<>Make an Offer</>}
                      className={"border pointer-events-none"}
                    />
                  </DialogWrapper>
                ) : (
                  <button
                    className={
                      "border w-full pointer-events-click font-medium flex items-center justify-center rounded-md bg-accentGray-400 hover:bg-accentGray-500 text-accentGray-700 gap-1.5 transition-colors text-sm border-solid border-[rgba(134, 145, 168, 0.20)]"
                    }
                    onClick={() =>
                      dispatch(
                        notifySelect({
                          title: "Login first",
                          type: 2,
                          open: true,
                        })
                      )
                    }
                  >
                    <p className="md:text-sm font-semibold leading-tight text-white capitalize text-xs w-full">
                      Make an Offer
                    </p>
                  </button>
                )
              ) : null
            ) : (
              <GreyBtn
                to={`/user/${info.author.id}/shop`}
                label={`Message Seller`}
                className={"border text-center"}
              />
            )}

            <GreyBtn
              to={`/user/${info.author.id}/shop`}
              label={
                <span
                  onClick={() =>
                    mixpanel.track("Seller store button clicked", {
                      product_name: info.title,
                      product_id: info.productId,
                      listing_id: info.id,
                      email: user ? user["email"] : "",
                    })
                  }
                  className="flex gap-2"
                >
                  <Icon icon="uil:cart" className="text-xl" />
                  Seller Store
                </span>
              }
              className={"border"}
            />
          </div>

          {/* {!authState?.isAuthenticated && (
            <NotifyButton
              className={"w-full max-w-xs"}
              placeholder="Signin or signup to comment and like"
            />
          )} */}
        </div>
      </div>
    </Card>
  );
}
export function RatingStars({ numberOfReviews }: { numberOfReviews?: string }) {
  return (
    <div className="flex items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 text-yellow-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
      {numberOfReviews && (
        <p className="text-gray-600 font-bold text-xs ml-">
          {`${numberOfReviews}`}
        </p>
      )}
    </div>
  );
}
