import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { fetchAProfile } from "../../../redux/features/user/userProfileSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Loader from "../../common/Loader/loader";
import GearRoomProfileUser from "../My-Gear-Room/gear-profile";
import GearRoomProfile from "../My-Gear-Room/profile";

export default function UserPage() {
  const { otherProfileData, loadingProfile } = useAppSelector(
    (state) => state.userProfile
  );
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fetchAProfile(id));
    }
  }, [id]);

  return (
    <section className="mb-[10em]">
      <GearRoomProfileUser data={otherProfileData} user />
      <Outlet />
    </section>
  );
}
