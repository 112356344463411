import { Fade } from "react-awesome-reveal";
import GradientBtn from "../../buttons/GradientBtn";

const Hero = () => {
  return (
    <section>
      <div className="flex  justify-between items-center ">
        <div className="flex justify-start sm:text-left text-center w-full  items-start flex-col gap-3 sm:gap-5 drop-shadow-xl">
          <Fade triggerOnce cascade direction="left" className="w-full">
            <h4 className="font-bold text-black text-[20px] sm:leading-[1.3] sm:text-[30px] xl:text-[35px] 2xl:text-[35px] drop-shadow-xl">
              Where Fragrance Fanatics Meet
            </h4>
            <p className="text-slate-900 font-medium xl:text-base text-[12px]">
              Pairrit is a fragrance driven social marketplace where members can
              buy and sell their products. Our platform provides a secure,
              transparent environment where you can confidently purchase your
              favorite fragrances or discover new ones. We verify every product
              through a rigorous authentication process to guarantee quality.
            </p>
            <div className="flex justify-start w-full items-center gap-4 sm:flex-row flex-col">
              <GradientBtn className={"w-full"} label="Register Today" />
            </div>
          </Fade>
        </div>
        <Fade
          triggerOnce
          cascade
          direction="right"
          className="max-w-[600px] lg:max-w-[450px] xl:max-w-[550px] w-full"
        >
          <div className="w-full grid place-items-center relative">
            <img src="/assets/imgs/home/fragVerify1.png" />
            <img
              src="/assets/imgs/about/hero-vid-bg.png"
              className="lg:block hidden w-full object-cover absolute left-[51%] top-1/2 -translate-x-1/2 -translate-y-1/2 -z-10  "
              alt=""
            />
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Hero;
