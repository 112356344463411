import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import Loader from "../../common/Loader/loader";
import GearRoomProfile from "../My-Gear-Room/profile";
import { useOktaAuth } from "@okta/okta-react";
import ComingSoon from "../../common/Error/ComingSoon";

export default function MyPage() {
  const { profileData, loading } = useAppSelector((state) => state.userProfile);
  const { authState } = useOktaAuth();
  if (!authState?.isAuthenticated) {
    return <ComingSoon />;
  }
  return (
    <section className="mb-[10em]">
      <GearRoomProfile data={profileData} />
      <Outlet />
    </section>
  );
}
