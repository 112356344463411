import Header from "./Header";
import clsx from "clsx";
import "./Notifications.css";
import Jumbo from "../../common/Jumbotron/Jumbo";
import { useAppSelector } from "../../../redux/hooks";
import {
  KnockFeedProvider,
  KnockProvider,
  NotificationFeed,
} from "@knocklabs/react";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";

const Notifications = () => {
  const { userId } = useAppSelector((state) => state.userProfile);

  useEffect(() => {
    mixpanel.track("Notifications page view");
  }, []);
  return (
    <div className="mb-[7em] lg:mt-10">
      <Header />

      <div className="bg-white border border-solid border-accentGray-600 rounded-lg p-[10px] sm:p-[18px]">
        <div className="justify-between items-center pb-[18px] sm:flex hidden">
          <h3 className="text-[22px] font-semibold">Earlier</h3>
        </div>
        <KnockProvider
          apiKey={`pk_test__ZPXXyg_r4GCG9W0hY2epVE9SFm5-EYLsJc_t0Nd0J0`}
          userId={userId}
        >
          {/* Optionally, use the KnockFeedProvider to connect an in-app feed */}
          <KnockFeedProvider feedId={`15a981a9-213c-486f-ba16-122ddd56735c`}>
            <NotificationFeed />
          </KnockFeedProvider>
        </KnockProvider>
      </div>
    </div>
  );
};

export default Notifications;
