import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import { useAppDispatch } from "../../../redux/hooks";

const Verification = () => {
  return (
    <div className="mt-7">
      <div className="mt-7 grid grid-cols-3 gap-2 max-w-[882px] mx-auto">
        <div className="bg-zinc-200 border border-solid border-accentGray-600 pb-2 px-3 text-center rounded-lg">
          <img
            className="w-24 h-24 cursor-pointer mx-auto object-cover md:block hidden "
            src="/assets/imgs/home/fragVerify1.png"
            alt="google logo"
          />
          <div className="xl:text-2xl text-sm  font-bold md:mt-0 mt-2">
            We Authenticate
          </div>
          <div className="text-xs font-medium">All Products are Checked</div>
          <hr className="v2 my-2" />
          <div className="lg:my-6 flex justify-center">
            <GradientBtn className="w-full" label="Confirm" />
          </div>

          <hr className="v2 my-2" />

          <div className="mt-3 font-semibold text-xs">
            <div className="font-semibold my-2">We confirm validity</div>
            <div className="font-semibold my-2">
              We make sure items match description
            </div>
          </div>
        </div>
        <div className="bg-zinc-200 border border-solid border-accentGray-600 pb-2 px-3 text-center rounded-lg">
          <img
            className="w-24 h-24 cursor-pointer mx-auto object-cover md:block hidden "
            src="/assets/imgs/home/fragVerify2.png"
            alt="google logo"
          />
          <div className="xl:text-2xl text-sm font-bold md:mt-0 mt-2">
            No Fakes
          </div>
          <div className="text-xs font-medium">Experienced Authenicators</div>
          <hr className="v2 my-2" />
          <div className="lg:my-6 flex justify-center">
            <GradientBtn className="w-full" label={`Check`} />
          </div>
          <hr className="v2 my-2" />

          <div className="mt-3 font-semibold text-xs">
            <div className="font-semibold my-2">
              We test for Authicaity of Product
            </div>
            <div className="font-semibold my-2">
              We assure quality matches product
            </div>
          </div>
        </div>
        <div className="bg-zinc-200 border border-solid border-accentGray-600 px-3 pb-2 text-center rounded-lg">
          <img
            className="w-24 h-24 cursor-pointer mx-auto md:block hidden "
            src="/assets/imgs/home/fragVerify3.png"
            alt="google logo"
          />
          <div className="xl:text-2xl text-sm  font-bold  md:mt-0 mt-2">
            We Protect
          </div>
          <div className="text-xs font-medium">We confirm description</div>
          <hr className="v2 my-2" />
          <div className="lg:my-6 flex justify-center">
            <GradientBtn className="w-full" label="Package" />
          </div>
          <hr className="v2 my-2" />
          <div className="mt-3 font-semibold text-xs">
            <div className="font-semibold my-2">
              No Scams or Malicious Acivity
            </div>
            <div className="font-semibold my-2">
              We assure your product is cared for
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
