import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../../../../common/Card";
import "../Post.css";
import { Author } from "../common/author";
import { CardFooter } from "../common/card-footer";
import { User } from "../common/user";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../../../hooks/getAuthUser";

const Topic = ({ el, idx }: { el: any; idx: number }) => {
  const [isExpandedState, setIsExpandedState] = useState(false);
  const user = useAuthUser();
  return (
    <Card className="h-full home-post bg-white shadow" key={"home-post" + idx}>
      <User el={el} time={el.postContent.createdDateTime} />
      <hr />
      <Author topic el={el} idx={idx} />
      <Link
        onClick={() => {
          mixpanel.track("Single post opened", {
            id: el.postContent.id,
            post_type: "topic",
            email: user ? user["email"] : "",
          });
        }}
        to={`/single/topic/${el.postContent.id}`}
      >
        {el.postContent.title && (
          <div className="mt-3 text-base md:text-xl font-medium">
            {el.postContent.title}
          </div>
        )}
        {el.postContent.text && (
          <div className="mt-3 text-sm font-normal">
            {isExpandedState ? (
              <>
                {el.postContent.text}{" "}
                {el.postContent.text.length > 194 && (
                  <span
                    className="cursor-pointer text-accentYellow-500"
                    onClick={() => setIsExpandedState(false)}
                  >
                    Read less
                  </span>
                )}
              </>
            ) : (
              <>
                {el.postContent.text.slice(0, 194)}
                {el.postContent.text.length > 194 && (
                  <>
                    ...{" "}
                    <span
                      className="cursor-pointer text-accentYellow-500"
                      onClick={() => setIsExpandedState(true)}
                    >
                      Read more
                    </span>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </Link>
      <CardFooter el={el} idx={idx} />
    </Card>
  );
};

export default Topic;
