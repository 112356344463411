import { NavLink, useSearchParams } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import Card from "../../common/Card";

export const SearchCategory = () => {
  const [searchParams] = useSearchParams();

  return (
    <Card noMt className=" py-2 px-3.5 max-md:bg-transparent max-md:border-0 md:flex gap-8 bg-white">
      <div className=" flex md:flex-wrap overflow-x-auto scrollbar-hidden gap-3.5 items-center">
        {searchCategories.map((el) => (
          <NavLink
            to={el + "?q=" + searchParams.get("q")}
            key={el}
            className={({ isActive }) =>
              cn(
                "md:p-2.5 p-1.5 px-2 whitespace-nowrap text-sm font-semibold capitalize bg-slate-200 md:bg-white border-slate-400 border rounded md:border-none",
                {
                  "px-2.5 text-red-500 border-red-500 bg-red-100 underline underline-offset-4 md:underline-offset-8":
                    isActive,
                }
              )
            }
          >
            {el.replace("-", " ")}
          </NavLink>
        ))}
      </div>
      <p className="mt-6 md:mt-0 md:font-semibold max-md:text-neutral-400 pb-0.5 pt-2 max-md:border-b max-md:text-xs font-medium">
        Results for "{searchParams.get("q")}"
      </p>
    </Card>
  );
};

export const searchCategories = [
  "fragrances",
  "market",
  // "communities",
  "shops",
];
