import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import GradientBtn from "../../../buttons/GradientBtn/GradientBtn";
import GreyBtn from "../../../buttons/GreyBtn/GreyBtn";
import Card from "../../../common/Card/Card";
import { Chip } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import Share from "./share";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import DialogWrapper from "../../../common/dialog-wrapper";
import NewGear from "../../Create-Post/NewGear";
import { cn } from "../../../../helpers/utils";




export default function GearDetails({
  info,
  listing
}: {
  info?: any;
  listing?: any;
}) {

 const {
  inCollection
 } = useAppSelector((state) => state.newGearPost);


  // Make sure its a listing
  return (
    <Card noMt className="bg-white  flex flex-col max-lg:py-2 shadow md:p-3 p-1">
      <div className="justify-center ">
        <div className="text-2xs md:text-xs flex items-center font-semibold ">
          {/* <img
              src={`/assets/imgs/avatar/${info.author.avatar}.svg`}
              alt=""
              className="w-7 rounded-full aspect-square"
            /> */}
          {/* Seller's username */}
          {/* <div className="ml-2">{info.author.username}</div> */}
          {/* Seller's ratings */}
          <Chip
            icon={
              <Icon className={"text-2xs max-md:hidden"} icon={"mdi:smell"} />
            }
            sx={{
              bgcolor: "background.paper",
              fontSize: "10px",
            }}
            className="text-2xs max-md:hidden mr-1"
            color="success"
            size="small"
            label={info.category}
            variant="outlined"
          />
          <Chip
            icon={
              <Icon className={"text-2xs max-md:hidden"} icon={"mdi:smell"} />
            }
            sx={{
              bgcolor: "background.paper",
              fontSize: "10px",
            }}
            className="text-2xs max-md:hidden mr-1"
            color="success"
            size="small"
            label={info.gender}
            variant="outlined"
          />
          <Chip
            icon={
              <Icon className={"text-2xs max-md:hidden"} icon={"mdi:smell"} />
            }
            sx={{
              bgcolor: "background.paper",
              fontSize: "10px",
            }}
            className="text-2xs max-md:hidden mr-1"
            color="success"
            size="small"
            label={info.formula}
            variant="outlined"
          />
          <Chip
            icon={
              <Icon className={"text-2xs max-md:hidden"} icon={"mdi:smell"} />
            }
            sx={{
              bgcolor: "background.paper",
              fontSize: "10px",
            }}
            className="text-2xs max-md:hidden mr-1"
            color="success"
            size="small"
            label={info.year}
            variant="outlined"
          />
          <Share postId={info.id} />
        </div>
      </div>
    </Card>
  );
}
export function RatingStars({ numberOfReviews, className }: { numberOfReviews?: string; className?: string; }) {
  return (
    <div className={cn("flex items-center mr-1", className
        )}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 text-yellow-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
      </svg>
      {numberOfReviews && (
        <p className="text-gray-600 font-bold text-xs ml-">
          {`${numberOfReviews}`}
        </p>
      )}
    </div>
  );
}
