import Skeleton from "@mui/material/Skeleton";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuthUser from "../../../../hooks/getAuthUser";
import { fetchUserProducts } from "../../../../redux/features/userStore/userStoreSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import YellowBtn from "../../../buttons/YellowBtn";
import Card from "../../../common/Card/Card";
import NotAdded from "../../../common/Error/NotAdded";
import Loader from "../../../common/Loader/loader";
import ForSaleTopBar from "../../Market/for-sale-topbar";
import { ProductCard } from "../../Market/product-card";
import ProductList from "../../Market/product-list";
import GearRoomProfile from "../../My-Gear-Room/profile";

const MyStore = () => {
  const dispatch = useAppDispatch();
  const [isDelete, setIsDelete] = useState(false);
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { userProducts, loading, error } = useAppSelector(
    (state) => state.userStore
  );

  useEffect(() => {
    dispatch(fetchUserProducts(userId));
    mixpanel.track("Shop page view", { email: user ? user["email"] : "" });
  }, [userId]);
  return (
    <div>
      <section>
        {userProducts?.length != 0 ? (
          isDelete === true ? (
            <YellowBtn
              className="mt-2"
              label={"Done Editing"}
              onClick={() => setIsDelete(false)}
              short
            />
          ) : (
            <YellowBtn
              className="mt-2"
              label={"Edit Store"}
              onClick={() => setIsDelete(true)}
              short
            />
          )
        ) : (
          ""
        )}
        {loading ? (
          <Card className="bg-white mt-4">
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={350}
              height={48}
            />
          </Card>
        ) : userProducts?.length != 0 ? (
          <div className="mt-3 grid grid-cols-2 gap-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {userProducts?.map((_: any, key: any) => (
              <div>
                <ProductCard isDelete={isDelete} key={key} info={_} />
              </div>
            ))}
          </div>
        ) : (
          <Card className="bg-white shadow-lg w-full ">
            {" "}
            <NotAdded
              m1="Nothing for sale"
              m2="No Products Added"
              m3="Start Community Shopping"
            />
          </Card>
        )}
      </section>
    </div>
  );
};

export default MyStore;
