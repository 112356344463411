import { ComponentProps } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import Card from "../../../common/Card";
import Loader from "../../../common/Loader/loader";

type CommunityRulesProps = ComponentProps<"div">;

export function Rules({ value }: { value: Array<any> }) {
  const { communityData, loading } = useAppSelector((state) => state.community);
  return (
    <>
      {value.map((el, idx) => (
        <RuleCard rule={el.rule} idx={idx} />
      ))}
    </>
  );
}

const RuleCard = ({ idx, rule }: { idx: number; rule: any }) => {
  return (
    <Card className="border md:mt-6 px-5 py-6 rounded-lg bg-white">
      <div key={idx} className="flex gap-3 md:gap-4 items-center">
        <span className="md:w-16 shrink-0 text-center text-stone-700 text-base md:text-base font-semibold">
          {(idx + 1).toLocaleString("en", { minimumIntegerDigits: 2 })}
        </span>
        <h5 className="text-slate-800 text-sm md:text-base  font-medium">
          {rule}
        </h5>
      </div>
    </Card>
  );
};
