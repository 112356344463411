import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

// Define a type for the slice state
interface InitialStateType {}

// Define the initial state using that type
const initialState: InitialStateType = {};

export const userTransactionsSlice = createSlice({
  name: "userTransactions",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
});

export const {} = userTransactionsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userTransactionsSlice.reducer;
