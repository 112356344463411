import Skeleton from "@mui/material/Skeleton";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useAuthUser from "../../../../../hooks/getAuthUser";
import { fetchUserCommunities } from "../../../../../redux/features/userCommunites/userCommunitiesSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Card from "../../../../common/Card/Card";
import NotAdded from "../../../../common/Error/NotAdded";
import Loader from "../../../../common/Loader/loader";
import ForSaleTopBar from "../../../Market/for-sale-topbar";
import GearRoomProfile from "../../../My-Gear-Room/profile";
import { CommunityCard } from "../Communities";

const OwnedCommunities = () => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { userCommunities, loading } = useAppSelector(
    (state) => state.userCommunities
  );
  useEffect(() => {
    dispatch(fetchUserCommunities(userId));
    mixpanel.track("Community page view", {
      email: user ? user["email"] : "",
    });
  }, []);
  return (
    <div>
      <ForSaleTopBar communities />
      {loading ? (
        <Card className="bg-white mt-1">
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={350}
            height={48}
          />
        </Card>
      ) : userCommunities?.length != 0 ? (
        <section className="grid grid-cols-1 gap-5 p-4 mt-4  bg-white border rounded-lg border-slate-200 lg:grid-cols-2">
          {userCommunities?.map((_: any, idx: any) => (
            <CommunityCard props={_} idx={idx + 1} />
          ))}
        </section>
      ) : (
        <Card className="bg-white shadow-lg w-full ">
          {" "}
          <NotAdded
            m1="Nothing to find"
            m2="No Communities"
            m3="Start Community Shopping"
          />
        </Card>
      )}
    </div>
  );
};

export default OwnedCommunities;
