import React from "react";

const Testimonals = () => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6">
      {action.map((el) => (
        <div className="bg-white rounded-lg p-3">
          <div className="flex items-center space-x-6 mb-4">
            <img
              className="h-16 w-16 object-cover object-center rounded-full"
              src={el.img}
              alt="photo"
            />
            <div>
              <p className="text-base text-gray-700 font-normal mb-1">
                {el.label}
              </p>
              <p className="text-xs text-blue-600 font-normal">{el.job}</p>
            </div>
          </div>
          <div>
            <p className="text-gray-600 leading-loose font-normal text-xs">
              {el.des}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Testimonals;

const action = [
  {
    label: "Jeremy Fragrance",
    job: "Creator/Perfumer",
    des: "I absoluty love Pairrit, its the one stop for getting feedback and answers. Truly get to connect with my peers in my community and find great deals on scents I want! ",
    img: "https://fivmagazine.com/wp-content/uploads/2023/09/jeremy-fragrance-beginn-start-story-history-money-youtube-parfume-usa-interview.jpg",
  },
  {
    label: "Curly Fragrance",
    job: "Reviewer/Creator",
    des: "There's nothing better than finding a community with authentic dialog only focused on fragrances and finding scents. I am addicted to Pairrit.",
    img: "https://yt3.googleusercontent.com/ytc/AIdro_nLRfw4bYymnHJQoWUfTVDr2Di9iUKYzWmDXxa5k0P8Cz8=s176-c-k-c0x00ffffff-no-rj",
  },
  {
    label: "Gents Scents",
    job: "Reviewer/Creator",
    des: "Pairrit is like stripping the best parts of social media and threads speficially for frag heads. Being apart of the Pairrit Fam is for all fragheads. ",
    img: "https://yt3.googleusercontent.com/ytc/AIdro_lrfWUW1O03rqOTSdP4FIx4dAUsR8MdvJCruMQrWNAhH4w=s176-c-k-c0x00ffffff-no-rj",
  },
];
