import mixpanel from "mixpanel-browser";
import { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useAuthUser from "../../../hooks/getAuthUser";
import { useOktaAuth } from "@okta/okta-react";
import { cn } from "../../../helpers/utils";
import { Icon } from "@iconify/react";

const SidebarTop = () => {
  const user = useAuthUser();
 
  const location = useLocation();
  const [first, setfirst] = useState(location);
  
  useEffect(() => {
    setfirst(location);

  }, [location])
  
  const { authState } = useOktaAuth();
  const navItems = useMemo(() => {
    if (authState?.isAuthenticated)
      return [
        {
          to: "/",
          icon: "/assets/vectors/navbar/SHOPPING.svg",
          label: "Home",
        },
        {
          to: "/market",
          icon: "/assets/vectors/navbar/SHOPPING.svg",
          label: "Shop All",
        },

        {
          to: "/shop/women",
          icon: "/assets/vectors/navbar/SHOPPING.svg",
          label: "Women",
        },
        {
          to: "/shop/men",
          icon: "/assets/vectors/navbar/SHOPPING.svg",
          label: "Men",
        },
        {
          to: "/premium",
          icon: "/assets/vectors/navbar/home.svg",
          label: "Protection",
        },
        {
          to: "/help",
          icon: "/assets/vectors/navbar/home.svg",
          label: "Help",
        },
        {
          to: "/about",
          icon: "/assets/vectors/navbar/career-growth.svg",
          label: "How it Works?", //Until We grow will be "Releases"
        },
        {
          to: "/how",
          icon: "/assets/vectors/navbar/help.svg",
          label: "Sell on Pairrit",
        },

        {
          to: "/my/page",
          icon: "/assets/vectors/navbar/COLLECTORS.svg",
          label: "Profile",
        },
      ];
    return [
      {
        to: "/",
        icon: "/assets/vectors/navbar/SHOPPING.svg",
        label: "Home",
      },
      {
        to: "/market",
        icon: "/assets/vectors/navbar/SHOPPING.svg",
        label: "Shop All",
      },
      {
        to: "/shop/women",
        icon: "/assets/vectors/navbar/SHOPPING.svg",
        label: "Shop Women",
      },
      {
        to: "/shop/men",
        icon: "/assets/vectors/navbar/SHOPPING.svg",
        label: "Shop Men",
      },
      {
        to: "/premium",
        icon: "/assets/vectors/navbar/home.svg",
        label: "Protection",
      },
      {
        to: "/help",
        icon: "/assets/vectors/navbar/home.svg",
        label: "Help",
      },
      {
        to: "/about",
        icon: "/assets/vectors/navbar/career-growth.svg",
        label: "How it Works?", //Until We grow will be "Releases"
      },
      {
        to: "/how",
        icon: "/assets/vectors/navbar/help.svg",
        label: "Sell on Pairrit",
      },
    ];
  }, [authState?.isAuthenticated]);

  const navItems1 = useMemo(() => {
    if (authState?.isAuthenticated)
      return [
        {
          to: "/",
          icon: "/assets/vectors/navbar/SHOPPING.svg",
          label: "Home",
        },
        {
          to: "/market",
          icon: "/assets/vectors/navbar/SHOPPING.svg",
          label: "Shop",
        },

        {
          to: "/shop/women",
          icon: "/assets/vectors/navbar/SHOPPING.svg",
          label: "Women",
        },
        {
          to: "/shop/men",
          icon: "/assets/vectors/navbar/SHOPPING.svg",
          label: "Men",
        },
        {
          to: "/premium",
          icon: "/assets/vectors/navbar/home.svg",
          label: "Protection",
        },
     
        {
          to: "/help",
          icon: "/assets/vectors/navbar/home.svg",
          label: "Help",
        },
        {
          to: "/how",
          icon: "/assets/vectors/navbar/help.svg",
          label: "Sell?",
        }
      ];
    return [
      {
        to: "/",
        icon: "/assets/vectors/navbar/SHOPPING.svg",
        label: "Home",
      },
      {
        to: "/market",
        icon: "/assets/vectors/navbar/SHOPPING.svg",
        label: "Shop",
      },
      {
        to: "/shop/women",
        icon: "/assets/vectors/navbar/SHOPPING.svg",
        label: "Women",
      },
      {
        to: "/shop/men",
        icon: "/assets/vectors/navbar/SHOPPING.svg",
        label: "Men",
      },
      {
        to: "/premium",
        icon: "/assets/vectors/navbar/home.svg",
        label: "Protection",
      },
      {
        to: "/help",
        icon: "/assets/vectors/navbar/home.svg",
        label: "Help",
      },

      {
        to: "/how",
        icon: "/assets/vectors/navbar/help.svg",
        label: "Sell?",
      },
    ];
  }, [authState?.isAuthenticated]);
  return (
    <div className="z-40 mt-[6.5em] md:mt-[8em] lg:mt-[4.5em] bg-white fixed w-full flex  shadow md:px-8">
      <div className="max-md:hidden md:justify-between  flex  w-full">
        {navItems.map((el: any, idx: any) => {
          return (
            <NavLink
              to={el.to}
              className={cn(
                "flex items-center  p-2 transition-colors hover:bg-slate-200",
                {
                  "bg-slate-200": first.pathname === el.to,
                }
              )}
              key={"sidebar-nav-item" + idx}
              onClick={() =>
                mixpanel.track("Desktop link clicked", {
                  link_name: el.label,
                  email: user ? user["email"] : "",
                })
              }
            >
              {/* <div>
              <img className="h-5" src={el.icon} alt={el.label} />
            </div> */}
              <div className="font-medium text-black text-sm lg:text-base">
                {el.label}
              </div>
            </NavLink>
          );
        })}
      </div>
      <div className="md:hidden justify-between  flex  w-full">
        {navItems1.map((el: any, idx: any) => {
          return (
            <NavLink
              to={el.to}
              className={cn(
                "flex items-center  p-2 transition-colors hover:bg-slate-200",
                {
                  "bg-slate-200": first.pathname === el.to,
                }
              )}
              key={"sidebar-nav-item" + idx}
              onClick={() =>
                mixpanel.track("Desktop link clicked", {
                  link_name: el.label,
                  email: user ? user["email"] : "",
                })
              }
            >
              {/* <div>
              <img className="h-5" src={el.icon} alt={el.label} />
            </div> */}
              <div className="font-medium text-black text-sm lg:text-base">
                {el.label}
              </div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default SidebarTop;
