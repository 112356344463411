import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import * as Tabs from "@radix-ui/react-tabs";
import GradientBtn from "../../buttons/GradientBtn";
import DialogWrapper from "../../common/dialog-wrapper";
import CreatePost from "../../common/Search/create-post-popover";
import CreateCommunityModal from "./create-community-modal";
import Card from "../../common/Card/Card";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";

export default function Category({
  category,
  post,
}: Partial<{ category: any; post: any; name: string }>) {
  return (
    <section className="flex flex-wrap items-center gap-2">
      <Card noMt className="bg-neutral-200">
        <div className="flex overflow-auto hidden-scrollbar gap-[8px] ">
          {category.map((_: any) => (
            <CategoryCard {..._} post={post} />
          ))}
        </div>
      </Card>
   
    </section>
  );
}

// @ts-ignore
export const CategoryCard = ({ title, icon, value, active, link, post }) => {
  const user = useAuthUser();
  return (
    <NavLink
      role="button"
      className={({ isActive }) =>
        cn(
          "flex items-center h-full max-md:gap-1 gap-2.5 box-border  border border-slate-400 rounded-lg max-md:py-2 py-3 md:p-2.5 p-1.5 px-2 whitespace-nowrap text-sm font-semibold capitalize bg-neutral-300 border-slate-400/20 md:border ",
          {
            "bg-orange-400  text-black text-sm": isActive,
          }
        )
      }
      onClick={() =>
        mixpanel.track("Communities Tab buttons clicked", {
          type: title,
          single:post, 
          email: user ? user["email"] : "",
        })
      }
      to={link}
    >
      <Icon icon={icon} width={20} /> {title}
    </NavLink>
  );
};
