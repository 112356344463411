import { Icon } from "@iconify/react";
import { useOktaAuth } from "@okta/okta-react";
import { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { cn } from "../../../../../../helpers/utils";
import { useAppSelector } from "../../../../../../redux/hooks";
import GradientBtn from "../../../../../buttons/GradientBtn";
import { Privacy } from "../../../../../common/privacy";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch } from "react-redux";
import { notifySelect } from "../../../../../../redux/features/notify/notifySlice";
import { AppDispatch } from "../../../../../../redux/store";
import { Form, FormControl, FormField, FormItem } from "../../../../../ui/form";
import {
  deletePost,
  reportPost,
} from "../../../../../../redux/features/homeFeed/reportSlice";
import { Loading } from "../../../../../common/Widget/Loading";

const formSchema = z.object({
  id: z.string(),
});

interface CreateCommunityModalProps extends ComponentProps<"form"> {
  id: any;
}

export default function DeletePostModal({
  className,
  id,
  ...props
}: CreateCommunityModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const { deleteError, deleteLoading } = useAppSelector(
    (state) => state.reportPost
  );

  function onSubmit(values: any) {
    dispatch(deletePost(values))
      .then(() =>
        dispatch(
          notifySelect({
            title: "Post Removed!",
            type: 0,
            open: true,
          })
        )
      )
      .finally(() => window.location.reload());
  }
  useEffect(() => {
    form.setValue("id", id);
    if (deleteError) {
      dispatch(
        notifySelect({
          title: "Error Sending",
          type: 1,
          open: true,
        })
      );
    }
  }, [deleteError, id]);

  return (
    <Form {...form}>
      <form className="z-50 " onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-1 font-medium">Delete Post</p>
        <hr className="my-2" />
        <div className="text-zinc-900 font-medium">Delete this post?</div>{" "}
        <div className="-mx-6 p-3.5 mt-2 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
          <button className="text-zinc-600 !text-sm border border-current py-2 px-[17px]  rounded-full font-bold ">
            Cancel
          </button>
          {deleteLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              label={"Remove"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}
