import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import { removeGear } from "../../../redux/features/userCollection/userCollectionSlice";
import { useAppSelector } from "../../../redux/hooks";
import { AppDispatch } from "../../../redux/store";
import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import { Loading } from "../../common/Widget/Loading";
import { Form } from "../../ui/form";

type DeleteGearProps = ComponentProps<"div"> & { index: any };

const formSchema = z.object({
  userId: z.string(),
  index: z.number(),
});

export function DeleteGear({ className, index, ...props }: DeleteGearProps) {
  const { userId } = useAppSelector((state) => state.userProfile);
  const { updateLoading, updateError } = useAppSelector(
    (state) => state.userCollection
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const dispatch = useDispatch<AppDispatch>();
  function onSubmit(values: any) {
    dispatch(removeGear(values))
      .then(() =>
        dispatch(
          notifySelect({
            title: "Gear Removed!",
            type: 0,
            open: true,
          })
        )
      )
      .finally(() => window.location.reload());
  }
  useEffect(() => {
    form.setValue("userId", userId);
    form.setValue("index", index);
    if (updateError) {
      dispatch(
        notifySelect({
          title: "Error Adding",
          type: 1,
          open: true,
        })
      );
    }
  }, [updateError, userId]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-3 font-medium">Delete Item</p>
        <hr className="my-6" />
        <div className="text-zinc-900 font-medium">
          Are you sure you want to remove this item?
        </div>{" "}
        <div className="-mx-6 p-3.5 mt-10 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
          <button className="text-zinc-600 !text-sm border border-current py-2 px-[17px]  rounded-full font-bold ">
            Cancel
          </button>
          {updateLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              type="submit"
              label={"Remove Item"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}
