
import { useOktaAuth } from "@okta/okta-react";
import * as Popover from "@radix-ui/react-popover";
import { Link } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { useAppSelector } from "../../../redux/hooks";
import Skeleton from "@mui/material/Skeleton";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";


export function UserMenuMobile() {
  const { oktaAuth, authState } = useOktaAuth();
  const user = useAuthUser();
  const { profileData, loading } = useAppSelector((state) => state.userProfile);

  if (!authState?.isAuthenticated) return <></>;
  return (
      <Link
        to={"/my/page"}
        onClick={() => {
          mixpanel.track("Profile Icon clicked Mobile", {
            email: user ? user["email"] : "",
          });
        }}
      className="md:hidden "
      >
          {loading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          ) : (
            <UserImg
              className={loading ? "animate-pulse" : ""}
              img={profileData?.profileUrl}
            />
          )}
      </Link>
 
  );
}
export function UserImg({ className, img }: { img?: string; className: string }) {
  return (
    <div
      className={cn(
        "relative w-8 md:w-8 bg-white rounded-full aspect-square",
        className
      )}
    >
      <img
        src={`/assets/imgs/avatar/${img}.svg`}
        alt=""
        className="object-cover w-full h-full rounded-full ring-1 ring-zinc-300"
      />{" "}
      <img
        src="/assets/vectors/common/flower.svg"
        alt=""
        className="absolute w-3.5 h-3.5 p-0.5 right-0 bottom-0 bg-white rounded-full"
      />
    </div>
  );
}
