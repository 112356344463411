import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import {
  currentGear,
  pastGear,
  testedGear,
  wantedGear,
} from "./collectionList";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  currentList: any;
  loading: boolean;
  isDelete: boolean;
  error: string | null;
  updateLoading: boolean;
  updateError: string | null;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  loading: false,
  isDelete: false,
  error: null,
  currentList: null,
  updateLoading: false,
  updateError: null,
};

export const fetchGearRoom = createAsyncThunk(
  "user/fetchGearRoom",
  async (data: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/gearRooms/user/${data}`
    );
    return response.data.gears;
  }
);

export const removeGear = createAsyncThunk(
  "remove/gear",
  async ({ userId, index }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/gearRooms/removeGear/${userId}/${index}`
    );
    return response.status;
  }
);

export const userCollectionSlice = createSlice({
  name: "userCollection",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    editItemStatus: (state, action: PayloadAction<boolean>) => {
      state.isDelete = action.payload;
    },
    removeItem: (state, action: PayloadAction<string>) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGearRoom.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGearRoom.fulfilled, (state, action) => {
        state.loading = false;
        state.currentList = action.payload;
      })
      .addCase(fetchGearRoom.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(removeGear.pending, (state) => {
        state.updateLoading = true;
        state.updateError = null;
      })
      .addCase(removeGear.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(removeGear.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.error.message || "An error occurred";
      });
  },
});

export const { editItemStatus } = userCollectionSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userCollectionSlice.reducer;
