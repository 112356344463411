import { useOktaAuth } from "@okta/okta-react";
import { ComponentProps, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@nextui-org/progress";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { add2Cart, add2CartSession, addProd2Cart } from "../../../../redux/features/cart/cartSlice";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import mixpanel from "mixpanel-browser";
import { generateUUID } from "../../../../helpers/getUUID";
import GradientBtn from "../../../buttons/GradientBtn";
import Quantity from "../Listing/quantity";
import Cookies from "js-cookie";
import useAuthUser from "../../../../hooks/getAuthUser";

type CartCardProps = ComponentProps<"div"> & { data: any };
interface CartType {
  name: string;
  id: string;
  postId: string;
  category: string;
  price: number;
  total: number;
  quantity: number;
  size: number;
  formula: string;
  condition: string;
  images: string;
}

export function ProdCard({ className, data, ...props }: CartCardProps) {
  const { authState } = useOktaAuth();
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { cartData, fetchLoading } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState(1);
  const exists = cartData.some((e: any) => e.id == data.id);
  const [inCart, setInCart] = useState(exists);
  const [cartDetails, setCartDetails] = useState<CartType>({
    name: data.name,
    id: data.id,
    postId: data.postId,
    category: data.category,
    price: data.price,
    total: amount,
    quantity: data.quantity,
    size: data.size,
    formula: data.formula,
    condition: data.condition,
    images: data.images,
  });
  useEffect(() => {
    setCartDetails({
      name: data.name,
      id: data.id,
      postId: data.postId,
      category: data.category,
      price: data.price,
      total: amount,
      quantity: data.quantity,
      size: data.size,
      formula: data.formula,
      condition: data.condition,
      images: data.images,
    });
  }, [cartData, amount]);

  const onSubmit = () => {
    if (!cartData.some((e: any) => e.id == data.id)) {
      if (authState?.isAuthenticated) {
        dispatch(
          add2Cart({
            userId: userId,
            productId: data.id,
            qty: amount,
          })
        ).then(() =>
          dispatch(
            notifySelect({
              title: "Item Added!",
              type: 0,
              open: true,
            })
          )
        );
        dispatch(addProd2Cart(cartDetails));
   
        setInCart(true);
      } else {
        if (Cookies.get("cartId")) {
          dispatch(
            add2CartSession({
              userId: Cookies.get("cartId"),
              productId: data.id,
              qty: amount,
            })
          ).then(() =>
            dispatch(
              notifySelect({
                title: "Item Added!",
                type: 0,
                open: true,
              })
            )
          );
        } else {
          const uuid = generateUUID();
          Cookies.set("cartId", uuid, {
            expires: 3,
            path: "/",
            secure: true,
            sameSite: "None",
          });
          dispatch(
            add2CartSession({
              userId: uuid,
              productId: data.id,
              qty: amount,
            })
          ).then(() =>
            dispatch(
              notifySelect({
                title: "Item Added!",
                type: 0,
                open: true,
              })
            )
          );
        }
        dispatch(addProd2Cart(cartDetails));
        setInCart(true);
      }
    } else {
      dispatch(
        notifySelect({
          title: "Check Your Cart",
          type: 2,
          open: true,
        })
      );
    }
  };

  useEffect(() => {
    if (exists) {
      setInCart(true);
    }
  }, [data.product]);
  return (
    <>
      <Link to={`/single/listing/${data.postId}`}>
        <img
          className="rounded-lg flex flex-col items-center h-[5em]  md:h-[7em] w-full"
          src={data.images}
          alt=""
        />
      </Link>

      {/* <div className=" items-center gap-4">
        <p className="font-bold text-xs text-red-500 text-center">New</p>
        <CircularProgress
          classNames={{
            svg: "w-10 h-10 drop-shadow-md ",
            indicator: "stroke-black",
            track: "stroke-white/10",
            value: "text-xs font-semibold text-black",
          }}
          size="sm"
          value={100}
          showValueLabel={true}
        />
      </div> */}
      <div className="flex  md:gap-6 gap-3" {...props}>
        <Link
          className="text-xs max-md:leading-snug max-md:text-[10px]"
          to={`/single/listing/${data.postId}`}
        >
          <p className="font-bold text-sm md:text-base text-red-500">${data.price}</p>
          <p className="text-neutral-400">
            Condition: <span className=" font-semibold">{data.condition}</span>
          </p>
          {/* <p className="text-neutral-400">
            Product code : <span className=" font-semibold">{data.id}</span>
          </p> */}
          <p className="text-neutral-400">
            Size:{" "}
            <span className=" font-semibold">
              {data.size < 0.1 ? "Sample" : `${data.size}ml`}
            </span>
          </p>
          <p className="text-neutral-400">
            Formula: <span className=" font-semibold">{data.formula}</span>
          </p>
        </Link>
      </div>
      {/* <div className=" [&_*]:text-black mt-1   max-md:hidden">
        <Quantity
          max={data.quantity}
          setValue={setAmount}
          postId={data.postId}
          value={amount}
        />{" "}
      </div> */}
      {inCart ? (
        <GradientBtn
          label={"Already in cart"}
          iconClassName="text-xl text-green-500"
          iconify="icon-park:check-one"
          onClick={() =>
            dispatch(
              notifySelect({
                title: "Already in cart",
                type: 2,
                open: true,
              })
            )
          }
          className={"w-full [&_*]:!text-white font-bold"}
        />
      ) : (
        <GradientBtn
          label={"Add to Cart"}
          iconClassName="text-xl text-green-500"
          iconify="bi:cart-plus-fill"
          onClick={() => onSubmit()}
          className={"w-full [&_*]:!text-white font-bold"}
        />
      )}
    </>
  );
}
