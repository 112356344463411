import * as Tabs from "@radix-ui/react-tabs";
import Card from "../../common/Card/Card";
import YellowBtn from "../../buttons/YellowBtn/YellowBtn";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { editItemStatus } from "../../../redux/features/userCollection/userCollectionSlice";
import Loader from "../../common/Loader/loader";
import { Link } from "react-router-dom";
import Gears from "./gears";
import NotAdded from "../../common/Error/NotAdded";

export default function GearIHave({
  data,
}: {
  data: any;
}) {
  const dispatch = useAppDispatch();
  const { isDelete, loading } = useAppSelector(
    (state) => state.userCollection
  );
  const [isUser, setIsUser] = useState(false);
  const [gearSelection, setGearSelection] = useState("current");
  useEffect(() => {
    if (window.location.href.indexOf("my/gear") > -1) {
      setIsUser(true);
    }
  }, [window]);

  return (
    <Tabs.Root value={gearSelection} onValueChange={setGearSelection}>
      <Card className="bg-white shadow-lg">
        <div className="md:mt-2 flex items-center justify-between ">
          <p className="text-2xs lg:text-sm">Fragrance Collection</p>
          {isUser &&
            (isDelete === true ? (
              <YellowBtn
                label={"Done Editing"}
                className="text-xs md:scale-90 scale-[0.89]"
                onClick={() => dispatch(editItemStatus(false))}
                short
              />
            ) : (
              <YellowBtn
                label={"Edit Collection"}
                className="text-xs md:scale-90 scale-[0.89]"
                onClick={() => dispatch(editItemStatus(true))}
                short
              />
            ))}
        </div>
        {loading ? (
          <Loader />
        ) : data && data.length > 0 ? (
          <Gears data={data} />
        ) : (
          <NotAdded
            m1="Nothing to Find"
            m2="No Frags Added"
            m3="Build your collection online"
          />
        )}
      </Card>
    </Tabs.Root>
  );
}



