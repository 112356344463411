import { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Loader from "../../../common/Loader/loader";
import { Chip } from "@mui/material";
import InfoSection from "./info-section";
import { useOktaAuth } from "@okta/okta-react";
import { fetchGearProducts, fetchSingleGear } from "../../../../redux/features/singlePages/newGear/newGearSlice";
import { ProductCard } from "../../Market/product-card";
import NotAdded from "../../../common/Error/NotAdded";
import MarketCategories from "../../Market/Categories";
import GearDetails, { RatingStars } from "./details";
import ProdDetails from "./prod-details";
import GearGallery from "./carousel";
import Card from "../../../common/Card/Card";
import { Icon } from "@iconify/react";
import { PaymentExt } from "../../Checkout/payment-ext";
import Carousel from "./carousel";
import DialogWrapper from "../../../common/dialog-wrapper";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import { add2Wishlist } from "../../../../redux/features/userWishlist/wishlistSlice";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { GearCard } from "./gear-card";
import ProductList from "../../Market/product-list";
import { CartCard } from "../../Cart/cart-card";
import { ProdCard } from "./prod-card";

export default function NewGearPage() {
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const user = authState?.idToken?.claims;
  const { userId } = useAppSelector((state) => state.userProfile);
  const { productList, similarList,error, postData, loading } = useAppSelector((state) => state.newGearPost);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fetchSingleGear({ id: id, user: "Guest" }));
    }
  
  }, [id, authState]);

  const [currentProd, setCurrentProd] = useState("FULL");


function getRandomNumber() {
  return Math.floor(Math.random() * (35 - 18 + 1)) + 18;
}

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <NotAdded
          m1="Nothing to Find"
          m2="Fragrance Not Found"
          m3="Error with finding Fragrance"
        />
      ) : (
        <section className="mb-[8em]">
          {/* <img
            className="md:w-full md:h-[6em] mx-auto mb-2 md:mb-4 object-cover md:mt-2 rounded-lg"
            src="/assets/imgs/home/banner-2.png"
            alt="ad"
          /> */}
          <div className="flex flex-col md:flex-row w-full md:gap-4 lg:gap-2 md:mt-2">
            <GearGallery
              item={postData}
              lg={12}
              md={12}
              imgs={postData.img.split()}
            />

            <div className="lg:w-[40em] md:w-[25em]">
              <Card
                noMt
                className="bg-white flex flex-col max-lg:py-4 gap-1   p-4"
              >
                {" "}
                <div className="flex justify-between">
                  <div className="lg:text-2xl text-lg font-extrabold uppercase text-left">
                    {postData.model}{" "}
                    <span className="font-light text-zinc-500 italic">
                      {postData.brandAbr}
                    </span>
                  </div>
                  <RatingStars numberOfReviews={postData.rate} />
                </div>
                <GearDetails info={postData} />
                <MarketCategories
                  setValue={setCurrentProd}
                  value={currentProd}
                  selection={postData.sizes}
                />
              </Card>
              <p className="font-medium text-center md:mt-3">
                Showing 1-2 of 2
              </p>
              <div className="grid grid-cols-3 md:grid-cols-3 items-center justify-items-center md:mt-1">
                {["Picture", "Details", "Add to Cart"].map((el) => (
                  <p key={el} className="text-xs md:text-sm  font-medium">
                    {el}
                  </p>
                ))}
                <hr className="col-span-full my-2 w-full" />

                {productList?.length != 0 ? (
                  productList
                    .filter((obj: any) => {
                      return obj.label === currentProd;
                    })
                    .map((el: any, idx: any) => {
                      return (
                        <>
                          {/* <ProductCard info={el} />{" "} */}
                          <ProdCard className="" data={el} />
                        </>
                      );
                    })
                ) : (
                  <NotAdded
                    m1="Nothing to Find"
                    m2="Sold Out"
                    m3="Start Selling Today"
                  />
                )}
              </div>
              <hr className="col-span-full my-2 w-full" />
              <NotAdded
                m1="Get Verified"
                m2="Become a Seller"
                m3="8% Sellers Fee"
              />
              <hr className="col-span-full my-1 w-full" />
              <PaymentExt info={content} />
            </div>
          </div>

          {/* <Rates info={postData} /> */}

          <section>
            <div className="bg-white border rounded-lg mt-3 border-slate-400 border-opacity-20 p-2">
              {" "}
              <div className="capitalize text-center">Similar Fragrances</div>
            </div>
            {similarList?.length != 0 ? (
              <div className="mt-6 grid grid-cols-2 gap-2 z-40 lg:grid-cols-4 md:grid-cols-4 xl:grid-cols-6">
                {similarList.map((_: any, key: any) => (
                  <div>
                    <GearCard key={key} info={_} />
                  </div>
                ))}
              </div>
            ) : (
              <NotAdded
                m1="Nothing to Find"
                m2=" No Products Yet"
                m3="Start Listing to earn FlexPoints"
              />
            )}
          </section>
        </section>
      )}
    </>
  );
}
const content = [
  {
    icon: "ic:baseline-gpp-good",
    title: "Seller's",
    desc: "Verified",
  },
  {
    icon: "bi:send-check",
    title: "Free Shipping",
    desc: "Over $149+",
  },
  {
    icon: "healthicons:eco-care",
    title: "Fast Delivery",
    desc: "2-3 Days",
  },
];

const list = [
  {
    id: "PRD100242",
    postId: "ITM100212",
    name: "Intense Cafe",
    price: 89.99,
    usage: 100,
    sellerId: "US100027",
    size: 3.4,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.18021.jpg",
    condition: "NEW",
  },
  {
    id: "PRD100128",
    postId: "ITM100100",
    name: "Libre Intense",
    price: 62.99,
    usage: 100,
    sellerId: "US100032",
    size: 3,
    formula: "EDP",
    images: "https://fimgs.net/mdimg/perfume/375x500.62318.jpg",
    condition: "NEW",
  },
  {
    id: "PRD101060",
    postId: "ITM101030",
    name: "Greenwich Village",
    price: 289.99,
    usage: 100,
    sellerId: "US100038",
    size: 3.4,
    quantity: 4,
    total: 1,
    formula: "PAR",
    images: "https://fimgs.net/mdimg/perfume/375x500.54157.jpg",
    condition: "NEW",
  }];