import { Outlet } from "react-router-dom";
import Jumbo from "../../../common/Jumbotron/Jumbo";
import Tabs from "../../../common/Tabs/Tabs";
import { useOktaAuth } from "@okta/okta-react";
import ComingSoon from "../../../common/Error/ComingSoon";
import { useAppSelector } from "../../../../redux/hooks";
import Card from "../../../common/Card";
import Skeleton from "@mui/material/Skeleton";

const tabs = [
  {
    to: "/settings/account",
    icon: "/assets/vectors/settings/subscription.svg",
    label: "Account",
  },
  {
    to: "/settings/profile",
    icon: "/assets/vectors/settings/profile.svg",
    label: "Profile",
  },
  {
    to: "/settings/notifications",
    icon: "/assets/vectors/settings/notifications.svg",
    label: "Notifications",
  },
  {
    to: "/settings/payment",
    icon: "/assets/vectors/transactions/my-bill.svg",
    label: "Payout",
  },
  {
    to: "/settings/safety",
    icon: "/assets/vectors/settings/safety.svg",
    label: "Safety & Privacy",
  },
];

const SettingsLayout = () => {
  const { authState } = useOktaAuth();
  const { profileData, loading } = useAppSelector((state) => state.userProfile);
  return (
    <div className="mb-[8em] lg:mt-10">
      <Jumbo title={"Settings"} des={"Find and update your settings"} />
      <Tabs tabsData={tabs} />

      {!authState?.isAuthenticated ? (
        <ComingSoon />
      ) : loading ? (
        <Card className="bg-white">
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={230}
            height={48}
          />
        </Card>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default SettingsLayout;
