import { Icon } from "@iconify/react";
import * as Toggle from "@radix-ui/react-toggle";
import { HtmlHTMLAttributes, forwardRef, useId } from "react";
import { cn } from "../helpers/utils";
import { FormMessage } from "./ui/form";

interface TextFieldProps extends HtmlHTMLAttributes<HTMLInputElement> {
  defaultValue?: string;
  placeholder?: string;
  characters?: number;
  onOptionSelect: (val: string) => void;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      className,
      placeholder = "Text (optional)",
      onOptionSelect,
      onChange,
      characters=320,
      defaultValue,
      ...props
    },
    ref
  ) => {
    const id = useId();
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          "overflow-hidden mb-5 bg-stone-50 rounded-md border border-slate-400/20 ",
          {},
          className
        )}
      >
        <textarea
          maxLength={characters}
          defaultValue={defaultValue}
          id={id}
          placeholder={placeholder}
          rows={5}
          className="w-full p-2.5 -mb-2 outline-none text-xs"
          onChange={(v: any) => onOptionSelect(v.target.value)}
        />
        <FormMessage className="text-[10px]" />
        <p className="text-neutral-500 text-xs font-normal">
          {characters} Characters Max
        </p>
      </div>
    );
  }
);

TextField.displayName = "TextField";
export { TextField };
