export const preamble =
  "This policy is a part of Pairrit’s Terms of Use. By opening a shop on Pairrit, you’re agreeing to this Policy and our Terms of Use.";
export const billing = [
  {
    title: "1. Selling fees",
    terms:
      "The Pairrit marketplace was built to provide a safe and secure place for music makers of all types to buy and sell music gear. Our 5% selling fee helps us not only invest in the people needed to sustain our community but also the technology necessary to keep your experience world-class and hiccup-free.",
  },
  {
    title: "2. Fees for Pairrit",
    terms:
      "Selling fees for Pairrit are 5% of the total value of the transaction, including shipping. You pay only when your item sells. Your fee is based on the total selling price, inclusive of all costs except sales tax. You pay only 5% of the total price, with a $0.50 minimum and a $500 maximum fee. No listing fees, listing renewal fees, or monthly membership fees. Fees are based on the final sale price of an item as well as shipping fees on an order.",
  },
  {
    title: "3. Additional Fees",
    terms:
      "Payment processing fees for Pairrit Payments Fees - $0.49 plus 3.19% of the transaction total. For Pairrit Preferred Sellers, the fee is $.49 plus 2.99% of the transaction total. See below for differences in currencies.",
  },
  {
    title: "4. Paypal Fees",
    terms:
      "Fees that apply to sellers using PayPal only - If you do not use Pairrit Payments and instead accept payments only by PayPal, there is no payment processing fee for Pairrit Payments. PayPal fees will be determined by PayPal based on your account and nature of the transaction. You can read more about fees associated with PayPal here.",
  },
  {
    title: "5. Cancelations",
    terms:
      "Payment processing fees for Pairrit Payments will be refunded for up to and including five (5) seller-initiated canceled and fully refunded orders per month only. Payment processing fees for Pairrit Payments will not be refunded for additional seller-initiated canceled and fully refunded orders. For orders above the 5 monthly threshold, payment processing fees for Pairrit Payments will be refunded and recharged via your monthly statement in the following month.",
  },
  {
    title: "6. Billing of Fees and Statements",
    terms:
      "At the beginning of the month, each seller is emailed an invoice detailing the amount due. A seller must pay the amount due in full within 15 days of the date of the invoice. If you are a Pairrit Payments user your fees will be deducted from your payout(s) rather than billed on the first of the month. If you use Pairrit Payments and you receive a statement credit in a particular month, your seller fees may not be deducted from your pay out and, instead, will be deducted from your credited statement.",
  },
  {
    title: "7. Change In Services",
    terms:
      "Pairrit may, at Pairrit's sole discretion, change or terminate some or all of Pairrit's services at any time. In the event Pairrit introduces a new service, the fees for that service are effective at the launch of the service unless otherwise notified to you.",
  },
  {
    title: "8. Additional Information",
    terms:
      "Unless otherwise stated, all fees are quoted in US Dollars (USD). In certain situations, including but not limited to a void or invalid transaction and returns, Pairrit may issue a credit for the applicable fees to a seller's billing statement. You are responsible for paying all fees and shipping fees based on accurate weights and measurements and applicable taxes associated with using Pairrit. Pairrit allows sellers to indicate applicable tax jurisdictions which will be collected from buyers through Pairrit at point of sale. Pairrit keeps accepted payment information on file.",
  },
  {
    title: "9. Pairrit Bump",
    terms:
      "Pairrit provides a method of promoting your listing(s) on the platform, and it's called Pairrit Bump. Pairrit Bump allows you to bid a percentage of your final sale price to compete with other sellers for premium placement of your item(s) on the platform. Pairrit charges for these services in a manner explained below. By purchasing Pairrit Bump, you consent in advance to these charges to the fullest extent permissible by law. Please note, if your listing does sell, the Pairrit Bump fee will be charged in addition to the fees under this Billing Policy. By using Pairrit Bump, you consent to the deduction of Pairrit Bump fees from the proceeds of promoted items.",
  },
];
