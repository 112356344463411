import {
  KnockFeedProvider,
  KnockProvider,
  NotificationFeedPopover,
  NotificationIconButton,
} from "@knocklabs/react";
// Required CSS import, unless you're overriding the styling
import "@knocklabs/react/dist/index.css";
import mixpanel from "mixpanel-browser";
import React from "react";
import { useRef, useState } from "react";
import useAuthUser from "../../../hooks/getAuthUser";
import { useAppSelector } from "../../../redux/hooks";

export const Notifies = () => {
  const [isVisible, setIsVisible] = useState(false);
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const notifButtonRef = useRef(null);

  return (
    <KnockProvider
      apiKey={`pk_test__ZPXXyg_r4GCG9W0hY2epVE9SFm5-EYLsJc_t0Nd0J0`}
      userId={userId}
    >
      {/* Optionally, use the KnockFeedProvider to connect an in-app feed */}
      <KnockFeedProvider feedId={`15a981a9-213c-486f-ba16-122ddd56735c`}>
        <div>
          <NotificationIconButton
            ref={notifButtonRef}
            onClick={(e) => {
              setIsVisible(!isVisible);
              mixpanel.track("Alerts Icon clicked", {
                screen_name: "value",
                email: user ? user["email"] : "",
              });
            }}
          />
          <NotificationFeedPopover
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
          />
        </div>
      </KnockFeedProvider>
    </KnockProvider>
  );
};
