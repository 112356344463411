import React from 'react'
import Payout from './Payout';

const HowTo = () => {
  return (
    <div className="lg:mb-10">
      <Payout />
    </div>
  );
}

export default HowTo;