import { ref, uploadBytesResumable, getDownloadURL } from '@firebase/storage';
import { arrayUnion, collection, doc, getDoc, getDocs, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { db, storage } from './firebase';

export interface MessageType {
  message: string;
  sender: string;
  receiver: string;
  createdAt?: string;
  unSeen?: boolean;
}

export interface ChatType {
  messages: MessageType[];
  users: [string, string];
}

const CHAT_COLLECTION = 'chat'

export async function sendMessage(input: MessageType) {
  const chatId = [input.sender, input.receiver].sort().join('-');
  const data = {
    messages: arrayUnion({
      sender: input.sender,
      message: input.message,
      unSeen: true,
      createdAt: new Date().toISOString(),
      timestamp: new Date().toISOString()
    }) as any,
    users: [input.sender, input.receiver],
  } satisfies ChatType

  try {
    const result = await setDoc(doc(db, CHAT_COLLECTION, chatId), data, {
      merge: true,
    });
    return { result, error: null };
  } catch (error) {
    return { result: null, error };
  }
}

export async function markAsSeen(input: Pick<MessageType, 'sender' | 'receiver'>) {
  const chatId = [input.sender, input.receiver].sort().join('-');
  const docRef = doc(db, CHAT_COLLECTION, chatId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    await setDoc(
      docRef,
      {
        messages: docSnap.data().messages.map((message: MessageType) => {
          return {
            ...message,
            unSeen: false,
          };
        }),
      },
      { merge: true }
    );
  } else {
    console.log('No such document!');
  }
}


export function getMessages(input: Pick<MessageType, 'sender' | 'receiver'>, onChange: (data: MessageType[]) => void) {
  const chatId = [input.sender, input.receiver].sort().join('-');
  const docRef = doc(db, CHAT_COLLECTION, chatId);
  return onSnapshot(docRef, async (snapshot) => {
    const data = snapshot.data()
    onChange(data?.messages as MessageType[])
  })
}


export function getAllChat(userId: string, onChange: (data: ChatType[]) => void) {
  const q = query(
    collection(db, CHAT_COLLECTION),
    where('users', 'array-contains', userId)
  );

  return onSnapshot(q, async (snapshot) => {
    const data: ChatType[] = [];
    snapshot.forEach((doc) => {
      data.push({ ...(doc.data() as ChatType) });
    });
    onChange(data)
  })
}

export function getUnreadCount(userId: string, onChange: (count: number) => void) {
  const q = query(
    collection(db, CHAT_COLLECTION),
    where('users', 'array-contains', userId)
  )
  return onSnapshot(q, async (snapshot) => {
    let count = 0
    snapshot.forEach(snap => {
      (snap.data() as ChatType).messages.forEach(m => {
        if (m.unSeen && m.sender !== userId) ++count
      })
    })
    onChange(count)
  })
}

export function getUnreadCountPerUser(input: { userId: string, otherUserId: string }, onChange: (count: number) => void) {
  const chatId = [input.otherUserId, input.userId].sort().join('-');
  const docRef = doc(db, CHAT_COLLECTION, chatId);
  return onSnapshot(docRef, async (snapshot) => {
    const data = snapshot.data() as ChatType
    let count = 0
    data.messages.forEach(m => {
      if (m.unSeen && m.sender !== input.userId) ++count
    })
    onChange(count)
  })
}

export async function uploadFile(
  file: File,
) {
  if (!file) return;

  const storageRef = ref(storage, `chat-resources/${file.name}`);
  const task = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    task.on(
      'state_changed',
      () => { },
      (error) => {
        reject(error);
      },
      async () => {
        const url = await getDownloadURL(storageRef);
        resolve({ url, ref: storageRef });
      }
    );
  });
}

// TODO: remove it
// 665d695428173437be879880 -  paugustin347 "Ballfor@3"
// 66741f4c4a31fa4ff337227d -  japanfox "Seepass123!"
