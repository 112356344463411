import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  loading: boolean;
  error: string | null;
  addLoading: boolean;
  addError: string | null;
  userProducts: any;
}
// Define the initial state using that type
const initialState: InitialStateType = {
  loading: false,
  error: null,
  addLoading: false,
  addError: null,
  userProducts: null,
};

export const fetchWishlist = createAsyncThunk(
  "user/fetchWishlist",
  async (userId: any) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/wishlist/user/${userId}/wishlist`
    );
    return response.data;
  }
);

export const add2Wishlist = createAsyncThunk(
  "add/wishlist",
  async ({ prodId, userId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/wishlist/products/${prodId}/wishlist/${userId}`
    );
    return response.status;
  }
);

export const removeWishlist = createAsyncThunk(
  "remove/wishlist",
  async ({ prodId, userId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/wishlist/remove/products/${prodId}/wishlist/${userId}`
    );
    return response.status;
  }
);

export const wishlistSlice = createSlice({
  name: "wishlist",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWishlist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWishlist.fulfilled, (state, action) => {
        state.userProducts = action.payload;
        state.loading = false;
      })
      .addCase(fetchWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(add2Wishlist.pending, (state) => {
        state.addLoading = true;
        state.addError = null;
      })
      .addCase(add2Wishlist.fulfilled, (state, action) => {
        state.addLoading = false;
      })
      .addCase(add2Wishlist.rejected, (state, action) => {
        state.addLoading = false;
        state.addError = action.error.message || "An error occurred";
      })
      .addCase(removeWishlist.pending, (state) => {
        state.addLoading = true;
        state.addError = null;
      })
      .addCase(removeWishlist.fulfilled, (state, action) => {
        state.addLoading = false;
      })
      .addCase(removeWishlist.rejected, (state, action) => {
        state.addLoading = false;
        state.addError = action.error.message || "An error occurred";
      });
  },
});

export const {} = wishlistSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default wishlistSlice.reducer;
