import { createSlice } from "@reduxjs/toolkit";

import { notifications } from "./userAlertsList";

// Define a type for the slice state
interface InitialStateType {
  alertList: Array<any>;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  alertList: notifications,
};

export const alertsSlice = createSlice({
  name: "alerts",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
   
  },
});

export const {} = alertsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default alertsSlice.reducer;
