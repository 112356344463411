import { HTMLAttributes, useState } from "react";
import { cn } from "../../../helpers/utils";
import {
  addQuantity,
  addQuantitySession,
  removeQuantity,
  removeQuantitySession,
  updateQuantityAdd,
  updateQuantityRemove,
} from "../../../redux/features/cart/cartSlice";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import Cookies from "js-cookie";
export default function Quantity({
  className,
  max,
  total,
  idx,
  ...props
}: {
  className?: string;
  total: number;
  max: number;
  idx: any;
} & HTMLAttributes<HTMLDivElement>) {
  const [value, setValue] = useState(total);
  const { userId } = useAppSelector((state) => state.userProfile);
  const { authState } = useOktaAuth();
  const dispatch = useAppDispatch();
  const onAdd = (idx: number) => {
    if (authState?.isAuthenticated) {
      dispatch(addQuantity({ userId: userId, index: idx }));
    } else {
      dispatch(
        addQuantitySession({ userId: Cookies.get("cartId"), index: idx })
      );
    }
    dispatch(updateQuantityAdd(idx));
    setValue((p) => ++p);
  };
  const onRemove = (idx: number) => {
    if (authState?.isAuthenticated) {
      dispatch(removeQuantity({ userId: userId, index: idx }));
    } else {
      dispatch(
        removeQuantitySession({ userId: Cookies.get("cartId"), index: idx })
      );
    }
    dispatch(updateQuantityRemove(idx));
    setValue((p) => --p);
  };
  return (
    <section className={cn("md:flex my-4 select-none w-fit", className)}>
      <div
        onClick={() => {
          if (value < max) {
            onAdd(idx);
          }
        }}
        className="grid md:order-3 text-lg font-semibold rounded-tr rounded-br max-md:rounded cursor-pointer place-content-center bg-accentYellow-500 w-11 h-10"
      >
        +
      </div>

      <div className="grid order-2 text-lg font-semibold bg-white place-content-center w-11 h-10">
        {value}
      </div>
      <div
        onClick={() => {
          if (value > 1) {
            onRemove(idx);
          }
        }}
        className="grid  md:order-1 text-lg font-semibold border rounded-tl rounded-bl max-md:rounded  cursor-pointer bg-zinc-100 place-content-center w-11 h-10"
      >
        -
      </div>
    </section>
  );
}
