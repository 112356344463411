import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { searchCommunities } from "../../../redux/features/search/searchSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Card from "../../common/Card";
import NotAdded from "../../common/Error/NotAdded";
import Loader from "../../common/Loader/loader";
import CommunityItems from "../Community/CommunityItems";

export const SearchCommunities = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const { communitiesList, loading, error } = useAppSelector(
      (state) => state.search
    );
    useEffect(() => {
      dispatch(searchCommunities(searchParams.get("q") || ""));
    }, [searchParams]);
  return (
    <Card className="bg-white">
      {loading ? (
        <Loader />
      ) : communitiesList ? (
        <CommunityItems value={communitiesList} />
      ) : (
        <NotAdded
          m1="Nice Try"
          m2="No Results"
          m3="No matches to your request."
        />
      )}
    </Card>
  );
};

