// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jumbotron {
  background: linear-gradient(
    250deg,
    rgba(234, 67, 53, 0.2) 0%,
    rgba(251, 188, 5, 0.2) 100%
  );
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Jumbotron/Jumbotron.css"],"names":[],"mappings":"AAAA;EACE;;;;GAIC;AACH","sourcesContent":[".jumbotron {\n  background: linear-gradient(\n    250deg,\n    rgba(234, 67, 53, 0.2) 0%,\n    rgba(251, 188, 5, 0.2) 100%\n  );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
