import * as React from 'react';

export const useScrollableChatContainer = (deps: any[] = []) => {
  const scrollableChatContainerRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (scrollableChatContainerRef.current) {
      scrollableChatContainerRef.current.scrollTop =
        scrollableChatContainerRef.current.scrollHeight;
    }
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom, ...deps]);
  return scrollableChatContainerRef;
};
