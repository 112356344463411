import { useLocation, useNavigate } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { Fragment, useState } from "react";
import { SortSearch } from "../Search/common";
import Card from "../../common/Card/Card";

export default function ForSaleTopBar({
  communities,
  user,
  className,
}: {
  className?: string;
  communities?: boolean;
  user?: string;
}) {
  return (
    <section className={className!}>
      <div
        className={cn(
          "flex gap-4 p-2 bg-white border rounded-lg border-slate-400/20 mt-2",
          { "max-md:hidden": communities }
        )}
      >
        <Fragment>
          <div className="text-sm flex  items-center font-semibold max-md:hidden text-zinc-600 [&>button]:px-3">
            <Actions communities={communities} />
          </div>
        </Fragment>
      </div>
      <div className="text-sm flex items-center mt-2 font-semibold md:hidden text-zinc-600 [&>button]:px-3">
        {communities && <Actions communities={communities} user={user} />}
      </div>
    </section>
  );
}

export function Actions({
  communities,
  user,
}: {
  communities?: boolean;
  user?: string;
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const communitiesOwn = pathname.includes("/communities/own");
  return (
    <Card className="bg-neutral-200 ">
      <div className="flex  md:w-[20em]">
        <button
          onClick={() =>
            navigate(user ? `/user/${user}/communities` : "/my/communities")
          }
          className={cn(
            "w-full rounded-md text-xs h-8  from-red-500 to-yellow-500",
            {
              "bg-gradient-to-l text-white": communities && !communitiesOwn,
            }
          )}
        >
          My Communities
        </button>
        <button
          onClick={() =>
            navigate(
              user ? `/user/${user}/communities/own` : "/my/communities/own"
            )
          }
          className={cn(
            "w-full rounded-md text-xs h-8 from-red-500 to-yellow-500",
            {
              "bg-gradient-to-l text-white": !communities || communitiesOwn,
            }
          )}
        >
          Communities Owned
        </button>

        {/* <button className="inline-flex gap-2">
          <Icon icon={"mdi:edit-outline"} className="text-lg" />
          <span>Edit Listing</span>
        </button>
  */}
      </div>
    </Card>
  );
}
