import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchComments } from "../../../../redux/features/post/commentsSlice";
import { fetchSingleListing } from "../../../../redux/features/singlePages/newListings/newListingSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Loader from "../../../common/Loader/loader";
import Flags from "../../Home/Flags/Flags";
import Flags2 from "../../Home/Flags/Flags2";
import FlagsBanner from "../../Home/Flags/FlagsBanner";
import GearGallery from "../New-Gear/carousel";
import GearDetails from "../New-Gear/details";
import InfoSection from "../New-Gear/info-section";
import AboutTabs from "./about-tabs";
import Carousel from "./carousel";
import CommentSection from "./comment-section";
import Details from "./details";
import PaymentSection from "./payment-section";
import SimilarProduct from "./similar-product";

export default function ListingPage() {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fetchSingleListing(id));
    }
  }, [id]);
  const { postData, loading } = useAppSelector((state) => state.listingPost);

  function getOccurrence(array: any, value: any) {
    return array.filter((v: any) => v === value).length;
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="mb-[8em]">
          <img
            className="md:w-[45em] md:h-[5em] mx-auto mb-2 md:mb-4 object-cover md:mt-2 rounded-lg"
            src="/assets/imgs/home/banner-2.png"
            alt="ad"
          />
          <div className="flex flex-col md:flex-row w-full md:gap-4 lg:gap-2">
            {postData.condition != "NEW" ? (
              <Carousel lg={24} md={19} item={true} imgs={postData.images} />
            ) : (
              <GearGallery
                item={false}
                lg={12}
                md={12}
                imgs={postData.gear.img.split()}
              />
            )}
            <div className="md:w-[55em] gap-5">
              <Details
                isListing
                info={postData}
                offersMade={getOccurrence(postData.offerIds, userId)}
              />
              <GearDetails listing={true} info={postData.gear} />
              <PaymentSection info={postData} />
            </div>
          </div>
          <hr className=" my-1" />
          <div className="flex flex-col lg:flex-row w-full md:gap-4 lg:gap-2 mt-2">
            <InfoSection info={postData.gear} />
          </div>
          <AboutTabs isListing info={postData} />
          {/* <CommentSection comments={postData} /> */}
        </section>
      )}
    </>
  );
}
