import { Link } from "react-router-dom";
import Card from "../../../../../common/Card";
import "../Post.css";
import { Author } from "../common/author";
import { CardFooter } from "../common/card-footer";
import { User } from "../common/user";
import { useState } from "react";
import { CircularProgress } from "@nextui-org/progress";

const Poll = ({ el, idx }: { el: any; idx: number }) => {
  const [showStats, setShowStats] = useState(false);
  return (
    <Card className="h-full home-post bg-white shadow" key={"home-post" + idx}>
      <User el={el} time={el.postContent.createdDateTime} />
      <hr />
      <Author el={el} idx={idx} />
      <Link to={`/single/poll/${el.postContent.id}`}>
        <div className="">
          <p className="text-base md:text-xl font-medium ">
            {el.postContent.title}
          </p>
        </div>
        {el.postContent.text && (
          <div className="mt-4 text-xs font-medium text-accentGray-800">
            {el.postContent.text}
          </div>
        )}
      </Link>
      {el.postContent.pollOptions && (
        <div className="mt-2 md:mt-[18px]">
          {el.postContent.pollOptions.map((el2: any, idx2: any) => {
            return (
              <div
                className="flex items-center bg-accentGray-400 text-lg py-3 px-3 rounded-[10px] mb-[10px]"
                key={"home-post-option" + idx + "-" + idx2}
              >
                <input
                  className="relative float-left mr-1 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-black checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625em] checked:after:w-[0.625em] checked:after:rounded-full checked:after:border-black checked:after:bg-black checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-black checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-black dark:checked:after:border-black dark:checked:after:bg-black dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-black dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
                  type="radio"
                  name="flexRadioDefault"
                  id={el2.name}
                  onChange={() => setShowStats(true)}
                />
                <label
                  className="mt-px  text-xs inline-block pl-[0.15em] hover:cursor-pointer"
                  htmlFor={el2.id}
                >
                  {el2.optionText}
                </label>
                {showStats && (
                  <CircularProgress
                    classNames={{
                      svg: "w-10 h-10 drop-shadow-md ",
                      indicator: "stroke-black",
                      track: "stroke-white/10",
                      value: "text-xs font-semibold text-black",
                    }}
                    size="md"
                    value={el2.votes}
                    showValueLabel={true}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}

      <div className="flex items-center justify-between mt-2">
        <div className="text-[10px] font-medium text-accentGray-700">
          {el.votesCount} votes
        </div>
        <div className="text-[10px] font-medium text-accentGray-700">
          {el.timeLeft}
        </div>
      </div>
      <CardFooter el={el} idx={idx} />
    </Card>
  );
};

export default Poll;
