import React, { useState } from "react";
import SubWhy from "./SubWhy";
import Jumbo from "../../common/Jumbotron/Jumbo";
import Verification from "./Verification";

const Protection = () => {
  return (
    <div className="mb-[9em]">
      <div className="">
        <div className="flex-row  mb-4 text-center font-semibold text-[30px]">
          <img
            className="w-24 h-24 cursor-pointer mx-auto "
            src="/assets/imgs/home/badge.png"
            alt="google logo"
          />
          Pairrit Protection Program
        </div>
        <Verification />

        <SubWhy />
      </div>
    </div>
  );
};

export default Protection;
