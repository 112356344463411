import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import useAuthUser from "../../../hooks/getAuthUser";
import mixpanel from "mixpanel-browser";
import { IconButton } from "@mui/material";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

const Navbar = (props:any) => {
  const { href, tip, icon, active, num } = props;
  const user = useAuthUser();
  return (
    <Link
      className="transition-transform shrink-0 hover:scale-105  md:mr-2 "
      to={href}
      key={href}
      onClick={() => {
        mixpanel.track("Messages Icon clicked", {
          email: user ? user["email"] : "",
        });
      }}
    >
      <IconButton aria-label="messages">
        <StyledBadge badgeContent={num} color="error">
          <Icon icon={icon} className="text-slate-700 text-xl cursor-pointer" />
        </StyledBadge>
      </IconButton>
    </Link>
  );
};

export default Navbar;


const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 1,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 0px",
    height: "16px",
    fontSize: "10px",
  },
}));