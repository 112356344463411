import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Jumbo from "../../../common/Jumbotron/Jumbo";
import { useOktaAuth } from "@okta/okta-react";
import Tabs from "../../../common/Tabs/Tabs";
import ComingSoon from "../../../common/Error/ComingSoon";

const tabs = [
  {
    to: "/transactions/purchases",
    icon: "/assets/vectors/transactions/purchases.svg",
    label: "Purchases",
  },
  {
    to: "/transactions/orders",
    icon: "/assets/vectors/transactions/orders.svg",
    label: "Orders",
  },
  {
    to: "/transactions/offers/sent",
    icon: "/assets/vectors/transactions/offers.svg",
    label: "Offers",
  },
  {
    to: "/transactions/earnings",
    icon: "/assets/vectors/transactions/earnings.svg",
    label: "Earnings",
  },
  {
    to: "/transactions/manage-listings",
    icon: "/assets/vectors/transactions/manage-listings.svg",
    label: "Manage Listings",
  },
];

const TransactionsLayout = () => {
  const { authState } = useOktaAuth();
  return (
    <div className="mb-[8em] lg:mt-10">
      {/* <Navigate replace to="/transactions/purchases" /> */}
      <Jumbo title="Transactions" des="Find all your busniess operations" />
      <Tabs tabsData={tabs} />
      {!authState?.isAuthenticated ? <ComingSoon /> : <Outlet />}
    </div>
  );
};

export default TransactionsLayout;
