import React, { useEffect, useState } from "react";
import { ComponentProps } from "react";
import { cn } from "../../../helpers/utils";
import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import Card from "../../common/Card/Card";
import { useAppSelector } from "../../../redux/hooks";
import { categoriesData } from "../Home/Categories/categoriesData";
import Loader from "../../common/Loader/loader";
import { z } from "zod";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import { updateCommSettings } from "../../../redux/features/communityMod/settings/modSettingsSlice";
import { AppDispatch } from "../../../redux/store";
import { Loading } from "../../common/Widget/Loading";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../ui/form";
import { TextField } from "../../text-field";
import Select from "../../common/select2";
import { useParams } from "react-router-dom";
import useAuthUser from "../../../hooks/getAuthUser";

type SettingProps = ComponentProps<"div">;

const formSchema = z.object({
  id: z.string(),
  userId: z.string(),
  genre: z.string().toUpperCase().optional(),
  description: z.string().optional(),
  privacy: z.string().optional(),
});

export function Setting({ className, ...props }: SettingProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { communityData, loading } = useAppSelector((state) => state.community);
  const { userId } = useAppSelector((state) => state.userProfile);
  const { id } = useParams();
  const { updateError, updateLoading } = useAppSelector(
    (state) => state.modSettings
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
    dispatch(updateCommSettings(values)).finally(() =>
      window.location.reload()
    );
    dispatch(
      notifySelect({
        title: "Update Sent!",
        type: 0,
        open: true,
      })
    );
  }

  useEffect(() => {
    form.setValue("id", id);
    form.setValue("userId", userId);
    if (updateError) {
      dispatch(
        notifySelect({
          title: "Error Updating",
          type: 1,
          open: true,
        })
      );
    }
  }, [updateError, userId, id]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {loading ? (
          <Loader />
        ) : (
          <Card className={cn("p-6 bg-white", className)}>
            <div className="flex justify-between ">
              <h5 className="text-sm text-accentGray-700">COMMUNITY PROFILE</h5>
              {updateLoading ? (
                <Loading />
              ) : (
                <GradientBtn
                  label={"Save Changes"}
                  className={"md:px-6 md:w-[15%]  mb-1 "}
                  type="submit"
                />
              )}
            </div>
            <hr className="v2 my-6" />
            <div className="flex items-center justify-between">
              <Heading label="Community Name" sub={communityData.name} />
            </div>
            <hr className="border-slate-400/20 my-6" />
            <div className="flex items-center justify-between">
              <Heading label="Category" sub={communityData.genre} />
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        long
                        onChange={field.onChange}
                        light
                        placeholder={communityData.genre}
                        uniqueKey="genre-select"
                        options={categoriesData}
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"genre"}
                control={form.control}
              />
            </div>
            <hr className="v2 my-6" />
            <Heading label="Community Description" sub={""} />
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <TextField
                      className="mt-2"
                      defaultValue={communityData.description}
                      onOptionSelect={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
              name={"description"}
              control={form.control}
            />
            <hr className="v2 my-6" />
            <div className="flex items-center justify-between">
              <Heading label="Access" sub={communityData.type} />
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        long
                        onChange={field.onChange}
                        light
                        placeholder={communityData.type}
                        uniqueKey="privacy-select"
                        options={level}
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"privacy"}
                control={form.control}
              />
            </div>
            {/* <div className="flex gap-1 mt-20 grid-2">
        <button className="border-current px-4 border rounded-md inline-flex gap-1 md:text-sm font-semibold leading-tight text-white capitalizew-full bg-red-600 items-center text-xs">
          <Icon icon="material-symbols:delete-outline" className="text-lg" />
          Delete Community
        </button>
        <GradientBtn
          label={"Save Changes"}
          className={"px-10"}
          onClick={handleSaveChanges}
        />
      </div> */}
          </Card>
        )}
      </form>
    </Form>
  );
}

function Heading({ label, sub }: { label: string; sub: string }) {
  return (
    <div>
      <h3 className="text-sm font-semibold">{label}</h3>
      <p className="text-neutral-400 -mt-1 text-base font-medium">{sub}</p>
    </div>
  );
}

const level = [
  {
    label: "Public",
    value: "Public",
  },
  {
    label: "Restricted",
    value: "Restricted",
  },
  {
    label: "Private",
    value: "Private",
  },
];
