import clsx from "clsx";
import { Link, useMatch } from "react-router-dom";

export interface TabProps {
  to: string;
  icon: string;
  label: string;
}

const Tab = ({ to, icon, label }: TabProps) => {
  const isUserRoute = useMatch(to);

  return (
    <Link
      to={to}
      className={clsx(
        "flex-shrink-0 flex items-center gap-[10px] cursor-pointer rounded-md py-[10px] px-4 h-11 relative md:border-none md:bg-transparent",
        isUserRoute
          ? "bg-red-100 border-red-500 [&>div]:text-red-700 text-red-700 underline underline-offset-4 md:underline-offset-8"
          : "bg-slate-200 border border-solid [&>div]:text-accentGray-700 hover:underline-offset-8 hover:underline "
      )}
    >
      <div className="text-xs md:text-base ">{label}</div>
      {isUserRoute && (
        <div className="bg-gradient-to-l from-red-500 to-yellow-500 absolute h-1 w-full left-0 -bottom-[18px]"></div>
      )}
    </Link>
  );
};

export default Tab;
