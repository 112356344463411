import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import axios from "axios";

interface CartType {
  name: string;
  id: string;
  postId: string;
  category: string;
  price: number;
  total: number;
  quantity: number;
  size: number;
  formula: string;
  condition: string;
  images: string;
}

// Define a type for the slice state
interface InitialStateType {
  cartTotal: number;
  fetchLoading: boolean;
  fetchError: string | null;
  updateLoading: boolean;
  updateError: string | null;
  cartData: Array<CartType> | any;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  cartData: [],
  fetchLoading: false,
  fetchError: null,
  updateLoading: false,
  updateError: null,
  cartTotal: 0,
};

export const fetchCart = createAsyncThunk(
  "user/fetchCart",
  async (userId: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/get/${userId}`
    );
    return response.data;
  }
);

export const fetchCartSession = createAsyncThunk(
  "user/fetchCartSession",
  async (cartId: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/getSession/${cartId}`
    );
    return response.data;
  }
);

export const add2Cart = createAsyncThunk(
  "cart/add",
  async ({ userId, qty, productId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/add`,
      {
        userId: userId,
        productId: productId,
        qty: qty,
      }
    );
    return response.status;
  }
);

export const add2CartSession = createAsyncThunk(
  "cart/addSession",
  async ({ userId, qty, productId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/add2Cart`,
      {
        userId: userId,
        productId: productId,
        qty: qty,
      }
    );
    return response.status;
  }
);

export const removeFromCart = createAsyncThunk(
  "cart/remove",
  async ({ userId, index }: any) => {
    const response = await axios.delete(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/remove/${userId}/${index}`
    );
    return response.status;
  }
);

export const removeQuantity = createAsyncThunk(
  "cart/removeQuantity",
  async ({ userId, index }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/quantity/remove/${userId}/${index}`
    );
    return response.status;
  }
);

export const addQuantity = createAsyncThunk(
  "cart/addQuantity",
  async ({ userId, index }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/quantity/add/${userId}/${index}`
    );
    return response.status;
  }
);

export const removeFromCartSession = createAsyncThunk(
  "cart/removeSession",
  async ({ userId, index }: any) => {
    const response = await axios.delete(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/session/remove/${userId}/${index}`
    );
    return response.status;
  }
);

export const removeQuantitySession = createAsyncThunk(
  "cart/removeQuantitySession",
  async ({ userId, index }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/session/quantity/remove/${userId}/${index}`
    );
    return response.status;
  }
);

export const addQuantitySession = createAsyncThunk(
  "cart/addQuantitySession",
  async ({ userId, index }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/cart/session/quantity/add/${userId}/${index}`
    );
    return response.status;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addProd2Cart: (state, action: PayloadAction<CartType>) => {
      state.cartData = [action.payload, ...state.cartData];
      state.cartTotal++;
    },
    removeProd2Cart: (state, action: PayloadAction<number>) => {
      state.cartData = [
        ...state.cartData.slice(0, action.payload),
        ...state.cartData.slice(action.payload + 1),
      ];
      state.cartTotal--;
    },
    updateQuantityAdd: (state, action: PayloadAction<number>) => {
      state.cartData[action.payload].total++;
    },
    updateQuantityRemove: (state, action: PayloadAction<number>) => {
      state.cartData[action.payload].total--;
    },
    setCartTotal: (state, action: PayloadAction<number>) => {
      state.cartTotal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeFromCart.pending, (state) => {
        state.updateLoading = true;
        state.updateError = null;
      })
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(removeFromCart.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.error.message || "An error occurred";
      })
      .addCase(removeFromCartSession.pending, (state) => {
        state.updateLoading = true;
        state.updateError = null;
      })
      .addCase(removeFromCartSession.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(removeFromCartSession.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.error.message || "An error occurred";
      })
      .addCase(add2Cart.pending, (state) => {
        state.updateLoading = true;
        state.updateError = null;
      })
      .addCase(add2Cart.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(add2Cart.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.error.message || "An error occurred";
      })
      .addCase(fetchCartSession.pending, (state) => {
        state.fetchLoading = true;
        state.fetchError = null;
      })
      .addCase(fetchCartSession.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.cartTotal = action.payload.length;
        state.cartData = action.payload;
      })
      .addCase(fetchCartSession.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message || "An error occurred";
      })
      .addCase(fetchCart.pending, (state) => {
        state.fetchLoading = true;
        state.fetchError = null;
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.cartTotal = action.payload.length;
        state.cartData = action.payload;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchError = action.error.message || "An error occurred";
      });
  },
});

export const {
  addProd2Cart,
  removeProd2Cart,
  updateQuantityAdd,
  updateQuantityRemove,
  setCartTotal,
} = cartSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default cartSlice.reducer;
