import { useEffect } from "react";
import {
  fetchOffersReceived,
  sortSelect,
} from "../../../../redux/features/userTransactions/offers/userOffersSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import NotAdded from "../../../common/Error/NotAdded";
import Loader from "../../../common/Loader/loader";
import OfferCard from "./OfferCard";

const OfferRecieved = () => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { sellOfferList, status, loading } = useAppSelector(
    (state) => state.userOffers
  );
  useEffect(() => {
    dispatch(fetchOffersReceived(userId));
  }, [userId]);
  return (
    <div className="mt-2">
      {loading ? (
        <Loader />
      ) : (
        <div className="mt-4">
          {sellOfferList?.length != 0 ? (
            sellOfferList?.map((el: any, idx: any) => {
              return <OfferCard el={el} idx={idx} />;
            })
          ) : (
            <NotAdded
              m1="Nothing to Find"
              m2="No Offers Recieved"
              m3="Make offers to earn FlexPoints"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OfferRecieved;
