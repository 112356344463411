import Skeleton from "@mui/material/Skeleton";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchUserProducts } from "../../../../redux/features/userStore/userStoreSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Card from "../../../common/Card/Card";
import NotAdded from "../../../common/Error/NotAdded";
import Loader from "../../../common/Loader/loader";
import ForSaleTopBar from "../../Market/for-sale-topbar";
import { ProductCard } from "../../Market/product-card";
import ProductList from "../../Market/product-list";
import GearRoomProfile from "../../My-Gear-Room/profile";

const UserStore = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
   const {userProducts, loading, error} = useAppSelector(
     (state) => state.userStore
   );
  useEffect(() => {
        if (id) {
          dispatch(fetchUserProducts(id));
        } 
    }, []);
  
  return (
    <div>
      <section>
        {loading ? (
          <Card className="bg-white mt-4">
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={350}
              height={48}
            />
          </Card>
        ) : userProducts?.length != 0 ? (
          <div className="mt-3 grid grid-cols-2 gap-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {userProducts?.map((_: any, key: any) => (
              <div>
                <ProductCard key={key} info={_} />
              </div>
            ))}
          </div>
        ) : (
          <Card className="bg-white shadow-lg w-full ">
            {" "}
            <NotAdded
              m1="Nothing for sale"
              m2="No Products Added"
              m3="Start Community Shopping"
            />
          </Card>
        )}
      </section>
    </div>
  );
};

export default UserStore;
