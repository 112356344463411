import { HTMLAttributes, useEffect, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import {
  FreeMode,
  Navigation,
  Thumbs,
  Zoom,
  EffectFade,
  Pagination,
} from "swiper";
import { cn } from "../../../../helpers/utils";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { fetchImgs } from "../../../../redux/features/singlePages/getImgs/getImgsSlice";
import Loader from "../../../common/Loader/loader";
import Skeleton from "@mui/material/Skeleton";
import Card from "../../../common/Card";

const ThumbGallery = ({
  className,
  imgs,
  item,
  md,
  lg,
  ...props
}: {
  className?: string;
  imgs?: any;
  md?: any;
  item?: boolean;
  lg?: any;
} & HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchImgs(imgs.toString()));
  }, []);

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();
  const { loading, error, imgData } = useAppSelector((state) => state.getImgs);
  console.log(imgData);
  return loading ? (
    <Card className="bg-white col-span-5">
      <Skeleton
        className="my-2 mx-auto"
        variant="rounded"
        width={250}
        height={300}
      />
    </Card>
  ) : (
    <>

        <div className="hidden lg:flex h-full">
          <Swiper
            //@ts-ignore
            onSwiper={setThumbsSwiper}
            spaceBetween={5}
            direction={"vertical"}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="h-full"
          >
            {imgData?.map((_: any) => (
              <SwiperSlide
                tabIndex={0}
                className="ring-accentYellow-500 !h-[5em] !w-[5em] !mb-2 [&:where(.swiper-slide-thumb-active)]:ring m-0.5 rounded-lg"
                key={_}
              >
                <img
                  alt={_}
                  className={cn(
                    "h-[5em] !w-[5em] cover  rounded-lg shadow-lg cursor-pointer"
                  )}
                  src={`${_}`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
  
      <div
        {...props}
        className={cn(
          "w-full  overflow-hidden border-none  select-none rounded-lg bg-white shadow-lg shadow-slate-100/50 max-md:mb-2",
          {}
        )}
      >
        <Swiper
          loop={true}
          effect={"fade"}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, FreeMode, Thumbs, Zoom]}
          spaceBetween={10}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          zoom={true}
          className={`overflow-hidden w-full h-full `}
        >
          {imgData?.map((_: any) => (
            <SwiperSlide
              className="rounded swiper-zoom-container  p-1 w-full"
              key={_}
            >
              <img alt="" className="object-cover rounded h-[20rem]" src={`${_}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default ThumbGallery;
