import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { prodList } from "../../../components/pages/Market/MarketHome";

// Define a type for the slice state
interface InitialStateType {
  search: string;
  loading: boolean;
  error: string | null;
  productList: any;
  postList: any;
  fragList: any;
  communitiesList: any;
  usersList: Array<any> | null;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  search: "",
  loading: false,
  error: null,
  productList: null,
  postList: prodList,
  fragList: prodList,
  communitiesList: null,
  usersList: null,
};

export const searchUsers = createAsyncThunk(
  "search/Users",
  async (search: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/search/profiles?query=${search}`
    );
    return response.data;
  }
);

export const searchTopics = createAsyncThunk(
  "search/Topics",
  async (search: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/search/gears/auto?query=${search}`
    );
    return response.data;
  }
);

export const searchAll = createAsyncThunk(
  "search/All",
  async ({ sort, search }: any) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/search/gears/all/${
        sort ? sort : "POPULAR"
      }?query=${search}`
    );
    return response.data;
  }
);

export const searchCommunities = createAsyncThunk(
  "search/Communities",
  async (search: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/search/communities?query=${search}`
    );
    return response.data;
  }
);

export const searchStore = createAsyncThunk(
  "search/Products",
  async ({ sort, search }: any) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/search/listings/${
        sort ? sort : "RECENT"
      }?query=${search}`
    );
    return response.data;
  }
);

export const searchSlice = createSlice({
  name: "search",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    search: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.usersList = action.payload;
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(searchCommunities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchCommunities.fulfilled, (state, action) => {
        state.loading = false;
        state.communitiesList = action.payload;
      })
      .addCase(searchCommunities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(searchStore.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchStore.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload;
      })
      .addCase(searchStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(searchTopics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchTopics.fulfilled, (state, action) => {
        state.loading = false;
        state.postList = action.payload;
      })
      .addCase(searchTopics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(searchAll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchAll.fulfilled, (state, action) => {
        state.loading = false;
        state.fragList = action.payload;
      })
      .addCase(searchAll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const { search } = searchSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default searchSlice.reducer;
