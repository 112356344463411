import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface TopicState {
  loading: boolean;
  error: string | null;
  postData: any;
  postComments: {};
}

// Define the initial state using that type
const initialState: TopicState = {
  loading: true,
  error: null,
  postData: null,
  postComments: {},
};

export const fetchSingleTopic = createAsyncThunk(
  "single/Topic",
  async (id: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/topics/${id}`
    );
    return response.data;
  }
);

export const newTopicSlice = createSlice({
  name: "newTopic",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleTopic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSingleTopic.fulfilled, (state, action) => {
        state.loading = false;
        state.postData = action.payload;
      })
      .addCase(fetchSingleTopic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const {} = newTopicSlice.actions;

export default newTopicSlice.reducer;
