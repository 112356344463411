import { useAppSelector } from "../../../../redux/hooks";
import { ModeratorCard } from "../../Mod-Que/moderators";

export function Mods({ value }: { value: Array<any> }) {
  return (
    <div>
      {value.map((_, idx) => (
        <ModeratorCard
          link={_.id}
          img={_.profilePicUri}
          minimal
          points={_.muzPoints}
          name={_.userName}
          idx={idx + 1}
          key={idx}
        />
      ))}
    </div>
  );
}
