import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import axios from "axios";

interface FeedState {
  loading: boolean;
  error: string | null;
  feedLoading: boolean;
  feedError: string | null;
  joinLoading: boolean;
  joinError: string | null;
  communityData: any;
  memberStatus: string;
  filterType: string;
  communityFeed: any;
  subscriberIds: any;
}

export const initialState: FeedState = {
  loading: true,
  error: null,
  feedLoading: true,
  feedError: null,
  joinLoading: true,
  joinError: null,
  communityData: null,
  memberStatus: "Mod", //Mod, Guest or Member
  filterType: "New",
  communityFeed: null,
  subscriberIds: [],
};

export const joinCommunity = createAsyncThunk(
  "community/join",
  async ({ userId, communityName }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/${communityName}/subscribe/${userId}`
    );
    return response.status;
  }
);

export const unJoinCommunity = createAsyncThunk(
  "community/unjoin",
  async ({ userId, communityName }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/${communityName}/removeMember/${userId}`
    );
    return response.status;
  }
);

export const fetchCommunityByName = createAsyncThunk(
  "community/communityByName",
  async (name: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/${name}`
    );
    return response.data;
  }
);
export const fetchCommunityPosts = createAsyncThunk(
  "community/fetchPosts",
  async (name: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/posts/community/${name}`
    );
    return response.data;
  }
);

export const communitySlice = createSlice({
  name: "community",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addMember: (state, action: PayloadAction<any>) => {
      state.subscriberIds = [action.payload, ...state.subscriberIds];
    },
    removeMember: (state, action: PayloadAction<any>) => {
      state.subscriberIds = [
        ...state.subscriberIds.slice(0, action.payload),
        ...state.subscriberIds.slice(action.payload + 1),
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunityByName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommunityByName.fulfilled, (state, action) => {
        state.loading = false;
        state.communityData = action.payload;
        state.subscriberIds = action.payload.subscriberIds;
      })
      .addCase(fetchCommunityByName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchCommunityPosts.pending, (state) => {
        state.feedLoading = true;
        state.feedError = null;
      })
      .addCase(fetchCommunityPosts.fulfilled, (state, action) => {
        state.feedLoading = false;
        state.communityFeed = action.payload;
      })
      .addCase(fetchCommunityPosts.rejected, (state, action) => {
        state.feedLoading = false;
        state.feedError = action.error.message || "An error occurred";
      })
      .addCase(joinCommunity.pending, (state) => {
        state.joinLoading = true;
        state.joinError = null;
      })
      .addCase(joinCommunity.fulfilled, (state, action) => {
        state.joinLoading = false;
      })
      .addCase(joinCommunity.rejected, (state, action) => {
        state.joinLoading = false;
        state.joinError = action.error.message || "An error occurred";
      });
  },
});

export const { removeMember, addMember } = communitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectFeed = (state: RootState) => state.community;

export default communitySlice.reducer;
