export const preamble =
  "PLEASE READ THESE TERMS OF USE AND ANY HYPERLINKED POLICIES (COLLECTIVELY THE 'AGREEMENT' OR 'TERMS OF USE') CAREFULLY BEFORE USING THE WEBSITES AND SERVICES OFFERED BY Pairrit.COM, LLC. ('Pairrit'). THIS AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR USE OF THE WEBSITES (INCLUDING THE Pairrit APP) AT Pairrit.COM (HEREINAFTER COLLECTIVELY REFERRED TO AS THE 'SITE') AND ALL SERVICES PROVIDED BY Pairrit ON THE SITE (THE “SERVICES”). BY USING THE SITE AND SERVICES OFFERED BY Pairrit (INCLUDING THE Pairrit APP), AND INCLUDING BUT NOT LIMITED TO BROWSING THE SITE, YOU, WHETHER AN INDIVIDUAL OR AN ENTITY, (THE “USER” OR “YOU”) ARE EXPRESSLY AGREEING TO BE BOUND BY THESE TERMS OF USE INCLUDING THOSE ADDITIONAL TERMS AND CONDITIONS AND POLICIES REFERENCED HEREIN AND/OR AVAILABLE BY HYPERLINK. BY USING THE SITE AND SERVICES OFFERED BY Pairrit YOU HEREBY WAIVE ANY AND ALL CLAIMS CHALLENGING THE APPLICABILITY OR BINDING NATURE OF THE TERMS OF USE. THESE TERMS OF USE APPLY TO ALL USERS OF THE SITE, INCLUDING WITHOUT LIMITATION USERS WHO ARE VENDORS, CUSTOMERS, MERCHANTS, CONTRIBUTORS OF CONTENT, INFORMATION AND OTHER MATERIALS OR SERVICES ON THE SITE.YOU DO NOT AGREE WITH THE TERMS OF USE, PLEASE DO NOT USE THE SITE.";
export const preamble2 =
  "Please note that Section 6 below contains an arbitration clause and class action waiver. By agreeing to the Terms of Use, you agree to resolve all disputes through binding individual arbitration, which means that you waive any right to have those disputes decided by a judge or jury, and that you waive your right to participate in class actions, class arbitrations, or representative actions.";
export const terms = [
  {
    title: "4. Pairrit is a Marketplace/Venue",
    terms:
      "Pairrit acts as a marketplace/venue to allow users who comply with Pairrit's Terms of Use and policies to offer, sell, advertise, and buy musical instruments and related merchandise. While Pairrit has processes in place to review the veracity and propriety of listings and to vet buyers and sellers, it cannot guarantee the truth, accuracy or legality of listings or the ability of sellers to sell items or the ability of buyers to pay for items.  Pairrit also cannot ensure that a buyer or seller will actually complete a transaction or guarantee the true identity, age, and nationality of a user. Pairrit encourages you to communicate directly with potential transaction partners through the tools available on the Site. You may also wish to consider using a third-party escrow service or services that provide additional user verification. You agree that to the fullest extent protected by law, Pairrit is a marketplace/venue and as such is not responsible or liable for any content, for example, data, text, information, usernames, graphics, images, photographs, profiles, audio, video, items, and links posted by you, other users, or third parties on Pairrit. YOU USE THE Pairrit SERVICE AT YOUR OWN RISK AND AGREE THAT THE SITE IS PROVIDED TO YOU WITH ALL FAULTS, INCLUDING NO PROMISE OF CONTINUOUS SERVICE (SEE SECTION 16 BELOW). PLEASE ALSO SEE THE SECTIONS ON NO WARRANTIES (SECTION 11), LIMITATION ON LIABILITIES (SECTION 12) AND INDEMNITIES (SECTION 15) BELOW.",
  },
  {
    title: "5. Fees and Services",
    terms:
      "Joining and setting up a shop on Pairrit is free. Pairrit does not charge fees to list an item for sale. Pairrit does charge a percentage of the sale price when the item sells. When you list an item you have an opportunity to review and accept the fees that you will be charged. Pairrit’s Billing Policy, which is subject to change, is incorporated into this Agreement by reference. Changes to the Billing Policy and the fees for Pairrit's services are effective immediately after posting the changes on the Site to the fullest extent permitted by law, or otherwise immediately after the minimum period permitted by law. Pairrit may also choose to temporarily change the Billing Policy and the fees for Pairrit's services for promotional events (for example, free selling days); such changes are effective when Pairrit posts the temporary promotional event on the Site or otherwise notifies you of the change. Fees and Termination: If Pairrit terminates a listing or your account, if you close your account, or if the payment of your Pairrit fees cannot be completed for any reason, you remain obligated to pay Pairrit for all unpaid fees plus any penalties, if applicable. If the seller's account is not paid in full and becomes past due, the seller risks penalties such as the suspension of privileges and/or termination of the account and other collection mechanisms (including retaining collection agencies and legal counsel). YOU HEREBY EXPRESSLY AGREE THAT Pairrit MAY CHARGE YOUR CREDIT CARD, OR ANY OTHER METHOD OF PAYMENT, ON FILE FOR ANY AND ALL OUTSTANDING OR DELINQUENT FEES OR BALANCES AND WAIVE ANY AND ALL CLAIMS AGAINST Pairrit FOR EXERCISING THIS RIGHT (INCLUDING, FOR EXAMPLE, USING YOUR CONTACT INFORMATION TO AID IN COLLECTING PAYMENT). ",
  },
  {
    title: "6. Information Control",
    terms:
      "Pairrit does not control the Content provided by users that is made available on Pairrit. You may find some Content to be offensive, harmful, inaccurate, or deceptive. Pairrit users must be eighteen or over, but there are risks of dealing with underage persons or people acting under false pretense. Additionally, there may also be risks dealing with international trade and foreign nationals. By using Pairrit, you agree to accept such risks and expressly agree that Pairrit (and Pairrit's officers, directors, agents, subsidiaries, joint ventures and employees) is not responsible for any and all acts or omissions of users on Pairrit. Please use caution, common sense, and practice safe buying and selling when using Pairrit. Other Resources: Pairrit is not responsible for the availability of outside websites or resources linked to or referenced on the Site. Pairrit does not endorse and is not responsible or liable for any content, advertising, products, or other materials on or available from such websites or resources. You agree that Pairrit shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such websites or resources.",
  },
  {
    title: "7. Email, Text and Fax Communications",
    terms:
      "By using the Site and services available on the Site, you agree that you have an established business relationship (EBR) with Pairrit and hereby waive any and all claims under the Telephone Consumer Protection Act (TCPA) as they relate to email, text message or fax communications from Pairrit. You hereby expressly consent to communications of any kind from Pairrit to the fullest extent permissible by law. When you register for an account, subscribe to a newsletter, or provide us with your email address or phone number, you will receive notice of and agree to receive marketing emails and messages from us. You can unsubscribe at any time from marketing emails or messages through the opt-out link included in marketing emails or messages, or by reaching out to Pairrit. Please note that some changes to your account settings may take a few days to take effect.",
  },
  {
    title: "8. Pairrit's Intellectual Property",
    terms:
      "Pairrit, and other Pairrit graphics, logos, designs, page headers, button icons, scripts, and service names are registered trademarks, trademarks, trade dress or copyrights of Pairrit or its affiliated companies in the U.S. and/or other countries. Pairrit's trademarks, trade dress, copyrights, patents, and all other Pairrit intellectual property (including Pairrit Integrations) may not be used, in any manner, without the prior express written consent of a duly authorized representative of Pairrit or in violation of or inconsistently with these Terms of Use.",
  },
  {
    title: "9. Access and Interference",
    terms:
      "Pairrit may contain robot exclusion headers which contain internal rules for software usage. Much of the information on Pairrit is updated on a real-time basis and is proprietary or is licensed to Pairrit by Pairrit's users or third-parties. You agree that you will not use any robot, spider, scraper or other automated means to access Pairrit for any purpose whatsoever, except to the extent expressly permitted by Pairrit's with prior express written permission. Additionally, you agree that you will not take any action that imposes, or may impose, in Pairrit's sole discretion, an unreasonable or disproportionately large load on Pairrit's infrastructure. You will not copy, publicly reproduce, modify, create derivative works from, publicly distribute or publicly display any user Content, including via use of plug-ins, extensions, applications or scripts that interfere with the Content, features, or functions of the Site or Services. You will not interfere or attempt to interfere with the proper working of the Site or any activities conducted on the Site. And you will not bypass Pairrit's robot exclusion headers or other measures Pairrit may use to prevent or restrict access to Pairrit.",
  },
  {
    title: "10. Breach of Pairrit Terms of Use and Policies",
    terms:
      "Without limiting its legal recourse or any other remedies, Pairrit may, without notice, and without refunding any fees, delay or immediately remove Content, warn Pairrit's community of a user's actions, issue a warning to a user, temporarily suspend a user, temporarily or indefinitely suspend a user's account privileges, terminate a user's account, prohibit access to the Site, take technical and legal steps to keep a user off the Site and refuse to provide services to a user for any reason, including, for example if any of the following apply: (a) Pairrit suspects (by information, investigation, conviction, settlement, insurance or escrow investigation, or otherwise) a user has breached this Agreement, including the Privacy Policy, Intellectual Property Policy, the Community Rules or other policies and community guidelines incorporated herein; (b) Pairrit is unable to verify or authenticate any of your personal information or Content; (c) Pairrit believes that a user is acting inconsistently with the letter or spirit of Pairrit's Community Rules or policies; or (d) Pairrit believes a user has engaged in improper or fraudulent activity in connection with Pairrit or the actions may cause legal liability or financial loss to Pairrit's users or to Pairrit. Notwithstanding the foregoing, Pairrit retains the right to suspend or terminate a user should we have reason to believe you, your Content, or your use of our Services have violated our Terms of Use or Community Rules. ",
  },
  {
    title: "11. No Warranty",
    terms:
      "Pairrit IS DEDICATED TO MAKING OUR SERVICES THE BEST THEY CAN BE, BUT WE’RE NOT PERFECT AND SOMETIMES THINGS CAN GO WRONG. YOU UNDERSTAND THAT OUR SITE AND SERVICES ARE PROVIDED “AS IS” AND WITHOUT ANY KIND OF WARRANTY (EXPRESS OR IMPLIED). WE ARE EXPRESSLY DISCLAIMING ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AS WELL AS ANY WARRANTIES IMPLIED BY A COURSE OF PERFORMANCE, COURSE OF DEALING, OR USAGE OF TRADE.",
  },
  {
    title: "12. Limitation of Liability",
    terms:
      "IN NO EVENT SHALL Pairrit, AND (AS APPLICABLE) Pairrit’S SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH THE USE OF THE SITE, Pairrit’S SERVICES, OR THIS AGREEMENT, INCLUDING WITHOUT LIMITATION, LOST PROFITS, BODILY INJURY, EMOTIONAL DISTRESS, OR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES. Pairrit’S LIABILITY, AND (AS APPLICABLE) THE LIABILITY OF Pairrit’S SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND SUPPLIERS, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES YOU PAY TO Pairrit IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, AND (B) $100. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.",
  },
  {
    title: "13. Indemnity",
    terms:
      "YOU AGREE TO INDEMNIFY AND HOLD Pairrit AND (AS APPLICABLE) Pairrit'S PARENT, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, AGENTS, AND EMPLOYEES, HARMLESS FROM ANY CLAIM OR DEMAND, INCLUDING REASONABLE ATTORNEYS' FEES, MADE BY ANY THIRD PARTY DUE TO OR ARISING OUT OF YOUR BREACH OF THIS AGREEMENT, THE PRIVACY POLICY, THE COMMUNITY RULES OR THE DOCUMENTS THEY INCORPORATE BY REFERENCE, OR YOUR VIOLATION OF ANY LAW OR THE RIGHTS OF A THIRD PARTY, INCLUDING, BUT NOT LIMITED TO, THE FAILURE TO APPROPRIATELY COLLECT AND REMIT TAXES ON ANY SALES.",
  },
  {
    title: "14. No Guarantee of Continuous Service",
    terms:
      "Pairrit does not guarantee continuous, uninterrupted access to the Site and no SLA representations or guarantees are included herein. Operation of the Site may be interfered with by numerous factors outside Pairrit's control.",
  },
  {
    title: "15. Intellectual Property Policy",
    terms:
      "Pairrit takes intellectual property infringement very seriously. The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. Copyright law. Additional laws apply to trademark rights and other types of rights in the U.S. and worldwide. You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining all necessary prior written consents of the owner(s) of such proprietary rights. If you do so repeatedly on the Site, you will be removed as a user. Without limiting the foregoing, if content that you own or have rights to has been posted to the Services without your permission and you want it removed, please follow the steps listed in our  Intellectual Property Policy.",
  },
  {
    title: "16. Trademarks for Modified Instruments",
    terms:
      "Users of the Site who engage in modifications of instruments or gear may be prohibited from referring to those items by their original trademarked names. Enforcement remains the responsibility of the trademark owner, but Pairrit will apply its intellectual property policy in the enforcement of legitimate trademarks. As a user, you are prohibited from using trademarked names or logos without all necessary written permission(s) from the trademark owner(s).",
  },
  {
    title: "18. Pairrit Integrations",
    terms:
      "Your use of Pairrit Integrations indicates your assent to Pairrit’s Terms and Policies contained herein. By utilizing Pairrit Integrations, you acknowledge that Pairrit is providing an integration of the Pairrit.com platform with third party platforms providing e-commerce and other digital services and solutions (collectively the “Third Party Service Providers”). You are subject to all applicable Third Party Service Providers’ terms of service by utilizing Pairrit Integrations. While Pairrit is providing Pairrit Integrations, maintenance, design, content and compliance with federal, state and local laws remains your responsibility as the Pairrit Integrations user. You hereby waive any and all claims against Pairrit, its officers, directors, employees and agents related to the use, maintenance, design and content of your Pairrit Integration(s). You acknowledge that you are responsible for compliance with federal, state and local laws applicable to your Pairrit Integration(s). You further agree to indemnify and hold Pairrit harmless for any third party claims or complaints associated with your use of Pairrit Integration(s). Pairrit Integrations are provided “as is” and with all faults. DO NOT UTILIZE Pairrit INTEGRATIONS IF YOU DO NOT AGREE WITH THESE TERMS.",
  },
  {
    title: "19. Your Responsibility for Legal Compliance and Taxes",
    terms:
      "You shall comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of the Site and any Pairrit service and, if applicable, your listing, purchase, solicitation of offers to purchase, and sale of items. In addition, you shall be responsible for paying any and all taxes applicable to any purchases or sales of items you make on the Site (excluding any taxes on Pairrit's net income). This includes sales and/or use taxes applicable in each jurisdiction, depending on the threshold required for collection. This responsibility may include applicable customs and fees for import and export sales; Pairrit assumes no responsibility for any losses, fees, or confiscations related to importing and exporting products purchased through Pairrit.",
  },
  {
    title: "20. Proposition 65 - California",
    terms:
      "The California Safe Drinking Water and Toxic Enforcement Act of 1986 (“Prop 65”) requires manufacturers, producers, packagers, importers, suppliers or distributors (the “Notice Parties”) of products to provide warnings to California consumers about potentially hazardous or cancer-causing materials in those products.  Notice Parties are encouraged to include a warning that lists the specific chemicals in the product(s) if they are present in an amount that may be hazardous. Example warnings could read: WARNING: This product can expose you to chemicals, including [name of chemical], which is/are known to the State of California to cause cancer. For more information, go to www.P65Warnings.ca.gov.",
  },
  {
    title: "21. Severability",
    terms:
      "If any provision of this Agreement is held unenforceable, then such provision will be modified to reflect the parties' intention. All remaining provisions of this Agreement shall remain in full force and effect.",
  },
  {
    title: "22. No Agency",
    terms:
      "You and Pairrit are independent contractors, and no agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by this Agreement.",
  },
  {
    title: "23. Modification of Service or Terms and Policies",
    terms:
      "Pairrit reserves the right to modify or terminate the Pairrit service for any reason, without notice, at any time, to the maximum extent permitted by law. Pairrit reserves the right to alter these Terms of Use, including the Privacy Policy, the Community Rules or other Site policies at any time, so please review the policies frequently. Your continued use of the Site will act as your unqualified acceptance of any changes to the Terms and Policies, regardless of actual notice.",
  },
  {
    title: "24. Choice of Law",
    terms:
      "This Agreement shall in all respects be interpreted and construed with and by the laws of the State of Connecticut, excluding its conflicts of laws rules, and the United States of America.",
  },
  {
    title: "25. Survival",
    terms:
      "All Sections which are intended by their terms to survive the expiration of this Agreement shall survive any termination or expiration of this Agreement.",
  },
  {
    title: "26. Notices/Disclosures",
    terms:
      "Except as explicitly stated otherwise, any notices shall be given by postal mail to Pairrit; Attn: Legal Department; 1536 Broad St, Hartford, CT 060106 (in the case of Pairrit) or, in your case, to the email address you provide to Pairrit (either during the registration process or when your email address changes), by notice posted to your user dashboard or by notice posted to the Site. Notice shall be deemed given 24 hours after email is sent or notice is posted. Alternatively, Pairrit may give you notice by certified mail, postage prepaid and return receipt requested, to the address provided to Pairrit. In such case, notice shall be deemed given three days after the date of mailing.",
  },
];
export const membership = [
  "(A) Age: Pairrit is fully committed to compliance with the Children’s Online Privacy Protection Rule (“COPPA”) as well as applicable contract law. Accordingly, Pairrit's services are available only to, and may only be used by, individuals who are 18 years of age and older who can form legally binding contracts. You represent and warrant that you are at least 18 years old and that all registration information you submit is accurate and truthful. Pairrit may, in its sole discretion, refuse to offer access to, or use of the Site to, any person or entity and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the Site is revoked in such jurisdictions. Individuals under the age of 18 must at all times use Pairrit's services only in conjunction with and under the supervision of a parent or legal guardian who is at least 18 years of age. In all such cases, the adult is the User and is responsible for any and all activities.",
  "(B) Compliance: You agree to comply with all applicable laws regarding online conduct and acceptable content. In addition, you must abide by Pairrit's policies as stated in the Terms of Use as well as all other Community Rules, policies and procedures that may be published from time to time on the Site by Pairrit, each of which is incorporated herein by reference and each of which may be updated by Pairrit from time to time without notice to you. To the fullest extent permitted by law, you hereby waive any and all claims and rights with respect to notice of changes to the Terms of Use or other associated policies. In addition, some services offered through the Site may be subject to additional terms and conditions promulgated by Pairrit from time to time; your use of such services is subject to those additional terms, conditions and policies, which are incorporated into this Agreement by this reference.",
  "(C) Password: Your password is your responsibility. Keep your password secure. You are fully responsible for all activity, liability and damage resulting from your failure to maintain password confidentiality. You agree to immediately notify Pairrit of any unauthorized use of your password or any breach of security. You also agree that Pairrit cannot and will not be liable for any loss or damage arising from your failure to keep your password secure. Except for password managers used in accordance with best practices, you agree not to provide your username and password information in combination to any other party other than Pairrit without Pairrit's express written permission.",
  "(D) Account Information: You must keep your account information up-to-date and accurate at all times, including a valid email address. To sell items on Pairrit you must provide and maintain valid payment information such as valid credit card information, contact information, bank account information, and/or a valid PayPal account.",
  "(E) Account Transfer: You may not transfer or sell your Pairrit account and User ID to another party. If you are registering as a business entity, you personally guarantee that you have the authority to bind the entity to this Agreement.",
  "(F) Right to Refuse Service: ACCESS TO Pairrit’S SERVICES AND THE SITE IS A PRIVILEGE GRANTED AT Pairrit’S SOLEDISCRETION. YOU HAVE NO RIGHT TO ACCESS Pairrit’S SITE. Pairrit will terminate any user who it determines is providing services competitive to Pairrit through the Site. Pairrit's services, and all accesses to the Site, are not available to temporarily or indefinitely suspended Pairrit members. Additionally, Pairrit reserves the right, in Pairrit's sole discretion, to cancel unconfirmed or inactive accounts or to disable, deactivate or terminate your account should we have reason to believe you, your Content, or your use of our Services have violated our Terms of Use or Community Rules. Generally, Pairrit will notify you that your account has been disabled, deactivated, or terminated, unless you’ve repeatedly violated our Terms or we have legal or regulatory reasons preventing us from notifying you. See here for more information.",
];

export const dispute = [
  "(A) Arbitration: In the event a dispute arises between you and Pairrit, please contact Pairrit. Any dispute arising from or relating to the subject matter of this Agreement shall be finally settled by arbitration in Cook County, Illinois, using the English language administered by the American Arbitration Association (the “AAA”) under its Consumer Arbitration Rules (the 'AAA Rules') then in effect (those rules are deemed to be incorporated by reference into this section, and as of the date of these Terms you can find the AAA Rules here), unless otherwise required by law. **Arbitration, including threshold questions of arbitrability of the dispute, will be handled by a sole arbitrator in accordance with those rules. Judgment on the arbitration award may be entered in any court that has jurisdiction. Payment for any and all reasonable AAA filing, administrative, and arbitrator fees will be in accordance with the Consumer Arbitration Rules, and in the case of CEDR, its rules. For EU sellers, if any dispute arises in connection with these Terms of Use, the parties should first try to resolve the dispute through the complaints procedure published here. In addition, the dispute may be referred by either party to the Centre for Effective Dispute Resolution (“CEDR”) for mediation. The Parties agree to enter into mediation to settle a good faith dispute and will do so in accordance with the CEDR’s mediation procedures. Unless otherwise agreed between the parties within 14 days of notice of the dispute, the mediator will be nominated by CEDR. To initiate the mediation a party must give notice in writing to the other party to the dispute, referring the dispute to mediation. A copy of the referral should be sent to CEDR.",
  "(B) Class Action and Collective Action Waiver: The parties agree to arbitrate solely on an individual basis, and this Agreement does not permit class arbitration or any claims brought as a plaintiff or class member in any class or representative arbitration proceeding. The arbitral tribunal may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding. The tribunal shall rule on its own jurisdiction and the validity or enforceability of the agreement to arbitrate.",
  "(C) Enforcement of Arbitration Judgment: Judgment upon the award so rendered may be entered in a court having jurisdiction or application may be made to such court for judicial acceptance of any award and an order of enforcement, as the case may be. Notwithstanding the foregoing, each party shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator.",
  "(D) Jurisdiction and Venue: For all purposes of any injunctive or equitable proceeding, the parties consent to exclusive jurisdiction and venue in the United States Federal Courts or state courts located in the Northern District of Illinois. Use of the Site is not authorized in any jurisdiction that does not give effect to all provisions of the Agreement, including without limitation, this section.",
  "(E) Limitations Period: You and Pairrit agree that any cause of action arising out of or related to the Site, Services or this Agreement must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred.",
  "(F) Intra-User Disputes and EU Dispute: In the event that you have a dispute with another Pairrit user or third party, we encourage you to contact the other party and try to resolve the dispute directly. Should you have a dispute with one or more users, or an outside party, YOU RELEASE Pairrit (AND Pairrit'S OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, JOINT VENTURES AND EMPLOYEES) FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. Pairrit encourages users to report any unlawful activity to your local law enforcement, and to use a certified mediation or arbitration entity, as applicable.",
  "(G) Order Disputes: Issues related to transactions on Pairrit can usually be resolved via direct communication between the buyer and the seller. If a buyer and seller are unable to resolve a dispute, Pairrit, may be able to help. You can find more details about when Pairrit may be able to assist in the Community Rules policies for Buyers and Sellers.",
  "(H) Feedback: Pairrit WILL NOT AMEND, ALTER OR OTHERWISE CHANGE FEEDBACK LEFT BETWEEN USERS UNLESS THE CONTENT IS FALSE, UNREASONABLY INFLAMMATORY OR OTHERWISE IN VIOLATION OF Pairrit POLICIES AS DETERMINED BY Pairrit IN ITS SOLE DISCRETION. FULLY REFUNDED ORDERS ARE NOT ELIGIBLE FOR FEEDBACK.",
  "(I) Full Waiver: YOU EXPRESSLY WAIVE AND RELEASE Pairrit (AND Pairrit'S OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, JOINT VENTURES AND EMPLOYEES) FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE SITE, SERVICES OR YOUR USE OF THE SAME.",
];

export const content = [
  "(A) Your Content: Pairrit does not claim ownership rights in content you place on the Site or in your listings (the “Content”). You understand that you are solely responsible for Your Content. You represent that you have all necessary rights to Your Content and that you’re not infringing or violating any third party’s rights by posting it. You grant Pairrit a license solely to enable Pairrit to use any information or Content you supply Pairrit with, so that Pairrit is not violating any rights you might have in that Content. By posting Your Content through our Services, You grant Pairrit a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable (through multiple tiers) right to use, display, edit, modify, reproduce, distribute, store, and prepare derivative works of Your Content. This allows us to provide the Services and to promote Pairrit, your Pairrit shop, or the Services in general, in any formats and through any channels, including across any Pairrit Services, our partners, or third-party website or advertising medium. You agree not to assert any moral rights or rights of publicity against us for using Your Content. You also recognize our legitimate interest in using it, in accordance with the scope of this license, to the extent Your Content contains any personal information. You agree to allow Pairrit to store or re-format your Content on Pairrit and display your Content on Pairrit in any way as Pairrit chooses. Pairrit can also use any transactional information including sale date and final price for sales completed on Pairrit as it sees fit.",
  "(B) Personal Information: Pairrit will only use personal information in accordance with Pairrit's Privacy Policy. As part of a transaction,you may obtain personal information, including email address and shipping information, from another Pairrit user. Without obtaining prior permission from the other user, this personal information shall only be used for that transaction or for Pairrit-related communications.Pairrit has not granted you a license to use the information for unsolicited commercial messages or for any other purpose. Without limiting the foregoing, without express consent from the user, you are not licensed to add any Pairrit user to your email or physical mail list. For more information, see Pairrit’s Privacy Policy.",
  "(C) Re-Posting Content: By posting Content on Pairrit, it is possible for an outside website or a third party to re-post or index that Content. You agree to hold Pairrit harmless for any dispute concerning this use, along with the limitations on liability and indemnification provisions below. If you choose to display your own Pairrit-hosted image on another website, the image must provide a link back to its listing page on Pairrit.",
  "(D) Idea Submissions: Pairrit considers any unsolicited suggestions, ideas, proposals or other material submitted to it by users via the Site or otherwise (other than the Content and the tangible items sold on the Site by users) (collectively, the Material) to be non-confidential and non-proprietary, and Pairrit shall not be liable for the disclosure or use of such Material. If, at Pairrit's request, any member sends Material to improve the site (for example through the Forums or to customer support), Pairrit will also consider that Material to be non-confidential and non-proprietary and Pairrit will not be liable for use or disclosure of the Material. Any communication by you to Pairrit issubject to this Agreement. You hereby grant and agree to grant Pairrit, under all of your rights in the Material, a worldwide, non-exclusive,perpetual, irrevocable, royalty-free, fully-paid, sublicensable and transferable right and license to incorporate, make, use, sell, offer to sell,import, export, publicly display, publicly distribute, create derivative use from, publish and in all other ways exploit such Material for any purpose whatsoever, commercial or otherwise, including but not limited to incorporating it in the API, documentation, or any product or service, without compensation or accounting to you and without further recourse by you.",
];
