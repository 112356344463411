import React from "react";
import GradientBtn from "../../buttons/GradientBtn";
import Widget from "../Widget";
import { useOktaAuth } from "@okta/okta-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RegisterForm from "../../auth/RegisterForm";
import DialogWrapper from "../dialog-wrapper";
import mixpanel from "mixpanel-browser";
import Card from "../Card";

const ComingSoon = () => {
  const location = useLocation();
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  const loggingIn = async () => {
    oktaAuth.closeSession();
    oktaAuth.clearStorage();
    navigate("/login");
  };
  return (
    <Card className="bg-white shadow max-w-screen-xl mx-auto px-4 flex mt-3 justify-start h-[20em] md:px-8">
      <div className="max-w-lg mx-auto space-y-3 text-center">
        <h3 className="text-orange-600 font-semibold">Not Available</h3>
        <p className="text-gray-800 text-4xl font-semibold sm:text-5xl">
          Please Login
        </p>
        <p className="text-gray-600">
          Nice try, login or register to access this page.
        </p>
        <div className="w-full flex flex-wrap items-center justify-center gap-3">
          <GradientBtn
            label="Login / Register"
            className={"w-full"}
            onClick={() => {
              mixpanel.track("Page Register button click");
              loggingIn();
            }}
          ></GradientBtn>
        </div>
      </div>
    </Card>
  );
};

export default ComingSoon;
