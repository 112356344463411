import mixpanel from "mixpanel-browser";
import { ComponentProps } from "react";
import { Link } from "react-router-dom";
import { cn } from "../../helpers/utils";

interface DialogHeaderProps extends ComponentProps<"div"> {}

export default function DialogHeader({
  className,
  ...props
}: DialogHeaderProps) {
  return (
    <>
      <div
        className={cn("flex items-center justify-center", className, {})}
        {...props}
      >
        <Link
          onClick={() => {
            mixpanel.track("Logo clicked from auth");
          }}
          className=""
          to={"/"}
        >
          <img src="/assets/imgs/okta/login_logo.png" className="w-20 mx-auto" alt="" />
        </Link>

        <div className="h-px bg-gradient-to-l my-4 from-red-500 to-yellow-500" />
      </div>
    </>
  );
}
