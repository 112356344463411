import { Icon } from "@iconify/react";
import { ComponentProps, Fragment } from "react";
import { useMediaQuery } from "../../../hooks/useMediaQueries";
import {
  removeFromCart,
  removeFromCartSession,
  removeProd2Cart,
} from "../../../redux/features/cart/cartSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";
import { CartCard } from "./cart-card";
import { useOktaAuth } from "@okta/okta-react";
import Quantity from "./quantity";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import Cookies from "js-cookie";

type CardRowProps = ComponentProps<"div"> & {
  later?: boolean;
  data: any;
  idx: number;
};

export function CardRow({
  className,
  data,
  later,
  idx,
  ...props
}: CardRowProps) {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { cartTotal } = useAppSelector((state) => state.cart);
  const { authState } = useOktaAuth();
  const md = useMediaQuery("md");
  const Wrapper = md ? Fragment : "div";
  const onSubmit = () => {
    if (cartTotal > 0) {
      if (authState?.isAuthenticated) {
        dispatch(
          removeFromCart({
            userId: userId,
            index: idx,
          })
        ).then(() =>
          dispatch(
            notifySelect({
              title: "Item Removed",
              type: 0,
              open: true,
            })
          )
        );
        dispatch(removeProd2Cart(idx));
      } else {
          dispatch(
            removeFromCartSession({
              userId: Cookies.get("cartId"),
              index: idx,
            })
          ).then(() =>
            dispatch(
              notifySelect({
                title: "Item Removed",
                type: 0,
                open: true,
              })
            )
          );
        dispatch(removeProd2Cart(idx));
      }
    }
  };
  return (
    <>
      <Wrapper className="flex items-center mt-2 gap-3">
        {later ? (
          <div className="max-md:grow ">
            <GradientBtn
              className={"max-md:h-9"}
              label={
                <span className="flex !text-xs items-center gap-2">
                  <Icon icon="uil:cart" className="text-xl" />
                  Add to cart
                </span>
              }
            />
          </div>
        ) : (
          <div>
            {" "}
            <Quantity
              idx={idx}
              total={data.total}
              max={data.quantity}
              className="max-md:grow max-md:scale-75 my-0 origin-left "
            />{" "}
            {data.quantity < 2 ? (
              <p className="text-red-500 md:text-center text-2xs md:text-xs mt-1">Last One Left</p>
            ) : (
              <p className="text-red-500 md:text-center text-2xs md:text-xs mt-1">Limited Supply</p>
            )}
          </div>
        )}

        <div className="max-md:hidden text-neutral-400 flex flex-col items-center text-[8px] ">
          <p className="text-xl text-black font-medium leading-loose max-md:hidden">
            {data.price * data.total}$
          </p>
          <p>USPS Shipping</p>
          <div className="text-sm">+ 4 $</div>
        </div>
        <p className="md:text-xl text-lg text-black font-medium leading-loose md:hidden">
          ${data.price * data.total}<div className="text-xs">+ $4</div>
        </p>
      </Wrapper>
      <div className="flex flex-col items-center justify-center h-full">
        <div />
        <button
          onClick={() => onSubmit()}
          className="grid text-xs  md:text-lg  font-semibold border rounded-tr rounded-br cursor-pointer bg-zinc-100 place-content-center w-8 h-8 md:w-11 md:h-11"
        >
          <Icon icon="ph:x" />
        </button>
      </div>
    </>
  );
}
