import React, { useEffect, useRef, useState } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import { oidc } from './config';
import './signup.css'

import { useOktaAuth } from '@okta/okta-react';
import mixpanel from "mixpanel-browser";

const OktaSignup = ({ onSuccess, onError }) => {

  const { oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn(oidc);

    widget.el = '#widget-container2'
    widget.useInteractionCodeFlow = false
    widget.useClassicEngine = true

    var searchParams = new URL(window.location.href).searchParams;
    widget.otp = searchParams.get('otp');

    widget.options.logo = "/assets/imgs/okta/login_logo.png"
    widget.options.idpDisplay = 'SECONDARY'
    widget.state = searchParams.get('state');

    widget.options.idps = [{ type: 'GOOGLE', id: '0oajc6ez0nHFNgjGc5d7' }]
    widget.showSignInToGetTokens
      ({
        el: widgetRef.current,
      }).then(onSuccess).catch(onError);

    widget.on('afterRender', function (context) {
      
      if (context.controller === 'primary-auth') {

        const referenceNode2 = document.getElementsByClassName('primary-auth-form o-form o-form-edit-mode')
        while (referenceNode2.length > 0) {
          referenceNode2[0].parentNode.removeChild(referenceNode2[0]);
        }

        const referenceNode3 = document.getElementsByClassName('okta-sign-in-header auth-header')
        while (referenceNode3.length > 0) {
          referenceNode3[0].parentNode.removeChild(referenceNode3[0]);
        }

        const referenceNode4 = document.getElementsByClassName('auth-divider')
        while (referenceNode4.length > 0) {
          referenceNode4[0].parentNode.removeChild(referenceNode4[0]);
        }

        const referenceNode5 = document.getElementsByClassName('link help js-help')
        while (referenceNode5.length > 0) {
          referenceNode5[0].parentNode.removeChild(referenceNode5[0]);
        }

        const referenceNode6 = document.getElementsByClassName('auth-footer')
        while (referenceNode6.length > 0) {
          referenceNode6[0].parentNode.removeChild(referenceNode6[0]);
        }
      }
    });
    return () => widget.remove();
  }, []);

  return (

    <div id='widget-container2' ref={widgetRef} >
    </div>);
};

export default OktaSignup;
