import { useEffect } from "react";
import { fetchGearRoom } from "../../../redux/features/userCollection/userCollectionSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import GearIHave from "./gear-i-have";
import Skeleton from "@mui/material/Skeleton";
import Card from "../../common/Card/Card";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";

export default function MyGearRoom() {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  useEffect(() => {
    dispatch(fetchGearRoom(userId));
    mixpanel.track("Collection page view", {
      email: user ? user["email"] : "",
    });
  }, [userId]);

  const { currentList, loading } = useAppSelector(
    (state) => state.userCollection
  );

  return (
    <div>
      {loading ? (
        <Card className="bg-white mt-4">
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={350}
            height={48}
          />
        </Card>
      ) : (
        <>
          <GearIHave data={currentList} />
        </>
      )}
    </div>
  );
}
