import { HtmlHTMLAttributes, forwardRef, useId } from "react";
import { cn } from "../../helpers/utils";
import { FormMessage } from "../ui/form";
import TextField from '@mui/material/TextField';

interface InputProps extends HtmlHTMLAttributes<HTMLInputElement> {
  wrapperClassName?: string;
  type?: string;
  max?: number;
  noLabel?: boolean;
  placeholder?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, wrapperClassName, max = 40, type, noLabel, ...props }, ref) => {
    const id = useId();
    return (
      <div className={cn("", wrapperClassName)}>
        {!noLabel && (
          <label htmlFor={id} className="flex">
            <span className="text-[11px] font-semibold">
              {props["aria-label"] || props.placeholder}{" "}
            </span>
            {props["aria-required"] && (
              <span className="text-red-600 text-[11px] font-semibold ">
                *
              </span>
            )}
          </label>
        )}

        <div>
          {/* <TextField
            className={cn(
              "pl-3.5 w-full shadow appearance-none focus:outline-none focus:shadow-outline pt-3 pb-[13px]  placeholder:text-neutral-400 text-sm font-medium bg-stone-50 rounded-md border border-slate-400 h-10",
              {},
              className
            )}
            ref={ref}
            id={id}
            label="Required"
            type={type}
            defaultValue="Hello World"
          placeholder=""
            {...props}
          /> */}
          <input
            id={id}
            maxLength={max}
            ref={ref}
            type={type}
            {...props}
            className={cn(
              "pl-3.5 w-full shadow appearance-none focus:outline-none focus:shadow-outline pt-3 pb-[13px]  placeholder:text-neutral-400 text-sm font-medium bg-stone-50 rounded-md border border-slate-400 h-10",
              {},
              className
            )}
          />
          <FormMessage className="text-[10px]" />
        </div>
        <p className="text-[10px] py-0.5">{props["aria-details"]}</p>
      </div>
    );
  }
);

Input.displayName = "Input";
export { Input };
