import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../../store";
import axios from "axios";

interface InitialStateType {
  ruleLoading: boolean;
  ruleError: string | null;
  updateLoading: boolean;
  updateError: string | null;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  ruleLoading: false,
  ruleError: null,
  updateLoading: false,
  updateError: null,
};

export const updateCommSettings = createAsyncThunk(
  "comm/updateComm",
  async ({ description, userId, id, privacy, genre }: any) => {
    const response = await axios.put(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/updateCommunity/${id}`,
      {
        userId: userId,
        description: description,
        genre: genre,
        privacy: privacy,
      }
    );
    return response.data;
  }
);

export const addRule = createAsyncThunk(
  "rule/add",
  async ({ communityId, userId, name, rule }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/${name}/addRule/${userId}`,
      {
        communityId: communityId,
        rule: rule,
      }
    );
    return response.status;
  }
);

export const removeRule = createAsyncThunk(
  "rule/remove",
  async ({ userId, name, index }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/${name}/removeRule/${userId}/${index}`
    );
    return response.status;
  }
);

export const modSettingsSlice = createSlice({
  name: "modSettings",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRule.pending, (state) => {
        state.ruleLoading = true;
        state.ruleError = null;
      })
      .addCase(addRule.fulfilled, (state, action) => {
        state.ruleLoading = false;
      })
      .addCase(addRule.rejected, (state, action) => {
        state.ruleLoading = false;
        state.ruleError = action.error.message || "An error occurred";
      })
      .addCase(removeRule.pending, (state) => {
        state.ruleLoading = true;
        state.ruleError = null;
      })
      .addCase(removeRule.fulfilled, (state, action) => {
        state.ruleLoading = false;
      })
      .addCase(removeRule.rejected, (state, action) => {
        state.ruleLoading = false;
        state.ruleError = action.error.message || "An error occurred";
      })
      .addCase(updateCommSettings.pending, (state) => {
        state.updateLoading = true;
        state.updateError = null;
      })
      .addCase(updateCommSettings.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(updateCommSettings.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.error.message || "An error occurred";
      });
  },
});

export const {} = modSettingsSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default modSettingsSlice.reducer;
