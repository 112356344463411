import React from 'react'

const WhyUs = () => {
    return (
      <React.Fragment>
        <div className="mt-10 mb-4 text-center font-semibold text-2xl">
          Why Pairrit?
        </div>

        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Authenticatiion</div>
            <div className="font-medium mt-1 text-xs">
              All used products are verified by us.
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Reduced Fees</div>
            <div className="font-medium mt-1 text-xs">
              Lower Fee rates on sold items, at 8.5%!
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Listings are Boosted</div>
            <div className="font-medium mt-1 text-xs">
              All listings are promoted to related feeds!
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Community</div>
            <div className="font-medium mt-1 text-xs">
              Join the fastest growing fragrance community
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default WhyUs