import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  loading: boolean;
  error: string | null;
  productList: any;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  loading: true,
  error: null,
  productList: null,
};

export const fetchProductsByCommunity = createAsyncThunk(
  "market/fetchProductsByComm",
  async (data: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/products/byCommunity/${data}`
    );
    return response.data;
  }
);

export const fetchProductsByCommAndType = createAsyncThunk(
  "market/fetchProductsByCommType",
  async ({ data, data1 }: any) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/products/byCommunity/${data}/category/${data1}`
    );
    return response.data;
  }
);

export const commMarketSlice = createSlice({
  name: "commMarket",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsByCommunity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductsByCommunity.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload;
      })
      .addCase(fetchProductsByCommunity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchProductsByCommAndType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductsByCommAndType.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload;
      })
      .addCase(fetchProductsByCommAndType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});
export const {} = commMarketSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default commMarketSlice.reducer;
