import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useId, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import useAuthUser from "../../../../hooks/getAuthUser";
 import { addNewListingProperties } from "../../../../redux/features/post/postSlice";
import { useAppSelector } from "../../../../redux/hooks";

import GreyBtn2 from "../../../buttons/GreyBtn2";
import YellowBtn from "../../../buttons/YellowBtn";
import Select from "../../../common/select";
import { TextField } from "../../../text-field";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../../ui/form";
import MarketCategories from "../../Market/Categories";
import SizeSelector from "./SizeSelector";

export function Checkbox({
  onChange,
  label,
  extra,
  className,
  defaultValue, checked
}: {
  onChange?: (value: boolean) => void;
  label: string | React.ReactNode;
  extra?: string;
  defaultValue?: boolean;
  checked?: boolean;
  className?: string;
}) {
  const [value, setValue] = useState(false);
  const onSelect = (value: any) => {
  
    if (onChange) {
      onChange(value);
    }
  };
  const id = useId();
  return (
    <div className={className}>
      <input
        type="checkbox"
        id={id}
        checked={checked ? checked : undefined}
        defaultChecked={defaultValue ? defaultValue : undefined}
        className="hidden peer"
        onChange={(v: any) => onSelect(!value)}
      />
      <label
        htmlFor={id}
        className="peer-checked:[&>div]:bg-blue-700 flex items-center gap-2.5 select-none"
      >
        <div className="icon-container w-[18px] h-[18px] grid place-content-center group   rounded border border-slate-400 border-opacity-20">
          <Icon
            icon={"material-symbols:check"}
            className="text-lg text-white"
          />
        </div>
        <p className="label text-sm font-medium ">{label}</p>
        {extra && (
          <span className="text-blue-700 bg-blue-100 rounded-sm py-0.5 px-1.5 text-[8px] font-normal">
            {extra}
          </span>
        )}
      </label>
    </div>
  );
}


export default function Information({
  setValue,
  sizes
}: {
    setValue: (value: string) => void;
    sizes: any;
  }) {
  const post = useAppSelector((state) => state.post.newListing);

  const dispatch = useDispatch();
  const user = useAuthUser();
  const [currentProd, setCurrentProd] = useState(post.size);
  const formSchema = z.object({
    condition: z.string().default(post.condition),
    size: z.number().default(post.size),
    quantity: z.number().default(post.quantity),
    description: z
      .string()
      .min(1, { message: "Description is required😅" })
      .max(350, { message: "350 Characters Max😅" })
      .default(post.description),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

function onSubmit(values: any) {
   
      dispatch(addNewListingProperties(values));
      mixpanel.track("Sell product (Step 3 Completed)", {
        ...values,
        email: user ? user["email"] : "",
      });
      setValue("Photos & Desc");
}
  
    useEffect(() => {
      form.setValue("size", currentProd);

    }, [currentProd]);
  

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className="font-semibold leading-tight">Sell Your Fragrance</p>

        <SizeSelector
          value={currentProd}
          selection={sizes}
          setValue={setCurrentProd}
        />
        <div className="my-3">
          <hr />
        </div>
        <p className="text-2xs italic  subpixel-antialiased">
          NEW Fragrances = Skip uploading photos 😅
        </p>
        <div className="grid grid-cols-2 gap-8 mt-2">
          {options2.map((_, idx) => (
            <div className="w-full">
              <p className=" text-[11px] font-semibold my-1">
                {_.label}
                <span className="text-red-600">*</span>
              </p>
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        uniqueKey="condition-selector"
                        onOptionSelect={field.onChange}
                        defaultValue={post[_.label]}
                        options={_.options}
                        label={_.label}
                        noLabel
                        className=" bg-zinc-100 mb-2"
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={_.label}
                control={form.control}
              />
            </div>
          ))}
        </div>
        <div className="my-3">
          <hr />
        </div>

        <p className=" text-[11px] font-semibold my-1">
          Description
          <span className="text-red-600">*</span>
        </p>
        <FormField
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <TextField
                  defaultValue={post["description"]}
                  {...field}
                  onOptionSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
          name={"description"}
          control={form.control}
        />

        <div className="flex gap-5">
          <GreyBtn2
            className="w-32 my-8 text-sm"
            label={"Back"}
            onClick={() => setValue("Choose Fragrance")}
          />
          <YellowBtn
            type="submit"
            className="px-8 my-8 w-fit text-sm"
            label={"Continue"}
          />
        </div>
      </form>
    </Form>
  );
}




const options2 = [
  {
    placeholder: "Condition",
    label: "condition",
    options: [
      { label: "Partial", value: "PARTIAL" },
      { label: "Brand New", value: "NEW" },
      { label: "New(Opened)", value: "OPENED" }
    ],
  },
  {
    placeholder: "Quantity",
    label: "quantity",
    required: true,
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 },
      { label: "8", value: 8 },
    ],
  },
];