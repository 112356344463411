import { zodResolver } from "@hookform/resolvers/zod";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useAuthUser from "../../../../hooks/getAuthUser";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { acceptOffer } from "../../../../redux/features/userTransactions/offers/userOffersSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import GradientBtn from "../../../buttons/GradientBtn";
import { Loading } from "../../../common/Widget/Loading";
import { Form } from "../../../ui/form";

const formSchema = z.object({
  id: z.string(),
});

interface Props {
  id: any;
}

export const AcceptOffer: React.FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { responseLoading, responseError } = useAppSelector(
    (state) => state.userOffers
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
    dispatch(acceptOffer(values))
      .then(() =>
      {
        dispatch(
          notifySelect({
            title: "Offer Accepted!",
            type: 0,
            open: true,
          })
        );
mixpanel.track("Offer accepted", {
  offer_id: id,
  email: user ? user["email"] : "",
});
      }
      )
      .finally(() => window.location.reload());
  }

  useEffect(() => {
    form.setValue("id", id);
    if (responseError) {
      dispatch(
        notifySelect({
          title: "Error Sending",
          type: 1,
          open: true,
        })
      );
    }
  }, [responseError, id]);
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-3 font-bold text-lg">
          Confirm Offer
        </p>
        <hr className="my-6" />
        <div className="text-zinc-900 font-medium">
          Are you sure you want to Accept this Offer?
        </div>{" "}
        <div className="-mx-6 p-3.5 mt-10 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
          {responseLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              label={"Accept"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}
