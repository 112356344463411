import { Icon } from "@iconify/react";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import GreyBtn from "../../buttons/GreyBtn";
import Card from "../../common/Card";

export default function UserAbout({ store, desc }: { store?: boolean; desc: any }) {
  const [isExpanded, setIsExpanded] = useState(false);
  let params = useParams();
  const location = useLocation().pathname;
  const [currentTab, setCurrentTab] = useState(location);
  return (
    <Card className=" bg-white mt-4">
      <p className="text-sm font-semibold text-black">About Me</p>
      <div className="mt-1 text-xs font-normal">
        {isExpanded ? (
          <>
            {desc.description}
            {desc.description.length > 220 && (
              <span
                className="cursor-pointer text-accentYellow-500"
                onClick={() => setIsExpanded(false)}
              >
                {" "}
                Read less
              </span>
            )}
          </>
        ) : (
          <>
            {desc.description.slice(0, 220)}
            {desc.description.length > 220 && (
              <>
                ...{" "}
                <span
                  className="cursor-pointer text-accentYellow-500"
                  onClick={() => setIsExpanded(true)}
                >
                  Read more
                </span>
              </>
            )}
          </>
        )}
      </div>

     
    </Card>
  );
}

const action = [
  {
    icon: "carbon:storage-pool",
    label: "Collection",
    link: "gear",
  },

  {
    icon: "mdi:cart-percent",
    label: "Listings",
    link: "shop",
  },
  {
    icon: "tabler:needle-thread",
    label: "Threads",
    link: "threads",
  },
  {
    icon: "ri:star-line",
    label: "Communities",
    link: "communities",
  },
];
