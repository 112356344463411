import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { searchAll, searchTopics } from "../../../redux/features/search/searchSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Card from "../../common/Card";
import NotAdded from "../../common/Error/NotAdded";
import Loader from "../../common/Loader/loader";
import SortbySelect from "../../common/Selectors/sortbyselect";
import GearList from "../Single-Page/New-Gear/gear-list";
import { SearchCategories, SortSearch } from "./common";
import { SearchFilterMobFull } from "./filter-mob-full";
import { SearchPostList } from "./post-list";
import mixpanel from "mixpanel-browser";
import { Icon } from "@iconify/react";
import GradientBtn from "../../buttons/GradientBtn/GradientBtn";

export const SearchPosts = () => {

  const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const sort = searchParams.get("sort");
    const [isSearchFilterVisible, setSearchFilterVisible] = useState(false);
    const [isSearchFilterVisibleFull, setSearchFilterVisibleFull] =
     useState(false);
    const { fragList, loading, error } = useAppSelector(
      (state) => state.search
    );
    useEffect(() => {
      dispatch(searchAll({sort:sort, search: searchParams.get("q") || ""}));
    }, [sort]);
  return (
    <div className="mt-6 flex grid grid-cols-12 gap-5 justify-between">
     
      <div className="md:col-start-1 lg:col-span-12 col-span-full">
        <div className="flex justify-bewteen gap-2">
        
          <SortbySelect
            options={options}
            wrapperClass={cn("max-md:grow ml-auto")}
            className="w-full bg-zinc-100 "
            onClick={() => {
              mixpanel.track("Best match button clicked");
            }}
          />
        </div>
        {loading ? (
          <Card className="bg-white mt-4">
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={350}
              height={40}
            />
          </Card>
        ) : fragList ? (
          <GearList
              value={fragList}
              search={true}
            className="md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6"
          />
        ) : (
          <NotAdded
            m1="Nice Try"
            m2="No Products"
            m3="No matches to your request."
          />
        )}
      </div>
    </div>
  );
};


const options = [
  { label: "Most Popular", value: "POPULAR" },
  { label: "Recent Released", value: "RECENT" },
  { label: "Price Low to High", value: "LOW" },
  { label: "Price High to Low", value: "HIGH" },
  { label: "Top Rated", value: "RATE" },
];
