import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import mixpanel from "mixpanel-browser";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import useAuthUser from "../../../../hooks/getAuthUser";
import { updateProfileProperties } from "../../../../redux/features/user/updateProfileSlice";
import { Form, FormControl, FormField, FormItem } from "../../../ui/form";
import Select from "../../Selectors/select2";


const formSchema = z.object({
  gender: z.string().toUpperCase(),
  birthDate: z.date(),

});

const OnBoardingPl = ({ setValue }: { setValue: (value: string) => void }) => {
  const dispatch = useDispatch();
  const user = useAuthUser();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  function onSubmit(values: any) {
    dispatch(updateProfileProperties(values));
    mixpanel.track("Member card (Step 2 Complete)", {
      ...values,
      email: user ? user["email"] : "",
    });
    setValue("Third");
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <img
          src="/assets/imgs/common/logo.png"
          className="w-16 mx-auto"
          alt=""
        />
        <p className="text-center text-xl font-extrabold text-gray-100">
          Member Card
        </p>
        <div className="flex  justify-between items-start  gap-4  mt-3">
          <h4 className="text-semibold text-[17px] text-white">Gender:</h4>
          <FormField
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select
                    light
                    uniqueKey="gender-select"
                    onChange={field.onChange}
                    defaultValue={"Select"}
                    options={[
                      { label: "Male", value: "male" },
                      { label: "Female", value: "female" },
                      { label: "Other", value: "other" },
                    ]}
                    placeholder={"gender"}
                  />
                </FormControl>
              </FormItem>
            )}
            name={"gender"}
            control={form.control}
          />
        </div>
        <hr className=" my-3" />
        <div className="flex justify-between items-start  gap-1  mt-3">
          <h4 className="text-semibold text-[17px] text-white">Age:</h4>
          <FormField
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BirthSelector onChange={field.onChange} />
                </FormControl>
              </FormItem>
            )}
            name={"birthDate"}
            control={form.control}
          />
          
        </div>
        <footer className="my-5 flex justify-center">
          <button
            type="submit"
            className="flex items-baseline gap-2 rounded-lg bg-gradient-to-l from-red-500  to-yellow-500  px-4 py-2.5 text-xl font-bold text-white hover:opacity-75 border-2 border-white"
          >
            <span>Next</span>
            <Icon
              icon="solar:play-bold-duotone"
              className="text-xl text-white h-4"
            />
          </button>
        </footer>
      </form>
    </Form>
  );
};

export default OnBoardingPl;


const BirthSelector = ({ onChange }: { onChange: (value: any) => void }) => {
  const [birthMonth, setbirthMonth] = useState();
  const [birthDay, setbirthDay] = useState();
  const [birthYear, setbirthYear] = useState();
  const [value, setValue] = useState("");
const selectRef = useRef(null);
  useEffect(() => {
     if (birthDay && birthMonth && birthYear) {
        onChange(new Date(`${birthMonth}/${birthDay}/${birthYear} 4:50:40 PM UTC`));
     }
   
  });
  return (
    <div className="flex justify-between items-start  gap-1 ">
      <Select
        light
        short
        uniqueKey="month-select"
        onChange={(x: any) => setbirthMonth(x)}
        defaultValue={"Select"}
        options={[
          { label: "Jan", value: "1" },
          { label: "Feb", value: "2" },
          { label: "Mar", value: "3" },
          { label: "Apr", value: "4" },
          { label: "May", value: "5" },
          { label: "June", value: "6" },
          { label: "July", value: "7" },
          { label: "Aug", value: "8" },
          { label: "Sep", value: "9" },
          { label: "Oct", value: "10" },
          { label: "Nov", value: "11" },
          { label: "Dec", value: "12" },
        ]}
        placeholder={"Month"}
      />
      <Select
        light
        short
        uniqueKey="day-select"
        onChange={(x: any) => setbirthDay(x)}
        defaultValue={"Select"}
        options={Array.from(Array(31).keys()).map((e, i) => ({
          label: `${i + 1}`,
          value: `${i + 1}`,
        }))}
        placeholder={"Day"}
      />
      <Select
        light
        short
        uniqueKey="year-select"
        onChange={(x: any) => setbirthYear(x)}
        defaultValue={"Select"}
        options={Array.from(Array(84).keys()).map((e, i) => ({
          label: `${2012 - i}`,
          value: `${2012 - i}`,
        }))}
        placeholder={"Year"}
      />
    </div>
  );
};