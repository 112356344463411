import { Icon } from "@iconify/react";
import { useOktaAuth } from "@okta/okta-react";
import * as Popover from "@radix-ui/react-popover";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { useAppSelector } from "../../../redux/hooks";
import Loader from "../Loader/loader";
import { Loading } from "../Widget/Loading";
import Skeleton from "@mui/material/Skeleton";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";


export function UserMenu() {
  const { oktaAuth, authState } = useOktaAuth();
  const user = useAuthUser();
  const loggingOut = async () => {
     oktaAuth.signOut();
     oktaAuth.clearStorage();
     oktaAuth.closeSession();
   };
  const { profileData, loading } = useAppSelector((state) => state.userProfile);

  if (!authState?.isAuthenticated) return <></>;
  return (
    <Popover.Root>
      <Popover.Trigger
        className="hidden md:block"
        onClick={() => {
          mixpanel.track("Profile Icon clicked", {
            email: user ? user["email"] : "",
          });
        }}
      >
        {loading ? (
          <Skeleton
            animation="wave"
            variant="circular"
            width={35}
            height={35}
          />
        ) : (
          <UserImg
            className={loading ? "animate-pulse" : ""}
            img={profileData?.profileUrl}
          />
        )}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="hidden md:block relative z-50 [--profile-x:200px] md:[--profile-y:-200px] [--profile-y:-0px] md:[--profile-x:0px]">
          <motion.div
            initial={{
              x: "var(--profile-x)",
              y: "var(--profile-y)",
            }}
            transition={{ type: "keyframes" }}
            animate={{ x: 0, y: 0 }}
            className="p-5 shadow-lg bg-[#d1d8e0] mt-3 w-60 mx-px md:mx- rounded-2xl  "
          >
            {loading ? (
              <Loader />
            ) : (
              <Link to={"/my/page"}>
                <div className="flex items-center gap-3 border-b pb-2 md:pb-5">
                  <UserImg className="" img={profileData?.profileUrl} />
                  <div>
                    <div className="flex gap-3">
                      <p className="texxt-sm md:text-base font-medium">
                        {profileData?.userName}
                      </p>
                      <button className="w-[35px] h-[21px] bg-indigo-50 rounded-md text-blue-600 text-[11px] font-bold border border-indigo-200 ">
                        PRO
                      </button>
                    </div>
                    <p className="text-neutral-400 text-sm font-medium">
                      {profileData?.email}
                    </p>
                  </div>
                </div>
              </Link>
            )}

            <div className="space-y- md:space-y-8 mt-3">
              {menus.map((_) =>
                _.label === "Sign Out" ? (
                  <div
                    className="mb-3 flex cursor-pointer px-3 items-center gap-3 hover:opacity-75"
                    onClick={() => {
                      loggingOut();
                      mixpanel.reset();
                    }}
                  >
                    <Icon icon={_.icon} className="text-lg text-blue-800" />
                    <div className="text-xs md:text-base font-medium">
                      {_.label}
                    </div>
                  </div>
                ) : (
                  <Link to={_.link}>
                    <div className="mb-4 md:mb-6 flex cursor-pointer px-3 items-center gap-3 hover:opacity-75">
                      <Icon icon={_.icon} className="text-lg text-blue-800" />
                      <div className="text-xs md:text-base font-medium">
                        {_.label}
                      </div>
                    </div>
                  </Link>
                )
              )}
            </div>
          </motion.div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
export function UserImg({ className, img }: { img?: string; className: string }) {
  return (
    <div
      className={cn(
        "relative w-8 md:w-8 bg-white rounded-full aspect-square",
        className
      )}
    >
      <img
        src={`/assets/imgs/avatar/${img}.svg`}
        alt=""
        className="object-cover w-full h-full rounded-full ring-1 ring-zinc-300"
      />{" "}
      <img
        src="/assets/vectors/common/flower.svg"
        alt=""
        className="absolute w-3.5 h-3.5 p-0.5 right-0 bottom-0 bg-white rounded-full"
      />
    </div>
  );
}
const menus = [
  { label: "My Shop", icon: "tdesign:shop", link: "/my/shop" },
  {
    label: "Manage Listings",
    icon: "icon-park-outline:list",
    link: "/transactions/manage-listings",
  },
  {
    label: "Purchases",
    icon: "arcticons:bestbuy",
    link: "/transactions/purchases",
  },
  {
    label: "Orders",
    icon: "icon-park-twotone:order",
    link: "/transactions/orders",
  },
  {
    label: "Wistlist",
    icon: "quill:list",
    link: "/my/watchlist",
  },
  {
    label: "Communities",
    icon: "fluent:people-community-16-filled",
    link: "/my/communities/own",
  },
  {
    label: "Offers",
    icon: "tdesign:undertake-transaction",
    link: "/transactions/offers/sent",
  },
  {
    label: "Profile Settings",
    icon: "ic:twotone-settings",
    link: "/settings/account",
  },
  {
    label: "Messages",
    icon: "iconoir:privacy-policy",
    link: "/messages",
  },
  { label: "Help Center", icon: "material-symbols:help", link: "/help" },
  { label: "Sign Out", icon: "tabler:logout", link: "" },
];
