import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { searchStore } from "../../../redux/features/search/searchSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import NotAdded from "../../common/Error/NotAdded";
import Loader from "../../common/Loader/loader";
import ProductList from "../Market/product-list";
import { SortSearch } from "./common";
import mixpanel from "mixpanel-browser";
import SortbySelect from "../../common/Selectors/sortbyselect";
import Skeleton from "@mui/material/Skeleton";
import Card from "../../common/Card";
import { SearchFilterMobFull } from "./filter-mob-full";
import { Icon } from "@iconify/react";
import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import { cn } from "../../../helpers/utils";

export const SearchShop = () => {
  const [searchParams] = useSearchParams();
  const sort = searchParams.get("sort");
  const dispatch = useAppDispatch();
  const { productList, loading, error } = useAppSelector(
    (state) => state.search
  );

  useEffect(() => {
    dispatch(searchStore({ sort: sort, search: searchParams.get("q") || "" }));
  }, [searchParams]);

  return (
    <div className="mt-6 flex grid grid-cols-12 gap-5 justify-between">
      <div className="md:col-start-1 lg:col-span-12 col-span-full">
        <div className="flex justify-bewteen gap-2">
          <SortbySelect
            options={options}
            wrapperClass={cn("max-md:grow ml-auto")}
            className="w-full bg-zinc-100 "
            onClick={() => {
              mixpanel.track("Best match button clicked");
            }}
          />
        </div>
        {loading ? (
          <Card className="bg-white mt-4">
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={350}
              height={40}
            />
          </Card>
        ) : productList ? (
          <ProductList
            search={true}
            value={productList}
            className="md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6"
          />
        ) : (
          <NotAdded
            m1="Nice Try"
            m2="No Products"
            m3="No matches to your request."
          />
        )}
      </div>
    </div>
  );
};


const options = [
  { label: "Recent Released", value: "RECENT" },
  { label: "Price Low to High", value: "LOW" },
  { label: "Price High to Low", value: "HIGH" },
];