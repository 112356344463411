import mixpanel from "mixpanel-browser";
import { HTMLAttributes } from "react";
import { cn } from "../../../helpers/utils";
import useAuthUser from "../../../hooks/getAuthUser";
import { ProductCard } from "./product-card";

export default function ProductList({
  className,
  value,
  props,
  search
}: {
  className?: string;
  props?: any;
  search?: boolean;
  value: Array<any>;
} & HTMLAttributes<HTMLDivElement>) {
  const user = useAuthUser();
  return (
    <section {...props}>
      <div className={cn("mt-6 grid grid-cols-2 gap-2 z-40", className, {})}>
        {value?.map((_, key) => (
          <div
            onClick={() =>
              mixpanel.track("Product card clicked", {
                product_name: _.name,
                product_id: _.id,
                product_amount: _.price,
                email: user ? user["email"] : "",
              })
            }
          >
            <ProductCard alt={search} key={key} info={_} />
          </div>
        ))}
      </div>
    </section>
  );
}
