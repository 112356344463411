import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import {store} from './redux/store'
import { TourProvider } from "@reactour/tour";
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import axios, {  AxiosHeaders } from 'axios';


const root = ReactDOM.createRoot(document.getElementById('root')!);
const radius = 10;
const steps = [
  {
    selector: ".first-step",
    content: "Select Category",
  },
  {
    selector: ".second-step",
    content: "Select Type",
  },
  {
    selector: ".third-step",
    content: "Select Community (Optional)",
  },
];
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <TourProvider
        steps={steps}
        styles={{
          popover: (base) => ({
            ...base,
            "--reactour-accent": "#ef5a3d",
            borderRadius: radius,
          }),
          maskArea: (base) => ({ ...base, rx: radius }),
          maskWrapper: (base) => ({ ...base, color: "#ef5a3d" }),
          badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
          controls: (base) => ({ ...base, marginTop: 10 }),
          close: (base) => ({ ...base, right: "auto", left: 8, top: 8 }),
        }}
        disableDotsNavigation
      >
        <App />
      </TourProvider>
    </Provider>
  </BrowserRouter>
);

axios.interceptors.request.use((request)=>{
  let token = localStorage.getItem('myAccessToken') ?? '';

  
  if(!request.url?.toString().includes("v1/okta/token")){
    (request.headers as AxiosHeaders).set('Authorization', token)
  return request;
  }
  return request;
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// @ts-ignore
reportWebVitals();
