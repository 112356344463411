import React, { useEffect, useRef, useState } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import { oidc } from './config';
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

const OktaSignInWidget = ({ onSuccess, onError }) => {

  
  const [userInfo, setUserInfo] = useState(null);
 
  const navigate = useNavigate()
  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn(oidc);
    // widget.el = '#widget-container'
    widget.useInteractionCodeFlow = false
    widget.useClassicEngine = true

    // Search for URL Parameters to see if a user is being routed to the application to recover password
    var searchParams = new URL(window.location.href).searchParams;
    widget.otp = searchParams.get('otp');

    widget.options.logo = "/assets/imgs/okta/login_logo.png"
    widget.options.idpDisplay = 'PRIMARY'
    widget.state = searchParams.get('state');
    widget.options.customButtons = [{
      click: function () { navigate("/signup") },
      title: 'Sign Up',
      className: 'button button-secondary bg'
    }]

    widget.options.idps = [{ type: 'GOOGLE', id: '0oajc6ez0nHFNgjGc5d7' }]
    widget.showSignInToGetTokens
      ({
        el: widgetRef.current,
      }).then(onSuccess).catch(onError);

    return () => widget.remove();
  }, [onSuccess, onError]);

  return (
    <div className="bg-white grid-cols-12 grid gap-10 h-screen" style={{overflow: 'auto'}}>
      <div className="bg-gray-200 hidden md:block md:col-span-4 ">
        <Link
          onClick={() => {
            mixpanel.track("Logo clicked from auth");
          }}
          className=""
          to={"/"}
        >
          <img
            src="/assets/imgs/common/logo-2.png"
            className="w-22 h-16 mt-10 mx-auto"
            alt="logo"
          />
          <h2 className="text-xl text-center">
            Making Community Shopping Fun!
          </h2>
        </Link>

        <img
          className=" h-[25em] w-full object-cover bg-transparent mt-24 mx-auto "
          src="/assets/imgs/home/fragVerify1.png"
          alt=""
        />
      </div>
      <div className="hidden md:block md:col-span-2"></div>
      <div className="col-span-full md:col-span-4 md:w-full w-screen">
        <section className=''>
          <div className="mt-8">
            <p className="text-xs md:text-sm text-center xs:w-fit  m-auto">
              Don't have an account?
              <Link
                to="/signup"
                className="ml-1 underline font-bold text-orange-600 cursor-pointer"
              >
                Sign up!
              </Link>
            </p>
          </div>
        </section>
        <div style={{
        // backgroundImage: `url("/assets/imgs/okta/login_bg_image.jpeg")`,
        // backgroundRepeat: 'no-repeat', backgroundSize: 'cover'
      }} ref={widgetRef} >
        
      </div>
      </div>
    </div>
   );
};

export default OktaSignInWidget;
