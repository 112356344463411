import React from 'react';
import { useState } from "react";
import OktaSignInWidget from './OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate, Navigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../src/hooks/getAuthUser";
import axios from "axios";
import { useAppDispatch } from "../redux/hooks";
import { notifySelect } from "../redux/features/notify/notifySlice";
import { generateRandomUsername } from '../components/auth/SignupA';

const Login = ({ config }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();

  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const user = useAuthUser();

  const loggingIn = async () => {
    navigate("/");
  };

  const handleSubmit = async (fname, lname, email, displayname, password, isIdpLogin, idp, oktaUserId) => {

    try {
      const response = await axios.post(
        `https://webservice.pairrit.com/pairrit-ws/api/auth/signup`,
        {
          firstName: fname,
          lastName: lname,
          email: email,
          login: email,
          displayName: displayname,
          password: password,
          isIdpLogin: isIdpLogin,
          idp: idp,
          oktaUserId: oktaUserId
        }
      );
      dispatch(
        notifySelect({
          title: "Success, Please Login!",
          type: 0,
          open: true,
        })
      );
      // mixpanel.alias(formData.email);
      loggingIn();
    } catch (err) {
      // setLoading(false);
      if (!err?.response) {
        dispatch(
          notifySelect({
            title: "No Server Response",
            type: 1,
            open: true,
          })
        );
      } else {
        dispatch(
          notifySelect({
            title: "Welcome Back",
            type: 0,
            open: true,
          })
        );
      }
    }
  };
  console.log("USERINFO: " + userInfo);
  const onSuccess = (tokens) => {

    setUserInfo(tokens.idToken.claims);
    
    oktaAuth.handleLoginRedirect(tokens);
    mixpanel.track("Logo clicked", {
      email: tokens.idToken.claims.email ? tokens.idToken.claims.email : "",
    });

    // Checking whether this is ISP login or not
    if (tokens.idToken.claims.idp == "0oajc6ez0nHFNgjGc5d7") {
      handleSubmit(tokens.accessToken.claims.firstName,
        tokens.accessToken.claims.lastName,
        tokens.idToken.claims.email,
        generateRandomUsername(),
        'randompass',
        true,
        tokens.idToken.claims.idp,
        tokens.idToken.claims.sub)
      navigate("/")
    } else {
      navigate("/")
    }

  };
  const onError = (err) => {
    console.log('Sign in error:', err);
  };

  if (!authState) {
    return <div>Loading ... </div>;
  }
  return authState.isAuthenticated ?
     <Navigate to="/" replace={true} /> :
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />;
};

export default Login;