import { Icon } from "@iconify/react";
import * as Accordion from "@radix-ui/react-accordion";

export default function RulesAccordion({ rules }: { rules: Array<any> }) {
  return (
    <section>
      <p className="text-base font-semibold underline">Rules</p>
      <Accordion.Root type="single" collapsible>
        {rules.map((_, idx) => (
          <Accordion.Item
            value={_.id}
            role="button"
            className="py-2 text-sm font-semibold text-left "
            key={_.id}
          >
            <Accordion.Trigger asChild className="group">
              <div className="flex items-center justify-between">
                <p className="text-xs font-semibold">
                  {idx + 1}. {_.id} {_.rule}
                </p>
              </div>
            </Accordion.Trigger>
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </section>
  );
}
