// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    
    #widget-container2 #okta-sign-in.auth-container.main-container {
        border: none !important;
        margin: 0px -30px;
      
    }
  

        #widget-container2 .default-custom-button {
            display: none !important;
        }`, "",{"version":3,"sources":["webpack://./src/okta/signup.css"],"names":[],"mappings":";;IAEI;QACI,uBAAuB;QACvB,iBAAiB;;IAErB;;;QAGI;YACI,wBAAwB;QAC5B","sourcesContent":["\n    \n    #widget-container2 #okta-sign-in.auth-container.main-container {\n        border: none !important;\n        margin: 0px -30px;\n      \n    }\n  \n\n        #widget-container2 .default-custom-button {\n            display: none !important;\n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
