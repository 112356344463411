import { ComponentProps, useEffect } from "react";
import { useParams } from "react-router-dom";
import { cn } from "../../../../helpers/utils";
import Wrapper from "../../../../layouts/MainLayout/wrapper";
import { fetchCommunityPosts } from "../../../../redux/features/communityFeed/communitySlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Card from "../../../common/Card";
import Loader from "../../../common/Loader/loader";
import FeedType from "../../Home/Feeds";
import Posts from "../../Home/Posts";

type CommunityPostProps = ComponentProps<"div">;

export function CommunityPost({ className, ...props }: CommunityPostProps) {
  const dispatch = useAppDispatch();
  const { id } = useParams();
    const { communityFeed, feedError, feedLoading } = useAppSelector(
      (state) => state.community
    );
    useEffect(() => {
      if (id) { dispatch(fetchCommunityPosts(id)) };
    }, []);
  
  
  const feed = useAppSelector((state) => state.community.communityFeed);
  return (
    <div className="mb-24">
      {/* <Wrapper className={cn(className)}>
        <FeedType />
      </Wrapper> */}
      {feedLoading ? (
        <Loader />
      ) : communityFeed ? (
        <Posts posts={communityFeed} />
      ) : (
        <Card className="bg-white p-6">No Posts Added Yet</Card>
      )}
    </div>
  );
}
