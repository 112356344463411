import { Icon } from "@iconify/react";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import GradientBtn from "../../buttons/GradientBtn";
import { cn } from "../../../helpers/utils";
import SortbySelect from "../../common/Selectors/sortbyselect";
import { SearchFilterMob } from "../Search/filter-mob";
import { SearchFilterMobFull } from "../Search/filter-mob-full";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  fetchProducts,
  fetchMarket,
  resetFilter,
} from "../../../redux/features/market/marketplaceSlice";
import Loader from "../../common/Loader/loader";
import ShopFlags from "../Home/Flags/ShopFlags";
import Flags from "../Home/Flags";
import NotAdded from "../../common/Error/NotAdded";
import Card from "../../common/Card/Card";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import React from "react";
import FlagsBanner from "../Home/Flags/FlagsBanner";
import { useMediaQuery } from "../../../hooks/useMediaQueries";
import mixpanel from "mixpanel-browser";
import GearList from "../Single-Page/New-Gear/gear-list";

const ForSale = () => {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.getAll("category");
  const formula = searchParams.getAll("formula");
  const gender = searchParams.getAll("gender");
  const brands = searchParams.getAll("brand");
  const maxYear = searchParams.get("maxYear");
  const minYear = searchParams.get("minYear");
   const maxPrice = searchParams.get("maxPrice");
   const minPrice = searchParams.get("minPrice");
   const sort = searchParams.get("sort");
  const [isSearchFilterVisible, setSearchFilterVisible] = useState(false);
  const [isSearchFilterVisibleFull, setSearchFilterVisibleFull] =
    useState(false);
  const { productList, loading, error } = useAppSelector(
    (state) => state.marketplace
  );
  const [page, setPage] = React.useState<number>(0);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(fetchProducts(value - 1));
  };
  useEffect(() => {
    mixpanel.track("Home screeen view");

    if (
      category.length > 0 ||
      gender.length > 0 ||
      brands.length > 0 ||
      formula.length > 0 ||
      maxYear ||
      minYear ||
      maxPrice ||
      minPrice || sort
    ) {
      dispatch(
        fetchMarket({
          sort,
          category,
          formula,
          gender,
          brands,
          minPrice,
          maxPrice,
          minYear,
          maxYear,
        })
      );
    } else {
      dispatch(fetchProducts(page));
    }
  }, [searchParams]);
  return (
    <div className="mb-[8em] ">
      <div className="justify-between flex">
        <p className="text-sm font-bold capitalize ">Shop All</p>
        <p className="text-xs font-medium capitalize text-neutral-400">
          999+ Results
        </p>
      </div>
      <div className="h-0 md:pb-4 pb-2 mt-1 border-t border-slate-400/20" />

      {/* <AnimatePresence>
        {isSearchFilterVisible && (
          <SearchFilterMob setFilterVisible={setSearchFilterVisible} />
        )}
      </AnimatePresence> */}
      <AnimatePresence>
        {/* {isSearchFilterVisible && (
          <SearchFilterMobFull setFilterVisible={setSearchFilterVisible} />
        )} */}
        {isSearchFilterVisibleFull && (
          <SearchFilterMob setFilterVisible={setSearchFilterVisibleFull} />
        )}
      </AnimatePresence>
      <div className="flex grid grid-cols-12 gap-5 justify-between">
        <SearchFilterMobFull setFilterVisible={setSearchFilterVisible} />
        <div className="md:col-start-4 lg:col-span-9 col-span-full">
          <div className="flex justify-between ">
            <GradientBtn
              onClick={() => {
                setSearchFilterVisibleFull(true);
                mixpanel.track("Filter button clicked");
              }}
              textClassName="inline-flex gap-1 items-center text-2xs text-black"
              className="w-fit flex inset-y-0 right-0 rounded-full pointer-events-none active:scale-50 cursor-pointer relative p-3 font-medium capitalize h-11 "
              label={
                <>
                  <Icon icon="pajamas:hamburger" className="mr-1" /> Filter (
                  {category.length +
                    formula.length +
                    gender.length +
                    brands.length}
                  )
                </>
              }
            />

            {/* <p
              className="text-xs underline font-semibold text-slate-800 text-center"
              onClick={(val) => {
                dispatch(fetchProducts(0));
                dispatch(resetFilter());
              }}
            >
              Clear all
            </p> */}
            <SortbySelect
              options={options}
              wrapperClass={cn("")}
              className="lg:w-full w-fit bg-zinc-100"
              onClick={() => {
                mixpanel.track("Best match button clicked");
              }}
            />
          </div>
          {loading ? (
            <Card className="bg-white mt-4">
              <Skeleton
                className="my-2 mx-auto"
                variant="rounded"
                width={350}
                height={40}
              />
            </Card>
          ) : productList ? (
            <GearList
              value={productList}
              className="md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 "
            />
          ) : (
            <NotAdded
              m1="Nice Try"
              m2="No Products"
              m3="No matches to your request."
            />
          )}
        </div>
      </div>

      {/* <Pagination
        className="mt-3 relative lg:mt-10 bottom-10 bg-white p-1 rounded-xl shadow-lg items-center  justify-center"
        count={8}
        variant="outlined"
        color="primary"
        boundaryCount={2}
        page={page + 1}
        onChange={handleChange}
      /> */}
    </div>
  );
};

export default ForSale;

const options = [
  { label: "Most Popular", value: "POPULAR" },
  { label: "Recent Released", value: "RECENT" },
  { label: "Price Low to High", value: "LOW" },
  { label: "Price High to Low", value: "HIGH" },
  { label: "Top Rated", value: "RATE" },
];
