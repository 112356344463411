import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentProps, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { useOktaAuth } from "@okta/okta-react";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { useAppSelector } from "../../../../redux/hooks";
import { AppDispatch } from "../../../../redux/store";
import GradientBtn from "../../../buttons/GradientBtn";
import Select from "../../../common/select";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../../ui/form";
import CommSelect from "../../../common/comm-select";
import { TextField } from "../../../text-field";
import DialogWrapper from "../../../common/dialog-wrapper";
import { Icon } from "@iconify/react";
import PricingFees from "../../Create-Post/Listing/pricing-fees";
import { updateListingPost } from "../../../../redux/features/post/postSlice";
import { Loading } from "../../../common/Widget/Loading";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";


interface BoostListingProps extends ComponentProps<"form"> {
  info: any;
}

export function EditListing({
  className,
  info,

  ...props
}: BoostListingProps) {

    const dispatch = useDispatch<AppDispatch>();
  const { authState } = useOktaAuth();
  const user = useAuthUser();
    const { profileData, loading } = useAppSelector(
      (state) => state.userProfile
    );
    const { updateError, updateLoading } = useAppSelector(
      (state) => state.post
    );
    const [selectedInstrument, setSelectedInstrument] = useState(info.category);
    const [selectedComm, setSelectedComm] = useState(info.community);
     const [sellPrice, setSellPrice] = useState(info.price);
     const [priceError, setPriceError] = useState(false);
    const formSchema = z.object({
      id: z.string().default(info.id),
      description: z
        .string()
        .min(1, { message: "Description is required😅" })
        .max(250, { message: "250 Characters Max😅" })
        .default(info.description),
      price: z
        .number()
        .lte(5000, { message: "too much dip there" })
        .default(info.price),
      condition: z.string().default(info.condition),
      category: z.string().default(info.category),
      community: z.string().default(info.community),
      formula: z.string().default(info.formula),
    });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const onPaymentAmountChanged = (e: any) => {
    const inputNumber = parseFloat(e.target.value); //parseFloat if you have decimals
    if (Number.isNaN(inputNumber)) {
    } else if (inputNumber > 0.99 && inputNumber < 5000) {
      setPriceError(false);
      setSellPrice(inputNumber);
    } else {
      setPriceError(true);
    }
  };

  function onSubmit(values: any) {
     dispatch(updateListingPost(values))
       .then(() =>
       {
         dispatch(
           notifySelect({
             title: "Your Listing is Updated",
             type: 0,
             open: true,
           })
         );
mixpanel.track("Edit listing", {
  ...values,
  email: user ? user["email"] : "",
});
       }
       )
       .finally(() => window.location.reload());
  }
    useEffect(() => {
      if (updateError) {
        dispatch(
          notifySelect({
            title: `Name Taken`,
            type: 1,
            open: true,
          })
        );
      }
         form.setValue("price", sellPrice);
    form.setValue("community", selectedComm);
  }, [info, updateError, selectedComm, sellPrice]);
  return (
    <Form {...form}>
      <form className="z-50 " onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-3 font-bold text-lg">
          Edit {info.name}
        </p>
        <hr className="mt-6" />
        <div className="grid grid-cols-1 md:gap-3 my-3 gap-1">
          {/* <Select
            className="first-step"
            uniqueKey="instrument-selector"
            label="Category"
            defaultValue={selectedInstrument}
            options={categoriesData}
            onOptionSelect={(val) => setSelectedInstrument(val)}
            required
          /> */}
          {loading ? null : (
            <CommSelect
              className="second-step"
              uniqueKey="instrument-selector2"
              label="Select Community"
              defaultValue={selectedComm}
              options={
                authState?.isAuthenticated && profileData.myCommunities
                  ? profileData.myCommunities
                  : []
              }
              onOptionSelect={(val) => setSelectedComm(val)}
              optional
            />
          )}
        </div>
        <div className="grid grid-cols-3 gap-3 mt-1">
          {options2.map((_, idx) => (
            <div className="w-full">
              <p className=" text-[11px] font-semibold my-1">
                {_.label}
                <span className="text-red-600">*</span>
              </p>
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        uniqueKey="condition-selector"
                        onOptionSelect={field.onChange}
                        defaultValue={info[_.label]}
                        options={_.options}
                        label={_.label}
                        noLabel
                        className=" bg-zinc-100 mb-2"
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={_.label}
                control={form.control}
              />
            </div>
          ))}
        </div>
        <p className=" text-[11px] font-semibold my-1">
          Description
          <span className="text-red-600">*</span>
        </p>
        <FormField
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <TextField
                  defaultValue={info["description"]}
                  {...field}
                  onOptionSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
          name={"description"}
          control={form.control}
        />
        <div>
          <label htmlFor="price">
            <span className="text-[11px] font-semibold mb-1">Price</span>
            <span className="text-red-600 text-[11px] font-semibold">*</span>
          </label>
          <div>
            <input
              id="price"
              defaultValue={info["price"]}
              max="5000"
              step=".01"
              name="price"
              type="number"
              onChange={onPaymentAmountChanged}
              className="pl-3.5 w-1/2 pt-3 pb-[13px]   placeholder:text-neutral-400 text-sm font-medium bg-stone-50 rounded-md border border-slate-400 h-10"
            />
          </div>
          <p className="text-[10px] py-0.5">Price</p>
        </div>
        <div className="grid grid-cols-[3fr_5fr] text-sm gap-x-3 py-2 gap-y-2 ">
          <>
            <DialogWrapper className="z-50" content={<PricingFees />}>
              <div className="flex items-center gap-1">
                <p>Selling fee</p> <Icon icon={"ri:question-line"} />
              </div>
            </DialogWrapper>
            <p>${(Math.round(sellPrice * 0.05 * 100) / 100).toFixed(2)}</p>
          </>
          <>
            <DialogWrapper className="z-50" content={<PricingFees />}>
              <div className="flex items-center gap-1">
                <p>Processing fee</p> <Icon icon={"ri:question-line"} />
              </div>
            </DialogWrapper>
            <p>${(Math.round(sellPrice * 0.03 * 100) / 100).toFixed(2)}</p>
          </>
          <>
            <DialogWrapper className="z-50" content={<PricingFees />}>
              <div className="flex items-center gap-1">
                <p>Est. Earnings</p> <Icon icon={"ri:question-line"} />
              </div>
            </DialogWrapper>
            <p>
              {priceError
                ? "Enter 1-5000"
                : `$${(
                    sellPrice -
                    Math.round(sellPrice * 0.08 * 100) / 100
                  ).toFixed(2)}`}
            </p>
          </>
        </div>
        <div className="-mx-6 p-3.5 mt-10 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 ">
          <button className="text-zinc-600 !text-sm border border-current py-2 px-[17px]  rounded-full font-bold ">
            Cancel
          </button>
          {updateLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              type="submit"
              label={"Submit"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}


const options2 = [
  {
    label: "condition",
    options: [
      { label: "Partial", value: "PARTIAL" },
      { label: "Brand New", value: "NEW" },
      { label: "New(Opened)", value: "OPENED" }
    ],
  },
  {
    label: "category",
    options: [
      { label: "General", value: "GENERAL" },
      { label: "Niche", value: "NICHE" },
      { label: "Floral", value: "FLORAL" },
      { label: "Fresh", value: "FRESH" },
      { label: "Fruity", value: "FRUITY" },
      { label: "Gourmond", value: "GOURMOND" },
      { label: "Aromatic", value: "AROMATIC" },
      { label: "Oriental", value: "ORIENTAL" },
      { label: "Woody", value: "WOODY" },
      { label: "Leather", value: "LEATHER" },
    ],
  },
  {
    label: "formula",
    options: [
      { label: "Eau Fraiche", value: "EF" },
      { label: "Eau De Cologne", value: "EDC" },
      { label: "Eau De Toilette", value: "EDT" },
      { label: "Eau De Parfume", value: "EDP" },
      { label: "Parfume", value: "PAR" },
      { label: "Body Spray", value: "BOD" },
      { label: "Oil", value: "OIL" },
    ],
  },
];
