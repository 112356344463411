import type * as React from 'react';
import { cn } from '../../../../helpers/utils';

type SearchPeopleProps = React.ComponentProps<'input'>;

export function SearchPeople({
  className,
  ...props
}: SearchPeopleProps) {
  return (
    <div
      className="relative w-full h-[35px] flex-grow"
    >
      <img
        className="absolute right-1 top-2/4 -translate-y-2/4 cursor-pointer"
        src="/assets/vectors/common/search.svg"
        alt="search"
      />
      <input
        className={cn("h-full w-full block outline-none rounded-xl pl-3 pr-4 font-semibold", className)}
        {...props}
      />
    </div>
  );
}
