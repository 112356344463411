import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface FeedState {
  loadingComments: boolean;
  loadingReply: boolean;
  loading: boolean;
  error: string | null;
  commentsList: any;
}

const initialState: FeedState = {
  loadingComments: false,
  loadingReply: false,
  loading: false,
  error: null,
  commentsList: null,
};

export const fetchComments = createAsyncThunk(
  "post/fetchComments",
  async (postId: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/comments/post/${postId}`
    );
    return response.data;
  }
);

export const createCommentPost = createAsyncThunk(
  "comment/post",
  async ({ postId, userId, text, createdDateTime }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/comments`,
      {
        postId: postId,
        userId: userId,
        text: text,
        createdDateTime: createdDateTime,
      }
    );
    return response.status;
  }
);

export const createReplyComment = createAsyncThunk(
  "comment/reply",
  async ({ postId, commentId, userId, text, createdDateTime }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/comments/reply`,
      {
        commentId: commentId,
        postId: postId,
        userId: userId,
        text: text,
        createdDateTime: createdDateTime,
      }
    );
    return response.status;
  }
);

const commentSlice = createSlice({
  name: "post/topic",
  initialState,
  reducers: {
    addNewComment: (state, action: PayloadAction<any>) => {
      state.commentsList = [action.payload, ...state.commentsList];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCommentPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCommentPost.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createCommentPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(createReplyComment.pending, (state) => {
        state.loadingReply = true;
        state.error = null;
      })
      .addCase(createReplyComment.fulfilled, (state, action) => {
        state.loadingReply = false;
      })
      .addCase(createReplyComment.rejected, (state, action) => {
        state.loadingReply = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchComments.pending, (state) => {
        state.loadingComments = true;
        state.error = null;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.loadingComments = false;
        state.commentsList = action.payload;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.loadingComments = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const { addNewComment } = commentSlice.actions;

export const commentReducer = commentSlice.reducer;
