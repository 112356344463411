import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  loading: boolean;
  error: string | null;
  updateLoading: boolean;
  updateError: string | null;
  userFeed: any;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  loading: true,
  error: null,
  userFeed: null,
  updateLoading: false,
  updateError: null,
};

export const fetchPosts = createAsyncThunk(
  "user/fetchPosts",
  async (data: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/posts/user/${data}`
    );
    return response.data;
  }
);

export const updatePost = createAsyncThunk(
  "update/post",
  async ({ id, postCategory, community }: any) => {
    const response = await axios.put(
      `https://webservice.pairrit.com/pairrit-ws/api/posts/update/${id}`,
      { postCategory: postCategory, community: community }
    );
    return response.status;
  }
);

export const userThreadSlice = createSlice({
  name: "userThread",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.userFeed = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(updatePost.pending, (state) => {
        state.updateLoading = true;
        state.updateError = null;
      })
      .addCase(updatePost.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.error.message || "An error occurred";
      });
  },
});

export const {} = userThreadSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userThreadSlice.reducer;
