
export const avatarData = [
  {
    icon: "/assets/imgs/avatar/Default.svg",
    label: "Default",
  },
  {
    icon: "/assets/imgs/avatar/A1.svg",
    label: "A1",
  },
  {
    icon: "/assets/imgs/avatar/A2.svg",
    label: "A2",
  },
  {
    icon: "/assets/imgs/avatar/A3.svg",
    label: "A3",
  },
  {
    icon: "/assets/imgs/avatar/A4.svg",
    label: "A4",
  },
  {
    icon: "/assets/imgs/avatar/A5.svg",
    label: "A5",
  },
  {
    icon: "/assets/imgs/avatar/A6.svg",
    label: "A6",
  },
  {
    icon: "/assets/imgs/avatar/A7.svg",
    label: "A7",
  },
  {
    icon: "/assets/imgs/avatar/A8.svg",
    label: "A8",
  },
  {
    icon: "/assets/imgs/avatar/A9.svg",
    label: "A9",
  },
  {
    icon: "/assets/imgs/avatar/A10.svg",
    label: "A10",
  },
  {
    icon: "/assets/imgs/avatar/A11.svg",
    label: "A11",
  },
  {
    icon: "/assets/imgs/avatar/A12.svg",
    label: "A12",
  },
  {
    icon: "/assets/imgs/avatar/A13.svg",
    label: "A13",
  },
  {
    icon: "/assets/imgs/avatar/A14.svg",
    label: "A14",
  },
  {
    icon: "/assets/imgs/avatar/A15.svg",
    label: "A15",
  },
  {
    icon: "/assets/imgs/avatar/A16.svg",
    label: "A16",
  },
  {
    icon: "/assets/imgs/avatar/A17.svg",
    label: "A17",
  },
  {
    icon: "/assets/imgs/avatar/A18.svg",
    label: "A18",
  },
  {
    icon: "/assets/imgs/avatar/A19.svg",
    label: "A19",
  },
  {
    icon: "/assets/imgs/avatar/A20.svg",
    label: "A20",
  },
  {
    icon: "/assets/imgs/avatar/A21.svg",
    label: "A21",
  },
  {
    icon: "/assets/imgs/avatar/A22.svg",
    label: "A22",
  },
];


export const avatarPick= (x: any) => {
  switch (x) {
    case "A1":
      return "/assets/imgs/avatar/A1.svg";
      break;
    case "CONTENT_CREATOR":
      return "Content Creator";
      break;
    case "RETAIL_STORE":
      return "Retail Store";
      break;
    case "SELLER":
      return "Seller";
      break;
    case "CASUAL":
      return "Casual";
      break;
    case "HOBBIEST":
      return "Hobbiest";
      break;
    default:
    // code block
  }
};