import React from 'react';
import { content, preamble } from './PolicyData/content';




const Content = () => {
  return (
    <div className=" mt-4  mx-auto  leading-relaxed">
      <h3 className="text-lg">Content</h3>
      <h3 className="text-sm underline">Preamble</h3>
      <p className="pb-2 text-xs">{preamble}</p>
      {content.map((_) => (
        <div>
          <h3 className="text-sm underline font-bold">{_.title}</h3>
          <p className="pb-1 text-xs">{_.terms}</p>
        </div>
      ))}
      <p>Effective Date: Dec 5, 2023</p>
      <p>Updated: November 3, 2023</p>
    </div>
  );
}

export default Content