import { Variants, motion } from "framer-motion";
import clsx from "clsx";
import { AnimatePresence } from "framer-motion";
import mixpanel from "mixpanel-browser";
import {
  ChangeEvent,
  ElementRef,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useAuthUser from "../../../hooks/getAuthUser";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {
  search,
  searchAll,
  searchTopics,
} from "../../../redux/features/search/searchSlice";
import { prodList } from "../../pages/Market/MarketHome";
import { Icon } from "@iconify/react";
import { Chip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

interface Props {
  fullWidth?: boolean;
  noPost?: boolean;
  placeholder?: string;
  extraComp?: React.ReactNode;
}

const Search = ({ fullWidth, placeholder, noPost, extraComp }: Props) => {
  return (
    <div className="flex gap-[10px] w-full">
      <SearchBar fullWidth={fullWidth} placeholder={placeholder} />
      {/* {!noPost && (
        <CreatePost>
          <button className="flex items-baseline gap-2 rounded-lg bg-gradient-to-l from-red-500  to-yellow-500  px-3 py-1.5 text-base font-bold text-white hover:opacity-75 border-2 border-white">
            <span>New Post</span>
            <Icon
              icon="solar:play-bold-duotone"
              className="text-xl text-white h-4 mx-auto"
            />
          </button>
        </CreatePost>
      )} */}
      {extraComp && extraComp}
    </div>
  );
};

export default Search;

export function SearchBar({ fullWidth, placeholder }: any) {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort");
  const regex = /[.,!(){}[\]<>?;:'"\/\\|`~@#$%^&*+=_—]/g;
  const user = useAuthUser();
  const [typing, setTyping] = useState(false);
  const [value, setValue] = useState("");
  const searchRef = useRef<ElementRef<"div">>(null);
  const inputRef = useRef<ElementRef<"input">>(null);
  const navigate = useNavigate();
  const { postList, loading, error } = useAppSelector((state) => state.search);
  function handleInput(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value) {
      dispatch(searchTopics(e.target.value.replaceAll(regex, "")));
      setValue(e.target.value.replaceAll(regex, ""));
      return setTyping(true);
    }
    setTyping(false);
  }

  useOnClickOutside(searchRef, () => setTyping(false));

  function handleAction(e: KeyboardEvent<HTMLInputElement>) {
    e.key === "Enter" &&
      navigate(
        `/search/fragrances?q=${inputRef.current?.value.replaceAll(regex, "")}`
      );
    e.key === "Enter" &&
      value &&
      dispatch(searchAll({ sort: sort, search: value }));

    e.key === "Enter" &&
      value &&
      mixpanel.track("Item searched", {
        screen_name: "value",
        search_query: value,
        email: user ? user["email"] : "",
      });
    setTyping(false);
  }

  // useEffect(() => {
  //   if (typing) {
  //      debouncedResults()
  //    }
  //    return () => {
  //      debouncedResults.cancel();
  //    };
  //  });

  return (
    <div ref={searchRef} className="relative w-full  h-[40px] flex-grow">
      {/* <img
        className="absolute right-1 top-2/4 -translate-y-2/4 cursor-pointer"
        src="/assets/vectors/common/search.svg"
        alt="search"
        onClick={() => {
          navigate(`/search/fragrances?q=${value.replaceAll(regex, "")}`);
        }}
      /> */}
      <button
        className="!absolute right-0 h-full my-auto  z-10 select-none rounded-r-xl bg-gradient-to-l from-red-500  to-yellow-500 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none peer-placeholder-shown:pointer-events-none peer-placeholder-shown:bg-blue-gray-500 peer-placeholder-shown:opacity-50 peer-placeholder-shown:shadow-none"
        type="button"
        data-ripple-light="true"
        onClick={() => {
          navigate(`/search/fragrances?q=${value.replaceAll(regex, "")}`);
        }}
      >
        <Icon
          icon="gala:search"
          className="lg:text-2xl text-xl text-white justify-center "
        />
      </button>
      <input
        ref={inputRef}
        onFocus={handleInput}
        onChange={handleInput}
        onKeyDown={handleAction}
        className={clsx(
          "h-full w-full block outline-none rounded-xl pl-3 pr-4 font-semibold"
        )}
        type="search"
        placeholder={placeholder || "Search Fragrances..."}
      />
      <AnimatePresence>
        {typing ? (
          <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            transition={{ type: "keyframes", duration: 0.2 }}
            style={{ filter: "drop-shadow(0px 2px 8px #BBC100)" }}
            className="md:w-[44em] lg:w-[43em] 2xl:w-[47em] w-full lg:ml-1  space-y-1.5 origin-top bg-white p-3 z-50 absolute rounded-lg top-full mt-[2.5px]"
          >
            {postList.map((info: any, idx: any) => (
              <Link
                key={idx}
                to={`/frag/${info._id}/${info.model}`}
                onClick={() => setTyping(false)}
                className="flex cursor-pointer items-center gap-3 py-0.5 hover:bg-slate-100"
              >
                <img
                  alt=""
                  className="w-14 h-14 object-contain rounded-sm shadow"
                  src={info.img}
                />
                <div className="font-semibold ">
                  <p className="text-[13px] font-semibold">{info.model}</p>
                  <span className="flex text-gray-400 md:mr-3 uppercase text-[9px] md:text-[8px]  lg:text-2xs">
                    {info.year} • {info.formula} • {info.brandName}
                  </span>
                </div>
                {info.status === "active" ? null : (
                  <Chip
                    sx={{
                      bgcolor: "background.paper",
                      fontSize: "10px",
                    }}
                    className="text-2xs"
                    color="primary"
                    size="small"
                    label={info.status}
                    variant="outlined"
                  />
                )}
              </Link>
            ))}

            <hr />
            <h3 className="text-xs font-semibold">Recent Searches</h3>

            {top100Films.slice(0, 4).map((option, index) => {
              return (
                <li
                  key={index}
                  onClick={() => {
                    navigate(
                      `/search/fragrances?q=${value.replaceAll(regex, "")}`
                    );
                  }}
                  className="line-clamp-1 text-xs font-medium hover:opacity-75 hover:bg-gray-100 cursor-pointer"
                >
                  {option.title}{" "}
                  <span className="text-red-400 font-semibold">
                    in Products
                  </span>
                </li>
              );
            })}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

const variants: Variants = {
  hidden: { scaleY: 0, opacity: 0 },
  visible: { scaleY: 1, opacity: 1 },
};

const top100Films = [
  { title: "Maison Francis Kurkdjian", year: 1994 },
  { title: "Christian Dior", year: 1972 },
  { title: "Tom Ford", year: 1974 },
  { title: "Chanel", year: 2008 },
  { title: "Floral", year: 1957 },
  { title: "Fresh", year: 1993 },
];
