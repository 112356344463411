import CommentSection from "../Listing/comment-section";
import TopicCard from "./topic-card";
import PromotedPost from "./promoted-post";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchSingleTopic } from "../../../../redux/features/singlePages/newTopics/newTopicSlice";
import Loader from "../../../common/Loader/loader";

const TopicPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  useEffect(() => {
    console.log(id);
    if (id) {
      dispatch(fetchSingleTopic(id));
    }
  }, [id]);

  const { postData, loading } = useAppSelector((state) => state.topicPost);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="mb-[8em]">
          <TopicCard info={postData} />
          <PromotedPost />
          <CommentSection comments={postData} />
        </section>
      )}
    </>
  );
};

export default TopicPage;
