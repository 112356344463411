import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { acceptOffer } from "../userTransactions/offers/userOffersSlice";

// Define a type for the slice state
interface InitialStateType {
  sortType: string;
  loading: boolean;
  error: string | null;
  deleteLoading: boolean;
  deleteError: string | null;
  deleteSuccess: boolean;
  boostLoading: boolean;
  boostError: string | null;
  boostSuccess: boolean;
  offersLoading: boolean;
  offersError: string | null;
  offerSuccess: boolean;
  userProducts: Array<any> | null;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  sortType: "Recent",
  loading: true,
  error: null,
  userProducts: null,
  deleteLoading: false,
  deleteError: null,
  boostLoading: false,
  boostError: null,
  offersLoading: false,
  offersError: null,
  deleteSuccess: false,
  boostSuccess: false,
  offerSuccess: false,
};

export const fetchUserProducts = createAsyncThunk(
  "user/fetchProducts",
  async (data: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/products/user/${data}`
    );
    return response.data;
  }
);

export const removeProduct = createAsyncThunk(
  "user/removeProduct",
  async ({ postId }: any) => {
    const response = await axios.delete(
      `https://webservice.pairrit.com/pairrit-ws/api/products/product/${postId}`
    );
    return response.data;
  }
);

export const boostProduct = createAsyncThunk(
  "user/boostProduct",
  async ({ postId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/products/boost/${postId}`
    );
    return response.data;
  }
);

export const unBoostProduct = createAsyncThunk(
  "user/unBoostProduct",
  async ({ postId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/products/unBoost/${postId}`
    );
    return response.data;
  }
);

export const addOffers = createAsyncThunk(
  "user/addOffers",
  async ({ postId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/products/acceptOffers/${postId}`
    );
    return response.data;
  }
);

export const removeOffers = createAsyncThunk(
  "user/removeOffers",
  async ({ postId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/products/denyOffers/${postId}`
    );
    return response.data;
  }
);

export const userStoreSlice = createSlice({
  name: "userStore",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.userProducts = action.payload;
      })
      .addCase(fetchUserProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(removeProduct.pending, (state) => {
        state.deleteLoading = true;
        state.deleteError = null;
      })
      .addCase(removeProduct.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.deleteSuccess = true;
      })
      .addCase(removeProduct.rejected, (state, action) => {
        state.deleteLoading = false;
        state.deleteError = action.error.message || "An error occurred";
      })
      .addCase(boostProduct.pending, (state) => {
        state.boostLoading = true;
        state.boostError = null;
      })
      .addCase(boostProduct.fulfilled, (state, action) => {
        state.boostLoading = false;
        state.boostSuccess = true;
      })
      .addCase(boostProduct.rejected, (state, action) => {
        state.boostLoading = false;
        state.boostError = action.error.message || "An error occurred";
      })
      .addCase(unBoostProduct.pending, (state) => {
        state.boostLoading = true;
        state.boostError = null;
      })
      .addCase(unBoostProduct.fulfilled, (state, action) => {
        state.boostLoading = false;
        state.boostSuccess = true;
      })
      .addCase(unBoostProduct.rejected, (state, action) => {
        state.boostLoading = false;
        state.boostError = action.error.message || "An error occurred";
      })
      .addCase(addOffers.pending, (state) => {
        state.offersLoading = true;
        state.offersError = null;
      })
      .addCase(addOffers.fulfilled, (state, action) => {
        state.offersLoading = false;
        state.offerSuccess = true;
      })
      .addCase(addOffers.rejected, (state, action) => {
        state.offersLoading = false;
        state.offersError = action.error.message || "An error occurred";
      })
      .addCase(removeOffers.pending, (state) => {
        state.offersLoading = true;
        state.offersError = null;
      })
      .addCase(removeOffers.fulfilled, (state, action) => {
        state.offersLoading = false;
        state.offerSuccess = true;
      })
      .addCase(removeOffers.rejected, (state, action) => {
        state.offersLoading = false;
        state.offersError = action.error.message || "An error occurred";
      });
  },
});

export const {} = userStoreSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userStoreSlice.reducer;
