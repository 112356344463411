import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import { ComponentProps, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { cn } from "../../../../helpers/utils";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { offerRequest } from "../../../../redux/features/userTransactions/offers/userOffersSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import GradientBtn from "../../../buttons/GradientBtn/GradientBtn";
import { useOktaAuth } from "@okta/okta-react";
import DialogHeader from "../../../common/dialog-header";
import { Loading } from "../../../common/Widget/Loading";
import { TextField } from "../../../text-field";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../ui/form";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";
import Card from "../../../common/Card";

const formSchema = z.object({
  userId: z.string(),
  listingId: z.string(),
  comment: z.string(),
  offerAmount: z.coerce.number().lte(2000, { message: "too much dip there" }),
});

interface MakeAnOfferProps extends ComponentProps<"div"> {
  id: any;
  price: any;
  offersLeft: any;
}

export default function MakeAnOffer({
  id,
  offersLeft,
  price,
  className,
  ...props
}: MakeAnOfferProps) {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { authState } = useOktaAuth();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { requestLoading, requestError, success } = useAppSelector(
    (state) => state.userOffers
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const [sellPrice, setSellPrice] = useState(price);
  const [priceError, setPriceError] = useState(false);
  function onSubmit(values: any) {
    if (authState?.isAuthenticated) {
      dispatch(offerRequest(values)).then(() => {
        dispatch(
          notifySelect({
            title: "Offer Sent!",
            type: 0,
            open: true,
          })
        );
        mixpanel.track("New offer made", {
          ...values,
          email: user ? user["email"] : "",
        });
      });
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
      // Handle form invalidation
    }
    // .finally(() => window.location.reload());
  }
  const onPaymentAmountChanged = (e: any) => {
    const inputNumber = parseFloat(e.target.value); //parseFloat if you have decimals
    if (Number.isNaN(inputNumber)) {
      console.log(0); // set default state for NAN inputs
    } else if (
      inputNumber > price - price * 0.4 &&
      inputNumber < price + price * 0.4
    ) {
      setPriceError(false);
      setSellPrice(inputNumber);
    } else {
      setPriceError(true);
      console.log(inputNumber);
    }
  };
  useEffect(() => {
    form.setValue("listingId", id);
    form.setValue("userId", userId);
    form.setValue("offerAmount", sellPrice);
    if (requestError) {
      dispatch(
        notifySelect({
          title: "Error Sending",
          type: 1,
          open: true,
        })
      );
    }
  }, [requestError, id, sellPrice, userId]);
  return (
    <div className={cn("", className, {})} {...props}>
      <Form {...form}>
        {success ? (
          <Card>
            <Icon
              icon={"icon-park-twotone:success"}
              className="h-12 w-12 text-green-400 mx-auto my-3"
            />

            <h2 className="text-center mb-3 text-black text-xl font-semibold">
              Your Offer for {sellPrice} has been sent !
            </h2>
            <h4 className="text-center text-black text-base font-bold">
              Buy Now Price: ${price.toFixed(2)}
            </h4>
            <p className="text-center text-black text-base font-normal">
              {offersLeft - 1} Offer(s) left
            </p>
            <p className="text-center mt-4 md:w-[460px] text-zinc-600 text-sm font-medium">
              Best Offer lets you offer the seller a price you’re willing to pay
              for the item. The seller can accept, reject, or counter your offer
              by suggesting another price.
            </p>
          </Card>
        ) : (
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader />
            <h2 className="text-center mb-3 text-black text-3xl font-semibold">
              Make An Offer
            </h2>
            <h4 className="text-center text-black text-base font-bold">
              Buy Now Price: ${price.toFixed(2)}
            </h4>
            <p className="text-center text-black text-base font-normal">
              {offersLeft} Offer(s) left
            </p>
            <p className=" text-center !text-red-500 text-[15px] font-semibold mt-8 mb-6">
              Your Offer here{" "}
            </p>

            {/* <div className="h-px max-w-[240px] bg-black/50 ml-[50%] -translate-x-1/2 mb-5" /> */}
            <div className="flex justify-center ">
              <input
                defaultValue={price}
                max="5000"
                step=".01"
                name="price"
                type="number"
                onChange={onPaymentAmountChanged}
                className=" mb-5 bg-transparent border-b-2 border-gray-500 focus:outline-none focus:border-blue-500 "
                style={{ textIndent: "40%" }}
              />
              {priceError ? (
                <Icon
                  icon={"ep:warning-filled"}
                  className="text-red-500 text-2xl"
                />
              ) : (
                <Icon
                  icon={"lets-icons:check-fill"}
                  className="text-green-700 text-2xl"
                />
              )}
            </div>
            <div className="my-2">
              <p className=" text-[11px] font-semibold my-1">
                Add a comment
                <span className="text-red-600">*</span>
              </p>
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <TextField {...field} onOptionSelect={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
                name={"comment"}
                control={form.control}
              />
            </div>
            {requestLoading ? (
              <Loading />
            ) : (
              <GradientBtn
                label={"Submit Offer"}
                className="mx-auto block [&_*]:text-white mt-5 mb-10"
              />
            )}
            <p className="text-center md:w-[460px] text-zinc-600 text-sm font-medium">
              Best Offer lets you offer the seller a price you’re willing to pay
              for the item. The seller can accept, reject, or counter your offer
              by suggesting another price.
            </p>
          </form>
        )}
      </Form>
    </div>
  );
}
