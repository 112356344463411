import { Icon } from "@iconify/react";
import { useOktaAuth } from "@okta/okta-react";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useAuthUser from "../../../hooks/getAuthUser";

const MyMainPage = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const user = useAuthUser();
   const loggingOut = async () => {
     oktaAuth.signOut();
     oktaAuth.clearStorage();
     oktaAuth.closeSession();
   };
  useEffect(() => {
    mixpanel.track("Profile page view", { email: user ? user["email"] : "" });
  }, []);
  return (
    <div className=" mt-3 flex flex-wrap gap-3 my-5 w-full  justify-center grid grid-cols-2 md:grid-cols-3">
      {menus.map((_) =>
        _.label === "Sign Out" ? (
          <button
            onClick={() => {
              loggingOut();
              mixpanel.reset();
            }}
            className="p-2 w-full h-16 font-medium flex items-center justify-center rounded-md bg-red-200 hover:bg-red-100 text-black gap-1.5 transition-colors text-sm border border-solid border-[rgba(134, 145, 168, 0.20)]"
          >
            <span className="flex items-center gap-2">
              <Icon icon={_.icon} className="text-lg text-red-500" />
              <p className="font-bold ">{_.label}</p>
            </span>
          </button>
        ) : (
          <Link to={_.link}>
            <button className="p-2 w-full h-16 font-medium flex items-center justify-center rounded-md bg-accentGray-600 hover:bg-accentGray-600 text-black gap-1.5 transition-colors text-sm border border-solid border-[rgba(134, 145, 168, 0.20)]">
              <span className="flex items-center gap-2">
                <Icon icon={_.icon} className="text-xl" />
                <p className="font-bold ">{_.label}</p>
              </span>
            </button>
          </Link>
        )
      )}
    </div>
  );
};

export default MyMainPage;

const menus = [
  { label: "My Shop", icon: "tdesign:shop", link: "/my/shop" },
  {
    label: "Manage Listings",
    icon: "icon-park-outline:list",
    link: "/transactions/manage-listings",
  },
  {
    label: "Purchases",
    icon: "arcticons:bestbuy",
    link: "/transactions/purchases",
  },
  {
    label: "Orders",
    icon: "icon-park-twotone:order",
    link: "/transactions/orders",
  },
  {
    label: "Wishlist",
    icon: "quill:list",
    link: "/my/watchlist",
  },
  {
    label: "Communities",
    icon: "fluent:people-community-16-filled",
    link: "/my/communities/own",
  },
  {
    label: "Offers",
    icon: "tdesign:undertake-transaction",
    link: "/transactions/offers/sent",
  },
  {
    label: "Profile Settings",
    icon: "ic:twotone-settings",
    link: "/settings/account",
  },
  {
    label: "Messages",
    icon: "iconoir:privacy-policy",
    link: "/messages",
  },
  { label: "Help Center", icon: "material-symbols:help", link: "/help" },
  { label: "Sign Out", icon: "tabler:logout", link: "" },
];
