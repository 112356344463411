import { Icon } from "@iconify/react";
import * as Accordion from "@radix-ui/react-accordion";
import { Variants, motion } from "framer-motion";
import { ElementRef,  useRef, useState } from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {
  fetchProducts,
  fetchMarket,
} from "../../../redux/features/market/marketplaceSlice";
import { useAppDispatch} from "../../../redux/hooks";
import { Checkbox } from "../Create-Post/Listing/shipping";
import { searchFilterData } from "./search-filter.data";
import SearchSelectMulti from "../../common/search-select-multi";
import { cn } from "../../../helpers/utils";
import { useOktaAuth } from "@okta/okta-react";
import {  useSearchParams } from "react-router-dom";

export const SearchFilterMobFull = ({
  setFilterVisible,
}: {
  setFilterVisible: (value: React.SetStateAction<boolean>) => void;
}) => {
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
 const [selectedPerson, setSelectedPerson] = useState(["Gender", "Brands"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.getAll("category");
  const formula = searchParams.getAll("formula");
  const gender = searchParams.getAll("gender");
  const brands = searchParams.getAll("brands");
  const filterContainerRef = useRef<ElementRef<typeof motion.nav>>(null);
  const close = () => setFilterVisible(false);

  useOnClickOutside(filterContainerRef, close);

  return (
    <motion.nav
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{ type: "keyframes" }}
      variants={filterVariants}
      ref={filterContainerRef}
      className={cn(
        "w-full h-[40em] col-span-3 col-end-4 flex flex-col   shadow-2xl rounded-lg  bg-gradient-to-r from-stone-100 to-zinc-200  mr-auto max-md:hidden",
        {
          "xl:max-w-[23em] 2xl:max-w-[20em] lg:max-w-[27em] md:max-w-[34em] 3xl:max-w-[27em]":
            authState?.isAuthenticated,
        }
      )}
    >
      <div className="py-1.5 flex items-center justify-between pl-4 pr-6 bg-gradient-to-l from-red-500 to-yellow-500 rounded-t  w-full">
        <h4 className="text-white text-[15px] font-semibold flex gap-4">
          Filter Search
        </h4>
        {/* <Icon
          role="button"
          onClick={close}
          className="text-black"
          icon={"ph:x-bold"}
        /> */}
      </div>
      <Accordion.Root type="multiple" defaultValue={selectedPerson}  >
        {searchFilterData.map((_, idx) => (
          <Accordion.Item
            value={_.label}
            role="button"
            data-state="opened"
            className="py-0.5 text-sm font-semibold text-left "
            key={_.label}
          >
            <Accordion.Trigger
              asChild
              
              className="group px-4 py-3 bg-gray-100 border-b border-amber-400"
            >
              <div className="flex items-center justify-between">
                {_.label}
                <Icon
                  icon={"mdi:plus"}
                  className="w-8 text-xl transition-all rotate-180 group-aria-expanded:hidden"
                />
                <Icon
                  icon={"mdi:minus"}
                  className="w-8 text-xl transition-all rotate-180 group-aria-expanded:block hidden"
                />
              </div>
            </Accordion.Trigger>
            <Accordion.Content className="font-normal bg-white cursor-default space-y-2.5 px-4 py-1.5">
              {_.extra || null}
              {_.options.map((el: any, idx) => (
                <div key={idx} role="button">
                  {_.label == "Category" && el.label && (
                    <Checkbox
                      className="[&_.label]:text-neutral-700 [&_.label]:text-xs [&_.icon-container]:rounded-none"
                      label={el.label}
                      checked={formula.includes(el.value) ? true : false}
                      defaultValue={category.includes(el.value) ? true : false}
                      onChange={(value: any) =>
                        category.includes(el.value)
                          ? setSearchParams(
                              removeExistingParamsArrayValue(
                                searchParams,
                                "category",
                                el.value
                              )
                            )
                          : setSearchParams(
                              addExistingParamsArrayValue(
                                searchParams,
                                "category",
                                el.value
                              )
                            )
                      }
                    />
                  )}
                  {_.label == "Formula" && el.label && (
                    <Checkbox
                      className="[&_.label]:text-neutral-700 [&_.label]:text-xs [&_.icon-container]:rounded-none"
                      label={el.label}
                      checked={formula.includes(el.value) ? true : false}
                      defaultValue={formula.includes(el.value) ? true : false}
                      onChange={(value: any) =>
                        formula.includes(el.value)
                          ? setSearchParams(
                              removeExistingParamsArrayValue(
                                searchParams,
                                "formula",
                                el.value
                              )
                            )
                          : setSearchParams(
                              addExistingParamsArrayValue(
                                searchParams,
                                "formula",
                                el.value
                              )
                            )
                      }
                    />
                  )}
                  {_.label == "Gender" && el.label && (
                    <Checkbox
                      className="[&_.label]:text-neutral-700 [&_.label]:text-xs [&_.icon-container]:rounded-none"
                      label={el.label}
                      checked={formula.includes(el.value) ? true : false}
                      defaultValue={gender.includes(el.value) ? true : false}
                      onChange={(value: any) =>
                        gender.includes(el.value)
                          ? setSearchParams(
                              removeExistingParamsArrayValue(
                                searchParams,
                                "gender",
                                el.value
                              )
                            )
                          : setSearchParams(
                              addExistingParamsArrayValue(
                                searchParams,
                                "gender",
                                el.value
                              )
                            )
                      }
                    />
                  )}
                </div>
              ))}

              {_.label == "Brands" && <SearchSelectMulti />}
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion.Root>
      <div className="grow" />
      <div className="p-2 flex [&>*]:grow gap-1 lg:gap-4 grid grid-cols-2">
        <button
          className="bg-slate-800 h-[35px] text-white text-xs font-semibold rounded-xl"
          onClick={(val) =>
            dispatch(
              fetchMarket({
                page: 1,
                category,
                formula,
                gender,
                brands,
              })
            )
          }
        >
          View Items
        </button>
        <button
          className="bg-[#fff] h-[35px]  text-xs font-semibold rounded-xl border-2 border-slate-800/50"
          onClick={(val) => {
            dispatch(fetchProducts(0));
            setSearchParams({});
            setSelectedPerson([])
          }}
        >
          Clear all
        </button>
      </div>
    </motion.nav>
  );
};

const filterVariants: Variants = {
  hidden: {
    x: "-100%",
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
  },
};


/**
 * Search params are held as an array of two elements arrays where member 0 is the key and member 1 is value
 * This function extracts the values from the array and returns an object with the key as the property name and the value as the property value
 * Where a key has multiple values, the value is an array of values
 * 
 * @param {URLSearchParams} searchParams 
 */
export function extractExistingParams(searchParams: any) {
  const entries = Array.from(searchParams.entries());
  return entries.reduce((acc:any, a:any) => ((acc[a[0]] = acc[a[0]] || []).push(a[1]), acc), {});
}
/**
 * Remove a value from an existing parameter where the parameter can occur multiple times
 * If the value is the last value, the parameter is removed
 * 
 * @param {URLSearchParams} searchParams 
 * @param {string} key 
 * @param {string} value 
 */
export function removeExistingParamsArrayValue(searchParams: any, key: string | number, value: any) {
  const existingParams: any = extractExistingParams(searchParams);
  if (existingParams[key]) {
    existingParams[key] = existingParams[key].filter((v: any) => v !== value);
  }
  if (existingParams[key].length === 0) {
    delete existingParams[key];
  }
  return existingParams;
}

export function addExistingParamsArrayValue(searchParams: any, key: string | number, value: any) {
  const existingParams: any = extractExistingParams(searchParams);
  if (existingParams[key]) { existingParams[key].push(value); }

  if (!existingParams[key]) {
    existingParams[key] = [value];
  }
   
  return existingParams;
}

export function addExistingParamsValue(searchParams: any, key: string | number, value: any) {
  const existingParams: any = extractExistingParams(searchParams);
  if (existingParams[key]) {
    existingParams[key] = value;
  }

  if (!existingParams[key]) {
    existingParams[key] = value;
  }
  return existingParams;
}