import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { fetchWishlist } from "../../../../redux/features/userWishlist/wishlistSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import DialogWrapper from "../../../common/dialog-wrapper";
import NotAdded from "../../../common/Error/NotAdded";
import Loader from "../../../common/Loader/loader";
import { ProductCard } from "../../Market/product-card";
import ProductList from "../../Market/product-list";
import { DeleteGear } from "../../My-Gear-Room/delete-gear";
import { GearCard } from "../../Single-Page/New-Gear/gear-card";
import { RemoveFromWatchlist } from "./remove-watchlist";

const UserWatchlist = () => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.userProfile);
  useEffect(() => {
    dispatch(fetchWishlist(userId));
  }, [userId]);
  const { loading, userProducts, error } = useAppSelector(
    (state) => state.userWishlist
  );
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <section className="">
          {/* <ProductList
            className="sm:grid-cols-1 md:grid-cols-3"
            value={userProducts}
          /> */}
          {userProducts && userProducts.length > 0 ? (
            <div className="mt-6 grid grid-cols-2 gap-2 sm:grid-cols-5 lg:grid-cols-6 ">
              {userProducts?.map((_: any, key: any) => (
                <div className=" relative">
                  <GearCard key={key} info={_} />
                  <DialogWrapper
                    wrapperClassName="md:min-w-[500px]"
                    className="absolute top-0 w-6 drop-shadow aspect-square right-0"
                    content={<RemoveFromWatchlist index={_.id} />}
                  >
                    <button className="hover:animate-spin w-7 z-50 aspect-square grid place-content-center bg-red-500 rounded-full">
                      <Icon icon={"lucide:x"} className="text-white text-lg" />
                    </button>
                  </DialogWrapper>
                </div>
              ))}
            </div>
          ) : (
            <NotAdded
              m1="Nothing to Find"
              m2="No Frags Added"
              m3="Build your collection online"
            />
          )}
        </section>
      )}
    </div>
  );
};

export default UserWatchlist;
