
import { DotWave} from "@uiball/loaders";

const Loader = () => {
  return (
    <div className="mx-auto h-full">
      <DotWave size={100} color="#FBBC05" />
    </div>
  );
}

export default Loader;