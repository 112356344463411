import { ComponentProps } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import Card from "../../../common/Card";
import Loader from "../../../common/Loader/loader";
import { Rules } from "./Rules";


type CommunityRulesProps = ComponentProps<"div">;

export function CommunityRules({ className, ...props }: CommunityRulesProps) {
  const { communityData, loading } = useAppSelector((state) => state.community);
  return (
    <>
      <h4 className="text-lg mt-4 md:hidden font-semibold">Rules</h4>
      {loading ? <Loader /> : (communityData.rules.length > 0? <Rules value={communityData.rules} />: "No Rules Added.")}
    </>
  );
}


