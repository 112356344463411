import { Icon } from "@iconify/react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { useState } from "react";
import { cn } from "../../../helpers/utils";
import GradientBtn from "../../buttons/GradientBtn";
import { Input } from "./input";

type PaymentExtProps = { info: any };
export function PaymentExt({ info }: PaymentExtProps) {
  const [state, setState] = useState("credit");
  return (
    <>
      <div className="grid grid-cols-3 md:lg:xl:grid-cols-3 group  rounded-lg shadow-xl shadow-neutral-100 rounded-t-lg">
        {info.map((_: any, idx: any) => (
          <div className="p-2 flex flex-col items-center text-center rounded-lg group border-r border-b hover:bg-slate-50 cursor-pointer">
            <Icon
              icon={_.icon}
              className="text-green-500 text-xl md:text-2xl shadow-xl shadow-neutral-100"
            />
            <p className=" text-xs font-medium text-slate-700 mt-1">
              {_.title}
            </p>
            <p className="mt-1 text-xs text-slate-500">{_.desc}</p>
          </div>
        ))}
      </div>
    </>
  );
}




