import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface AuthSignupState {
  username: string;
  email: string;
  password: string;
  firstName: string;
  accessToken?: string;
  refreshToken?: string;
}

interface AuthState {
  userId: string;
  loading: boolean;
  error: any;
  success: any;
}

// Define the initial state using that type
const initialState: AuthState = {
  userId: "",
  loading: false,
  error: null,
  success: null,
};

export const signup = createAsyncThunk(
  "auth/signup ",
  async ({ username, email, password, firstName }: AuthSignupState) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/auth/signup`,
      {
        firstName: firstName,
        email: email,
        login: email,
        displayName: username,
        password: password,
      }
    );
    return response.data;
  }
);

export const authSignupSlice = createSlice({
  name: "authSignup",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = action;
      });
  },
});

export const {} = authSignupSlice.actions;

export default authSignupSlice.reducer;
