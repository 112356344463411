import React, { useEffect } from 'react';
import clsx from "clsx";
import { z } from 'zod';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormMessage } from '../../../ui/form';
// import { addNewGearProperties } from '../../../../redux/features/post/postSlice';
interface SelectProps {
  placeholder: string;
  label: string;
  defaultValue?: string;
  onOptionSelect: (val: string) => void;
  className?: string;
}

const GearRatings = ({
  label,
  placeholder,
  defaultValue,
  onOptionSelect,
  className,
}: SelectProps) => {
  const [value, setValue] = React.useState();
 
  const onSelect = (el: any) => {
      setValue(el);
      if (onOptionSelect) {
        onOptionSelect(el);
      }
    };

  return (
    <div className="rounded-md bg-amber-500/10 p-2.5 ">
      <p className="text-[13px] font-bold underline">{label}</p>
      <p className=" text-[10px] ">{placeholder}</p>
      <div className="flex justify-center gap-3 lg:gap-2 mt-2 ">
        {Array.from(Array(5).keys()).map((_: number) => (
          <div
            className={clsx(
              value == _
                ? "w-[25px] h-[25px] px-2 bg-orange-400 text-white outline outline-offset-2 outline-1 grid place-content-center font-semibold rounded-full"
                : "cursor-pointer w-[25px] px-2 h-[25px] bg-white outline outline-offset-2 outline-1 text-white grid place-content-center font-semibold rounded-full"
            )}
            key={_}
            onClick={() => onSelect(_)}
          >
            {_ + 1}
          </div>
        ))}
      </div>
      <FormMessage className="text-[10px]" />
    </div>
  );
};

export default GearRatings
