import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../../../redux/hooks";
import FooterVoter from "./footer-voter";

export function CardFooter({ el, idx }: { el: any; idx: number }) {
  const { userId } = useAppSelector((state) => state.userProfile);
  const [isLiked, setIsLiked] = useState(
    el.allLikes?.includes(userId) ? true : false
  );

  return (
    <div className="flex justify-between items-center md:mt-[18px] mt-[12px]">
      <div className="flex text-zinc-600 items-center sm:gap-[50px] gap-4">
        <FooterVoter el={el} isliked={isLiked} />
        <Link to={el.link}>
          <div className="flex gap-1 md:gap-1.5 items-center cursor-pointer">
            <Icon icon="fluent:comment-16-regular" className="text-2xl" />
            <div className="md:text-sm text-[12px] md:font-semibold">
              {" "}
              {el.comments}
            </div>
          </div>
        </Link>
        <div className="flex gap-1 md:gap-1.5 items-center cursor-pointer">
          <Icon icon="quill:share" className="text-lg sm:text-2xl" />
          <div className="md:text-xs text-[10px] md:font-semibold"> Share</div>
        </div>
      </div>
      <div className="flex gap-1 md:gap-[14px]">
        {/* {el.tags.map((el2: any, idx2: any) => {
          return (
            <div
              key={"home-post-tags-star" + idx + "-" + idx2}
              className="md:px-2 whitespace-nowrap  px-1.5 py-0.5 md:py-1 text-[11px] sm:text-xs font-medium bg-accentGray-400 rounded-3xl"
            >
              {el2}
            </div>
          );
        })} */}
      </div>
    </div>
  );
}
