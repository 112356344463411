import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state

interface InitialStateType {
  status: string;
  loading: boolean;
  error: string | null;
  success: boolean;
  requestLoading: boolean;
  requestError: string | null;
  responseLoading: boolean;
  responseError: string | null;
  buyOfferList: any;
  sellOfferList: any;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  status: "All",
  buyOfferList: null,
  sellOfferList: null,
  loading: false,
  error: null,
  success: false,
  requestLoading: false,
  requestError: null,
  responseLoading: false,
  responseError: null,
};

export const acceptOffer = createAsyncThunk(
  "offer/accept",
  async ({ id }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/offers/accept/${id}`
    );
    return response.data;
  }
);

export const declineOffer = createAsyncThunk(
  "offer/decline",
  async ({ id }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/offers/decline/${id}`
    );
    return response.data;
  }
);

export const counterOffer = createAsyncThunk(
  "offer/counter",
  async ({ id, amount }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/offers/counter/${id}/${amount}`
    );
    return response.data;
  }
);

export const fetchOffersSent = createAsyncThunk(
  "user/fetchOffersSent",
  async (userId: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/offers/by-user/${userId}`
    );
    return response.data;
  }
);

export const fetchOffersReceived = createAsyncThunk(
  "user/fetchOffersReceived",
  async (userId: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/offers/by-seller/${userId}`
    );
    return response.data;
  }
);

export const offerRequest = createAsyncThunk(
  "user/offerRequest",
  async ({ offerAmount, userId, listingId, comment }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/offers/createOffer`,
      {
        offerAmount: offerAmount,
        listingId: listingId,
        comment: comment,
        userId: userId,
      }
    );
    return response.data;
  }
);

export const userOffersSlice = createSlice({
  name: "userOffers",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    sortSelect: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffersSent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOffersSent.fulfilled, (state, action) => {
        state.loading = false;
        state.buyOfferList = action.payload;
      })
      .addCase(fetchOffersSent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchOffersReceived.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOffersReceived.fulfilled, (state, action) => {
        state.loading = false;
        state.sellOfferList = action.payload;
      })
      .addCase(fetchOffersReceived.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(offerRequest.pending, (state) => {
        state.requestLoading = true;
        state.requestError = null;
      })
      .addCase(offerRequest.fulfilled, (state, action) => {
        state.requestLoading = false;
        state.success = true;
      })
      .addCase(offerRequest.rejected, (state, action) => {
        state.requestLoading = false;
        state.requestError = action.error.message || "An error occurred";
      })
      .addCase(acceptOffer.pending, (state) => {
        state.responseLoading = true;
        state.responseError = null;
      })
      .addCase(acceptOffer.fulfilled, (state, action) => {
        state.responseLoading = false;
      })
      .addCase(acceptOffer.rejected, (state, action) => {
        state.responseLoading = false;
        state.responseError = action.error.message || "An error occurred";
      })
      .addCase(declineOffer.pending, (state) => {
        state.responseLoading = true;
        state.responseError = null;
      })
      .addCase(declineOffer.fulfilled, (state, action) => {
        state.responseLoading = false;
      })
      .addCase(declineOffer.rejected, (state, action) => {
        state.responseLoading = false;
        state.responseError = action.error.message || "An error occurred";
      })
      .addCase(counterOffer.pending, (state) => {
        state.responseLoading = true;
        state.responseError = null;
      })
      .addCase(counterOffer.fulfilled, (state, action) => {
        state.responseLoading = false;
      })
      .addCase(counterOffer.rejected, (state, action) => {
        state.responseLoading = false;
        state.responseError = action.error.message || "An error occurred";
      });
  },
});

export const { sortSelect } = userOffersSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userOffersSlice.reducer;
