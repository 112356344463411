import clsx from "clsx";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Offers from "./OfferSent";
import Select from "../../../common/select";
import { sortSelect } from "../../../../redux/features/userTransactions/offers/userOffersSlice";
import { NavLink, Outlet } from "react-router-dom";
import { Loading } from "../../../common/Widget/Loading";

const OfferSelector = () => {
  const dispatch = useAppDispatch();
  const [selector, setSelector] = useState(true);
  const { loading } = useAppSelector((state) => state.userOffers);
  const [sortValue, setSortValue] = useState("All");

  return (
    <div>
      <div className="mt-3">
        <div className="grid grid-cols-2 mx-auto bg-white border border-solid border-accentGray-600 p-[10px] flex rounded-lg text-sm">
          <NavLink
            className="sm:w-[290px] p-[10px] flex rounded-lg text-sm"
            to={"/transactions/offers/sent"}
            key={"offers"}
          >
            {({ isActive }) => (
              <div
                className={clsx(
                  isActive
                    ? "cursor-pointer bg-gradient-to-l from-red-500 to-yellow-500 text-white font-semibold min-h-[44px] flex items-center justify-center flex-grow rounded-md"
                    : "cursor-pointer text-accentGray-200 font-semibold min-h-[44px] flex items-center justify-center flex-grow rounded-md"
                )}
              >
                Buying
              </div>
            )}
          </NavLink>
          <NavLink
            className="sm:w-[290px] p-[10px] flex rounded-lg text-sm"
            to={"/transactions/offers/recieved"}
            key={"offers"}
          >
            {({ isActive }) => (
              <div
                className={clsx(
                  isActive
                    ? "cursor-pointer bg-gradient-to-l from-red-500 to-yellow-500 text-white font-semibold min-h-[44px] flex items-center justify-center flex-grow rounded-md"
                    : "cursor-pointer text-accentGray-200 font-semibold min-h-[44px] flex items-center justify-center flex-grow rounded-md"
                )}
              >
                Selling
              </div>
            )}
          </NavLink>
        </div>
        <div className="mt-2">
          <div className="flex items-center text-xs">
            <div className="max-w-[190px]">
              <Select
                uniqueKey="order-selector"
                label="Offer Status"
                defaultValue={sortValue}
                options={sortOptions}
                onOptionSelect={(val) => dispatch(sortSelect(val))}
              />
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default OfferSelector;

const sortOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Accepted",
    value: "Accepted",
  },
  {
    label: "Declined",
    value: "Declined",
  },
  {
    label: "Expired",
    value: "Expired",
  },
];
