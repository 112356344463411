import Card from "../../../common/Card";
import AddFile from "../../../common/AddFiIe";
import GradientBtn from "../../../buttons/GradientBtn";
import { useAppSelector } from "../../../../redux/hooks";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../ui/form";
import { TextField } from "../../../text-field";
import { z } from "zod";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Loader from "../../../common/Loader/loader";
import { useEffect, useState } from "react";
import { AppDispatch } from "../../../../redux/store";
import AvatarSelector from "./Avatar/AvatarSelector";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { Loading } from "../../../common/Widget/Loading";
import { updateProfile } from "../../../../redux/features/user/updateProfileSlice";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";
import { Input } from "../../../common/input";
import { Icon } from "@iconify/react";
import axios from "axios";

const formSchema = z.object({
  userId: z.string(),
  profilePicUri: z.string().optional(),
  userName: z.string().optional(),
  description: z.string().optional(),
  isOnBoarding: z.boolean(),
});

const Profile = () => {
  const { profileData, loading, updateError, loadingUpdate } = useAppSelector(
    (state) => state.userProfile
  );
  const [isloading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { userId } = useAppSelector((state) => state.userProfile);
  const dispatch = useDispatch<AppDispatch>();
  const user = useAuthUser();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (profileData.userName !== searchTerm) {
        try {
          setIsLoading(true);
          const response = await axios.post(
            `https://webservice.pairrit.com/pairrit-ws/api/auth/username/check/${searchTerm}`
          );
          setIsLoading(false);
          setNameError(response.data);
          if (response.data === false) {
            form.setValue("userName", searchTerm);
          }
        } catch (err: any) {
          setIsLoading(false);
          setNameError(false);
          if (!err?.response) {
            dispatch(
              notifySelect({
                title: "No Server Response",
                type: 1,
                open: true,
              })
            );
          }
        }
      } else {
        setNameError(false);
      }
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  function onSubmit(values: any) {
    if (nameError === false) {
      dispatch(updateProfile(values))
        .then(() => {
          dispatch(
            notifySelect({
              title: "Update Sent!",
              type: 0,
              open: true,
            })
          );
          mixpanel.track("Profile information updated", {
            ...values,
            email: user ? user["email"] : "",
          });
        })
        .finally(() => window.location.reload());
    } else {
      dispatch(
        notifySelect({
          title: "Username Taken",
          type: 1,
          open: true,
        })
      );
    }
  }

  useEffect(() => {
    form.setValue("userId", userId);
    form.setValue("isOnBoarding", false);
    if (updateError) {
      dispatch(
        notifySelect({
          title: "Error Updating",
          type: 1,
          open: true,
        })
      );
    }
  }, [updateError, userId]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {loading ? (
          <Loader />
        ) : (
          <Card className="p-6 bg-white">
            <div className="flex justify-between">
              <h5 className="text-sm text-accentGray-700">
                PROFILE INFORMATION
              </h5>
            </div>
            <hr className="v2 my-6" />
            <div className="max-w-[880px]">
              <div className="flex justify-between">
                <div>
                  <h4 className="text-semibold text-[17px]">Username</h4>
                  <h5 className="text-base text-accentGray-700">
                    {profileData.userName}
                  </h5>
                </div>
                <div className="flex">
                  <FormField
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            autoFocus
                            {...field}
                            onChange={(e: any) => setSearchTerm(e.target.value)}
                            className="w-[10em]"
                            defaultValue={profileData.userName}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                    name={"userName"}
                    control={form.control}
                  />
                  {nameError ? (
                    <>
                      <Icon
                        icon={"ep:warning-filled"}
                        className="ml-1 text-red-500 text-2xl my-auto"
                      />
                      <p className="hidden md:block my-auto ml-1 text-2xs">
                        Username Taken
                      </p>
                    </>
                  ) : (
                    <>
                      <Icon
                        icon={"lets-icons:check-fill"}
                        className="ml-1 text-green-700 text-2xl my-auto"
                      />
                      <p className="hidden md:block my-auto ml-2 text-2xs">
                        Username Avaliable
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>

            <hr className="v2 my-6" />

            <div className="max-w-[880px]">
              <div>
                <h4 className="text-semibold text-[17px]">About (optional)</h4>
                <h5 className="text-2xs text-accentGray-700">
                  A brief description of yourself shown on your profile.
                </h5>
                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <TextField
                          autoFocus
                          defaultValue={profileData.description}
                          onOptionSelect={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                  name={"description"}
                  control={form.control}
                />
              </div>
            </div>

            <hr className="v2 my-6" />

            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 w-full">
              <div className="max-w-[880px] w-full">
                <h5 className="text-base text-accentGray-700">IMAGES</h5>
                <h4 className="text-semibold text-[17px]">Avatar Selection</h4>
                <h5 className="text-xs text-accentGray-700">
                  Select your own avatar to express to the world.
                </h5>
                {/* <div className="mt-[20px]">
                  <div className="flex flex-col sm:flex-row gap-6">
                    <div className="sm:max-w-[212px]">
                      <AddFile id="file-1" />
                    </div>
                    <div className="flex-grow">
                      <AddFile id="file-1" />
                    </div>
                  </div>
                </div> */}
                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <AvatarSelector
                          value={profileData.profileUrl}
                          onOptionSelect={field.onChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                  name={"profilePicUri"}
                  control={form.control}
                />
              </div>
            </div>

            <hr className="v2 my-6" />

            {loadingUpdate ? (
              <Loading />
            ) : (
              <GradientBtn label="Save Changes" type="submit" icon="" />
            )}
          </Card>
        )}
      </form>
    </Form>
  );
};

export default Profile;
