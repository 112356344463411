import React, { useEffect } from "react";
import GearRoomProfile from "../../My-Gear-Room/profile";
import Posts from "../../Home/Posts";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Loader from "../../../common/Loader/loader";
import { fetchPosts } from "../../../../redux/features/userThreads/userThreadsSlice";
import { useParams } from "react-router-dom";
import Card from "../../../common/Card";

const UserThreads = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {userFeed, loading, error} = useAppSelector((state) => state.userThreads);

  useEffect(() => {
    if (id) { dispatch(fetchPosts(id)); } 
  
   }, []);
  return (
    <div>
      {loading ? (
        <Loader />
      ) : userFeed ? (
        <Posts posts={userFeed} />
        ) : (
            <Card></Card>
      )}
    </div>
  );
};

export default UserThreads;
