import React, { useEffect } from "react";

import CommunityItems from "./CommunityItems";
import Wrapper from "../../../layouts/MainLayout/wrapper";

import Category from "./category";
import Filter from "./filter";
import { useAppSelector } from "../../../redux/hooks";
import Categories from "./Community-Categories";
import Loader from "../../common/Loader/loader";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";

const Community = () => {
  const category = useAppSelector(
    (state) => state.communities.communityCategory
  );
  const user = useAuthUser();
  useEffect(() => {
    mixpanel.track("Browse Communities page view", {
      email: user ? user["email"] : "",
    });
  }, []);

  const { communitiesList, loading } = useAppSelector(
    (state) => state.communities
  );

  return (
    <div className="lg:mb-[8em] lg:mx-auto">
      <Category category={category1} />
      <Wrapper>
        <Categories value={category} select={1} />
        <Filter />
        {loading ? <Loader /> : null}
        {communitiesList && <CommunityItems value={communitiesList} />}
      </Wrapper>
    </div>
  );
};

export default Community;

const category1 = [
  {
    title: "By Category",
    icon: "eva:flash-outline",
    active: true,
    link: "/browse/find",
  },
  {
    title: "Find All",
    icon: "icon-park-twotone:search",
    active: true,
    link: "/browse/all",
  },
  {
    title: "My Communities",
    icon: "ri:team-fill",
    active: true,
    link: "/my/communities",
  },
];
