import { useOktaAuth } from "@okta/okta-react";
import { HTMLAttributes, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import {
  addMember,
  joinCommunity,
  removeMember,
  unJoinCommunity,
} from "../../../redux/features/communityFeed/communitySlice";
import YellowBtn from "../YellowBtn/YellowBtn";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";

const JoinBtn = () => {
  const dispatch = useAppDispatch();
  const { loading, communityData, subscriberIds } = useAppSelector(
    (state) => state.community
  );
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);

  const { id } = useParams();
  const { authState } = useOktaAuth();
  const [isPressed, setIsPressed] = useState(subscriberIds);

  const onTap = () => {
    if (authState?.isAuthenticated) {
      dispatch(addMember(userId));
      const data = { communityName: id, userId: userId };
      dispatch(joinCommunity(data));
      mixpanel.track("Join community button clicked", {
        community_name: id,
        email: user ? user["email"] : "",
      });
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
    }
  };
  const onUnTap = () => {
    if (authState?.isAuthenticated) {
      dispatch(removeMember(userId));
      const data = { communityName: id, userId: userId };
      dispatch(unJoinCommunity(data));
      mixpanel.track("Leave community button clicked", {
        community_name: id,
        email: user ? user["email"] : "",
      });
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
    }
  };

  useEffect(() => {
    setIsPressed(subscriberIds);
  }, [subscriberIds]);
  return (
    <>
      {isPressed.includes(userId) ? (
        <YellowBtn
          onClick={onUnTap}
          label={"Leave"}
          className={"w-full bg-gray-200"}
        />
      ) : (
        <YellowBtn onClick={onTap} label={"Join Now"} className="w-full" />
      )}
    </>
  );
};

export default JoinBtn;
