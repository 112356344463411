import React from 'react'
import GradientBtn from '../../buttons/GradientBtn';

const Reverb = () => {
  return (
    <div className="bg-white border border-solid border-accentGray-600 py-10 px-7 text-center rounded-lg  md:block hidden">
      <div className="text-xl font-semibold">Ebay</div>
      <div className="text-xs">Online Marketplace</div>
      <div className="mt-6 text-3xl font-bold text-primary-gradient">Shop</div>
      <div className="text-accentGray-700 font-semibold">Buy and Sell</div>
      <div className="my-6 flex justify-center">
        <GradientBtn className="w-full" label="Good Bye" />
      </div>
      <div className="font-semibold">13% Sellers Fee</div>
      <div className="mt-3 font-semibold text-xs">
        <div className="font-semibold my-2 line-through decoration-orange-700 decoration-2">
          {" "}
          Used Fragrance Marketplace
        </div>
        <div className="font-semibold my-2 line-through decoration-orange-700 decoration-2">
          Verification Protection
        </div>
        <div className="font-semibold my-2">Buy and Sell Online</div>
        <div className="font-semibold my-2 line-through decoration-orange-700 decoration-2">
          Join and build Communities
        </div>
        <div className="font-semibold my-2 line-through decoration-orange-700 decoration-2">
          Share Your Collection
        </div>
        <div className="font-semibold my-2 line-through decoration-orange-700 decoration-2">
          Start Your OWN Threads
        </div>

        <div className="font-semibold my-2">Make Offers</div>
        <div className="font-semibold my-2">24/7 Support</div>
      </div>
    </div>
  );
}

export default Reverb