import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { notifySelect } from "../../../../../../redux/features/notify/notifySlice";
import {
  likeAPost,
  removeLike,
} from "../../../../../../redux/features/homeFeed/homeFeedSlice";
import { useOktaAuth } from "@okta/okta-react";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../../../hooks/getAuthUser";

const FooterVoter = ({ el, isliked }: { el: any; isliked: any }) => {
  const [value, setValue] = useState(el.likes);
  const user = useAuthUser();
  const { authState } = useOktaAuth();
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.userProfile);
  const [likePressed, setLikePressed] = useState(isliked);
  const [dislikePressed, setDislikePressed] = useState(false);

  const onLikeTap = () => {
    if (authState?.isAuthenticated) {
      setLikePressed(!likePressed);
      setDislikePressed(false);
      setValue(value + 1);
      const data = { postId: el.id, userId: userId };
      dispatch(likeAPost(data));
      mixpanel.track("Feed Post liked", {
        post_id: el.id,
        email: user ? user["email"] : "",
      });
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
    }
  };

  const onDisLikeTap = () => {
    setDislikePressed(!dislikePressed);
    setLikePressed(false);
    setValue(value - 1);
    const data = { postId: el.id, userId: userId };
    dispatch(removeLike(data));
  };
  return (
    <div className="flex gap-1 md:gap-1.5 items-center cursor-pointer">
      {likePressed ? (
        <Icon
          icon="icon-park-twotone:up-two"
          className="text-xl sm:text-2xl text-red-500"
          onClick={onDisLikeTap}
        />
      ) : (
        <Icon
          icon="icon-park-outline:up-two"
          className="text-xl sm:text-2xl hover:text-orange-500"
          onClick={onLikeTap}
        />
      )}

      <div className=" md:text-sm text-[12px] md:font-semibold">{value}</div>
      {/* {dislikePressed ? (
        <Icon
          icon="icon-park-twotone:down-two"
          className="text-xl sm:text-2xl text-red-500"
        />
      ) : (
        <Icon
          icon="icon-park-outline:down-two"
          className="text-xl sm:text-2xl hover:text-orange-500"
          onClick={onDisLikeTap}
        />
      )} */}
    </div>
  );
};

export default FooterVoter;
