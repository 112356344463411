import { Menu, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import friendlyTime from "../../../../../../helpers/friendlyTime";
import YellowBtn from "../../../../../buttons/YellowBtn";
import DialogWrapper from "../../../../../common/dialog-wrapper";
import { EditListing } from "../../../../Transactions/ManageListings/EditListing";

import "../Post.css";
import DeletePostModal from "./delete-post";
import ReportModal from "./report-modal";
import { UpdatePost } from "./update-post-model";

export function User({ el, time}: { el: any; time?: any; }) {
  const [isUser, setIsUser] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.href.indexOf("my/threads") > -1) {
      setIsUser(true);
    }
  }, [window]);

  return (
    <div className="flex items-center justify-between mb-2 ">
      <Link to={`/user/${el.author.id}/shop`}>
        <div className="flex flex-wrap items-center gap-4 sm:flex-nowrap">
          <div className="flex items-center gap-2">
            <img
              className="w-[25px] h-[25px] rounded-full"
              src={`/assets/imgs/avatar/${el.author.avatar}.svg`}
              alt="user"
            />
            <div className="flex max-md:flex-col md:items-center md:gap-2">
              <div className="text-xs font-bold">{el.author.username}</div>
              <div className="flex text-[7px] text-accentGray-700 md:text-xs items-center gap-1  md:gap-1">
                <p className="max-md:hidden">•</p>
                <div className="flex items-center">
                  <img
                    className="w-3 h-3"
                    src="/assets/vectors/home/flower.svg"
                    alt="flower"
                  />
                  <p className="ml-1 text-2xs">{el.author.muzPoints}</p>
                </div>
                <p>•</p>
                <p className="text-2xs">{friendlyTime(time)}</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div className="flex gap-2">
        <Link to={`/user/${el.author.id}/gear`}>
          <YellowBtn
            short
            label="My Collection"
            className="scale-75 origin-right"
          />
        </Link>

        <Menu as="div" className="relative">
          <Menu.Button>
            <img
              className="cursor-pointer"
              src="/assets/vectors/common/menu-vertical.svg"
              alt="menu"
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="px-1 py-1 ">
                <DialogWrapper
                  className="bgb-red-100"
                  content={<ReportModal id={el.id} />}
                >
                  <button
                    className={`
                             text-gray-900
                           group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <Icon
                      icon={"ic:baseline-report-problem"}
                      className="mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Report
                  </button>
                </DialogWrapper>
                {isUser && (
                  <DialogWrapper content={<UpdatePost info={el} />}>
                    <button
                      className={`
                             text-gray-900
                           group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <Icon
                        icon={"ic:baseline-edit"}
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Edit
                    </button>
                  </DialogWrapper>
                )}
                {isUser && (
                  <DialogWrapper content={<DeletePostModal id={el.id} />}>
                    <button
                      className={`
                             text-gray-900
                           group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <Icon
                        icon={"fluent:delete-32-filled"}
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Delete
                    </button>
                  </DialogWrapper>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
