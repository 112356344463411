import React from 'react'

const Steps = ({data}: any) => {
  return (
    <div className="flex items-start py-3  px-6 ">
      <div className="space-y-4 border-l-2 border-dashed">
        {data.map((details:any, index:any) => (
          <div className="relative w-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="absolute   -ml-3 h-6 w-6 rounded-full text-blue-900"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clip-rule="evenodd"
              />
            </svg>

            <div className="ml-6 ">
              <h4 className="font-bold text-blue-900 text-[15px]">{details.title}</h4>
              <p className=" max-w-screen-sm text-xs text-gray-800">
                {details.desc}
              </p>
              <span className="mt-1 block text-sm font-semibold text-blue-500">
                {details.subTitle}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Steps

