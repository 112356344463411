import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface FeedState {
  category: string;
  brand: string;
  community: string;
  loading: boolean;
  success: boolean;
  error: string | null;
  updateLoading: boolean;
  updateError: string | null;
  newListing: any;
}

const initialState: FeedState = {
  category: "General",
  brand: "Christian Dior",

  loading: false,
  error: null,
  community: "",
  newListing: {
    authorId: "",
    postCategory: "",
    brandId: "FRG100001",
    model: "Dior Addict Dior",
    size: 3.4,
    usage: 100,
    quantity: 1,
    year: 2024,
    title: "",
    images: [],
    pics: [],
    condition: "NEW",
    description: "",
    type: null,
    formula: null,
    createdDateTime: "",
    offerShipping: true,
    review: true,
    isDraft: false,
    isAcceptOffers: false,
    price: 0.0,
    is3PercentFromFinalSellingPrice: true,
  },
  updateLoading: false,
  updateError: null,
  success: false,
};

export const createListingPost = createAsyncThunk(
  "post/listing",
  async ({
    authorId,
    postCategory,
    community,
    description,
    usage,
    brandId,
    images,
    year,
    size,
    quantity,
    condition,
    isAcceptOffers,
    price,
    is3PercentFromFinalSellingPrice,
    offerShipping,
  }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/listings/create`,
      {
        authorId: authorId,
        postCategory: postCategory,
        community: community,
        description: description,
        isEnablePostReplyNotification: true,
        reviewed: true,
        isDraft: false,
        brand: brandId,
        images: images,
        year: year,
        size: size,
        quantity: quantity,
        usage: usage,
        condition: condition,
        price: price,
        is3PercentFromFinalSellingPrice: is3PercentFromFinalSellingPrice,
        offerShipping: offerShipping,
        isAcceptOffers: isAcceptOffers,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.status;
  }
);

export const updateListingPost = createAsyncThunk(
  "update/listing",
  async ({
    id,
    category,
    community,
    description,
    formula,
    condition,
    price,
  }: any) => {
    const response = await axios.put(
      `https://webservice.pairrit.com/pairrit-ws/api/products/update`,
      {
        id: id,
        category: category,
        community: community,
        description: description,
        formula: formula,
        condition: condition,
        price: price,
      }
    );
    return response.status;
  }
);

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    categorySelect: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
    brandSelect: (state, action: PayloadAction<string>) => {
      state.brand = action.payload;
    },
    commSelect: (state, action: PayloadAction<string>) => {
      state.community = action.payload;
    },
    addNewListingProperties: (state, action) => {
      state.newListing = { ...state.newListing, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createListingPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createListingPost.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(createListingPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(updateListingPost.pending, (state) => {
        state.updateLoading = true;
        state.updateError = null;
      })
      .addCase(updateListingPost.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(updateListingPost.rejected, (state, action) => {
        state.updateLoading = false;
        state.updateError = action.error.message || "An error occurred";
      });
  },
});

export const {
  categorySelect,
  brandSelect,
  commSelect,
  addNewListingProperties,
} = postSlice.actions;

export const postReducer = postSlice.reducer;
