import GradientBtn from "../../../buttons/GradientBtn";
import GreyBtn from "../../../buttons/GreyBtn";
import RelatedCommunities from "../../../pages/Community/Community-Page/related-communities";




const TopGearRooms = () => {
  return (
    <div>
     <img className="w-full h-full mx-auto object-cover rounded-lg" src="/assets/imgs/home/banner-5.png" alt="ad" /> 
    </div>
  );
};

export default TopGearRooms;
