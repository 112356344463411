import { Icon } from "@iconify/react";
import { ComponentProps } from "react";
import { cn } from "../../../helpers/utils";
import { useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import Card from "../../common/Card";
import DialogWrapper from "../../common/dialog-wrapper";
import Loader from "../../common/Loader/loader";
import { AddRule } from "./add-rule";
import { DeleteRule } from "./delete-rule";

type RulesProps = ComponentProps<"div">;

export function Rules({ className, ...props }: RulesProps) {

  const { communityData, loading } = useAppSelector((state) => state.community);
  return (
    <Card className={cn("p-6 bg-white", className)}>
      <div className="flex items-center justify-between">
        <h5 className="text-sm text-accentGray-700">COMMUNITY PROFILE</h5>
        {loading ? (
          <Loader />
        ) : (
          <DialogWrapper
            wrapperClassName="md:min-w-[500px]"
            className="w-fit"
            content={<AddRule commId={communityData.id} />}
          >
            <GradientBtn label={"Add Rule"} />
          </DialogWrapper>
        )}
      </div>
      <br />
      <p className="text-gray-500 text-lg underline">Rules</p>

      <div className="space-y-4">
        {loading ? (
          <Loader />
        ) : communityData.rules.length > 0 ? (
          communityData.rules.map((el: any, idx: any) => (
            <Card className="border bg-white px-4 py-4 rounded-lg">
              <div key={el} className="flex gap-2 md:gap-4 items-center">
                <span className="md:w-16 shrink-0 text-center text-stone-700 text-base md:text-base font-semibold">
                  {idx + 1}.
                </span>
                <h5 className="text-slate-800 text-sm md:text-base  font-medium">
                  {el.rule}
                </h5>
                <DialogWrapper
                  wrapperClassName="md:min-w-[500px]"
                  className="w-fit"
                  content={<DeleteRule commId={communityData.id} index={idx} />}
                >
                  <button className="w-5 aspect-square grid place-content-center bg-red-500 rounded-full">
                    <Icon icon={"lucide:x"} className="text-white" />
                  </button>
                </DialogWrapper>
              </div>
            </Card>
          ))
        ) : (
          "No Rules Added."
        )}
      </div>
    </Card>
  );
}
