import Skeleton from "@mui/material/Skeleton";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useAuthUser from "../../../../../hooks/getAuthUser";
import {  useAppSelector } from "../../../../../redux/hooks";
import YellowBtn from "../../../../buttons/YellowBtn/YellowBtn";
import Card from "../../../../common/Card/Card";
import NotAdded from "../../../../common/Error/NotAdded";
import CommunityItems from "../../../Community/CommunityItems";
import ForSaleTopBar from "../../../Market/for-sale-topbar";


const MyCommunities = () => {
  const user = useAuthUser();
  const { profileData, loading } = useAppSelector((state) => state.userProfile);
  useEffect(() => {
    mixpanel.track("Community page view", { email: user ? user["email"] : "" });
  }, []);
 
  return (
    <div>
      <ForSaleTopBar communities  />
      {loading ? (
        <Card className="bg-white mt-4">
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={350}
            height={48}
          />
        </Card>
      ) : (
        <section className="grid grid-cols-1 gap-5 p-4 mt-6  bg-white border rounded-lg border-slate-200 ">
          {profileData.myCommunities?.length != 0 ? (
            <CommunityItems value={profileData.myCommunities} />
          ) : (
            <Card className="bg-white shadow-lg w-full ">
              <NotAdded
                m1="Nothing to find"
                m2="No Communities"
                m3="Start Community Shopping"
              />
            </Card>
          )}
        </section>
      )}
    </div>
  );
};

export default MyCommunities;

export const CommunityCard = ({ props,idx }: { idx: number, props:any }) => {
  const { pathname } = useLocation();
  return (
    <div className="cursor-pointer border-b border-r hover:border-t-4 hover:border-orange-300 pb-2 ">
      <div className="flex items-center gap-2.5 text-black ">
        <p className="w-5 text-sm font-medium">0{idx}</p>
        <img
          alt=""
          className="w-[44px] h-[44px] rounded-[108.49px] shadow"
          src={`/assets/vectors/navbar/${props.genre}.svg`}
        />
        <div>
          <p className="text-sm  font-semibold ">r/{props.name}</p>
          <p className="text-sm font-medium">{props.category}</p>
          <p className="text-xs font-medium text-neutral-400 ">
            {props.subscriberCount} members
          </p>
        </div>
        {pathname.includes("/communities/own") ? (
          <div className="ml-4 mt-2">
            <div className=" " />
            <Link to={`/que/${props.name}/settings`}>
              <YellowBtn
                className="bg-red-500 border border-current hover:opacity-50"
                label={"Mod Que"}
                short
              />
            </Link>
          </div>
        ) : (
          <br />
        )}
      </div>
    </div>
  );
};
