import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import { inviteUsers, joinWaitlist } from "../../../redux/features/post/inviteSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../ui/form";
import DialogHeader from "../dialog-header";
import { Input } from "../input";
import Loader from "../Loader/loader";
import { useOktaAuth } from "@okta/okta-react";
import { Loading } from "../Widget/Loading";
import { Icon } from "@iconify/react";
import Card from "../Card/Card";
import { Privacy } from "../privacy";
import { RadioCard } from "../RadioCard";
import ShopFlags from "../../pages/Home/Flags/ShopFlags";
import WhyUs from "../../pages/Protection/WhyUs";

const formSchema = z.object({
  senderId: z.string().optional(),
  recipientEmail: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  number: z.string(),
  type: z.string(),
});

const JoinWaitList = () => {
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const { email, userId} = useAppSelector((state) => state.userProfile);
  const { waitError,waitLoading,waitSent } = useAppSelector((state) => state.inviteUser);
  const formSchema = z.object({
    senderId: z.string().optional(),
    recipientEmail: z.string().default(email),
    firstName: z.string(),
    lastName: z.string(),
    number: z.string(),
    type: z.string(),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  
  function onSubmit(values: any) {
      dispatch(joinWaitlist(values));
      dispatch(
        notifySelect({
          title: "Invite Sent!",
          type: 0,
          open: true,
        })
      );
  }

  useEffect(() => {
    
    if (authState?.isAuthenticated) {
      form.setValue("recipientEmail", email);
      form.setValue("senderId", userId);
    } else {
      form.setValue("senderId", "Guest");
    }
    if (waitError) {
      dispatch(
        notifySelect({
          title: "Error Sending 😅",
          type: 1,
          open: true,
        })
      );
    }
  }, [email, waitError]);

  return (
    <>
      {waitSent ? (
        <Card>
          <Icon
            icon={"icon-park-twotone:success"}
            className="h-12 w-12 text-green-400 mx-auto my-3"
          />
          <h1 className="text-center">
            Success! Your request to become a verified sellers has been
            submitted, thanks!
          </h1>
          <WhyUs />
        </Card>
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader />
            <h2 className="text-center mb-2 text-black text-xl font-semibold">
              Sell on Pairrit
            </h2>
            <p className="text-center text-red-500 text-2xs font-semibold">
              Sign up to start selling in Pairrit' fragrance marketplace. No
              set-up fees. No commitments.
            </p>

            <div className=" gap-1 md:my-4 my-1">
              <div className="w-full">
                <p className=" text-[13px] font-semibold my-1">
                  Type of Seller?
                </p>

                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <>
                          <FormMessage className="text-[10px]" />
                          <RadioCard
                            level={level}
                            value={"Casual"}
                            onOptionSelect={field.onChange}
                          />
                        </>
                      </FormControl>
                    </FormItem>
                  )}
                  name={"type"}
                  control={form.control}
                />
              </div>
            </div>
            <div className="flex w-full gap-3">
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        autoFocus
                        {...field}
                        type="name"
                        placeholder="First Name"
                        className="w-full md:w-[260px] h-12 px-3 mt-1 bg-zinc-100 rounded-[11px] border outline-accentYellow-500 border-slate-400/20"
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"firstName"}
                key={"name"}
                control={form.control}
              />

              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        autoFocus
                        {...field}
                        type="name"
                        placeholder="Last Name"
                        className="w-full md:w-[260px] h-12 px-3 mt-1 bg-zinc-100 rounded-[11px] border outline-accentYellow-500 border-slate-400/20"
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"lastName"}
                key={"name"}
                control={form.control}
              />
            </div>
            <div className="flex w-full gap-3 mt-2">
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        autoFocus
                        {...field}
                        type="tel"
                        placeholder="Phone Number"
                        className="w-full md:w-[260px] h-12 px-3 mt-1 bg-zinc-100 rounded-[11px] border outline-accentYellow-500 border-slate-400/20"
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"number"}
                key={"Phone"}
                control={form.control}
              />

              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        autoFocus
                        {...field}
                        type="email"
                        placeholder="Enter Email"
                        className="w-full md:w-[260px] h-12 px-3 mt-1 bg-zinc-100 rounded-[11px] border outline-accentYellow-500 border-slate-400/20"
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"recipientEmail"}
                key={"email"}
                control={form.control}
              />
            </div>

            {waitLoading ? (
              <Loading />
            ) : (
              <GradientBtn
                type="submit"
                label={"Submit Request"}
                className="mx-auto block mt-4 md:mt-14 md:mb-10 mb-4"
              />
            )}
            <p className="text-center  text-zinc-600 text-xs font-medium">
              We contact back within 24 hours. Please by patient as we verify
              your account.
            </p>
          </form>
        </Form>
      )}
    </>
  );
};

export default JoinWaitList;


const level = [
  {
    label: "Casual",
    value: "Casual",
    text: "10-20",
    icon: "material-symbols:tile-small-outline-rounded",
  },
  {
    label: "Small",
    value: "Small",
    text: "20-30",
    icon: "material-symbols:widget-medium-outline-rounded",
  },
  {
    label: "Medium",
    value: "Medium",
    text: "30-50",
    icon: "material-symbols:tile-large-outline",
  },
  {
    label: "Large",
    value: "Large",
    text: "50+",
    icon: "material-symbols:widget-small-rounded",
  },
];


