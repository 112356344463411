import { useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import BottomNavbar from "../../components/common/BottomNav/BottomNavbar";
import Explore from "../../components/common/Explore";
import Ad from "../../components/common/Explore/ad";
import Navbar from "../../components/common/Navbar";
import NotifyToast from "../../components/common/Notification/NotifyToast";
import GuestOnBoardWrapper from "../../components/common/Onboarding/Guest/guest-ob-wrapper";
import OnBoardWrapper from "../../components/common/Onboarding/NewUser/onboard-wrapper";
import PostWidget from "../../components/common/PostWidget";
import Sidebar from "../../components/common/Sidebar";
import Widget from "../../components/common/Widget";
import User from "../../components/pages/Home/User";
import { cn } from "../../helpers/utils";
import { useOktaAuth } from "@okta/okta-react";
import { NotifiesToast } from "../../components/common/Navbar/NotifiesToast";
import SidebarTop from "../../components/common/Sidebar/SidebarTop";
import Explore2 from "../../components/common/Explore/Explore2";
import Footer from "../../components/pages/Home/Footer";

const MainLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { authState } = useOktaAuth();
  const { pathname } = useLocation();
  const showExplorer = useMemo(() => {
    switch (true) {
      case pathname.includes("/my/"):
        return false;
      case pathname.includes("/user"):
        return false;
      case pathname.includes("browse"):
        return true;
      case pathname.includes("home"):
        return true;
      case pathname.includes("create"):
        return true;
      case pathname.includes("que"):
        return false;
      case pathname.includes("cart"):
        return false;
      case pathname.includes("checkout"):
        return false;
      case pathname.includes("help"):
        return false;
      case pathname.includes("about"):
        return true;
      case pathname.includes("single-gear"):
        return false;
      case pathname.includes("search"):
        return false;
      case pathname.includes("/"):
        return false;
      default:
        return true;
    }
  }, [pathname]);

   const showWidget = useMemo(() => {
     switch (true) {
       case pathname.includes("browse"):
         return false;
       case pathname.includes("create"):
         return false;
       case pathname.includes("que"):
         return false;
       case pathname.includes("cart"):
         return false;
       case pathname.includes("checkout"):
         return false;
       case pathname.includes("single"):
         return false;
       default:
         return true;
     }
   }, [pathname]);

  return (
  
      <div className="">
        <Navbar />
        <SidebarTop />
        {/* <BottomNavbar /> */}
        <OnBoardWrapper className="z-50" />
        <GuestOnBoardWrapper className="z-50" />
        {/* <NotifiesToast /> */}
        <NotifyToast />
        {/* <Widget /> */}
        {showWidget && <PostWidget />}
        {/* <Icons /> */}
        <div className="flex max-md:z-40">
          {showExplorer && (
            <div
              className={cn(
                "!h-fit lg:min-w-[250px]  scrollbar-hide 2xl:max-w-[30px] lg:max-w-[300px] xl:block hidden lg:mt-[9em] lg:ml-8"
              )}
            >
              <Ad />
              <div className=" rounded-lg">
                {/* {authState?.isAuthenticated ? <User isSide /> : null} */}
                <Explore2 />{" "}
              </div>
            </div>
          )}
          <div
            className={cn(
              "container [&>*]:overflow-auto   pb-0 md:[&>*]:h-[calc(100vh-100px)]  [&>*]:pb-6 flex flex-col gap-4 lg:p-6 md:gap-12 bg-white lg:flex-row mt-[10em] md:mt-[11em] lg:mt-[8em] lg:w-[82em] 2xl:w-[92em] 3xl:w-[92em]",
              {
                "lg:w-[84em] 2xl:w-[62em]": showExplorer,
              }
            )}
          >
            <div className="flex-grow pl-0 pb-0 lg:mr-[em] scrollbar-hide">
              <div className="gap-6 ">
                <div
                  className={cn({
                    "w-full  ":
                      !pathname.includes("shop") && !pathname.includes("about"),
                  })}
                >
                  {/* <div className='mb-5 lg:hidden'>
                 <Search />
               </div> */}
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
          {showExplorer && (
            <div
              className={cn(
                "!h-fit lg:min-w-[250px]  scrollbar-hide 2xl:max-w-[30px] lg:max-w-[300px] xl:block hidden lg:mt-[8em] lg:mr-8"
              )}
            >
              <Ad />
              <div className=" rounded-lg">
                {/* {authState?.isAuthenticated ? <User isSide /> : null} */}
                <Explore />{" "}
              </div>
            </div>
          )}
        </div>
        {/* <Footer /> */}
      </div>

  );
};

export default MainLayout;
