import { Icon } from "@iconify/react";
import { ComponentProps, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { cn } from "../../../helpers/utils";
import { useAppSelector } from "../../../redux/hooks";
import Card from "../../common/Card";
import { CardRow } from "./card-row";
import { CartCard } from "./cart-card";

import Loader from "../../common/Loader/loader";
import EmptyCart from "../../common/Error/EmptyCart";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";

type CartListProps = ComponentProps<"div">;

export function CartList({ className, ...props }: CartListProps) {

  const{cartData, fetchLoading, fetchError} = useAppSelector((state) => state.cart);
  const [total, setTotal] = useState(0);
  const user = useAuthUser();

  var cart = JSON.parse(localStorage.getItem("cart") || "[]");
  useEffect(() => {
    if (cartData && cartData.length > 0) {
      setTotal(
        cartData.map((i: any, idx:any) => i.price * i.total + (idx + 1 * 4)).reduce((a: any, b: any) => a + b)
      );
    } else {
      setTotal(0)
    }
  }, [cartData]);
  return (
    <div className={cn(className)} {...props}>
      <Card className="grid md:p-6 p-2.5 grid-cols-[4fr_5fr_0fr_3fr_0fr] md:grid-cols-[1fr_3fr_1fr_1fr_1fr] items-center justify-items-center bg-white gap-1">
        {["Photo", "Description", "Quantity", "Price", "Remove"].map((el) => (
          <p key={el} className="text-sm max-md:hidden font-medium">
            {el}
          </p>
        ))}
        <hr className="col-span-full max-md:hidden my-6 w-full" />
        {fetchLoading ? (
          <Loader />
        ) : cartData?.length != 0 ? (
          cartData.map((el: any, idx: any) => (
            <>
              <CartCard className="" data={el} />
              <CardRow data={el} idx={idx} />
              <hr className="col-span-full my-3 w-full" />
            </>
          ))
        ) : (
          <EmptyCart />
        )}

        <div className="col-span-full h-5" />
        <div className="md:grid grid-cols-4 gap- col-span-full w-full">
          <div className="flex h-10 md:h-12 col-span-3"></div>
          <div className="md:space-y-5 space-y-2.5 max-md:max-w-[175px] max-md:ml-auto max-md:mt-2.5 text-sm">
            <div className="flex items-center  justify-between md:p-5 rounded-md md:border mb-2 text-lg">
              <p className=" text-black text-xs md:text-sm md:font-medium">
                Total
              </p>
              <p className="font-semibold max-md:text-xs">
                ${total.toFixed(2)}
              </p>
            </div>
            <Link
              onClick={() =>
                mixpanel.track("Checkout button clicked ", {
                  email: user ? user["email"] : "",
                })
              }
              to="/checkout"
            >
              <div className="flex gap-0.5">
                <button className="bg-gradient h-10 md:h-12 w-full whitespace-nowrap text-white rounded-l-md">
                  Checkout
                </button>
                <button className="bg-gradient h-10 md:h-12 px-4 w-fit whitespace-nowrap text-white rounded-r-md">
                  <Icon icon={"solar:cart-4-bold"} className="text-2xl" />
                </button>
              </div>
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
}
