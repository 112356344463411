import React, { useState } from "react";
import Register from "../../interfaces/Register";
import { useOktaAuth } from "@okta/okta-react";
import { notifySelect } from "../../redux/features/notify/notifySlice";
import { useAppDispatch } from "../../redux/hooks";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Loading } from "../common/Widget/Loading";
import axios from "axios";
import OktaSignup from "../../okta/OktaSignup";
import TextField from "@mui/material/TextField";

interface RegisterProps {
  inputAssets: Register[];
  title: String;
}

interface FormErrors {
  lastName?: string;
  email?: string;
  password?: string;
  firstName?: string;
}

const validateEmail = (email: string) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email) ? "" : "Invalid email address.";
};

const validateName = (name: string) => {
  return name.length >= 3 && name.length <= 12
    ? ""
    : "Name must be between 3 and 12 characters long.";
};

const validatePassword = (password: string) => {
  const passwordPattern =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,16}$/;
  return passwordPattern.test(password)
    ? ""
    : "Must be 7-15 characters, one number and character.";
};

const SignupA: React.FC<RegisterProps> = ({
  inputAssets,
  title,
}: RegisterProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  const loggingIn = async () => {
    navigate("/login");
    // oktaAuth.signInWithRedirect({ originalUri: location.pathname });
  };
  const onSuccess = (tokens: any) => {
    setUserInfo(tokens.idToken.claims);
    oktaAuth.handleLoginRedirect(tokens);
    mixpanel.track("Logo clicked", {
      email: tokens.idToken.claims.email ? tokens.idToken.claims.email : "",
    });
    if (tokens.idToken.claims.idp == "0oajc6ez0nHFNgjGc5d7") {
      handleIdpSubmit(
        tokens.idToken.claims.name,
        tokens.idToken.claims.email,
        tokens.idToken.claims.preferred_username,
        "randompass",
        true,
        tokens.idToken.claims.idp,
        tokens.idToken.claims.sub
      );
      navigate("/");
    } else {
      navigate("/");
    }
  };

  const onError = (err: any) => {
    console.log("Sign in error:", err);
  };

  const handleIdpSubmit = async (
    fname: any,
    email: any,
    displayname: any,
    password: any,
    isIdpLogin: any,
    idp: any,
    oktaUserId: any
  ) => {
    try {
      const response = await axios.post(
        `https://webservice.pairrit.com/pairrit-ws/api/auth/signup`,
        {
          firstName: fname,
          email: email,
          login: email,
          displayName: displayname,
          password: password,
          isIdpLogin: isIdpLogin,
          idp: idp,
          oktaUserId: oktaUserId,
        }
      );
      dispatch(
        notifySelect({
          title: "Success, Please Login!",
          type: 0,
          open: true,
        })
      );
      loggingIn();
    } catch (err) {}
  };

  const [formData, setFormData] = useState({
    lastName: "",
    firstName: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const finalErrors = {
      firstName: validateName(formData.firstName),
      lastName: validateName(formData.lastName),
      email: validateEmail(formData.email),
      password: validatePassword(formData.password),
    };
    if (
      !finalErrors.firstName &&
      !finalErrors.lastName &&
      !finalErrors.email &&
      !finalErrors.password
    ) {
      try {
        setLoading(true);
        const response = await axios.post(
          `https://webservice.pairrit.com/pairrit-ws/api/auth/signup`,
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            login: formData.email,
            displayName: generateRandomUsername(),
            password: formData.password,
            isIdpLogin: false,
            idp: "OKTA",
            oktaUserId: "",
          }
        );
        setLoading(false);
        dispatch(
          notifySelect({
            title: "Success, Please Login!",
            type: 0,
            open: true,
          })
        );
        mixpanel.alias(formData.email);
      } catch (err: any) {
        setLoading(false);
        if (!err?.response) {
          dispatch(
            notifySelect({
              title: "No Server Response",
              type: 1,
              open: true,
            })
          );
        } else if (err.response?.status === 409) {
          dispatch(
            notifySelect({
              title: "Username or Email Taken",
              type: 1,
              open: true,
            })
          );
        } else {
          dispatch(
            notifySelect({
              title: "Registration Failed",
              type: 1,
              open: true,
            })
          );
        }
      }
    } else {
      setFormErrors(finalErrors);
      dispatch(
        notifySelect({
          title: "Check Inputs",
          type: 1,
          open: true,
        })
      );
    }
  };

  return (
    <div
      style={
        {
          // backgroundImage: `url("/assets/imgs/okta/login_bg_image.jpeg")`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
        }
      }
      id=""
      className="tablet:hidden md:mt-24 md:border border-solid border-zinc-300 rounded-lg lg:px-4"
    >
      <div className="bg-white p-4 w-screen lg:w-[25em] mx-auto text-gray-700">
        <Link
          onClick={() => {
            mixpanel.track("Logo clicked from auth");
          }}
          className=""
          to={"/"}
        >
          <img
            src="/assets/imgs/okta/login_logo.png"
            className="w-26 h-10 mx-auto mt-4"
            alt=""
          />
        </Link>
        <hr className="my-8 w-full bg-black"></hr>
        <section>
          <div className="mt-8">
            <h1 className="text-sm mb-4 text-center text-gray-700">{title}</h1>
            {/* <p className="text-2xs mb-4 text-center">
              To like or purchase an item, or to chat with the seller, please
              create your account.
            </p> */}
          </div>
        </section>
        <OktaSignup onSuccess={onSuccess} onError={onError} />

        {/* <button
          type="button"
          className="border mx-auto h-12 w-[18em] bg-[#d43722] text-white border-solid border-zinc-300 flex p-2 items-center rounded place-content-center gap-2 px-3 py-1.5"
        >
          <Icon
            icon="mdi:google-plus"
            className="text-2xl h-6 justify-center text-white"
          />
          <span className="justify-center font-semibold">
            Continue with Google
          </span>
        </button> */}
        <p className="text-sm text-center mt-2">OR</p>
        <form
          className="mt-2  z-0 justify-items-center"
          onSubmit={handleSubmit}
        >
          {inputAssets.map((details, index) => (
            <div className="mt-3 " key={details.name}>
              {/* <p className="font-medium text-xs mb-1 ">{details.placeholder}</p> */}
              <TextField
                name={details.name}
                error={false}
                label={details.placeholder}
                placeholder={details.placeholder}
                className="w-full place-self-center bg-white rounded  pl-1 py-2 border border-gray-200"
                type={details.type}
                helperText={formErrors[details.name as keyof FormErrors]}
                onChange={handleInputChange}
              />
              {/* {formErrors[details.name as keyof FormErrors] && (
                <p className="text-red-500 text-2xs ml-1">
                  *{formErrors[details.name as keyof FormErrors]}
                </p>
              )} */}
            </div>
          ))}
          {loading ? (
            <Loading />
          ) : (
            <button
              type="submit"
              className="mt-5 border mx-auto h-12 w-[18em] bg-gradient-to-l from-[#007dc1] to-[#0073b2] text-white border-solid border-zinc-300 flex p-2 items-center rounded place-content-center gap-2 px-3 py-1.5"
            >
              Continue
            </button>
          )}
          {/* <p className="text-[#B5B5B5] my-5 text-center">or continue with</p>
          <AuthBtns /> */}
          <div className=" max-max-w-72 text-center m-auto mt-6">
            <p className="text-xs md:sm">
              By continuing, you are setting up a Pairrit account and agree to
              our
              <span className="text-[#0079D3] cursor-pointer mx-1">
                User Agreement
              </span>
              and
              <span className="text-[#0079D3] cursor-pointer mx-1">
                Privacy Policy.
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupA;

export function generateRandomUsername() {
  // Arrays of adjectives and nouns
  const adjectives = [
    "Quick",
    "Happy",
    "Bright",
    "Brave",
    "Quiet",
    "Fuzzy",
    "Clever",
    "Swift",
  ];
  const nouns = [
    "Frag",
    "Scent",
    "Style",
    "Shop",
    "User",
    "Fan",
    "Member",
    "Agent",
    "Art",
    "Pro",
  ];

  // Generate random index for adjective and noun
  const randomAdjective =
    adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

  // Generate a random number to add uniqueness
  const randomNumber = Math.floor(Math.random() * 1000);

  // Combine them to form a username
  const username = `${randomAdjective}${randomNoun}${randomNumber}`;

  return username;
}
