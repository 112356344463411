import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import mixpanel from "mixpanel-browser";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { countries } from "../../../../data/states";
import useAuthUser from "../../../../hooks/getAuthUser";
import { updateProfileProperties } from "../../../../redux/features/user/updateProfileSlice";
import { Form, FormControl, FormField, FormItem } from "../../../ui/form";
import Select from "../../Selectors/select2";


const formSchema = z.object({
  background: z.string(),
  location: z.string(),
});

const OnBoardingP2 = ({ setValue }: { setValue: (value: string) => void }) => {
  const dispatch = useDispatch();
  const user = useAuthUser();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  function onSubmit(values: any) {
    dispatch(updateProfileProperties(values));
    mixpanel.track("Member card (Step 3 Complete)", {
      ...values,
      email: user ? user["email"] : "",
    });
    setValue("Last");
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <img
          src="/assets/imgs/common/logo.png"
          className="w-16 mx-auto"
          alt=""
        />
        <p className="text-center text-xl font-extrabold text-gray-100">
          Member Card
        </p>
        <div className="flex  sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-5 mt-3">
          <div>
            <h4 className="text-semibold text-[17px] text-white">Background</h4>
          </div>
          <FormField
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select
                    light
                    uniqueKey="background-select"
                    onChange={field.onChange}
                    defaultValue={"Select"}
                    options={[
                      { label: "Perfumer", value: "PERFUMER" },
                      {
                        label: "Content Creator",
                        value: "CONTENT_CREATOR",
                      },
                      { label: "Retail Store", value: "RETAIL_STORE" },
                      { label: "Collector", value: "COLLECTOR" },
                      { label: "Seller", value: "SELLER" },
                      { label: "Casual", value: "CASUAL" },
                      { label: "Hobbiest", value: "HOBBIEST" },
                    ]}
                    placeholder={"background"}
                  />
                </FormControl>
              </FormItem>
            )}
            name={"background"}
            control={form.control}
          />
        </div>
        <hr className=" my-3" />
        <div className="flex  sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-5 mt-3">
          <div>
            <h4 className="text-semibold text-[17px] text-white">Location</h4>
          </div>
          <FormField
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Select
                    uniqueKey="location-select"
                    onChange={field.onChange}
                    defaultValue={"Select"}
                    options={[
                      { label: "USA", value: "USA" },
                      { label: "United Kingdom", value: "UK" },
                      { label: "India", value: "INDIA" },
                      { label: "Australia", value: "AUS" },
                      { label: "Germany", value: "GERM" },
                      { label: "Brazil", value: "BRZL" },
                      { label: "China", value: "CHINA" },
                      { label: "France", value: "FRAN" },
                      { label: "Italy", value: "ITALY" },
                      { label: "Mexico", value: "MEXI" },
                      { label: "Canada", value: "CAN" },
                      { label: "Netherlands", value: "NETH" },
                      { label: "Sweden", value: "SWED" },
                    ]}
                    placeholder={"location"}
                  />
                </FormControl>
              </FormItem>
            )}
            name={"location"}
            control={form.control}
          />
        </div>
        <footer className="my-5 flex justify-center">
          <button
            type="submit"
            className="flex items-baseline gap-2 rounded-lg bg-gradient-to-l from-red-500  to-yellow-500  px-4 py-2.5 text-xl font-bold text-white hover:opacity-75 border-2 border-white"
          >
            <span>Next</span>
            <Icon
              icon="solar:play-bold-duotone"
              className="text-xl text-white h-4"
            />
          </button>
        </footer>
      </form>
    </Form>
  );
};

export default OnBoardingP2;
