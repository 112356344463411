import TopGearRooms from "./TopGearRooms";
import Reviews from "./Reviews";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ExploreMenu from "../../pages/Community/Community-Page/explore-menu";
import Card from "../Card";
import { useMemo } from "react";
import AppDownload from "./AppDownload";
import GradientBtn from "../../buttons/GradientBtn";
import Profile from "../../pages/Create-Post/profile";
import mixpanel from "mixpanel-browser";
import Steps from "../../pages/How-To/Steps";


const links = [
  "About Us",
  "Help Center",
  "Privacy and Terms",
];

const Explore2 = () => {
  const { pathname } = useLocation();
 
  const content = useMemo(() => {
    switch (true) {
      case pathname.includes("create"):
        return <Steps data={data} />;
      case pathname === "/":
        return (
          <SideCard/>
        );
      case pathname.includes("browse/s/"):
        return <ExploreMenu />;
      default:
        return extra;
    }
  }, [pathname]);
  const Comp = pathname.includes("create") ? "div" : "div";
  return (
    <Comp className="rounded lg:h-full lg:mt-0  shadow-lg p-1">
      {content}
    </Comp>
  );
};

export default Explore2;

const extra = (
  <>
    {/* <AppDownload/> */}
    <div>
      <img
        className="w-full h-full mx-auto object-cover rounded-lg"
        src="/assets/imgs/home/banner-7.png"
        alt="ad"
      />
    </div>

    <div className="my-3">
      <hr />
    </div>
    <div>
      <Link
        to="/create/sell"
        onClick={() => {
          mixpanel.track("RP Sell now button button click");
        }}
      >
        <GradientBtn className="w-full bg-green" lg label="Invite Friends" />
      </Link>
    </div>
    <div className="my-3">
      <hr />
    </div>

    <div className="mt-[10px] flex items-center justify-center gap-2">
      <div className="text-[26px] text-[#1D1929] font-bold">Earn</div>
      <div className="font-medium text-accentGray-700">100 FlexPoints</div>
    </div>
    <div className="font-medium text-center">More FlexPoints = More Savings</div>

    <div className="my-3">
      <hr />
    </div>
    <div className="flex flex-wrap justify-center gap-2">
      {links.map((el, idx) => {
        return (
          <Link
            className="text-accentGray-700 text-xs"
            to="/"
            key={"footer-link" + idx}
          >
            {el}
          </Link>
        );
      })}
    </div>
  </>
);

const SideCard= () => {
  return (
    <div>
      <img
        className="w-full h-full mx-auto object-cover rounded"
        src="/assets/imgs/home/banner-7.png"
        alt="ad"
      />
      <div className="my-3">
        <hr />
      </div>
      <div>
        <Link to="/create/sell">
          <GradientBtn className="w-full" lg label="Sell Fragrance" />
        </Link>
      </div>
      <div className="my-3">
        <hr />
      </div>
      <img
        className="w-full h-full mx-auto object-cover rounded"
        src="/assets/imgs/home/banner-9.png"
        alt="ad"
      />
    </div>
  );
};


const data = [
  {
    title: "Open Shop",
    desc: "Setup payout method through stripe",
    subTitle: "5 mins ~",
  },
  {
    title: "Product Selection",
    desc: "Select the 'Category' and 'Type' regarding your post.",
    subTitle: "1 mins ~",
  },
  {
    title: "Description",
    desc: "Fill out the items descriptions to match the listing",
    subTitle: "1 mins ~",
  },
  {
    title: "Shipping",
    desc: "Prepaid? ",
    subTitle: "1 mins ~",
  },
  {
    title: "Price",
    desc: "Experience worry-free transactions with our robust and secure platform.",
    subTitle: "1 mins ~",
  },
  {
    title: "Live Listing",
    desc: "Elevate your music career with our unwavering live musician support.",
    subTitle: "Live",
  },
];