import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { brandData } from "../../data/brands";
import { cn } from "../../helpers/utils";
import { fetchFragsByBrand } from "../../redux/features/market/marketplaceSlice";
import { brandSelect } from "../../redux/features/post/postSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";


interface SelectProps {
  ref?: any;
  className?: string;
  defaultValue?: string;
}

const SearchSelect = ({
  ref,
  className,
  defaultValue
}: SelectProps) => {
  const brand = useAppSelector((state) => state.post.brand);
  const [selectedPerson, setSelectedPerson] = useState(brand);
    const dispatch = useAppDispatch();
    const [isMenuActive, setIsMenuActive] = useState(false);
    const onSelect = (el: any) => {
    setSelectedPerson(el.value);
 
    if (ref && ref.onChange) {
     dispatch(fetchFragsByBrand(selectedPerson));
    }
    };
  
  useEffect(() => {
      dispatch(brandSelect(selectedPerson));
      dispatch(fetchFragsByBrand(selectedPerson));
    }, [selectedPerson]);
   
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? brandData
      : brandData.filter((item) => {
          return item.title.toLowerCase().includes(query.toLowerCase());
      });
  

  return (
    <Combobox value={selectedPerson} onChange={setSelectedPerson}>
      <Combobox.Button className={"w-full"}>
        <Combobox.Input
          onChange={(event) => setQuery(event.target.value)}
          className={clsx(
            "bg-zinc-100 p-3 w-full text-sm border border-solid border-accentGray-600 rounded-lg cursor-pointer relative shadow-lg",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
          )}
        />
      </Combobox.Button>
      <Combobox.Options className="bg-white shadow-lg rounded-lg absolute w-[21em] z-40">
        {filteredPeople.slice(0, 8).map((item) => (
          <Combobox.Option
            key={item.value}
            value={item.title}
            className="relative w-full flex md:gap-2 gap-1 items-center p-3 border-b border-solid border-accentGray-600 hover:bg-accentGray-30 transition-colors"
          >
            {item.title}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};

export default SearchSelect;

