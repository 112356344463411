import React from "react";
import Card from "../../common/Card";
import ComingSoon from "../../common/Error/ComingSoon";
import Hero from "./Hero";
import Marketplace from "./Marketplace";
import Threads from "./Threads";

const About = () => {
  return (
    <Card className="bg-white mb-[9em]">
      <Hero />
      <Marketplace />
      <Threads />
    </Card>
  );
};

export default About;
