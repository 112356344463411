import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { returnRequest } from "../../../../redux/features/userTransactions/purchases/userPurchasesSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import GradientBtn from "../../../buttons/GradientBtn";
import DialogHeader from "../../../common/dialog-header";
import Select from "../../../common/select";
import { Loading } from "../../../common/Widget/Loading";
import { TextField } from "../../../text-field";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../ui/form";

const formSchema = z.object({
  purchaseId: z.string(),
  reason: z.string(),
  message: z.string(),
});

interface Props {
  id: any;
}

const ReturnForm: React.FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { returnLoading, returnError } = useAppSelector(
    (state) => state.userPurchases
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
    dispatch(returnRequest(values))
      .then(() =>
        dispatch(
          notifySelect({
            title: "Request Sent!",
            type: 0,
            open: true,
          })
        )
      )
      .finally(() => window.location.reload());
  }

  useEffect(() => {
    form.setValue("purchaseId", id);
    if (returnError) {
      dispatch(
        notifySelect({
          title: "Error Sending",
          type: 1,
          open: true,
        })
      );
    }
  }, [returnError, id]);
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogHeader />
        <div className="mt-3 ">
          <h1 className="text-xl mb-4 text-center xs:w-fit m-auto">
            Return Request
          </h1>
          <div>
            <div className="my-2">
              <h1 className="text-base mb-1  xs:w-fit m-auto">
                Why are you returning this item?
              </h1>
              <div className="flex gap-2 ">
                <FormField
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormControl>
                        <Select
                          uniqueKey="reason-selector"
                          onOptionSelect={field.onChange}
                          defaultValue={field.value}
                          options={sortOptions}
                          label="Reason"
                          className=" bg-zinc-100 w-full"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                  name={"reason"}
                  control={form.control}
                />
              </div>
            </div>
            <div className="my-2">
              <p className=" text-[11px] font-semibold my-1">
                Add Details
                <span className="text-red-600">*</span>
              </p>
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <TextField {...field} onOptionSelect={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
                name={"message"}
                control={form.control}
              />
            </div>
            {returnLoading ? <Loading /> : <GradientBtn
              type="submit"
              label={"Submit Request"}
              className={"mx-auto mt-5"}
            />}
          </div>
        </div>
      </form>
    </Form>
  );
};

export default ReturnForm;

const sortOptions = [
  {
    label: "Arrived Damaged",
    value: "Damaged",
  },
  {
    label: "Don't Like it",
    value: "Dislike",
  },
  {
    label: "Item is defective",
    value: "Defective",
  },
  {
    label: "Received wrong item",
    value: "Wrong",
  },
  {
    label: "Doesn't match description",
    value: "Wrong Description",
  },
  {
    label: "Order by mistake",
    value: "Mistake",
  },
];
