import { HTMLAttributes, useEffect, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Chip } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "swiper/css/zoom";
import {
  FreeMode,
  Navigation,
  Thumbs,
  Zoom,
  EffectFade,
  Pagination,
} from "swiper";
import { cn } from "../../../../helpers/utils";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Card from "../../../common/Card";
import { Icon } from "@iconify/react";
import InfoSection from "./info-section";

const GearGallery = ({
  className,
  imgs,
  item,
  md,
  lg,
  ...props
}: {
  className?: string;
  imgs?: any;
  item?: any;
  md?: any;
  lg?: any;
} & HTMLAttributes<HTMLDivElement>) => {
  const dispatch = useAppDispatch();

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>();

   function getRandomNumber() {
     return Math.floor(Math.random() * (35 - 18 + 1)) + 18;
   }

  return (
    <>
      <div
        {...props}
        className={cn(
          "w-full overflow-hidden border-none  select-none rounded-lg bg-white shadow-lg shadow-slate-100/50 max-md:mb-2",
          {}
        )}
      >
        <Swiper
          loop={true}
          effect={"fade"}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination, FreeMode, Thumbs, Zoom]}
          spaceBetween={10}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          zoom={true}
          className={`overflow-hidden w-full h-[15em]  md:h-[30em]`}
        >
          {imgs.map((_: any) => (
            <SwiperSlide
              className="rounded swiper-zoom-container h-full p-1 w-full"
              key={_}
            >
              <Chip
                icon={
                  <Icon className={"text-2xs"} icon={"hugeicons:sale-tag-01"} />
                }
                sx={{
                  bgcolor: "background.paper",
                  fontSize: "10px",
                }}
                className="text-2xs absolute top-1 left-4"
                color="error"
                size="small"
                label={`${getRandomNumber()}+ Sold`}
                variant="outlined"
              />
              <Icon
                icon={"solar:heart-linear"}
                className="text-3xl absolute top-1 right-4"
              />
              <img alt="" className="object-cover rounded" src={`${_}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        {item ? <InfoSection className="hidden md:block" info={item} /> : null}
      </div>
    </>
  );
};

export default GearGallery;
