import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import axios from "axios";

// Define a type for the slice state
interface FeedState {
  homeCategory: string;
  filterType: string;
  loading: boolean;
  error: string | null;
  homeFeedData: any;
}

// Define the initial state using that type
const initialState: FeedState = {
  homeCategory: "General",
  filterType: "New",
  loading: true,
  error: null,
  homeFeedData: null,
};

export const fetchPosts = createAsyncThunk(
  "home/fetchPosts",
  async (page: number) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/posts/${page}`
    );
    return response.data;
  }
);

export const fetchPostsByType = createAsyncThunk(
  "home/fetchPostsByType",
  async (name: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/posts/genre/${name}`
    );
    return response.data;
  }
);

export const likeAPost = createAsyncThunk(
  "home/likePost",
  async ({ postId, userId }: { postId: string; userId: string }) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/posts/${postId}/like/${userId}`
    );
    return response.status;
  }
);

export const removeLike = createAsyncThunk(
  "home/likePost",
  async ({ postId, userId }: { postId: string; userId: string }) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/posts/${postId}/remove-like/${userId}`
    );
    return response.status;
  }
);

export const homeFeedSlice = createSlice({
  name: "homeFeed",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    homeFeed: (state, action: PayloadAction<string>) => {
      state.filterType = action.payload;
    },
    categorySelect: (state, action: PayloadAction<string>) => {
      state.homeCategory = action.payload;
    },
    refreshFeed: (state, action: PayloadAction<string>) => {
      state.filterType = action.payload;
    },
    filterFeed: (state, action: PayloadAction<string>) => {
      state.filterType = action.payload;
    },
    filterFeedByCategory: (state, action: PayloadAction<string>) => {
      state.filterType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.homeFeedData = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchPostsByType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPostsByType.fulfilled, (state, action) => {
        state.loading = false;
        state.homeFeedData = action.payload;
      })
      .addCase(fetchPostsByType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const { homeFeed, refreshFeed, filterFeed, categorySelect } =
  homeFeedSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectFeed = (state: RootState) => state.homeFeed;

export default homeFeedSlice.reducer;
