import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

export default function ContactCard() {
  return (
    <div className="grid w-full gap-1 grid-cols-2 ">
      {action.map((el) => (
        <div className="flex flex-col items-center gap-1 px-4 py-5 bg-white rounded-xl shadow-main">
          <span>
            <Icon icon={el.icon} className="h-8 w-8 text-orange-400" />
          </span>
          <p className="text-base md:text-xl font-extrabold text-dark-grey-900">
            {el.label}
          </p>

          <Link
            className="text-xs md:text-sm font-bold text-purple-blue-500"
            to={el.to}
          >
            {el.des}
          </Link>
          <p className="text-xs md:text-sm text-dark-grey-700">{el.location}</p>
        </div>
      ))}
    </div>
  );
}

const action = [
  {
    label: "Email",
    title: "Contact us at",
    des: "info@pairrit.com",
    icon: "line-md:email-twotone",
    to: "mailto: info@pairrit.com",
    location: "24/7",
  },
  {
    label: "Phone",
    title: "Reach out at",
    des: "1-800-245-8000",
    icon: "solar:phone-bold-duotone",
    to: "tel:+516-486-5135",
    location: "10AM-8PM EST",
  },
];
