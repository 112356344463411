import React from "react";
import { inputAssets } from "./inputs";
import { useAppSelector } from "../../redux/hooks";
import { Link } from "react-router-dom";
import SignupB from "./SignupB";
import mixpanel from "mixpanel-browser";

const LoginForm = () => {
  return (
    <div className=" grid-cols-12 grid gap-10 lg:h-screen">
      <div className="bg-gray-200 hidden lg:block lg:col-span-4 ">
        <Link
          onClick={() => {
            mixpanel.track("Logo clicked from auth");
          }}
          className=""
          to={"/"}
        >
          <img
            src="/assets/imgs/common/logo-2.png"
            className="w-22 h-16 mt-10 mx-auto"
            alt="logo"
          />
          <h2 className="text-xl text-center">
            Making Community Shopping Fun!
          </h2>
        </Link>

        <img
          className=" h-[25em] w-full object-cover bg-transparent mt-24 mx-auto "
          src="/assets/imgs/home/fragVerify1.png"
          alt=""
        />
      </div>
      <div className="hidden md:block md:col-span-2"></div>
      <div className="col-span-full lg:col-span-4 lg:w-full w-screen md:w-[30em] md:mx-auto">
        <section>
          <div className="mt-8 md:mt-0 lg:mt-8">
            <p className="text-xs md:text-sm text-center xs:w-fit  m-auto">
              Don't have an account?
              <Link
                to="/signup"
                className="ml-1 underline font-bold text-orange-600 cursor-pointer"
              >
                Sign up!
              </Link>
            </p>
          </div>
        </section>
        <SignupB inputAssets={inputAssets} title="Log in to Pairrit" />
      </div>
    </div>
  );
};

export default LoginForm;
