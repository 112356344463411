import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface GearState {
  loading: boolean;
  error: string | null;
  imgData: any;
}

// Define the initial state using that type
const initialState: GearState = {
  loading: true,
  error: null,
  imgData: null,
};

export const fetchImgs = createAsyncThunk("images/fetch", async (imgs: any) => {
  const response = await axios.get(
    `https://webservice.pairrit.com/pairrit-ws/api/getsignedurl?filenames=${imgs}`
  );
  return response.data;
});

export const imagesSlice = createSlice({
  name: "images",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImgs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchImgs.fulfilled, (state, action) => {
        state.loading = false;
        state.imgData = action.payload.data;
      })
      .addCase(fetchImgs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const {} = imagesSlice.actions;

export default imagesSlice.reducer;
