import React, { useEffect } from "react";
import GearRoomProfile from "../../My-Gear-Room/profile";
import Posts from "../../Home/Posts";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Loader from "../../../common/Loader/loader";
import { fetchPosts } from "../../../../redux/features/userThreads/userThreadsSlice";
import { useParams } from "react-router-dom";
import Card from "../../../common/Card";
import NotAdded from "../../../common/Error/NotAdded";
import Skeleton from "@mui/material/Skeleton";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";

const MyThreads = () => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { userFeed, loading, error } = useAppSelector(
    (state) => state.userThreads
  );

  useEffect(() => {
    dispatch(fetchPosts(userId));
    mixpanel.track("Threads page view", { email: user ? user["email"] : "" });
  }, [userId]);
  return (
    <div>
      {loading ? (
        <Card className="bg-white mt-4">
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={350}
            height={48}
          />
        </Card>
      ) : userFeed ? (
        <Posts posts={userFeed} />
      ) : (
        <Card className="bg-white shadow-lg w-full ">
          <NotAdded
            m1="No Post"
            m2="0 Threads Created"
            m3="Start Posting to earn FlexPoints"
          />
        </Card>
      )}
    </div>
  );
};

export default MyThreads;
