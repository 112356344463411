import { zodResolver } from "@hookform/resolvers/zod";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import useAuthUser from "../../../../hooks/getAuthUser";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { addNewListingProperties } from "../../../../redux/features/post/postSlice";
import { useAppSelector } from "../../../../redux/hooks";
import GreyBtn2 from "../../../buttons/GreyBtn2";
import YellowBtn from "../../../buttons/YellowBtn/YellowBtn";
import UploaderMulti from "../../../common/uploaderMulti";
import {
  Form,
} from "../../../ui/form";
import { useOktaAuth } from "@okta/okta-react";
import ListFlags from "../../Home/Flags/ListFlags";
import SizeSelector from "./SizeSelector";


export default function Photos({
  setValue,
}: {
  setValue: (value: string) => void;
  }) {
  const { authState } = useOktaAuth();
  
  const post = useAppSelector((state) => state.post.newListing);
  const [usage, setUsage] = useState(post.usage);
    const { profileData, loading } = useAppSelector(
      (state) => state.userProfile
    );
  const dispatch = useDispatch();
  const user = useAuthUser();
 
  const [img, setImg] = useState<any>([]);
  const [picPreview, setPicPreview] = useState<any>(post.pics);
  const formSchema = z.object({
    images: z.any(),
    pics: z.any(),
    usage: z.number().default(post.usage),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
    if (picPreview.length >= 1) {
      dispatch(addNewListingProperties(values));
      mixpanel.track("Sell product (Step 2 Completed)", {
        ...values,
        email: user ? user["email"] : "",
      });
      setValue("Pricing");
    } else {
      dispatch(
        notifySelect({
          title: "No Imgs Added",
          type: 1,
          open: true,
        })
      );
    }
  }
   const uploadPicture = (e: any) => {
     setImg(e);
     let pics = [];
     for (var val of e) {
       pics.push(URL.createObjectURL(val));
     }
     setPicPreview(pics);
   };

  useEffect(() => { 
   form.setValue("usage", usage);
    form.setValue("images", img);
    form.setValue("pics", picPreview);
  }, [img, picPreview]);
  
  // if (!profileData.detailsSubmitted) {
  //   return <ListFlags shop />;
  // }

   if (post.condition === "NEW") {
     setValue("Pricing");
   }


    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <p className="font-bold leading-tight mb-5">Sell Your Fragrance</p>
          <p className="text-sm">
            <span className="font-semibold ">
              Upload photos of your product{" "}
            </span>
            <span className="font-semibold text-red-600 ">*</span>
          </p>
          <ul className="list-disc list-inside font-semibold text-2xs mb-4">
            <li> Use original photos.</li>
            <li>
              Screenshots or pictures from other sites, sellers or manufacturers
              are not allowed.
            </li>
            <li>Min 1 Images. Max 4 Images.</li>
          </ul>
          {picPreview.length > 0 && (
            <div
              className="flex grid grid-cols-4 gap-2 my-4  relative grow md:h-[130px]"
              key={1}
              role="img"
            >
              {" "}
              {picPreview.slice(0, 4).map((_: any, idx: any) => (
                <img
                  className="object-cover w-full h-full lg:h-36 shadow-md rounded-lg "
                  src={_}
                  alt="product"
                />
              ))}
            </div>
          )}
          <UploaderMulti onFileSelected={(val: any) => uploadPicture(val)} />

          <SizeSelector
            value={usage}
            sell
            selection={[
              {
                label: "FULL",
                size: 100,
              },
              {
                label: "BARELY",
                size: 99,
              },
              {
                label: "A BIT",
                size: 95,
              },
              {
                label: "SOME",
                size: 90,
              },
            ]}
            setValue={setUsage}
          />

          <div className="flex gap-5">
            <GreyBtn2
              onClick={() => setValue("Information")}
              className="w-32 my-8 text-sm"
              label={"Back"}
            />
            <YellowBtn
              type="submit"
              label={"Continue"}
              className="px-8 my-8 w-fit text-sm"
            />
          </div>
        </form>
      </Form>
    );
}

 





