import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentProps, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { addRule } from "../../../redux/features/communityMod/settings/modSettingsSlice";
import { notifySelect } from "../../../redux/features/notify/notifySlice";
import { useAppSelector } from "../../../redux/hooks";
import { AppDispatch } from "../../../redux/store";
import GradientBtn from "../../buttons/GradientBtn/GradientBtn";
import RedOutlineBtn from "../../buttons/RedOutlineBtn";
import { Input } from "../../common/input";
import { Loading } from "../../common/Widget/Loading";
import { TextField } from "../../text-field";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../ui/form";

const formSchema = z.object({
  userId: z.string(),
  name: z.string(),
  communityId: z.string(),
  rule: z.string(),
});

type AddRuleProps = ComponentProps<"div"> & { editMode?: boolean; commId: any };

export function AddRule({
  className,
  commId,
  editMode,
  ...props
}: AddRuleProps) {
  const { userId } = useAppSelector((state) => state.userProfile);
  const { id } = useParams();
  const { ruleLoading, ruleError } = useAppSelector(
    (state) => state.modSettings
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  const dispatch = useDispatch<AppDispatch>();
  function onSubmit(values: any) {
    dispatch(addRule(values)).finally(() => window.location.reload());
    dispatch(
      notifySelect({
        title: "Rule Added!",
        type: 0,
        open: true,
      })
    );
  }
  useEffect(() => {
    form.setValue("userId", userId);
    form.setValue("name", id);
    form.setValue("communityId", commId);
    if (ruleError) {
      dispatch(
        notifySelect({
          title: "Error Adding",
          type: 1,
          open: true,
        })
      );
    }
  }, [ruleError, userId, commId, id]);
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className="text-zinc-900 absolute top-3 font-medium">
          {editMode ? "Edit" : "Add"} Rule
        </p>
        <hr className="my-6" />
        <p className="text-zinc-900 font-medium">Rule</p>
        <FormField
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input max={60} {...field} defaultValue={""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
          name={"rule"}
          control={form.control}
        />
        <p className="text-neutral-500 text-xs my-1">60 Characters Max</p>
        {/* <Privacy level={level} className="my-2 gap-2" /> */}
        <br />
        <div className="-mx-6 p-3.5 mt-10 -mb-6 flex items-center gap-2.5 bg-gray-100 ">
          {editMode && (
            <RedOutlineBtn className="rounded-full h-9 min-w-fit">
              Delete
            </RedOutlineBtn>
          )}
          <div className="grow" />

          {ruleLoading ? (
            <Loading />
          ) : (
            <GradientBtn
              short
              label={editMode ? "Update" : "Submit Rule"}
              className="!rounded-full !font-bold !text-sm !px-[17px]"
            />
          )}
        </div>
      </form>
    </Form>
  );
}

const level = [
  {
    label: "Posts & Comments ",
  },
  {
    label: "Posts only",
  },
  {
    label: "Comments only",
  },
];
