import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import useAuthUser from "../../../../hooks/getAuthUser";
import { addNewListingProperties } from "../../../../redux/features/post/postSlice";
import { useAppSelector } from "../../../../redux/hooks";
import GreyBtn2 from "../../../buttons/GreyBtn2";
import YellowBtn from "../../../buttons/YellowBtn";
import DialogWrapper from "../../../common/dialog-wrapper";
import { Form } from "../../../ui/form";
import PricingFees from "./pricing-fees";

export default function Pricing({
  setValue,
}: {
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  const dispatch = useDispatch();
  const user = useAuthUser();
  const post = useAppSelector((state) => state.post.newListing);
  const formSchema = z.object({
    price: z
      .number()
      .lte(999, { message: "too much dip there" })
      .default(post.price),
    isAcceptOffers: z.boolean().optional().default(post.isAcceptOffers),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
    form.setValue("isAcceptOffers", isValueA);
    dispatch(addNewListingProperties(values));
    mixpanel.track("Sell product (Step 4 Completed)", {
      ...values,
      email: user ? user["email"] : "",
    });
    setValue("Review");
  }

  const maxLengthCheck = (object:any) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  const [isValueA, setIsValueA] = useState(post.isAcceptOffers);
  const [sellPrice, setSellPrice] = useState(post.price);
  const [priceError, setPriceError] = useState(false);

  useEffect(() => {
    form.setValue("price", sellPrice);
    form.setValue("isAcceptOffers", false);
  }, [sellPrice]);
  const onPaymentAmountChanged = (e: any) => {
    const inputNumber = parseFloat(e.target.value); //parseFloat if you have decimals
    if (Number.isNaN(inputNumber)) {

    } else if (inputNumber > 0.99 && inputNumber < 5000) {
      setPriceError(false);
      setSellPrice(inputNumber);
    } else {
      setPriceError(true);
   
    }
  };
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <p className="font-semibold leading-tight">Sell Your Fragrance</p>
        <div className="flex items-center text-xs font-medium gap-2.5">
          <p>Your Listings</p>{" "}
          <Icon icon={"ri:arrow-right-s-line"} className="text-xs" />
          <p>{post.modal}</p>
        </div>
        <p className="font-semibold  mb-2.5">Pricing </p>
        <div>
          <label htmlFor="price">
            <span className="text-[11px] font-semibold mb-1">Price</span>
            <span className="text-red-600 text-[11px] font-semibold">*</span>
          </label>
          <div>
            <input
              id="price"
              defaultValue={post["price"]}
              max="999"
              min="3"
              maxLength={6}
              onInput={(x: any) => maxLengthCheck(x)}
              step=".01"
              name="price"
              type="number"
              onInvalid={(e: any) => {
                e.target.setCustomValidity("Be For Real 🧐 ($3-$999)");
              }}
              onChange={onPaymentAmountChanged}
              className="pl-3.5 w-1/2 pt-3 pb-[13px]   placeholder:text-neutral-400 text-sm font-medium bg-stone-50 rounded-md border border-slate-400 h-10"
            />
          </div>
          <p className="text-[10px] py-0.5">Price</p>
        </div>
        <div className="grid grid-cols-[3fr_5fr] text-sm gap-x-3 py-2 gap-y-2 ">
          <>
            <DialogWrapper className="z-50" content={<PricingFees />}>
              <div className="flex items-center gap-1">
                <p>Savings</p> <Icon icon={"ri:question-line"} />
              </div>
            </DialogWrapper>
            <p>${(Math.round(sellPrice * 0.05 * 100) / 100).toFixed(2)}</p>
          </>
          <>
            <DialogWrapper className="z-50" content={<PricingFees />}>
              <div className="flex items-center gap-1">
                <p>Processing fee</p> <Icon icon={"ri:question-line"} />
              </div>
            </DialogWrapper>
            <p>${(Math.round(sellPrice * 0.03 * 100) / 100).toFixed(2)}</p>
          </>
          <>
            <DialogWrapper className="z-50" content={<PricingFees />}>
              <div className="flex items-center gap-1">
                <p>Est. Earnings</p> <Icon icon={"ri:question-line"} />
              </div>
            </DialogWrapper>
            <p>
              {priceError
                ? "Enter 1-5000"
                : `$${(
                    sellPrice -
                    Math.round(sellPrice * 0.08 * 100) / 100
                  ).toFixed(2)}`}
            </p>
          </>
        </div>

        {/* <div className="mt-3">
          <p className="font-semibold underline">Accept Offers?</p>
          <div className="text-2xs mb-3 font-semibold">
            You can update later <span className="text-red-600 ">*</span>
          </div>
          <div className="space-y-2">
            <div className="flex items-start gap-2.5  px-2">
              <input
                type="radio"
                defaultChecked={isValueA}
                name="isAcceptOffers"
                className="mt-1"
                id="checkbox2"
                onChange={(v) => setIsValueA(true)}
              />
              <label htmlFor="checkbox" className="flex gap-2">
                <p className="text-sm font-bold leading-tight ">
                  Yes, Accept Offers
                </p>
                <span className="text-blue-700 bg-blue-100 rounded-sm py-0.5 px-1.5 text-[8px] font-normal">
                  Items sell faster
                </span>
              </label>
            </div>
            <div className="flex items-start gap-2.5 px-2">
              <input
                type="radio"
                name="isAcceptOffers"
                defaultChecked={!isValueA}
                className="mt-1"
                id="checkbox2"
                onChange={(v) => setIsValueA(false)}
              />
              <label htmlFor="checkbox2" className="">
                <p className="text-sm font-bold leading-tight ">No Offers</p>
              </label>
            </div>
          </div>
        </div> */}

        <div className="flex gap-5">
          <GreyBtn2
            className="w-32 my-8 text-sm"
            label={"Back"}
            onClick={() => {if(post.condition === "NEW"){setValue("Information"); }else{ setValue("Photos & Desc");}}}
          />
          <YellowBtn
            type="submit"
            className="px-8 my-8 w-fit text-sm"
            label={"Continue"}
          />
        </div>
      </form>
    </Form>
  );
}
