

export const inputAssets = [
  {
    type: "email",
    name: "email",
    placeholder: "Enter your email",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "",
  },
  {
    type: "password",
    name: "password",
    placeholder: "Enter your password",
    logo: "/assets/imgs/home/lock.png",
    logoTwo: "/assets/imgs/home/visible.png",
  },
];


export const inputAssetsA = [
  {
    name: "firstName",
    type: "text",
    placeholder: "Enter First Name",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "",
  },
  {
    name: "lastName",
    type: "text",
    placeholder: "Enter Last Name",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "/assets/imgs/home/visible.png",
  },
  {
    name: "email",
    type: "email",
    placeholder: "Enter Email",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "/assets/imgs/home/visible.png",
  },
  // {
  //   name: "username",
  //   type: "text",
  //   placeholder: "Enter your username",
  //   logo: "/assets/imgs/home/username.png",
  //   logoTwo: "",
  // },
  {
    name: "password",
    type: "password",
    placeholder: "Confirm your password",
    logo: "/assets/imgs/home/lock.png",
    logoTwo: "/assets/imgs/home/visible.png",
  },
];

export const inputAssetsB = [
  {
    name: "First Name",
    type: "text",
    placeholder: "Enter First Name",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "",
  },
  {
    name: "Last Name",
    type: "text",
    placeholder: "Enter Last Name",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "/assets/imgs/home/visible.png",
  },
  {
    name: "Email",
    type: "email",
    placeholder: "Enter Email",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "/assets/imgs/home/visible.png",
  },
];

export const inputAssetsC = [
  {
    name: "email",
    type: "email",
    placeholder: "Enter Email",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "/assets/imgs/home/visible.png",
  },
  {
    name: "username",
    type: "text",
    placeholder: "Enter your sername",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "",
  },

];

export const inputAssetsD = [
  {
    name: "Email",
    type: "email",
    placeholder: "Enter Email",
    logo: "/assets/imgs/home/username.png",
    logoTwo: "/assets/imgs/home/visible.png",
  },
];