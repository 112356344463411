// @ts-nocheck
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Grid } from "swiper";
import "swiper/css/grid";
import useSwiperRef from "../../../../../hooks/useSwiperRef";
import Card from "../../../../common/Card";
import { avatarData } from "./avatarData";
import { useEffect, useState } from "react";
import {
  updateProfile,
  updateProfileProperties,
} from "../../../../../redux/features/user/userProfileSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import clsx from 'clsx';
import { Link } from "react-router-dom";

const AvatarSelector = ({
  value,
  select,
  onOptionSelect,
}: {
  value: string;
  onOptionSelect: (val: string) => void;
  select?: Number;
}) => {
  const [nextEl, nextRef] = useSwiperRef();
  const [prevEl, prevRef] = useSwiperRef();
  const [selector, setSelector] = useState(value);
  const onSelect = (el: any) => {
    setSelector(el);
    if (onOptionSelect) {
      onOptionSelect(el);
    }
  };
  return (
    <Card className="bg-white shadow">
      <div className="flex justify-between">
        <p className="text-xs text-gray-700">Avatars</p>
        <div className="flex gap-1 self-end">
          <img
            ref={prevRef}
            className="cursor-pointer your-class-arrow your-class-prev"
            src="/assets/vectors/common/arrow-left.svg"
            alt="arrow left"
          />
          <img
            ref={nextRef}
            className="cursor-pointer your-class-arrow your-class-next"
            src="/assets/vectors/common/arrow-right.svg"
            alt="arrow right"
          />
        </div>
      </div>

      <div className="mt-[10px] ">
        <div className="">
          <Swiper
            modules={[Navigation]}
            navigation={{
              prevEl,
              nextEl,
            }}
            spaceBetween={8}
            // grid={{
            //   rows: 2,
            // }}
            breakpoints={{
              0: {
                slidesPerView: 6,
              },
              400: {
                slidesPerView: 6,
              },
              640: {
                slidesPerView: 9,
              },
              1480: {
                slidesPerView: 9,
              },
            }}
          >
            {avatarData.map((el, idx) => {
              return (
                <SwiperSlide key={"home-category-slider-item " + idx}>
                  <div
                    className={clsx(
                      selector == el.label
                        ? "cursor-pointer flex flex-col items-center border-b-4 border-orange-300  shadow-lg "
                        : "cursor-pointer flex flex-col items-center hover:border-b-4 hover:border-orange-300 shadow"
                    )}
                    onClick={() => onSelect(el.label)}
                  >
                    <div
                      className={clsx(
                        selector == el.label
                          ? "rounded-[50%] overflow-hidden w-[50px] h-[50px] relative bg-orange-100 "
                          : "rounded-[50%] overflow-hidden w-[50px] h-[50px] relative hover:bg-orange-100 bg-slate-100 "
                      )}
                    >
                      <img
                        className="absolute w-[76x] h-[76x]"
                        src="/assets/vectors/home/slider-border.svg"
                        alt="border"
                      />
                      <img
                        className="rounded-[50%] w-[48px] h-[48px] absolute left-2/4 -translate-x-2/4 top-2/4 -translate-y-2/4"
                        src={el.icon}
                        alt="slider"
                      />
                    </div>
                    <div className="mt-1 text-center font-medium text-[8px]">
                      {el.label}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Card>
  );
};

export default AvatarSelector;
