import React from "react";
import { Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Icon } from "@iconify/react";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";

const ShopFlags = () => {
  const user = useAuthUser();
  return (
    <div className=" mb-3">
      <div className=" grid grid-cols-12 gap-3 lg:gap-7 my-2 md:my-4">
        {data.map((_, idx) => (
          <Link
            to={_.link}
            className="col-span-3"
            key={idx}
            onClick={() =>
              mixpanel.track("Shop browse clicked", {
                button_clicked: _.name,
                email: user ? user["email"] : "",
              })
            }
          >
            <div className=" py-1 h-[5rem]  md:h-[7rem] md:pb-3 lg:pb-0 lg:h-[9rem] gender-card overflow-hidden  border border-gray-300 shadow-lg  rounded-lg">
              <div className="px-2 text-sm md:text-xl  text-left font-medium justify-between flex">
                <p className="text-left ">{_.name}</p>

                <Icon
                  icon="ph:arrow-right-duotone"
                  className="lg:text-2xl text-lg"
                />
              </div>
              <img
                className=" h-full w-full object-cover "
                src={_.img}
                alt=""
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ShopFlags;

const data = [
  {
    label: "Open Shop",
    name: "All",
    img: "/assets/imgs/home/shopit1.png",
    link: "/market",
  },
  {
    label: "Shop Women",
    name: "Women",
    img: "/assets/imgs/home/shopit3.png",
    // link: "/market?gender=FEMALE",
    link: "/shop/women",
  },
  {
    label: "Shop Men",
    name: "Men",
    img: "/assets/imgs/home/shopit7.png",
    // link: "/market?gender=MALE",
    link: "/shop/men",
  },
  {
    label: "Shop Samples",
    name: "Oils",
    img: "/assets/imgs/home/shopit8.png",
    link: "/shop/oil",
  },
];
