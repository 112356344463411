import axios from "axios";
import { useEffect, useState } from "react";
import { User } from "../types";

export const useUser = (id: string | null) => {
  const [userData, setUserData] = useState<User>();
  useEffect(() => {
    if (id) {
      axios
        .get(`https://webservice.pairrit.com/pairrit-ws/api/profile/${id}`)
        .then((res) => setUserData(res.data));
    }
  }, [id]);
  return userData;
};
