import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import useAuthUser from "../../../../hooks/getAuthUser";
import { fetchCommunityByName } from "../../../../redux/features/communityFeed/communitySlice";
import { useAppDispatch } from "../../../../redux/hooks";
import Category from "../category";
import Banner from "./banner";

export default function CommunityPage() {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fetchCommunityByName(id));
    }
    mixpanel.track("Single Community page view", {
      community_name: id,
      email: user ? user["email"] : "",
    });
  }, [id]);
  const category = [
    {
      title: "Posts",
      icon: "tabler:category",
      link: `/browse/s/${id}/posts`,
    },
    {
      title: "Store",
      icon: "fluent:store-microsoft-24-filled",
      link: `/browse/s/${id}/store`,
    },
    {
      title: "Rules",
      icon: "pajamas:push-rules",
      link: `/browse/s/${id}/rules`,
    },
    {
      title: "Mods",
      icon: "fluent-mdl2:party-leader",
      link: `/browse/s/${id}/mods`,
    },
  ];
  return (
    <section className="mb-[8em]">
      <Banner />
      <Category category={category} name={id} post />
      <Outlet />
    </section>
  );
}
