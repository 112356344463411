import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import Flags from "./Flags";
import ListFlags from "./ListFlags";
import Flags2 from "./Flags2";

const FlagsBanner = () => {
  return (
    <div className="lg:order-1  order-2">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 100000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay]}
        className="mySwiper h-[12em] md:h-[12em]"
      >
        <SwiperSlide>
          <Flags />
        </SwiperSlide>
        {/* <SwiperSlide>
          <ListFlags shop={false} />
        </SwiperSlide> */}
        <SwiperSlide>
          <Flags2 />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default FlagsBanner;
