import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import { fetchUserCommunities } from "../../../../../redux/features/userCommunites/userCommunitiesSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Card from "../../../../common/Card/Card";
import NotAdded from "../../../../common/Error/NotAdded";
import CommunityItems from "../../../Community/CommunityItems";
import ForSaleTopBar from "../../../Market/for-sale-topbar";

const UserCommunities = () => {
  const { otherProfileData, loadingProfile } = useAppSelector((state) => state.userProfile);
  const { id } = useParams();
  return (
    <div>
      <ForSaleTopBar user={id} communities />
      {loadingProfile ? (
        <Card className="bg-white mt-4">
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={350}
            height={48}
          />
        </Card>
      ) : (
        <section className="grid grid-cols-1 gap-5 p-4 mt-4  bg-white border rounded-lg border-slate-200 ">
          {otherProfileData.myCommunities?.length != 0 ? (
            <CommunityItems value={otherProfileData.myCommunities} />
          ) : (
            <Card className="bg-white shadow-lg w-full ">
              <NotAdded
                m1="Nothing to find"
                m2="No Communities"
                m3="Start Community Shopping"
              />
            </Card>
          )}
        </section>
      )}
    </div>
  );
};

export default UserCommunities;


