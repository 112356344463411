import { useEffect, useState } from "react";
import { fetchOrders } from "../../../../redux/features/userTransactions/orders/userOrdersSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import NotAdded from "../../../common/Error/NotAdded";
import Loader from "../../../common/Loader/loader";
import Select from "../../../common/select";
import OrderCard from "./OrderCard";

const sortOptions = [
  {
    label: "Open",
    value: "Open",
  },
  {
    label: "Closed",
    value: "Closed",
  },
];

const Orders = () => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { orderList, error, loading } = useAppSelector(
    (state) => state.userOrders
  );
  useEffect(() => {
    dispatch(fetchOrders(userId));
  }, [userId]);
  const [sortValue, setSortValue] = useState("Open");
  return (
    <div className="mt-2">
      <div className="flex items-center gap-5 text-xs">
        <div className="max-w-[190px] ">
          <Select
            uniqueKey="order-selector"
            className="w-full"
            label="Order Status"
            defaultValue={sortValue}
            options={sortOptions}
            onOptionSelect={(val) => setSortValue(val)}
          />
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="mt-5">
          {orderList?.length != 0 ? (
            orderList
              ?.filter((obj: any) => {
                return obj.orderStatus === sortValue;
              })
              .map((el: any, idx: any) => {
                return <OrderCard el={el} idx={idx} />;
              })
          ) : (
            <NotAdded
              m1="Nothing to Find"
              m2=" No Orders Yet"
              m3="Start Selling to earn FlexPoints"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
