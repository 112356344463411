import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  communityCategory: string;
  loading: boolean;
  error: string | null;
  createLoading: boolean;
  createError: string | null;
  communitiesList: any;
}

interface CreateCommState {
  name: String;
  type: String;
  genre: String;
  creatorId?: String;
}
// Define the initial state using that type
const initialState: InitialStateType = {
  communityCategory: "General",
  loading: false,
  error: null,
  createLoading: false,
  createError: null,
  communitiesList: null,
};

export const fetchCommunities = createAsyncThunk(
  "community/communities",
  async () => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/allcommunity`
    );
    return response.data;
  }
);

export const fetchCommunitiesByGenre = createAsyncThunk(
  "community/communitiesByGenre",
  async (category: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/genre/{genre}?genre=${category}`
    );
    return response.data;
  }
);

export const createCommunity = createAsyncThunk(
  "community/create",
  async ({ name, type, genre, creatorId }: CreateCommState) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/communities/create`,
      {
        name: name,
        type: type,
        genre: genre,
        creatorId: creatorId,
      }
    );
    return response.status;
  }
);

export const communitiesSlice = createSlice({
  name: "communities",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    categorySelect: (state, action: PayloadAction<string>) => {
      state.communityCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommunities.fulfilled, (state, action) => {
        state.loading = false;
        state.communitiesList = action.payload;
      })
      .addCase(fetchCommunities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(fetchCommunitiesByGenre.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommunitiesByGenre.fulfilled, (state, action) => {
        state.loading = false;
        state.communitiesList = action.payload;
      })
      .addCase(fetchCommunitiesByGenre.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(createCommunity.pending, (state) => {
        state.createLoading = true;
        state.createError = null;
      })
      .addCase(createCommunity.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(createCommunity.rejected, (state, action) => {
        state.createLoading = false;
        state.createError = action.error.message || "An error occurred";
      });
  },
});

export const { categorySelect } = communitiesSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default communitiesSlice.reducer;
