import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "../../../../../common/Card";
import "../Post.css";
import { Author } from "../common/author";
import { CardFooter } from "../common/card-footer";
import { User } from "../common/user";
import { Images } from "../common/images";
import axios from "axios";
import Loader from "../../../../../common/Loader/loader";
import Skeleton from "@mui/material/Skeleton";
import useAuthUser from "../../../../../../hooks/getAuthUser";
import mixpanel from "mixpanel-browser";

const TopicImg = ({ el, idx }: { el: any; idx: number }) => {
  const [data, setData] = useState([]);
  const user = useAuthUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (imgs: any) => {
      try {
        const response = await axios.get(
          `https://webservice.pairrit.com/pairrit-ws/api/getsignedurl?filenames=${imgs}`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData(el.postContent.s3ObjectName.toString());
  }, []);
  return (
    <Card className="h-full home-post bg-white shadow" key={"home-post" + idx}>
      <User el={el} time={el.postContent.createdDateTime} />
      <hr />
      <Author topic el={el} idx={idx} />
      <Link
        onClick={() => {
          mixpanel.track("Single post opened", {
            id: el.postContent.id,
            post_type: "topic-img",
            email: user ? user["email"] : "",
          });
        }}
        to={`/single/topic/${el.id}`}
      >
        {el.postContent.title && (
          <div className="mt-3 text-base md:text-xl font-medium">
            {el.postContent.title}
          </div>
        )}
        {loading ? (
          <Card className="bg-white">
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={350}
              height={48}
            />
          </Card>
        ) : (
          <img
            className="object-cover w-40 h-40 shadow-md rounded-lg "
            src={data[0]}
            alt="product"
          />
        )}
      </Link>
      <CardFooter el={el} idx={idx} />
    </Card>
  );
};

export default TopicImg;
