import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

interface InitialStateType {
  loading: boolean;
  error: string | null;
  returnLoading: boolean;
  returnError: string | null;
  purchaseList: any;
  feedbackLoading: boolean;
  feedbackError: string | null;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  loading: true,
  error: null,
  purchaseList: null,
  feedbackLoading: false,
  feedbackError: null,
  returnLoading: false,
  returnError: null,
};

export const fetchPurchases = createAsyncThunk(
  "user/fetchPurchases",
  async (userId: string) => {
    const response = await axios.get(
      `https://webservice.pairrit.com/pairrit-ws/api/purchases/buyer/${userId}`
    );
    return response.data;
  }
);

export const addSellerFeedback = createAsyncThunk(
  "user/addSellerFeedback",
  async ({ purchaseId, feedbackRate, userId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/purchases/buyer/feedback`,
      {
        userId: userId,
        purchaseId: purchaseId,
        feedbackRate: feedbackRate,
      }
    );
    return response.data;
  }
);

export const returnRequest = createAsyncThunk(
  "user/returnRequest",
  async ({ reason, message, purchaseId }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/returns`,
      {
        purchaseId: purchaseId,
        reason: reason,
        message: message,
      }
    );
    return response.data;
  }
);

export const userPurchasesSlice = createSlice({
  name: "userPurchases",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchases.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPurchases.fulfilled, (state, action) => {
        state.loading = false;
        state.purchaseList = action.payload;
      })
      .addCase(fetchPurchases.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(addSellerFeedback.pending, (state) => {
        state.feedbackLoading = true;
        state.feedbackError = null;
      })
      .addCase(addSellerFeedback.fulfilled, (state, action) => {
        state.feedbackLoading = false;
      })
      .addCase(addSellerFeedback.rejected, (state, action) => {
        state.feedbackLoading = false;
        state.feedbackError = action.error.message || "An error occurred";
      })
      .addCase(returnRequest.pending, (state) => {
        state.returnLoading = true;
        state.returnError = null;
      })
      .addCase(returnRequest.fulfilled, (state, action) => {
        state.returnLoading = false;
      })
      .addCase(returnRequest.rejected, (state, action) => {
        state.returnLoading = false;
        state.returnError = action.error.message || "An error occurred";
      });
  },
});

export const {} = userPurchasesSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userPurchasesSlice.reducer;
