import { Icon } from "@iconify/react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { useMediaQuery } from "../../../hooks/useMediaQueries";
import { useOktaAuth } from "@okta/okta-react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Search from "../Search/Search";
import { UserMenu } from "./user-menu";
import NavbarItem from "./NavbarItem";
import DialogWrapper from "../dialog-wrapper";
import RegisterForm from "../../auth/RegisterForm";
import { Notifies } from "./Notifies";
import { getUnreadCount } from "../../../helpers/firestore";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";
import { IconButton } from "@mui/material";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { UserMenuMobile } from "./user-menu-mobile";
import Invite from "../Sidebar/Invite";
import GradientBtn from "../../buttons/GradientBtn";
import { setCartTotal } from "../../../redux/features/cart/cartSlice";

const Navbar = () => {
  const { authState } = useOktaAuth();
  const dispatch = useAppDispatch();
  const userId: string = authState?.idToken?.claims.sub ?? "";
  const [unreadCount, setUnreadCount] = useState(0);
  const user = useAuthUser();
  const { fetchLoading, cartTotal } = useAppSelector((state) => state.cart);
  const navigate = useNavigate();

  const location = useLocation();
  const { oktaAuth } = useOktaAuth();
  const loggingIn = async () => {
    oktaAuth.clearStorage();
    oktaAuth.closeSession();
    navigate("/login");
    // oktaAuth.signInWithRedirect({ originalUri: location.pathname });
  };

  const cart = useAppSelector((state) => state.cart.cartTotal);
  const [total, setTotal] = useState(cart);
  useEffect(() => {
    dispatch(setCartTotal(cartTotal));
  }, [cartTotal]);
  useEffect(() => {
    const unsub = getUnreadCount(userId, (c) => {
      setUnreadCount(c);
    });
    return () => unsub();
  }, [userId]);
  const isMd = useMediaQuery("md");
  const navItems = useMemo(() => {
    let isAuthenticated = authState?.isAuthenticated ?? false;
    if (isAuthenticated) {
      return [
        {
          icon: "uiw:message",
          href: "/messages",
          tip: "Messages",
          active: true,
          num: unreadCount,
        },
      ];
    }

    return [];
  }, [isMd, unreadCount]);
  return (
    <nav className="fixed inset-x-0 top-0 z-50 shadow-lg bg-gradient-to-r from-zinc-200 to-slate-300 shadow-orange-100/50 ">
      <div className=" bg-slate-800 fixed w-full flex  shadow px-4">
        <p className="text-white text-center text-xs mx-auto">
          Enjoy FREE Shipping on Every Order Over $149 - U.S. Only
        </p>
      </div>
      <div className="h-[100px] mt-2 md:h-[120px] lg:h-[75px] flex flex-wrap justify-between items-center py-2 sm:px-[24px] px-[12px] z-0">
        <div className="w-1/2 lg:w-1/4 order-1 flex items-center flex-grow h-5 gap-3 xl:w-1/4 ">
          <Link
            className="k"
            onClick={() => {
              mixpanel.track("Logo clicked", {
                email: user ? user["email"] : "",
              });
            }}
            to={"/"}
          >
            <img
              className="h-9 lg:h-10 mx-auto lg:ml-[11em]"
              src="/assets/imgs/common/logo-2.png"
              alt="logo"
            />
          </Link>
          <Link
            onClick={() => {
              mixpanel.track("Logo clicked", {
                email: user ? user["email"] : "",
              });
            }}
            className="hidden min-[1185px]:hidden lg:block ml-6"
            to={"/"}
          >
            <img
              className="h-12 mx-auto"
              src="/assets/imgs/common/logo-1.png"
              alt="logo"
            />
          </Link>
        </div>
        <div className="w-[40em] lg:w-[48em] order-3 lg:order-2 justify-center flex-grow flex">
          <Search />
        </div>
        <div className="w-1/2 md:w-1/4 order-2 lg:order-3 flex items-center justify-end xl:w-1/4 ">
          <div className="flex items-center sm:gap-[22px] gap-1 md:gap-2 ">
            <Link
              className="lg:block hidden font-semibold leading-tight text-white capitalize"
              to={"/create/sell"}
              onClick={() => {
                mixpanel.track("Sell Fragrance button clicked", {
                  email: user ? user["email"] : "",
                });
              }}
            >
              <button className="flex  items-center gap-2 rounded-lg bg-gradient-to-l from-red-500  to-yellow-500  px-3 py-1.5 text-xs font-bold text-white hover:opacity-75 border-2 border-white">
                <span>Sell on Pairrit?</span>
                <Icon
                  icon="solar:play-bold-duotone"
                  className="text-xl text-white h-4 justify-center "
                />
              </button>
            </Link>
            {authState?.isAuthenticated && (
              <DialogWrapper className="z-50 " content={<Invite />}>
                <button
                  onClick={() => {
                    mixpanel.track("Invite User button clicked");
                  }}
                  className="flex md:hidden lg:block items-baseline gap-2 rounded-lg bg-gradient-to-r from-green-500  to-green-400 px-3 py-1.5 text-xs font-bold text-white hover:opacity-75 border-2 border-white"
                >
                  <span>Invite</span>
                </button>
              </DialogWrapper>
            )}
            {authState?.isAuthenticated && <Notifies />}
            {navItems.map((_: any, idx) => (
              <NavbarItem
                href={_?.href}
                tip={_.tip}
                icon={_?.icon}
                active={_.active}
                num={_?.num}
                key={_?.num}
              />
            ))}
            <div className="w-[1px] h-[22px] bg-black bg-opacity-100 sm:block hidden"></div>{" "}
            <Link
              className="transition-transform shrink-0 hover:scale-105 "
              to={"/cart"}
              onClick={() => {
                mixpanel.track("Cart Icon clicked", {
                  email: user ? user["email"] : "",
                });
              }}
            >
              <IconButton aria-label="cart">
                <StyledBadge badgeContent={cart} color="error">
                  <Icon icon="uil:cart" className="text-2xl text-slate-700 " />
                </StyledBadge>
              </IconButton>
            </Link>
            {authState?.isAuthenticated ? (
              <>
                <UserMenuMobile />
                <UserMenu />
              </>
            ) : (
              <>
                <span
                  className="text-slate-900 text-sm cursor-pointer "
                  onClick={() => {
                    loggingIn();
                    mixpanel.track("Login button click", {
                      email: user ? user["email"] : "",
                    });
                  }}
                >
                  Signin
                </span>
                {/* <span
                  className="text-slate-900 text-sm cursor-pointer "
                  onClick={() => {
                    navigate("/signup");
                    mixpanel.track("Register button click", {
                      email: user ? user["email"] : "",
                    });
                  }}
                >
                  Signup
                </span> */}
               
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 1,
    top: 7,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 0px",
    height: "16px",
    fontSize: "10px",
  },
}));
