import { useEffect, useState } from "react";
import { fetchPerferences } from "../../../../redux/features/user/userProfileSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Card from "../../../common/Card";
import Loader from "../../../common/Loader/loader";
import Switch from "../../../common/Switch";
import { updatePerferences } from "../../../../redux/features/user/updateProfileSlice";

const Notifications = () => {
  const { userId } = useAppSelector((state) => state.userProfile);
  const { preferencesData, loadingPerferences } = useAppSelector(
    (state) => state.userProfile
  );
  const [prefData, setPrefData] = useState({
    userId: userId,
    inboxMessages: true,
    chatMessages: true,
    orders: true,
    offersMadeAccepted: true,
    mentions: true,
    commentsOnPosts: true,
    repliesToComments: true,
    activity: true,
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPerferences(userId));
  }, []);

  return (
    <form>
      {loadingPerferences ? (
        <Loader />
      ) : (
        <Card className="p-6 bg-white">
          <div className="flex justify-between">
            <h5 className="text-sm text-accentGray-700">
              NOTIFICATIONS PREFERENCES
            </h5>
            {/* <GradientBtn type="submit" label="Save Changes" icon="" /> */}
          </div>

          <hr className="v2 my-6" />

          <h5 className="text-xs text-accentGray-700">MESSAGES</h5>
          <div className="flex  sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 my-3">
            <h4 className="text-semibold text-[14px]">Inbox messages</h4>
            <Switch
              value={preferencesData.inboxMessages}
              onOptionSelect={(val) =>
                dispatch(
                  updatePerferences({
                    userId: userId,
                    inboxMessages: val,
                  })
                )
              }
            />
          </div>
          <div className="flex sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 my-3">
            <h4 className="text-semibold text-[14px]">Chat messages</h4>
            <Switch
              value={preferencesData.chatMessages}
              onOptionSelect={(val) =>
                dispatch(
                  updatePerferences({
                    userId: userId,
                    chatMessages: val,
                  })
                )
              }
            />
          </div>
          <hr className="v2 my-6" />
          <h5 className="text-xs text-accentGray-700">ACTIVITY</h5>
          <div className="flex sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 my-3">
            <h4 className="text-semibold text-[14px]">
              Mentions of u/username
            </h4>
            <Switch
              value={preferencesData.mentions}
              onOptionSelect={(val) =>
                dispatch(
                  updatePerferences({
                    userId: userId,
                    mentions: val,
                  })
                )
              }
            />
          </div>
          <div className="flex  sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 my-3">
            <h4 className="text-semibold text-[14px]">
              Comments on your posts
            </h4>
            <Switch
              value={preferencesData.commentsOnPosts}
              onOptionSelect={(val) =>
                dispatch(
                  updatePerferences({
                    userId: userId,
                    commentsOnPosts: val,
                  })
                )
              }
            />
          </div>
          <div className="flex sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 my-3">
            <h4 className="text-semibold text-[14px]">
              Replies to your comments
            </h4>

            <Switch
              value={preferencesData.repliesToComments}
              onOptionSelect={(val) =>
                dispatch(
                  updatePerferences({
                    userId: userId,
                    repliesToComments: val,
                  })
                )
              }
            />
          </div>
          <div className="flex sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 my-3">
            <h4 className="text-semibold text-[14px]">
              Activity on threads you're in
            </h4>
            <Switch
              value={preferencesData.activity}
              onOptionSelect={(val) =>
                dispatch(
                  updatePerferences({
                    userId: userId,
                    activity: val,
                  })
                )
              }
            />
          </div>
          <div className="flex sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 my-3">
            <h4 className="text-semibold text-[14px]">Offers Made/Accepted</h4>
            <Switch
              value={preferencesData.offersMadeAccepted}
              onOptionSelect={(val) =>
                dispatch(
                  updatePerferences({
                    userId: userId,
                    offersMadeAccepted: val,
                  })
                )
              }
            />
          </div>
          <div className="flex sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 my-3">
            <h4 className="text-semibold text-[14px]">Orders</h4>
            <Switch
              value={preferencesData.orders}
              onOptionSelect={(val) =>
                dispatch(
                  updatePerferences({
                    userId: userId,
                    orders: val,
                  })
                )
              }
            />
          </div>
        </Card>
      )}
    </form>
  );
};

export default Notifications;
