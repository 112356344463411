import Card from "../../../../../common/Card";
import "../Post.css";
import { Author } from "../common/author";
import { CardFooter } from "../common/card-footer";
import { Images } from "../common/images";
import { User } from "../common/user";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../../../../common/Loader/loader";
import Skeleton from "@mui/material/Skeleton";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../../../hooks/getAuthUser";

const NewGear = ({ el, idx }: { el: any; idx: number }) => {
  const [data, setData] = useState([]);
  const user = useAuthUser();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (imgs: any) => {
      try {
        const response = await axios.get(
          `https://webservice.pairrit.com/pairrit-ws/api/getsignedurl?filenames=${imgs}`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };
    fetchData(el.postContent.s3ObjectName.toString());
  }, []);
  return (
    <Card className="h-full home-post bg-white shadow " key={"home-post" + idx}>
      <User el={el} time={el.postContent.createdDateTime} />
      <hr />
      <Author el={el} idx={idx} />
      <Link
        onClick={() => {
          mixpanel.track("Single post opened", {
            id: el.postContent.id,
            post_type: "new-frag",
            email: user ? user["email"] : "",
          });
        }}
        to={`/frag/${el.postContent.id}`}
      >
        <div className="">
          <div className="text-center">
            <p className="font-semibold text-base">
              {` ${el.postContent.brandName} 
              ${el.postContent.model}`}
            </p>
          </div>
        </div>
      </Link>
      {el.postContent.description && (
        <div className="mt-3 text-xs font-medium text-accentGray-800">
          {el.postContent.description}
        </div>
      )}
      {loading ? (
        <Card className="bg-white">
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={350}
            height={48}
          />
        </Card>
      ) : (
        <Images imgs={data} length={4} idx={idx} />
      )}
      <CardFooter el={el} idx={idx} />
    </Card>
  );
};

export default NewGear;
