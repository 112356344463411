import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { ComponentProps, useState } from "react";
import { cn } from "../../../helpers/utils";
import { useAppSelector } from "../../../redux/hooks";
import GreyBtn from "../../buttons/GreyBtn";
import Card from "../../common/Card";
import { Input } from "../Checkout/input";

type OrderSummaryMobProps = ComponentProps<"div">;

export function OrderSummaryMob({ className, ...props }: OrderSummaryMobProps) {
  const feed = useAppSelector((state) => state.cart.cartData);
   const [total, setTotal] = useState(
     feed.length < 1
       ? 0
       : feed
           .map((i: any) => i.price * i.total)
           .reduce((a: any, b: any) => a + b)
   );

  const totPrice = total + 4;
  const [showSummary, setShowSummary] = useState(false);
  return (
    <motion.div
      layout
      className={cn("md:hidden bg-slate-300 rounded-lg -mx-6 -mt-4", className)}
    >
      <div className="flex items-center gap-1 py-3 border-y px-8 justify-between">
        <div
          className="flex items-center gap-1"
          role="button"
          onClick={() => setShowSummary((p) => !p)}
        >
          <Icon
            icon="fluent:cart-16-regular"
            className="text-lg text-sky-700"
          />
          <p className="text-sky-700 text-xs ">
            {showSummary ? "Hide" : "Show"} order summary
          </p>

          <Icon
            icon="tabler:chevron-up"
            className={cn("text-sky-700", { "rotate-180": !showSummary })}
          />
        </div>
        <p className="text-zinc-800 text-lg font-medium">
          ${totPrice.toFixed(2)}
        </p>
      </div>
      {showSummary && (
        <Card className=" p-6  md:hidden flex flex-col justify-between bg-white">
          <div className="">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-[18px] font-medium">Summary</h3>
            </div>

            <div className="text-accentGray-200 text-xs font-medium">
              {feed?.length} items
            </div>

            <div>
              {feed?.map((el: any) => (
                <div className="flex justify-between font-medium gap-3 my-3">
                  <div>
                    {el.total} x {el.name}
                  </div>
                  <div className="flex-shrink-0">$ {el.price}</div>
                </div>
              ))}
            </div>
            <hr className="border-accentGray-600 my-4" />
            <div>
              <div className="flex justify-between gap-3 my-3 font-medium">
                <div>Shipping</div>
                <div className="flex-shrink-0">$ 4.00</div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center my-2">
            <div className="font-medium text-base">Total</div>
            <div className="font-medium text-base">${totPrice.toFixed(2)}</div>
          </div>
          <hr className="border-accentGray-600 my-4" />
        </Card>
      )}
    </motion.div>
  );
}
