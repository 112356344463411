import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import LightBlueBtn from "../../../buttons/LightBlueBtn";
import YellowBtn from "../../../buttons/YellowBtn";

export default function Moderators({ mods, name }: { mods: Array<any>; name: string; }) {
  return (
    <section>
      <p className="text-base font-semibold underline">Moderators</p>
      <div className="space-y-2">
        {mods.map((_) => (
          <p className="text-sm font-medium">- {_.userName}</p>
        ))}
      </div>
      <Link to={`/browse/s/${name}/store`}>
        <YellowBtn
          className={"my-5 w-full"}
          label={
            <div className="flex items-center gap-1">
              <Icon
                icon={"fluent:store-microsoft-24-filled"}
                className="text-xl"
              />
              View Store
            </div>
          }
        />
      </Link>
    </section>
  );
}

