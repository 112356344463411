// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-post .img-overlay {
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.13) 0%,
    rgba(0, 0, 0, 0.59) 42.43%,
    rgba(0, 0, 0, 0.59) 58.27%
  );
}

.home-post .commit-btn {
  background: linear-gradient(180deg, #f99807 0%, #fbbc05 100%);
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/Posts/Post/Post.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB;;;;;GAKC;AACH;;AAEA;EACE,6DAA6D;AAC/D","sourcesContent":[".home-post .img-overlay {\n  border-radius: 8px;\n  background: linear-gradient(\n    180deg,\n    rgba(217, 217, 217, 0.13) 0%,\n    rgba(0, 0, 0, 0.59) 42.43%,\n    rgba(0, 0, 0, 0.59) 58.27%\n  );\n}\n\n.home-post .commit-btn {\n  background: linear-gradient(180deg, #f99807 0%, #fbbc05 100%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
