import React from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";

const Pairrit = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="bg-white border border-solid border-accentGray-600 py-10 px-7 text-center rounded-lg">
      <div className="ring-2 rounded ring-slate-500 bg-accentYellow-500 ">
        <div className="text-xl font-semibold ">Pairrit</div>
        <div className="text-xs">Social Marketplace</div>
      </div>

      <div className="mt-6 md:text-3xl text-xl font-bold text-primary-gradient">
        Social Shop
      </div>
      <div className="text-accentGray-700 font-semibold">We Verify!</div>
      <div className="my-6 flex justify-center">
        <Link to="/signup">
          <GradientBtn className="w-full" label="Signup" />
        </Link>
      </div>
      <div className="font-semibold">8.5% Sellers Fee</div>

      <div className="mt-3 font-semibold text-xs">
        <div className="font-semibold my-2"> Used Fragrance Marketplace</div>
        <div className="font-semibold my-2">Authentication Protection</div>
        <div className="font-semibold my-2">Buy and Sell Online</div>
        <div className="font-semibold my-2">Join and build Communities</div>
        <div className="font-semibold my-2">Share Your Collection</div>
        <div className="font-semibold my-2">Start Your OWN Threads</div>

        <div className="font-semibold my-2">Make Offers</div>
        <div className="font-semibold my-2">24/7 Support</div>
      </div>
    </div>
  );
};

export default Pairrit;
