import { HTMLAttributes, useState } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { getAge } from "../../../helpers/getAge";
import { cn } from "../../../helpers/utils";
import * as RadixTabs from "@radix-ui/react-tabs";
import { useAppSelector } from "../../../redux/hooks";
import GradientBtn from "../../buttons/GradientBtn";
import YellowBtn from "../../buttons/YellowBtn";
import Card from "../../common/Card/Card";
import { RatingStars } from "../Single-Page/Listing/details";
import About from "./about";
import { Stat } from "./stat";
import UserAbout from "./user-about";
import { useOktaAuth } from "@okta/okta-react";
import Skeleton from "@mui/material/Skeleton";
import DialogWrapper from "../../common/dialog-wrapper";
import { CopyLink } from "./copy-link";
import { Icon } from "@iconify/react";
import mixpanel from "mixpanel-browser";

export default function GearRoomProfile({
  store,
  user,
  communities,
  thread,
  data,
}: {
  user?: boolean;
  store?: boolean;
  data: any;
  communities?: boolean;
  thread?: boolean;
}) {
  const { pathname } = useLocation();
  const { authState } = useOktaAuth();
  const { loading, loadingProfile } = useAppSelector((state) => state.userProfile);
  let params = useParams();
  const [currentTab, setCurrentTab] = useState(pathname.split("my/")[1]);

  return (
    <>
      <RadixTabs.Root
        className="rounded-xl bg-gradient-to-r from-zinc-400 to-slate-500"
        defaultValue={currentTab}
      >
        <RadixTabs.List className="grid justify-center grid-cols-4 gap-1 mb-2">
          {action.map((_, i) => (
            <RadixTabs.Trigger
              asChild
              key={_.label}
              value={_.link}
              className="flex items-center justify-center  gap-1 lg:gap-3  lg:px-6 px-3 max-lg:rounded-md md:whitespace-nowrap md:py-3 py-1  lg:py-4 text-sm font-semibold group rounded-xl from-red-500 to-yellow-500 text-neutral-100 aria-selected:underline  decoration-red-500 decoration-2 underline-offset-4 lg:underline-offset-8 "
            >
              <NavLink
                to={`/my/${_.link}`}
                className="hover:bg-zinc-400 capitalize  aria-selected:border-y aria-selected:bg-zinc-300 aria-selected:text-red-500"
              >
                <Icon
                  icon={_.icon}
                  className=" text-lg hidden md:block aria-selected:text-red-500"
                />
                {_.label}
              </NavLink>
            </RadixTabs.Trigger>
          ))}
        </RadixTabs.List>
      </RadixTabs.Root>
      <Card className=" bg-gradient-to-r from-zinc-200 to-slate-300 rounded shadow-lg">
        <div className="flex max-md:flex-col md:gap-4">
          {(!authState?.isAuthenticated ? loadingProfile : loading) ? (
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={350}
              height={78}
            />
          ) : (
            <div className="relative flex gap-4 mt-1">
              <img
                className="max-md:w-[90px] md:w-[170px]  rounded-lg aspect-square md:mt-3  shadow-lg"
                src={`/assets/imgs/avatar/${data.profileUrl}.svg`}
                alt=""
              />

              <UserInfo className="md:hidden flex-1" user={data} />
            </div>
          )}

          {(!authState?.isAuthenticated ? loadingProfile : loading) ? (
            <Skeleton
              className=" mx-auto"
              variant="rounded"
              width={330}
              height={48}
            />
          ) : (
            <div className="flex flex-wrap max-md:flex-col justify-between md:items-center grow">
              <UserInfo className="max-md:hidden md:ml-4" user={data} />
          
              <Stat className="px-3 mt-3 basis-full" user={data} />
              <About store={store} desc={data} />
            </div>
          )}
        </div>
      </Card>
    </>
  );
}
const action = [
  {
    icon: "tdesign:shop",
    label: "Shop",
    link: "shop",
  },
  {
    icon: "carbon:storage-pool",
    label: "Collection",
    link: "gear",
  },
  {
    icon: "tabler:needle-thread",
    label: "Wishlist",
    link: "watchlist",
  },
  {
    icon: "fluent:people-community-16-filled",
    label: "Community",
    link: "communities",
  },
];

export function UserInfo({
  className,
  user,
  isUser,
}: { className?: string; user: any; isUser?: any } & HTMLAttributes<HTMLDivElement>) {
  const { id } = useParams()
  const navigate = useNavigate()

  return (
    <div
      className={cn(
        "md:flex mt-3 md:mt-0 w-full items-center gap-1",
        className
      )}
    >
      <div className="flex">
        <div className="flex-auto ">
          <p className="md:text-lg text-xs font-semibold ">{user.userName}</p>
          <span className="mr-1 text-gray-400 text-[10px]">
            {user.background}
          </span>
          <span className="mr-1 border-r border-gray-600  max-h-0"></span>
          <span className="text-gray-400 text-[10px]">{user.location}</span>
        </div>

        <div className="flex gap-2 mx-auto">
          <div key={1} className="flex flex-col items-center gap-1 my-2 ">
            <div className="flex items-center gap-1">
              <img src="/assets/vectors/common/flower.svg" alt="" />
              <div className="text-[11px] font-medium ">
                {user.muzPoints.toLocaleString("en-US")}
              </div>
            </div>
          </div>

          <div key={2} className="flex flex-col items-center gap-1 my-2 ">
            <RatingStars numberOfReviews={user.sellerRatings.toFixed(1)} />
          </div>
          <div className="flex  ">
            {!isUser ? (
              <YellowBtn
                short
                onClick={() => navigate(`/settings/account`)}
                className="px-1 py-1 md:scale-90 scale-[0.82] border-2 border-white text-xs start-left"
                label={"Edit Profile"}
              />
            ) : (
              <YellowBtn
                onClick={() => navigate(`/messages?user=${id}`)}
                short
                className="px-1 py-1 md:scale-90 scale-[0.82] border-2 border-white text-xs start-left"
                label={"Send Message"}
              />
            )}
            <DialogWrapper
              wrapperClassName=""
              className="mb-5"
              content={<CopyLink type="user" id={user.userId} />}
            >
              <button
                onClick={() => mixpanel.track("User share button clicked")}
                className=" rounded bg-green-500 px-2 py-1 text-base font-bold text-white hover:opacity-75 border-2 border-white"
              >
                <Icon icon={"carbon:share"} className="text-xs text-white " />
              </button>
            </DialogWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}
