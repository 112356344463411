import { ComponentProps } from "react";

import { cn } from "../../../helpers/utils";
import { useAppSelector } from "../../../redux/hooks";
import Card from "../../common/Card";
import Switch from "../../common/Switch";

type PostsProps = ComponentProps<"div">;

export function Posts({ className, ...props }: PostsProps) {

  return (
    <Card className={cn("p-6 bg-white", className)}>
      <h5 className="text-sm text-accentGray-700">POSTS SETTINGS</h5>
      <hr className="border-slate-400/20 flex-1 mt-6" />
      <br />
      <p className=" text-neutral-400 font-semibold  uppercase">
        Types of Posts
      </p>
      <div className="space-y-3 mt-2 md:w-5/6">
        <div key="listings" className="flex items-center justify-between">
          <h4 className="md:text-xl text-base font-semibold">Allow Listings</h4>
          <Switch
            value={true}
            label="Allow Listings"
            className="hidden"
          />
        </div>
        <div key="listings" className="flex items-center justify-between">
          <h4 className="md:text-xl text-base font-semibold">Allow Polls</h4>
          <Switch value={true} label="Allow Polls" className="hidden" />
        </div>
        <div key="listings" className="flex items-center justify-between">
          <h4 className="md:text-xl text-base font-semibold">Allow New Gear</h4>
          <Switch value={true} label="Allow New Gear" className="hidden" />
        </div>
      </div>
    </Card>
  );
}


