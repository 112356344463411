import React, { useRef } from 'react';
import { cn } from '../../helpers/utils';

interface UploaderProps {
  className?: string;
  onFileSelected?: (file: any | null) => void;
}

const UploaderMulti: React.FC<UploaderProps> = ({ className, onFileSelected }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: any) => {
    const file: File = e.target.files || null;
    onFileSelected?.(file);
  };

  return (
    <div
      onClick={() => inputRef.current?.click()}
      className={cn(
        "flex flex-col items-center justify-center border rounded-lg border-dashed border-accentYellow-500 [&_*]:cursor-pointer cursor-pointer h-24 bg-[#FBBC0515]",
        className
      )}
    >
      <img src="/assets/vectors/common/upload.svg" alt="" />
      <p className="font-semibold text-accentYellow-500">+ Upload Photo</p>
      <input
        type="file"
        accept="image/*"
        multiple
        className="hidden"
        ref={inputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default UploaderMulti;
