import Card from "../../../common/Card";
import Select from "../../../common/Selectors/select2";
import RedOutlineBtn from "../../../buttons/RedOutlineBtn";
import GradientBtn from "../../../buttons/GradientBtn";
import { useAppSelector } from "../../../../redux/hooks";
import { z } from "zod";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormField, FormItem } from "../../../ui/form";
import { AppDispatch } from "../../../../redux/store";
import Loader from "../../../common/Loader/loader";
import { useEffect } from "react";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { Loading } from "../../../common/Widget/Loading";
import { updateProfile } from "../../../../redux/features/user/updateProfileSlice";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";

const formSchema = z.object({
  userId: z.string(),
  background: z.string().optional(),
  location: z.string().optional(),
  isOnBoarding: z.boolean(),
});

const Account = () => {
  const { userId } = useAppSelector((state) => state.userProfile);
  const user = useAuthUser();
  const dispatch = useDispatch<AppDispatch>();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const { profileData, loading, error, updateError, loadingUpdate } =
    useAppSelector((state) => state.userProfile);

  function onSubmit(values: any) {
    dispatch(updateProfile(values))
      .then(() => {
        dispatch(
          notifySelect({
            title: "Update Sent!",
            type: 0,
            open: true,
          })
        );
        mixpanel.track("Account preferences updated", {
          ...values,
          email: user ? user["email"] : "",
        });
      })
      .finally(() => window.location.reload());
  }
  useEffect(() => {
    form.setValue("userId", userId);
    form.setValue("isOnBoarding", false);
    if (updateError) {
      dispatch(
        notifySelect({
          title: "Error Updating",
          type: 1,
          open: true,
        })
      );
    }
  }, [updateError, userId]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {loading ? (
          <Loader />
        ) : (
          <Card className="p-6 bg-white">
            <div className="flex justify-between">
              <h5 className="text-sm text-accentGray-700">
                ACCOUNT PREFERENCES
              </h5>
              {loadingUpdate ? (
                <Loading />
              ) : (
                <GradientBtn type="submit" label="Save Changes" icon="" />
              )}
            </div>
            <hr className="v2 my-6" />

            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11">
              <div>
                <h4 className="text-semibold text-[17px]">Email address</h4>
                <h5 className="text-sm text-accentGray-700">
                  {profileData.email}
                </h5>
              </div>
            </div>
            <hr className="v2 my-6" />
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11">
              <div>
                <h4 className="text-semibold text-[17px]">Gender</h4>
                <h5 className="text-sm text-accentGray-700">
                  {profileData.gender}
                </h5>
              </div>
            </div>
            <hr className="v2 my-6" />
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11">
              <div>
                <h4 className="text-semibold text-[17px]">Background</h4>
                <h5 className="text-sm text-accentGray-700">
                  {profileData.background}
                </h5>
              </div>
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        long
                        onChange={field.onChange}
                        light
                        placeholder={profileData.background}
                        uniqueKey="background-select"
                        options={[
                          { label: "Perfumer", value: "PERFUMER" },
                          {
                            label: "Content Creator",
                            value: "CONTENT_CREATOR",
                          },
                          { label: "Retail Store", value: "RETAIL_STORE" },
                          { label: "Collector", value: "COLLECTOR" },
                          { label: "Seller", value: "SELLER" },
                          { label: "Casual", value: "CASUAL" },
                          { label: "Hobbiest", value: "HOBBIEST" },
                        ]}
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"background"}
                control={form.control}
              />
            </div>

            <hr className="v2 my-6" />

            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11">
              <div>
                <h4 className="text-semibold text-[17px]">Location</h4>
                <h5 className="text-sm text-accentGray-700">
                  {profileData.location}
                </h5>
              </div>
              <FormField
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Select
                        long
                        onChange={field.onChange}
                        light
                        placeholder={profileData.location}
                        uniqueKey="Location-select"
                        options={[
                          { label: "USA", value: "USA" },
                          { label: "United Kingdom", value: "UK" },
                          { label: "India", value: "INDIA" },
                          { label: "Australia", value: "AUS" },
                          { label: "Germany", value: "GERM" },
                          { label: "Brazil", value: "BRZL" },
                          { label: "China", value: "CHINA" },
                          { label: "France", value: "FRAN" },
                          { label: "Italy", value: "ITALY" },
                          { label: "Mexico", value: "MEXI" },
                          { label: "Canada", value: "CAN" },
                          { label: "Netherlands", value: "NETH" },
                          { label: "Sweden", value: "SWED" },
                        ]}
                      />
                    </FormControl>
                  </FormItem>
                )}
                name={"location"}
                control={form.control}
              />
            </div>
            <hr className="v2 my-6" />
            <div className="flex justify-end">
              <RedOutlineBtn icon="/assets/vectors/settings/bin.svg">
                Delete My Account
              </RedOutlineBtn>
            </div>
          </Card>
        )}
      </form>
    </Form>
  );
};

export default Account;
