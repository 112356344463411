import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { z } from "zod";
import useAuthUser from "../../../../hooks/getAuthUser";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import {
  onBoardingUser,
  updateProfile,
  updateProfileProperties,
} from "../../../../redux/features/user/updateProfileSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Form, FormControl, FormField, FormItem } from "../../../ui/form";
import Select from "../../Selectors/select2";
import { Loading } from "../../Widget/Loading";

const formSchema = z.object({
  userId: z.string(),
  oktaId: z.string(),
  isOnBoarding: z.boolean(),
});

const OnBoardingP3 = ({ setValue }: { setValue: (value: string) => void }) => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const [termsAndCon, setTermsAndCon] = useState(false);
  const { userId } = useAppSelector((state) => state.userProfile);
  const post = useAppSelector((state) => state.updateProfile.profileData);
  const { updateError, loadingUpdate } = useAppSelector(
    (state) => state.userProfile
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
  function onSubmit(values: any) {
    let submit = { ...values, ...post };
    if (termsAndCon === true) {
      dispatch(onBoardingUser(submit)).finally(() => window.location.reload());
      mixpanel.track("Member card (Step 4 Complete)", {
        agree: true,
        email: user ? user["email"] : "",
      });
      dispatch(
        notifySelect({
          title: "Welcome Fam!",
          type: 0,
          open: true,
        })
      );
    } else {
      dispatch(
        notifySelect({
          title: "Agree to Terms",
          type: 2,
          open: true,
        })
      );
    }
  }
  useEffect(() => {
    form.setValue("userId", userId);
    if (user) {
      form.setValue("oktaId", user["sub"]);
    }
    form.setValue("isOnBoarding", true);
    if (updateError) {
      dispatch(
        notifySelect({
          title: "Post Not Created 😅",
          type: 1,
          open: true,
        })
      );
    }
  }, [userId, updateError, user]);
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <img
          src="/assets/imgs/common/logo.png"
          className="w-16 mx-auto"
          alt=""
        />
        <p className="text-center text-xl font-extrabold text-gray-100">
          Member Card
        </p>
        <div className="grid  gap-3">
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Interact</div>
            <div className="font-medium mt-1 text-xs">
              Post Listings, Topics and your Collections!
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Avatar & Profile</div>
            <div className="font-medium mt-1 text-xs">
              Go to your profile settings to adjust.
            </div>
          </div>
        </div>
        <hr className=" my-3" />
        <div className="flex items-center gap-2.5 cursor-pointer">
          <input
            type="checkbox"
            id="check"
            checked={termsAndCon}
            className="checked:accent-zinc-100"
            onChange={() => setTermsAndCon(!termsAndCon)}
          />
          <label
            htmlFor="check"
            className="text-sm font-normal text-white cursor-pointer"
          >
            Yes, to confirm terms and conditions.
          </label>
        </div>
        <footer className="my-5 flex justify-center">
          {loadingUpdate ? (
            <Loading />
          ) : (
            <button
              type="submit"
              className="flex items-baseline gap-2 rounded-lg bg-gradient-to-l from-red-500  to-yellow-500  px-4 py-2.5 text-xl font-bold text-white hover:opacity-75 border-2 border-white"
            >
              <span>Submit</span>
              <Icon
                icon="solar:play-bold-duotone"
                className="text-xl text-white h-4"
              />
            </button>
          )}
        </footer>
      </form>
    </Form>
  );
};

export default OnBoardingP3;
