import { Icon } from "@iconify/react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { useState } from "react";
import { cn } from "../../../helpers/utils";
import { useOktaAuth } from "@okta/okta-react";
import GradientBtn from "../../buttons/GradientBtn";
import Select from "../../common/select";
import { Input } from "./input";
import { province } from "../../../data/states";
import { useAppSelector } from "../../../redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";

type BillingExtProps = {};
export function BillingExt({ ...props }: BillingExtProps) {
  const { oktaAuth, authState } = useOktaAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const loggingIn = async () => {
    oktaAuth.closeSession()
    oktaAuth.closeSession()
    navigate("/login");
    // oktaAuth.signInWithRedirect({ originalUri: location.pathname });
  };
  
  const [state, setState] = useState("member");
  const { profileData, loading } = useAppSelector(
     (state) => state.userProfile
   );
  return (
    <>
      <h4 className="text-[16px] font-bold mb-2.5">Checkout</h4>
      <p className="container-mini text-2xs font-semibold mb-2">
        Earn flex points with every purchase to wins giveways and promos
      </p>
      <RadioGroup.Root
        className="flex container-mini flex-col border rounded-md divide-y divide-border border-border items-start"
        defaultValue={authState?.isAuthenticated ? "member" : "guest"}
        onValueChange={(x) => setState(x)}
      >
        <div className="w-full">
          <div className="flex items-start w-full p-3.5 gap-2.5">
            <RadioGroup.Item
              value="member"
              className="aspect-square h-4 peer w-4 rounded-full border aria-checked:bg-[#1773B0]"
            >
              <RadioGroup.Indicator className="after:bg-white relative flex aspect-square h-full w-full items-center justify-center after:block after:h-1 after:w-1 after:rounded-full after:content-['']" />
            </RadioGroup.Item>
            <label
              htmlFor="member"
              className="text-xs -mt-0.5 grow cursor-pointer peer-aria-checked:[&_p]:text-[#1773B0] peer-aria-checked:[&_svg]:text-[#1773B0] gap-2 items-center"
            >
              <div className="flex w-full">
                <p className="text-zinc-900 text-sm font-semibold grow">
                  Member Account +25
                </p>
                {/* <GradientBtn label="Login" className="" /> */}
              </div>
            </label>
          </div>
          <hr />
          <div className="flex items-start w-full p-3.5 gap-2.5">
            <RadioGroup.Item
              value="guest"
              className="aspect-square h-4 peer w-4 rounded-full border aria-checked:bg-[#1773B0]"
            >
              <RadioGroup.Indicator className="after:bg-white relative flex aspect-square h-full w-full items-center justify-center after:block after:h-1 after:w-1 after:rounded-full after:content-['']" />
            </RadioGroup.Item>
            <label
              htmlFor="guest"
              className="text-xs -mt-0.5 grow cursor-pointer peer-aria-checked:[&_p]:text-[#1773B0] peer-aria-checked:[&_svg]:text-[#1773B0] gap-2 items-center"
            >
              <div className="flex w-full">
                <p className="text-zinc-900 text-sm font-semibold grow">
                  Guest Checkout
                </p>
              </div>
            </label>
            {/* same card boxes */}{" "}
          </div>
          <div
            className={cn(
              "bg-[#d1d8e0]/30 space-y-3.5 p-3.5",
              state === "same" ? "" : "hidden"
            )}
          >
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2 container-mini">
              <div className="relative">
                <Input placeholder="Full Name" className="md:max-w-4xl" />
              </div>
              <Input className="md:max-w-4xl" placeholder="Email" />
            </div>
          </div>
        </div>
      </RadioGroup.Root>
    </>
  );
}

