import e from "express";
import { cn } from "../../../../../../helpers/utils";

export function Author({
  el,
  idx,
  topic,
}: {
  el: any;
  idx: number;
  topic?: boolean;
}) {
  return (
    <>
      <div className="flex flex-wrap my-2 items-center justify-between gap-4">
        <div className="flex gap-1 sm:gap-3 max-sm:flex-col"></div>
        <div
          className={cn("flex flex-col items-end justify-center", {
            hidden: topic,
          })}
        >
          {el.type === "PROD_GEAR" || el.type === "PROD_SALE"
            ? el.type !== "PROD_SALE" && (
                <div className="max-sm:flex-col max-sm:[&_*]:text-right items-end sm:items-center ">
                  <div
                    key={1}
                    className="w-full py- px-1.5  text-center rounded-lg text-black text-[10px] md:text-[12px] tracking-wider md:font-semibold"
                  >
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-yellow-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <p className="text-gray-600 font-bold text-sm ml-1">
                        {(
                          (el.postContent.srate +
                            el.postContent.vrate +
                            el.postContent.prate +
                            el.postContent.lrate) /
                          4
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div className="text-[11px] font-bold text-center text-red-400 ">
                    {el.prodSubtitle}
                  </div>
                  {/* <div className="text-[10px] font-semibold text-[#F58960] text-center">
                    {el.prodSubtitle}
                  </div> */}
                </div>
              )
            : null}
          {el.type === "PROD_SALE" && (
            <div className="max-sm:flex-col max-sm:[&_*]:text-right items-end sm:items-center ">
              <p
                key={1}
                className="w-full  text-center text-black text-[18px] tracking-wider font-bold"
              >
                ${el.postContent.price.toFixed(2)}
              </p>
              <p
                key={2}
                className="w-full py- px-1.5  text-center  text-black text-[10px] md:text-[12px] tracking-wider font-semibold"
              >
                {"Listing Price"}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
