import Skeleton from '@mui/material/Skeleton';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchGearRoom } from '../../../redux/features/userCollection/userCollectionSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import Card from '../../common/Card/Card';
import Loader from '../../common/Loader/loader';
import GearIHave from './gear-i-have';
import GearRoomProfile from './profile';

export default function UserGearRoom() {
const dispatch = useAppDispatch();
const { id } = useParams();
useEffect(() => {
 if (id) {
   dispatch(fetchGearRoom(id));
 } 
}, [id]);
  const { currentList, loading } = useAppSelector(
    (state) => state.userCollection
  );
  
  return (
    <div>
      {loading ? (
        <Card className="bg-white mt-4">
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={350}
            height={48}
          />
        </Card>
      ) : (
        <>
          <GearIHave data={currentList} />
        </>
      )}
    </div>
  );
}
