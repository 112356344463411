import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface FeedState {
  loading: boolean;
  sent: boolean;
  error: string | null;
}

const initialState: FeedState = {
  loading: false,
  sent: false,
  error: null,
};

export const createGearPost = createAsyncThunk(
  "add/gear",
  async ({ authorId, brandId, status, prate, vrate }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/gears/add`,

      {
        authorId: authorId,
        brandId: brandId,
        status: status,
        prate: prate + 1,
        vrate: vrate + 1,
      }
    );
    return response.status;
  }
);

const gearSlice = createSlice({
  name: "post/gear",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createGearPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGearPost.fulfilled, (state, action) => {
        state.loading = false;
        state.sent = true;
      })
      .addCase(createGearPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const {} = gearSlice.actions;

export const gearReducer = gearSlice.reducer;
