import { getApps, initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDua7sGjl3vti9fBKPLCC2bQPhOhmJ4p5E",
  authDomain: "muzfi-a7318.firebaseapp.com",
  projectId: "muzfi-a7318",
  storageBucket: "muzfi-a7318.appspot.com",
  messagingSenderId: "258078128399",
  appId: "1:258078128399:web:1f7bf08527d4e798f0a67a",
  measurementId: "G-19CH2KWJ98"
};

let firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0]!;

export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
