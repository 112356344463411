import React from 'react'
import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
 const error = useRouteError();
    return (
    <>
      <h1 className='my-auto mx-auto mt-20'>Oops! An error occurred.</h1>
      {/* {error && <p>{error.message}</p>} */}
    </>
  )
}

export default ErrorPage

