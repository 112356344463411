import { HTMLAttributes, useEffect } from 'react';
import { cn } from '../../../../helpers/utils';
import { fetchComments } from '../../../../redux/features/post/commentsSlice';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Loader from '../../../common/Loader/loader';
import NotifyButton from '../../../common/Notification/NotifyButton';
import Comment from './comment';
import CommentBox from './comment-box';
import { useOktaAuth } from "@okta/okta-react";
import GearDetails from '../New-Gear/details';
import InfoSection from '../New-Gear/info-section';
import { Link } from 'react-router-dom';
import GearGallery from '../New-Gear/carousel';

export default function CommentSection({
  className,
  comments,
  ...props
}: { className?: string; comments?: any; } & HTMLAttributes<HTMLDivElement>) {
 
  
  return (
    <section
      className={cn(
        "mt-4 sm:bg-white sm:p-3 sm:rounded-xl sm:border-slate-400/40",
        {},
        className
      )}
      {...props}
    >
      <Link to={`/single/new-gear/${comments.gear.id}`}>
        <p className="text-base font-semibold">Fragrance Info</p>
        <div className="flex flex-col md:flex-row w-full md:gap-4 lg:gap-2 mt-2">
          <GearGallery
            item={false}
            lg={18}
            md={17}
            imgs={comments.gear.img.split()}
          />
          <div className="md:w-[45em]">
            <GearDetails info={comments.gear} />
          </div>
        </div>
        <InfoSection info={comments.gear} />
      </Link>
    </section>
  );
}
