import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import Community from "./components/pages/Community";
import Listing from "./components/pages/Create-Post/Listing";
import NewGear from "./components/pages/Create-Post/NewGear";
import Polls from "./components/pages/Create-Post/Polls";
import Topic from "./components/pages/Create-Post/Topic";
import Home from "./components/pages/Home/Home";
import MainLayout from "./layouts/MainLayout";
import TabsWrapper from "./layouts/MainLayout/tabs-wrapper";
import About from "./components/pages/About";
import Cart from "./components/pages/Cart";
import CheckoutLayout from "./components/pages/Checkout";
import Help from "./components/pages/Help-Center";
import ForSale from "./components/pages/Market";
import Messages from "./components/pages/Messages";
import MyGearRoom from "./components/pages/My-Gear-Room";
import Notifications from "./components/pages/Notifications";
import Premium from "./components/pages/Premium";
import Account from "./components/pages/Settings/Account";
import NotificationsSettings from "./components/pages/Settings/Notifications";
import Profile from "./components/pages/Settings/Profile";
import Safety from "./components/pages/Settings/Safety";
import SettingsLayout from "./components/pages/Settings/SettingsLayout";
import ListingPage from "./components/pages/Single-Page/Listing";
import NewGearPage from "./components/pages/Single-Page/New-Gear";
import PollsPage from "./components/pages/Single-Page/Poll";
import TopicPage from "./components/pages/Single-Page/Topic";
import Earnings from "./components/pages/Transactions/Earnings";
import ManageListings from "./components/pages/Transactions/ManageListings";
import MyBill from "./components/pages/Transactions/MyBill";
import Orders from "./components/pages/Transactions/Orders";
import Purchases from "./components/pages/Transactions/Purchases";
import TransactionsLayout from "./components/pages/Transactions/TransactionsLayout";
import MyCommunities from "./components/pages/User-Pages/User-Communities/Communities";
import { Confirmation } from "./components/pages/Checkout/confirmation";
import { Info } from "./components/pages/Checkout/info";
import { Payment } from "./components/pages/Checkout/payment";
import Us from "./components/pages/Comp";
import Contact from "./components/pages/Contact";
import Donate from "./components/pages/Donate";
import ModLayout from "./components/pages/Mod-Que/ModLayout";
import { Banned } from "./components/pages/Mod-Que/banned";
import { Insights } from "./components/pages/Mod-Que/insights";
import { Moderators } from "./components/pages/Mod-Que/moderators";
import { Posts } from "./components/pages/Mod-Que/posts";
import { Rules } from "./components/pages/Mod-Que/rules";
import { Setting } from "./components/pages/Mod-Que/setting";
import PolicyLayout from "./components/pages/Policy/PolicyLayout";
import Terms from "./components/pages/Policy/Terms";
import { SearchCommunities } from "./components/pages/Search/communities";
import SearchLayout from "./components/pages/Search/layout";
import CommunityPage from "./components/pages/Community/Community-Page";
import { CommunityMods } from "./components/pages/Community/Community-Page/community-mods";
import { CommunityPost } from "./components/pages/Community/Community-Page/community-post";
import { CommunityRules } from "./components/pages/Community/Community-Page/community-rules";
import { SearchPeople } from "./components/pages/Search/people";
import { SearchPosts } from "./components/pages/Search/post";
import { SearchShop } from "./components/pages/Search/shop";
import OfferSelector from "./components/pages/Transactions/Offers/OfferSelector";
import OwnedCommunities from "./components/pages/User-Pages/User-Communities/Owned";
import UserStore from "./components/pages/User-Pages/User-Store";
import UserThreads from "./components/pages/User-Pages/User-Threads";
import UserWatchlist from "./components/pages/User-Pages/User-Watchlist";
import Privacy from "./components/pages/Policy/Privacy";
import Payouts from "./components/pages/Policy/Payouts";
import Billing from "./components/pages/Policy/Billing";
import Content from "./components/pages/Policy/Content";
import Agreement from "./components/pages/Policy/Agreement";
import HowTo from "./components/pages/How-To";
import AllCommunities from "./components/pages/Community/AllCommunities";
import useAuthUser from "./hooks/getAuthUser";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useEffect, useState } from "react";
import axios from "axios";
import { fetchProfile } from "./redux/features/user/userProfileSlice";
import UserGearRoom from "./components/pages/My-Gear-Room/UserGearRoom";
import MyPage from "./components/pages/User-Pages/MyPage";
import UserPage from "./components/pages/User-Pages/UserPage";
import MyStore from "./components/pages/User-Pages/User-Store/MyStore";
import MyThreads from "./components/pages/User-Pages/User-Threads/MyThreads";
import UserCommunities from "./components/pages/User-Pages/User-Communities/Communities/UserCommunities";
import UserOwnedCommunities from "./components/pages/User-Pages/User-Communities/Owned/UserOwnedCommunities";
import { CommunityStore } from "./components/pages/Community/Community-Page/community-store";
import OfferSent from "./components/pages/Transactions/Offers/OfferSent";
import OfferRecieved from "./components/pages/Transactions/Offers/OfferRecieved";
import MyMainPage from "./components/pages/My-Gear-Room/MyMainPage";
import mixpanel from "mixpanel-browser";
import Login from "./okta/Login";
import LoginForm from "./components/auth/LoginForm";
import RegisterForm from "./components/auth/RegisterForm";
import { oidc } from "./okta/config";
import ErrorPage from "./components/common/Error/ErrorPage";
import MarketHome from "./components/pages/Market/MarketHome";
import NotAdded from "./components/common/Error/NotAdded";
import WomenShop from "./components/pages/Market/WomenShop";
import MenShop from "./components/pages/Market/MenShop";
import Cookies from "js-cookie";
import { fetchCart, fetchCartSession } from "./redux/features/cart/cartSlice";

const AppRouter = () => {
  const { authState } = useOktaAuth();
  const dispatch = useAppDispatch();
  const user = authState?.idToken?.claims;
  const { profileData, userId } = useAppSelector((state) => state.userProfile);
  const getToken = async () => {
    axios({
      // Endpoint to call
      url: "https://webservice.pairrit.com/pairrit-ws/v1/okta/token",
      method: "GET",
    })
      // Handle the response from backend here
      .then((res: any) => {
        localStorage.setItem(
          "myAccessToken",
          "Bearer " + res.data.data.access_token
        );
      })

      .catch((err: any) => {});
  };
  const userToken = Cookies.get("cartId");
  useEffect(() => {
    if (authState?.isAuthenticated) {
      getToken();
      dispatch(fetchCart(userId));
    } else {
      if (userToken) {
        dispatch(fetchCartSession(userToken));
      } else {
        dispatch(fetchCartSession(""));
      }
      localStorage.removeItem("myAccessToken");
      mixpanel.reset();
    }
    if (!profileData) {
      if (user) {
        let email = user.email ?? "";
        dispatch(fetchProfile(email));
        mixpanel.identify(email);
      }
    }
  }, [user, authState, userId]);

  return (
    <Routes>
      <Route path="/login" element={<Login config={oidc} />} />
      <Route path="/signup" element={<RegisterForm />} />
      <Route path="login/callback" element={<LoginCallback />} />
      <Route path="/signup" element={<RegisterForm />} />
      <Route path="/login1" element={<LoginForm />} />
      <Route path="/" element={<MainLayout />} errorElement={<ErrorPage />}>
        <Route path="/home" element={<Home />} />
        <Route path="" element={<MarketHome />} />
        <Route path="/shop/men" element={<MenShop />} />
        <Route path="/shop/women" element={<WomenShop />} />
        <Route path="/market" element={<ForSale />} />
        <Route path="/single/listing/:id" element={<ListingPage />} />
        <Route path="/single/new-gear/:id" element={<NewGearPage />} />
        <Route path="/single/poll/:id" element={<PollsPage />} />
        <Route path="/single/topic/:id" element={<TopicPage />} />
        <Route path="/single/topic-img/:id" element={<TopicPage />} />
        <Route path="/browse/find" element={<Community />} />
        <Route path="/browse/all" element={<AllCommunities />} />
        <Route path="/browse/s/:id" element={<CommunityPage />}>
          <Route index element={<CommunityPost />} />
          <Route path="/browse/s/:id/posts" element={<CommunityPost />} />
          <Route path="/browse/s/:id/rules" element={<CommunityRules />} />
          <Route path="/browse/s/:id/mods" element={<CommunityMods />} />
          <Route path="/browse/s/:id/store" element={<CommunityStore />} />
        </Route>
        <Route path="/my" element={<MyPage />}>
          <Route index element={<MyMainPage />} />
          <Route path="/my/page" element={<MyMainPage />} />
          <Route path="/my/gear" element={<MyGearRoom />} />
          <Route path="/my/shop" element={<MyStore />} />
          <Route path="/my/threads" element={<MyThreads />} />
          <Route path="/my/communities" element={<MyCommunities />} />
          <Route path="/my/communities/own" element={<OwnedCommunities />} />
          <Route path="/my/watchlist" element={<UserWatchlist />} />
        </Route>
        <Route path="/user/:id" element={<UserPage />}>
          <Route index element={<UserGearRoom />} />
          <Route path="/user/:id/gear" element={<UserGearRoom />} />
          <Route path="/user/:id/shop" element={<UserStore />} />
          <Route path="/user/:id/threads" element={<UserThreads />} />
          <Route path="/user/:id/communities" element={<UserCommunities />} />
          <Route
            path="/user/:id/communities/own"
            element={<UserOwnedCommunities />}
          />
        </Route>
        <Route path="/why" element={<Us />} />
        <Route path="/how" element={<HowTo />} />
        <Route path="/premium" element={<Premium />} />
        <Route path="/about" element={<About />} />
        <Route path="/help" element={<Help />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/create" element={<TabsWrapper />}>
          <Route index path="/create/sell" element={<Listing />} />
          {/* <Route path="/create/topic" element={<Topic />} />
          <Route path="/create/poll" element={<Polls />} /> */}
        </Route>
        <Route path="/messages" element={<Messages />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/checkout" element={<CheckoutLayout />}>
          <Route index element={<Info />} />
          <Route path="info" element={<Info />} />
          <Route path="payment" element={<Payment />} />
        </Route>
        <Route path="/order/confirmation/:id" element={<Confirmation />} />
        <Route path="/search" element={<SearchLayout />}>
          <Route index path="fragrances" element={<SearchPosts />} />
          <Route path="market" element={<SearchShop />} />
          <Route path="communities" element={<SearchCommunities />} />
          <Route path="shops" element={<SearchPeople />} />
        </Route>
        <Route path="/cart" element={<Cart />} />
        <Route path="/que/:id" element={<ModLayout />}>
          <Route index path="settings" element={<Setting />} />
          <Route path="rules" element={<Rules />} />
          <Route path="posts" element={<Posts />} />
          <Route path="insights" element={<Insights />} />
          <Route path="mods" element={<Moderators />} />
          <Route path="ban" element={<Banned />} />
          {/* <Route path="approve" element={<Approved />} /> */}
        </Route>
        <Route path="/settings" element={<SettingsLayout />}>
          <Route path="account" element={<Account />} />
          <Route path="profile" element={<Profile />} />
          <Route path="notifications" element={<NotificationsSettings />} />
          {/* <Route path="chat" element={<Chat />} /> */}
          <Route path="payment" element={<MyBill />} />
          <Route path="safety" element={<Safety />} />
        </Route>
        <Route path="/transactions" element={<TransactionsLayout />}>
          <Route path="purchases" element={<Purchases />} />
          <Route path="orders" element={<Orders />} />
          <Route path="offers" element={<OfferSelector />}>
            <Route path="sent" element={<OfferSent />} />
            <Route path="recieved" element={<OfferRecieved />} />
          </Route>
          <Route path="earnings" element={<Earnings />} />
          {/* <Route path="my-bill" element={<MyBill />} /> */}
          <Route path="manage-listings" element={<ManageListings />} />
        </Route>
        <Route path="/policy" element={<PolicyLayout />}>
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="payout" element={<Payouts />} />
          <Route path="billing" element={<Billing />} />
          <Route path="content" element={<Content />} />
          <Route path="agreement" element={<Agreement />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRouter;
