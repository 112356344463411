import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  loadingUpdate: boolean;
  updateError: string | null;
  profileData: any;
  otherProfileData: any;
  preferencesData: any;
  isSearchable: boolean;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  loadingUpdate: false,
  updateError: null,
  profileData: { background: "", location: "", birthDate: "", gender: "" },
  otherProfileData: null,
  preferencesData: null,
  isSearchable: true,
};

export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async ({
    description,
    userName,
    isOnBoarding,
    background,
    location,
    userId,
    profilePicUri,
    gender,
    birthDate,
  }: any) => {
    const response = await axios.put(
      `https://webservice.pairrit.com/pairrit-ws/api/profile/my/${userId}`,
      {
        isOnBoarding: isOnBoarding,
        description: description,
        background: background,
        location: location,
        birthDate: birthDate,
        gender: gender,
        userName: userName,
        profilePicUri: profilePicUri,
      }
    );
    return response.data;
  }
);

export const onBoardingUser = createAsyncThunk(
  "user/updateProfile",
  async ({ oktaId, background, location, userId, gender, birthDate }: any) => {
    const response = await axios.put(
      `https://webservice.pairrit.com/pairrit-ws/api/profile/my/OB/${userId}/${oktaId}`,
      {
        isOnBoarding: true,
        background: background,
        location: location,
        birthDate: birthDate,
        gender: gender,
      }
    );
    return response.data;
  }
);

export const updatePerferences = createAsyncThunk(
  "user/updatePerferences",
  async ({
    userId,
    inboxMessages,
    chatMessages,
    orders,
    offersMadeAccepted,
    mentions,
    commentsOnPosts,
    repliesToComments,
    activity,
  }: any) => {
    const response = await axios.put(
      `https://webservice.pairrit.com/pairrit-ws/api/notification/settings`,
      {
        userId: userId,
        inboxMessages: inboxMessages,
        chatMessages: chatMessages,
        orders: orders,
        offersMadeAccepted: offersMadeAccepted,
        mentions: mentions,
        commentsOnPosts: commentsOnPosts,
        repliesToComments: repliesToComments,
        activity: activity,
      }
    );
    return response.data;
  }
);

export const updateProfileSlice = createSlice({
  name: "updateProfile",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateProfileProperties: (state, action) => {
      state.profileData = { ...state.profileData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.loadingUpdate = true;
        state.updateError = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loadingUpdate = false;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loadingUpdate = false;
        state.updateError = action.error.message || "An error occurred";
      });
  },
});

export const { updateProfileProperties } = updateProfileSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default updateProfileSlice.reducer;
