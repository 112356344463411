import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { addTracking } from "../../../../redux/features/userTransactions/orders/userOrdersSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import GradientBtn from "../../../buttons/GradientBtn";
import DialogHeader from "../../../common/dialog-header";
import { Loading } from "../../../common/Widget/Loading";
import { Form, FormControl, FormField, FormItem } from "../../../ui/form";
import { Input } from "../../Checkout/input";

const formSchema = z.object({
  id: z.string(),
  trackNum: z.string(),
});

interface Props {
  id: any;
}

const TrackingForm: React.FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { trackingLoading, trackingError } = useAppSelector(
    (state) => state.userOrders
  );
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
    dispatch(addTracking(values))
      .then(() =>
        dispatch(
          notifySelect({
            title: "Tracking Added!",
            type: 0,
            open: true,
          })
        )
      )
      .finally(() => window.location.reload());
  }

  useEffect(() => {
    form.setValue("id", id);
    if (trackingError) {
      dispatch(
        notifySelect({
          title: "Error Sending",
          type: 1,
          open: true,
        })
      );
    }
  }, [trackingError, id]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogHeader />
        <div className="mt-3 ">
          <h1 className="text-xl mb-4 text-center xs:w-fit m-auto">
            Add Tracking Number for shipping
          </h1>
          <div>
            <div className="my-2 ">
              <div className="flex gap-2  mx-auto">
                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input className="w-[30em]" {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                  name={"trackNum"}
                  key={"Tracking"}
                  control={form.control}
                />
              </div>
            </div>

            {trackingLoading ? (
              <Loading />
            ) : (
              <GradientBtn label={"Add Tracking"} className={"mx-auto mt-5"} />
            )}
          </div>
        </div>
      </form>
    </Form>
  );
};

export default TrackingForm;

const sortOptions = [
  {
    label: "Arrived Damaged",
    value: "Damaged",
  },
  {
    label: "Don't Like it",
    value: "Dislike",
  },
  {
    label: "Item is defective",
    value: "Defective",
  },
  {
    label: "Received wrong item",
    value: "Wrong",
  },
  {
    label: "Doesn't match description",
    value: "Wrong Description",
  },
  {
    label: "Order by mistake",
    value: "Mistake",
  },
];
