import { HTMLAttributes } from "react";
import { cn } from "../../../../helpers/utils";
import Card from "../../../common/Card/Card";
import { Icon } from "@iconify/react";
import GradientBtn from "../../../buttons/GradientBtn/GradientBtn";
import GreyBtn from "../../../buttons/GreyBtn/GreyBtn";
import { Link } from "react-router-dom";

interface PaymentSectionProps extends HTMLAttributes<HTMLDivElement> {
  info: any;
}

export default function InfoSection({
  className,
  info,
  ...props
}: PaymentSectionProps) {
  return (
    <section
      className={cn(
        "grid grid-cols-1 mb-2 gap-1 max-md:grid-cols-1 ",
        className,
        {}
      )}
      {...props}
    >
      <div className="mt-2  md:block">
        <div className="flex overflow-hidden rounded-lg h-full">
          <div className="min-w-[11px] min-h-full bg-accentYellow-500 " />
          <div className="p-3.5 grow bg-yellow-500/30 space-y-1">
            <p className="font-bold text-2xs lg:text-sm">{info.description}</p>
            <div>
              <p className="font-semibold text-xs">Pairrit Protection</p>
              <p className="text-2xs font-semibold">
                Perfume rating {info.rate} out of 5 with {info.votes} votes
                <Link
                  to={info.fragUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="text-blue-700 underline"> Fragrantica</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Card className="[&>div]:flex !mt-0 space-y-3 !p-4 font-medium [&_aside]:min-w-[87px] bg-white">
        <div>
          <aside className="font-bold text-black text-xs">Shipping:</aside>
          <div className="text-xs">
            <p>
              <span className="font-semibold">
                Standard $4.00 | Express $6.50
              </span>
            </p>
          </div>
        </div>

        <div>
          <aside className="font-bold text-black text-xs">Delivery:</aside>
          <div className="text-xs">
            Estimated between{" "}
            <span className="font-semibold">4-6 Business Days </span>
          </div>
        </div>
        <div className="text-xs">
          <aside className="font-bold text-black text-xs">Returns:</aside>
          <p>Returns only on NEW products</p>
        </div>
        <div>
          <aside className="font-bold text-black text-xs">Payments:</aside>
          <div className="text-xs">
            <div className="flex gap-2.5 flex-wrap">
              {["visa", "mastercard", "american", "paypal"].map((img) => (
                <img
                  alt={img}
                  key={img}
                  src={`/assets/vectors/common/${img}.svg`}
                  className="w-8"
                />
              ))}
            </div>
          </div>
        </div>
      </Card> */}
    </section>
  );
}
