import { Icon } from "@iconify/react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { ComponentProps } from "react";
import { Link } from "react-router-dom";
import Card from "../../common/Card";

type HowPayProps = ComponentProps<"div">;

export function HowPay({ className, ...props }: HowPayProps) {
  return (
    <Card className="md:p-4 max-md:bg-transparent max-md:border-0 bg-white">
      <div className="flex md:items-center max-md:flex-col justify-between">
        <h4 className="md:text-[18px] text-base font-semibold">
          How You'll Pay
        </h4>
        <div className="flex md:items-center text-black max-md:text-xs font-medium gap-1">
          <Icon
            icon={"material-symbols:security"}
            className="text-[#66C600] text-xl md:text-2xl"
          />
          <p className="text-xs">
            Buy with confidence - Pairrit Protection included with all purchases
          </p>
        </div>
      </div>
    </Card>
  );
}
