import { HTMLAttributes, useState } from "react";
import { useParams } from "react-router-dom";
import { cn } from "../../../../helpers/utils";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import YellowBtn from "../../../buttons/YellowBtn/YellowBtn";
import Loader from "../../../common/Loader/loader";
import { useOktaAuth } from "@okta/okta-react";
import {
  joinCommunity,
  unJoinCommunity,
} from "../../../../redux/features/communityFeed/communitySlice";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import GreyBtn from "../../../buttons/GreyBtn";
import DialogWrapper from "../../../common/dialog-wrapper";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import { Icon } from "@iconify/react";
import JoinBtn from "../../../buttons/ActiveBtn/JoinBtn";
import mixpanel from "mixpanel-browser";

interface BannerProps extends HTMLAttributes<HTMLDivElement> {}

export default function Banner({ className, ...props }: BannerProps) {
  const { loading, communityData, subscriberIds } = useAppSelector(
    (state) => state.community
  );
  const { userId } = useAppSelector((state) => state.userProfile);
  const { id } = useParams();

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={cn("lg:hidden", className, {})} {...props}>
          <div className="relative">
            <img
              src="https://www.figma.com/file/mVoqAWE5gPKDvmyKTivM9M/image/b0478ca7f364a7c461b0d95b814fb2abb0c3adea"
              alt=""
              className="h-56 w-full object-cover"
            />
            <img
              src={`/assets/vectors/navbar/${communityData.genre}.svg`}
              alt=""
              className="rounded-full absolute inset-x-0 mx-auto -translate-y-1/2 w-20 aspect-square ring-4 ring-white"
            />
          </div>
          <p className="text-center mt-12 font-bold">c/{communityData.name}</p>
          <p className="text-center text-xs font-medium">
            {communityData.genre}
          </p>
          <p className="mt-2 text-center text-zinc-500 text-xs font-medium">
            {communityData.description}
          </p>
          <div className="flex gap-2 mt-5 ">
            <JoinBtn />
            <DialogWrapper
              wrapperClassName="md:min-w-[500px]"
              className="w-fit"
              content={<CopyLink type="comm" id={id} />}
            >
              <button
                onClick={() => mixpanel.track("Community share button clicked")}
                className="flex items-baseline gap-2 rounded-lg bg-green-500 px-3 py-1.5 text-base font-bold text-white hover:opacity-75 border-2 border-white"
              >
                <Icon
                  icon={"carbon:share"}
                  className="text-xl text-white mx-auto"
                />
              </button>
            </DialogWrapper>
          </div>
        </div>
      )}
    </>
  );
}
