import { useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import { RatingStars } from "./details";
import { Chip } from "@mui/material";
import { Icon } from "@iconify/react";

export const GearCard = ({
  alt = false,
  info,
  isDelete = true,
}: {
  alt?: boolean;
  info: any;
  isDelete?: any;
}) => {
  const [data, setData] = useState([]);
  const [isUser, setIsUser] = useState(false);
  const [loading, setLoading] = useState(false);

  function getRandomNumber() {
    return Math.floor(Math.random() * (35 - 18 + 1)) + 18;
  }

  return (
    <div className=" bg-white rounded-lg duration-500 hover:scale-105 hover:shadow-xl ">
      <div className="h-full w-full bg-neutral-100 rounded-lg  backdrop-blur-[20px] relative">
        <Link
          to={
            info.formula !== "OIL"
              ? `${`/frag/${alt ? info._id : info.id}/${info.model}`}`
              : `${`/oil/${alt ? info._id : info.id}/${info.model}`}`
          }
        >
          {loading ? (
            <Skeleton
              className="my-2 mx-auto"
              variant="rounded"
              width={180}
              height={130}
            />
          ) : (
            <>
              <RatingStars
                className="absolute top-1 left-4"
                numberOfReviews={info.rate.toFixed(2)}
              />
              {info.status !== "active" ? (
                <Chip
                  sx={{
                    fontSize: "9px",
                  }}
                  className="text-2xs  absolute top-1 right-4"
                  color={
                    info.status === "GOLD"
                      ? "warning"
                      : info.status === "TREND"
                      ? "primary"
                      : info.status === "CLASSIC"
                      ? "secondary"
                      : info.status === "OG"
                      ? "success"
                      : "default"
                  }
                  size="small"
                  label={info.status}
                />
              ) : null}
              <img className="h-full w-60 object-cover" src={info.img} alt="" />
            </>
          )}
        </Link>
      </div>
      <Link
        to={
          info.formula !== "OIL"
            ? `${`/frag/${alt ? info._id : info.id}/${info.model}`}`
            : `${`/oil/${alt ? info._id : info.id}/${info.model}`}`
        }
      >
        <div className="md:px-3 px-2 md:py-3 py-1">
          <div className="justify-between flex">
            <span className="flex text-gray-800 font-bold text-2xs md:mr-3 uppercase ">
              {info.formula !== "OIL"
                ? `${info.brandName} `
                : `${info.brandAbr} `}
            </span>
          </div>

          <p className="text-sm md:text-base font-semibold text-black truncate block capitalize">
            {info.model}
          </p>
          <div className="flex items-center">
            <p className="text-base font-light text-zinc-500 italic cursor-auto my-">
              Starting at{" "}
              <span className="text-black font-bold">${info.startPrice}</span>
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};
