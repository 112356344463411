import React from "react";
import SignupA from "./SignupA";
import { inputAssetsA } from "./inputs";
import { useOktaAuth } from "@okta/okta-react";
import { useAppSelector } from '../../redux/hooks';
import { Link, Navigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from "react-router-dom";
import NotifyToast from "../common/Notification/NotifyToast";

const RegisterForm = () => {
 const { authState } = useOktaAuth();
 const navigate = useNavigate();

 const loggingIn = async () => {
   navigate("/login");
   window.location.reload();
 };
   if (!authState) {
     return <div>Loading ... </div>;
   }
  return authState.isAuthenticated ? (
    <Navigate to="/" replace={true} />
  ) : (
    <div
      className="bg-white grid-cols-12 grid gap-10 h-screen scrollbar-hide"
      style={{ overflow: "auto" }}
    >
      <div className="bg-gray-200 hidden md:block md:col-span-4 ">
        <Link
          onClick={() => {
            mixpanel.track("Logo clicked from auth");
          }}
          className=""
          to={"/"}
        >
          <img
            src="/assets/imgs/common/logo-2.png"
            className="w-22 h-16 mt-10 mx-auto"
            alt="logo"
          />
          <h2 className="text-xl text-center">
            Making Community Shopping Fun!
          </h2>
        </Link>

        <img
          className=" h-[25em] w-full object-cover bg-transparent mt-24 mx-auto "
          src="/assets/imgs/home/fragVerify1.png"
          alt=""
        />
      </div>
      <div className="hidden md:block md:col-span-2"></div>
      <div className="col-span-full md:col-span-4 md:w-full w-screen">
        <section>
          <div className="mt-8">
            <p className="text-xs md:text-sm text-center xs:w-fit  m-auto">
              Already have an account?
              {/* <Link
                to="/login"
                className="ml-1 underline font-bold text-orange-600 cursor-pointer"
              >
                Login here!
              </Link> */}
              <span className="ml-1 underline font-bold text-orange-600 cursor-pointer">
                <a href="#" onClick={() => loggingIn()}>
                  Login here!
                </a>
              </span>
            </p>
          </div>
        </section>
        <SignupA inputAssets={inputAssetsA} title="Sign Up" />
      </div>
      <NotifyToast />
    </div>
  );
};

export default RegisterForm;
