import React from 'react'

const WhyUs = () => {
    return (
      <React.Fragment>
        <div className="mt-10 mb-4 text-center font-semibold text-2xl">
          Why Pairrit?
        </div>

        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3">
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Trusted Marketplace</div>
            <div className="font-medium mt-1 text-xs">
              All Sellers are Verified to Sell
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Best Prices</div>
            <div className="font-medium mt-1 text-xs">
              Find Your Favorite Fragrance at Great Prices
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Perfume Oils</div>
            <div className="font-medium mt-1 text-xs">
              We Pair your Favorites into Oil Sprays
            </div>
          </div>
          <div className="bg-white border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Growing Community</div>
            <div className="font-medium mt-1 text-xs">
              We are adding new fragrances everyday
            </div>
          </div>
        </div>
      </React.Fragment>
    );
}

export default WhyUs