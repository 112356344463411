import { Link } from "react-router-dom";

export default function CardContainer() {
  return (
    <section>
     
      <div className="grid grid-cols-4 gap-4 sm:grid-cols-3 md:grid-cols-8">
        {brands.map((record) => (
          <Card link={record.link} img={record.img} />
        ))}
      </div>
    </section>
  );
}

const Card = (props: any) => {
  return (
    <Link to={props.link} className="bg-white border rounded-full border-orange-400/20 ">
      <div className="h-20 w-full rounded-full bg-white backdrop-blur-[10px] p-1.5 shadow-lg">
        <img
          className="object-cover w-full h-full rounded-full "
          src={props.img}
          alt=""
        />
      </div>
    </Link>
  );
};

const brands = [
  {
    title: "YSL",
    img: "https://fimgs.net/mdimg/dizajneri/o.99.jpg",
    link: "/market?brand=Yves+Saint+Laurent",
  },
  {
    title: "Dior",
    img: "https://fimgs.net/mdimg/dizajneri/o.160.jpg",
    link: "/market?brand=Christian+Dior",
  },
  {
    title: "MFK",
    img: "https://fimgs.net/mdimg/dizajneri/o.770.jpg",
    link: "/market?brand=Maison+Francis+Kurkdjian",
  },
  {
    title: "PDM",
    img: "https://fimgs.net/mdimg/dizajneri/o.1188.jpg",
    link: "/market?brand=Parfums+De+Marly",
  },
  {
    title: "Prada",
    img: "https://fimgs.net/mdimg/dizajneri/o.143.jpg",
    link: "/market?brand=Prada",
  },
  {
    title: "Hermes",
    img: "https://fimgs.net/mdimg/dizajneri/o.69.jpg",
    link: "/market?brand=Hermes",
  },
  {
    title: "Chanel",
    img: "https://fimgs.net/mdimg/dizajneri/o.30.jpg",
    link: "/market?brand=Chanel",
  },
  {
    title: "Xerjoff",
    img: "https://fimgs.net/mdimg/dizajneri/o.720.jpg",
    link: "/market?brand=Xerjoff",
  },
];
