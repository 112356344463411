import React from 'react'
import WhyUs from './WhyUs';

const SubWhy = () => {
    return (
      <React.Fragment>
        <div className="mt-10 mb-4 text-center font-semibold text-2xl">
          Verification Process
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-4 gap-3">
          <div className="bg-amber-400  border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Step 1</div>
            <div className="flex justify-center">
              <div className="bg-accentGray-400 py-3 px-4 text-stone-900 my-3 rounded-lg">
                Condition
              </div>
            </div>

            <ul className="text-xs text-start list-disc pl-4">
              <li className="mb-1">Verify Products Condition</li>
              <li className="mb-1">We Confirm Validity</li>
            </ul>
          </div>
          <div className="bg-amber-400 border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Step 2</div>
            <div className="flex justify-center">
              <div className="bg-accentGray-400 py-3 px-4 text-stone-900 my-3 rounded-lg">
                Quality
              </div>
            </div>

            <ul className="text-xs text-start list-disc pl-4">
              <li className="mb-1">Verify product with description</li>
              <li className="mb-1">Test for quailty</li>
            </ul>
          </div>
          <div className="bg-amber-400 border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Step 3</div>
            <div className="flex justify-center">
              <div className="bg-accentGray-400 py-3 px-4 text-stone-900 my-3 rounded-lg">
                Authenicity
              </div>
            </div>

            <ul className="text-xs text-start list-disc pl-4">
              <li className="mb-1">Verify Batch Codes</li>
              <li className="mb-1">Validate with Manufacturer</li>
            </ul>
          </div>
          <div className="bg-amber-400 border border-solid border-accentGray-600 p-4 text-center rounded-lg">
            <div className="font-semibold">Ship 4</div>
            <div className="flex justify-center">
              <div className="bg-accentGray-400 py-3 px-4 text-stone-900 my-3 rounded-lg">
                Packaging
              </div>
            </div>

            <ul className="text-xs text-start list-disc pl-4">
              <li className="mb-1">Product is safely packaged</li>
              <li className="mb-1">Product is shipped</li>
            </ul>
          </div>
        </div>
        <WhyUs />
      </React.Fragment>
    );
}

export default SubWhy