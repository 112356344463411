import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface FeedState {
  loading: boolean;
  error: string | null;
}

const initialState: FeedState = {
  loading: false,
  error: null,
};

export const createPollPost = createAsyncThunk(
  "post/poll",
  async ({
    authorId,
    postCategory,
    community,
    title,
    createdDateTime,
    deadline,
    options,
  }: any) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/polls/create-poll`,
      {
        authorId: authorId,
        postCategory: postCategory,
        community: community,
        title: title,
        createdDateTime: createdDateTime,
        isEnablePostReplyNotification: true,
        reviewed: true,
        isDraft: false,
        deadline: deadline,
        options: options,
      }
    );
    return response.status;
  }
);

const pollSlice = createSlice({
  name: "post/poll",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPollPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPollPost.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createPollPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const {} = pollSlice.actions;

export const pollReducer = pollSlice.reducer;
