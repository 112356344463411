import { Outlet } from "react-router-dom";
import { SearchCategory } from "./category";

export default function SearchLayout() {
  return (
    <div className=" mb-[8em]">
      <SearchCategory />
      <Outlet />
    </div>
  );
}
