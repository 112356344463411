import { Icon } from "@iconify/react";
import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import DialogWrapper from "../../../common/dialog-wrapper";
import { CopyLink } from "../../My-Gear-Room/copy-link";
import mixpanel from "mixpanel-browser";
import { Chip } from "@mui/material";

export default function Share({

  postId,

}: {
  postId: any;

}) {
  


  return (
    <section className="flex select-none w-fit">
      <DialogWrapper
        wrapperClassName="md:min-w-[500px]"
        className="w-fit"
        content={<CopyLink type="listing" id={postId} />}
      >
        <Chip
          icon={<Icon className={"text-2xs"} icon={"carbon:share"} />}
          sx={{
            bgcolor: "background.paper",
            fontSize: "10px",
          }}
          className="text-2xs ml-2"
          color="primary"
          size="small"
          label={`Share`}
          variant="outlined"
          
        />
      </DialogWrapper>
    </section>
  );
}
