import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Card from "../../common/Card/Card";
import Categories from "./Categories";
import Posts from "./Posts";
import axios from "axios";
import FlagsBanner from "./Flags/FlagsBanner";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";
import { useInView } from "framer-motion";

const Home = () => {
  const user = useAuthUser();
  const { homeCategory } = useAppSelector((state) => state.homeFeed);
  const [items, setItems] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(0);
  const infiniteScrollRef = React.useRef(null);

  useEffect(() => {
    axios
      .get(`https://webservice.pairrit.com/pairrit-ws/api/posts/${index}`)
      .then((res) => setItems(res.data))
      .catch((err) => console.log(err));
    mixpanel.track("Feed page view", { email: user ? user["email"] : "" });
  }, []);

  const loadMore = useInView(infiniteScrollRef);

  React.useEffect(() => {
    if (!loadMore) return;
    axios
      .get(`https://webservice.pairrit.com/pairrit-ws/api/posts/${index}`)
      .then((res) => {
        setItems((p: any) => [...p, ...res.data]);
        res.data.length > 0 ? setHasMore(true) : setHasMore(false);
      })
      .catch((err) => console.log(err));

    setIndex((prevIndex) => prevIndex + 1);
  }, [loadMore]);

  return (
    <React.Fragment>
      <Categories value={homeCategory} select={1} />
      <FlagsBanner />
      <Categories value={homeCategory} select={2} />
      <Posts posts={items} />
      <br />
      {hasMore ? (
        <Card className="bg-white mt-4">
          <div ref={infiniteScrollRef} />
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={350}
            height={48}
          />
        </Card>
      ) : (
        <center>No more data to load.</center>
      )}
      {/* <FeedType /> */}
      {/* {loading ? (
        <Card className="bg-white mt-4">
          <Skeleton
            className="my-2 mx-auto"
            variant="rounded"
            width={350}
            height={48}
          />
        </Card>
      ) : (
        <Posts posts={todos} />
      )} */}
    </React.Fragment>
  );
};

export default Home;
