import { Icon } from "@iconify/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { fetchUserProducts } from "../../../../redux/features/userStore/userStoreSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import DialogWrapper from "../../../common/dialog-wrapper";
import NotAdded from "../../../common/Error/NotAdded";
import Loader from "../../../common/Loader/loader";
import Select from "../../../common/select";
import { BoostListing } from "./BoostListing";
import { Listing } from "./Listing";
import { RemoveListing } from "./RemoveListing";

const ManageListings = () => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { userProducts, loading, error } = useAppSelector(
    (state) => state.userStore
  );
  useEffect(() => {
    dispatch(fetchUserProducts(userId));
  }, [userId]);
  return (
    <div className="mt-5">
      {loading ? (
        <Loader />
      ) : userProducts?.length != 0 ? (
        <div className="mt-5">
          {userProducts?.map((el, idx) => (
            <Listing info={el} key={idx} />
          ))}
        </div>
      ) : (
        <NotAdded
          m1="Nothing to Find"
          m2=" No Products Yet"
          m3="Start Listing to earn FlexPoints"
        />
      )}
    </div>
  );
};

export default ManageListings;
