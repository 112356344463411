import Header from "./Header";
import Search from "../../common/Search/Search";
import { useOktaAuth } from "@okta/okta-react";
import ChatPeople from "./ChatPeople";
import GreyBtn from "../../buttons/GreyBtn/GreyBtn";
import React, {
  ElementRef,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { useAppSelector } from "../../../redux/hooks";
import {
  getMessages,
  markAsSeen,
  MessageType,
  sendMessage,
  uploadFile,
} from "../../../helpers/firestore";
import { Link, useSearchParams } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { cn } from "../../../helpers/utils";
import { useScrollableChatContainer } from "../../../hooks/useScrollableContainer";
import { motion, useInView } from "framer-motion";
import { Icon } from "@iconify/react";
import { SearchPosts } from "../Search/post";
import { SearchPeople } from "./ChatPeople/search-people";
import { search } from "../../../redux/features/search/searchSlice";
import ComingSoon from "../../common/Error/ComingSoon";
import mixpanel from "mixpanel-browser";

const Messages = () => {
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState<MessageType[]>([]);
  const { userId } = useAppSelector((state) => state.userProfile);
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const [userQuery, setUserQuery] = React.useState("");
  const inputRef = React.useRef<ElementRef<"input">>(null);
  const { authState } = useOktaAuth();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("user");
  const otherUser = useUser(id);
  useEffect(() => {
    mixpanel.track("Messages page view");
  }, []);

  useEffect(() => {
    const unsub = getMessages({ sender: userId, receiver: id || "" }, (m) => {
      setMessages(m);
    });
    return () => unsub();
  }, [id]);
  const scrollableChatContainerRef = useScrollableChatContainer([
    messages?.length,
  ]);

  const handleSendMessage: FormEventHandler<HTMLFormElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!id) return alert("Select a chat");
    if (!userId) return alert("Login First");
    if (!inputValue.trim()) return;
    sendMessage({ message: inputValue.trim(), sender: userId, receiver: id });
    mixpanel.track("Message Sent");
    setInputValue("");
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUserQuery(e.target.value);
  }

  function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target?.files?.[0]) return;
    setUploadingFile(true);
    uploadFile(e.target.files[0])
      .then((res) => {
        if (!id) return alert("Select a chat");
        if (!userId) return alert("Login First");
        sendMessage({
          message: "file:///" + (res as any)?.url,
          sender: userId,
          receiver: id,
        });
      })
      .finally(() => {
        setUploadingFile(false);
      });
  }

  if (!authState?.isAuthenticated) {
    return <ComingSoon />;
  }
  return (
    <div className="mb-[6em] lg:mt-10">
      <Header />
      <div className="flex">
        <div className="flex flex-col grow xl:flex-row bg-white border border-solid border-accentGray-600 rounded-lg">
          <div className="flex-shrink-0 max-w-auto xl:max-w-[300px] pb-0">
            <div className="">
              <div className="p-[10px]">
                <SearchPeople
                  placeholder="Search people"
                  onChange={handleChange}
                />
              </div>
            </div>

            <ChatPeople query={userQuery} />
          </div>
          <div className="flex-grow my-[10px] border-t xl:border-t-0 border-l border-solid border-accentGray-600 flex flex-col py-[10px]">
            {otherUser && (
              <Link
                to={`/user/${id}/shop`}
                className="flex justify-between items-start mx-2 pb-2 border-b border-solid border-accentGray-600"
              >
                <div className="flex items-center gap-2">
                  <img
                    className="w-[42px] h-w-[42px] rounded-[50%] flex-shrink-0"
                    src={`/assets/imgs/avatar/${otherUser?.profileUrl}.svg`}
                    alt={otherUser?.userName}
                  />
                  <div>
                    <div className="font-semibold text-sm">
                      {otherUser?.userName}
                    </div>
                    <div className="flex-auto text-gray-400 text-[10px]">
                      <span className="mr-1 ">{otherUser?.background}</span>
                      <span className="mr-1 border-r border-gray-600  max-h-0"></span>
                      <span>{otherUser?.location}</span>
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 pt-2 pr-2">
                  <img
                    className="cursor-pointer"
                    src="/assets/vectors/messages/star.svg"
                    alt="star"
                  />
                  <img
                    className="cursor-pointer"
                    src="/assets/vectors/messages/menu.svg"
                    alt="menu"
                  />
                </div>
              </Link>
            )}
            <div className="flex-grow flex justify-end flex-col gap-5 pt-5">
              <div
                ref={scrollableChatContainerRef}
                className="px-[10px] flex flex-col gap-5 h-72 overflow-y-auto"
              >
                {id &&
                  messages?.map((m, idx) => (
                    <MessageItem
                      message={m}
                      key={m.createdAt}
                      isLast={messages.length === idx + 1}
                      currentUser={userId}
                      otherUser={id}
                    />
                  ))}
              </div>
              <form
                onSubmit={handleSendMessage}
                className="p-[10px] bg-accentGray-400 rounded-lg mx-[10px]"
              >
                <div className="bg-white rounded-lg shadow-md relative">
                  <input
                    className="bg-white h-12 rounded-lg pl-[10px] outline-none w-full"
                    type="text"
                    placeholder="Type a message"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />

                  <div className="flex items-center gap-4 absolute right-1 top-1 bottom-1">
                    <input
                      onChange={handleFileUpload}
                      type={"file"}
                      accept="image/*"
                      ref={inputRef}
                      hidden
                    />
                    {uploadingFile ? (
                      <Icon
                        icon="eva:loader-outline"
                        className="animate-spin text-xl"
                      />
                    ) : (
                      <img
                        onClick={() => inputRef.current?.click()}
                        className="cursor-pointer"
                        src="/assets/vectors/messages/img-selector.svg"
                        alt="img-selector"
                      />
                    )}
                    <button
                      type="submit"
                      className="cursor-pointer w-12 h-9 rounded-md bg-accentGreen-500 flex items-center justify-center"
                    >
                      <img src="/assets/vectors/messages/send.svg" alt="send" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MessageItem = ({
  message: m,
  currentUser,
  otherUser,
  isLast,
}: {
  message: MessageType;
  currentUser: string;
  otherUser: string;
  isLast: boolean;
}) => {
  const messageItemRef = React.useRef(null);
  const inView = useInView(messageItemRef);
  const me = currentUser === m.sender;
  React.useEffect(() => {
    if (inView && isLast && m.sender !== currentUser)
      markAsSeen({ sender: currentUser, receiver: otherUser }).then(() =>
        console.log("marked as seen")
      );
  }, [inView]);

  if (m.message)
    return (
      <div
        ref={messageItemRef}
        className={cn("flex items-end gap-1", me && "justify-end")}
      >
        {me ? (
          m.unSeen ? (
            <Icon icon="quill:checkmark-double" className="grayscale" />
          ) : (
            <Icon
              icon="quill:checkmark-double"
              className="text-accentRed-500"
            />
          )
        ) : null}
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          className={cn(
            "p-2 has-[img]:p-0 overflow-clip bg-accentGray-400 w-fit max-w-[70%] rounded-lg ",
            me
              ? "bg-accentYellow rounded-br-none origin-bottom-right"
              : "rounded-bl-none origin-bottom-left"
          )}
        >
          {m.message.startsWith("file:///") ? (
            <img
              src={m.message.split("file:///").pop()}
              className="object-cover w-full h-auto"
            />
          ) : (
            <span> {m.message}</span>
          )}
        </motion.div>
      </div>
    );
  return null;
};

export default Messages;
