import React, { useEffect, useState } from "react";
import GreyBtn from "../../../buttons/GreyBtn/GreyBtn";
import YellowBtn from "../../../buttons/YellowBtn/YellowBtn";
import { TextField } from "../../../text-field";
import GearRatings from "./GearRatings";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../ui/form";
import { Input } from "../../../common/input";
import Select from "../../../common/select";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { createGearPost } from "../../../../redux/features/post/gearSlice";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import Loader from "../../../common/Loader/loader";
import { useOktaAuth } from "@okta/okta-react";
import UploaderMulti from "../../../common/uploaderMulti";
import mixpanel from "mixpanel-browser";
import DialogHeader from "../../../common/dialog-header";
import { Icon } from "@iconify/react";
import Card from "../../../common/Card";
import { Loading } from "../../../common/Widget/Loading";
import GradientBtn from "../../../buttons/GradientBtn";
import { Privacy } from "../../../common/privacy";
import { Link, useParams } from "react-router-dom";

const formSchema = z.object({
  authorId: z.string(),
  brandId: z.string(),
  status: z.string(),
  prate: z.number(),
  vrate: z.number(),
});
const NewGear = () => {
  const dispatch = useAppDispatch();
  const { authState } = useOktaAuth();
  const { id } = useParams();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { loading, sent, error } = useAppSelector((state) => state.postGear);
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: any) {
    if (authState?.isAuthenticated) {
        dispatch(createGearPost(values));
        dispatch(
          notifySelect({
            title: "Post is Sent!",
            type: 0,
            open: true,
          })
        );
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
    }
  }

  useEffect(() => {
    form.setValue("authorId", userId);
    form.setValue("brandId", id);
    if (error) {
      dispatch(
        notifySelect({
          title: "Frag Not Added 😅",
          type: 1,
          open: true,
        })
      );
    }
  }, [error, userId]);
  return (
    <>
      {sent ? (
        <Card>
          <Icon
            icon={"icon-park-twotone:success"}
            className="h-12 w-12 text-green-400 mx-auto my-3"
          />
          <h1 className="text-center">
            Success Your Fragrance is added!
          </h1>
          <div className="flex flex-col gap-2 [&_*]:text-black items-center mt-4 w-full ">
            <Link
              className={"w-full [&_*]:!text-white  mx-auto"}
              to={"/my/gear"}
            >
              <GradientBtn
                className={"w-full [&_*]:!text-white mb-2"}
                label={"My Collection"}
              />
            </Link>
            <div className="w-full flex [&_a]:w-full gap-3">
              <GreyBtn
                to={`/my/page`}
                label={`My Profile`}
                className={"border w-full p-0"}
              />
              <GreyBtn
                to={`/my/shop`}
                label={`My Shop`}
                className={"border w-full p-0"}
              />
            </div>
          </div>
        </Card>
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <DialogHeader />
            <h2 className="text-center text-black text-xl font-semibold">
              Build Collection
            </h2>
            <p className="text-center text-black text-xs font-normal">
              10pts per fragrance
            </p>
            <div className="grid grid-cols-2 gap-1 my-4 ">
              <div className="w-full">
                <p className=" text-[13px] font-semibold my-1">
                  Is This Your Signature Scent?
                </p>

                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <>
                          <FormMessage className="text-[10px]" />
                          <Privacy
                            level={level}
                            defaultValue={"Fun"}
                            onOptionSelect={field.onChange}
                          />
                        </>
                      </FormControl>
                    </FormItem>
                  )}
                  name={"status"}
                  control={form.control}
                />
              </div>
            </div>
            <div className="grid grid-cols-2  gap-2 mb-2">
              {data3.map((_, idx) => (
                <FormField
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <GearRatings
                          label={_.label}
                          placeholder={_.placeholder}
                          {...field}
                          onOptionSelect={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                  name={_.title}
                  key={_.title}
                  control={form.control}
                />
              ))}
            </div>

            <div className="-mx-6 p-3.5 -mb-6 flex justify-end items-center gap-2.5 bg-gray-100 mt-6">
              {loading ? (
                <Loading />
              ) : (
                <GradientBtn
                  type="submit"
                  label={"Add Fragrance"}
                  textClassName="text-white"
                  className="mx-auto mt-2 w-full [&_*]:!text-white "
                />
              )}
              {/* <YellowBtn type="submit" label={"Post"} className="px-6" /> */}
            </div>
          </form>
        </Form>
      )}
    </>
  );
};

export default NewGear;

// const level = [
//   { label: "Signature" },
//   { label: "Fun"},
//   { label: "Daily"},
//   { label: "Exclusive"},
// ];

const level = [
  {
    label: "Signature",
    value: "Signature",
  },
  {

    label: "Fun",
    value: "Fun",
  },
  {
   
    label: "Daily",
    value: "Daily",
  },
  {
 
    label: "Exclusive",
    value: "Exclusive",
  },
];

const data3 = [
  {
    label: "Performance Rating",
    title: "prate",
    placeholder: "The fragrance projection and strength",
  },
  {
    label: "Value Rating",
    title: "vrate",
    placeholder: "The quality, worth, or value of the fragrance",
  },
];
