import React, { useEffect } from "react";

import CommunityItems from "./CommunityItems";
import Wrapper from "../../../layouts/MainLayout/wrapper";

import Category from "./category";
import Filter from "./filter";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  categorySelect,
  fetchCommunities,
} from "../../../redux/features/communties/communtiesSlice";
import Loader from "../../common/Loader/loader";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../hooks/getAuthUser";

const AllCommunities = () => {
  const dispatch = useAppDispatch();
  const user = useAuthUser();
  const { communitiesList, loading } = useAppSelector(
    (state) => state.communities
  );
  useEffect(() => {
    dispatch(fetchCommunities());
    mixpanel.track("All Communities page view", {
      email: user ? user["email"] : "",
    });
  }, []);
  return (
    <div className="lg:mt-10 mb-[8em]">
      <Category category={category1} />
      <Wrapper>
        {loading ? <Loader /> : null}
        {communitiesList && <CommunityItems value={communitiesList} />}
      </Wrapper>
    </div>
  );
};

export default AllCommunities;

const category1 = [
  {
    title: "Find By Category",
    icon: "eva:flash-outline",
    active: true,
    link: "/browse/find",
  },
  {
    title: "All Communities",
    icon: "ri:team-fill",
    active: true,
    link: "/browse/all",
  },
];
