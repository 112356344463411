import React from 'react'
import { choices, privacy } from './PolicyData/privacy';


const Privacy = () => {
  return (
    <div className=" mt-4  mx-auto  leading-relaxed">
      <h3 className="text-lg">Privacy Policy</h3>
      {privacy.map((_) => (
        <div>
          <h3 className="text-sm underline font-bold">{_.title}</h3>
          <p className="pb-1 text-xs">{_.terms}</p>
        </div>
      ))}
      <h3 className="text-sm underline font-bold">
        15. Choices and Rights About How We Use and Disclose Your Information
      </h3>
      {choices.map((_) => (
        <p className="pb-1 text-xs">{_}</p>
      ))}
      <p>Effective Date: Dec 5, 2023</p>
      <p>Updated: November 3, 2023</p>
    </div>
  );
}

export default Privacy