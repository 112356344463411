import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import {
  addNewListingProperties,
  createListingPost,
} from "../../../../redux/features/post/postSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import GreyBtn2 from "../../../buttons/GreyBtn2";
import YellowBtn from "../../../buttons/YellowBtn/YellowBtn";
import Loader from "../../../common/Loader/loader";
import { Form } from "../../../ui/form";
import TableWrapper from "./table-wrapper";
import { useOktaAuth } from "@okta/okta-react";
import { Link, redirect, useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";
import { Loading } from "../../../common/Widget/Loading";
import Card from "../../../common/Card";
import GradientBtn from "../../../buttons/GradientBtn";
import GreyBtn from "../../../buttons/GreyBtn";

export default function Review({
  setValue,
}: {
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const now = new Date();
  const form = useForm({});
  const user = useAuthUser();
  const { userId } = useAppSelector((state) => state.userProfile);
  const { loading, error, success, category, community } = useAppSelector(
    (state) => state.post
  );
  const post = useAppSelector((state) => state.post.newListing);
  const brand = useAppSelector((state) => state.post.brand);
  const [picPreview, setPicPreview] = useState<any>(post.pics);
  const [review, setReview] = useState([
    { "Listing Title": post.model},
    { Size: `${post.size}oz` },
    { Brand: brand },
    {Price: `$${post.price}`}
  ]);
  const [desc, setDesc] = useState([
    { Condition: post.condition },
    { Usage: `${post.usage}%` },
    { Quantity: post.quantity },
    { Description: post.description },
  ]);

  const [isValue, setIsValue] = useState({
    authorId: userId,
    postCategory: category.toUpperCase(),
    community: community,
    createdDateTime: now,
  });

  function onSubmit(values: any) {
    if (authState?.isAuthenticated) {
      dispatch(createListingPost(post));
      dispatch(
        notifySelect({
          title: "Listing is Up!🤑",
          type: 0,
          open: true,
        })
      );
      mixpanel.track("Sell product (Step 5 Completed)", {
        ...post,
        email: user ? user["email"] : "",
      });
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin 🙄",
          type: 1,
          open: true,
        })
      );
    }
  }

  useEffect(() => {
    dispatch(addNewListingProperties(isValue));
    if (error) {
      dispatch(
        notifySelect({
          title: "Post Not Created 😅",
          type: 1,
          open: true,
        })
      );
    }
  }, [category, error]);

  return (
    <>
      {success ? (
        <Card>
          <div className="flex items-center text-sm gap-2.5">
            <p>Your Listings</p>{" "}
            <Icon icon={"ri:arrow-right-s-line"} className="text-xs" />
            <p>{post.model}</p>
          </div>

          <Icon
            icon={"icon-park-twotone:success"}
            className="h-12 w-12 text-green-400 mx-auto my-3"
          />
          <h1 className="text-center">Your New Listing has been Created!</h1>
          <div className="flex flex-col gap-2 [&_*]:text-black items-center mt-2 lg:my-4 w-full ">
            <Link
              className={"w-full [&_*]:!text-white mb-2 mx-auto"}
              to={"/my/shop"}
            >
              <GradientBtn
                className={"w-full [&_*]:!text-white mb-2"}
                label={"View My Shop"}
              />
            </Link>
            <div className="w-full flex [&_a]:w-full gap-3">
              <GreyBtn
                to={`/transactions/manage-listings`}
                label={`Edit Listings`}
                className={"border w-full p-0"}
              />
              <Link
                className={
                  "font-medium w-full p-0 flex items-center justify-center rounded-md bg-accentGray-400 hover:bg-accentGray-500 text-accentGray-700 gap-1.5 transition-colors text-sm border border-solid border-[rgba(134, 145, 168, 0.20)]"
                }
                to={"/create/sell"}
                onClick={() => window.location.reload()}
              >
                Add Another Listing
              </Link>
            </div>
          </div>
          <TableWrapper label="Product Info">
            <p className="text-sm font-semibold mb-2.5">Your Product</p>
            <div className="grid grid-cols-[3fr_5fr] gap-1.5">
              {review.map((_) => (
                <>
                  <p className="text-xs font-semibold">{Object.keys(_)}</p>
                  <p className="text-xs font-medium ">{Object.values(_)}</p>
                </>
              ))}
            </div>
          </TableWrapper>
          <TableWrapper label="Photos & Description">
        { post.condition === "NEW" ? null :   <div
              className="w-[100px] h-[100px] bg-zinc-100 rounded-lg border mt-1 mb-4 border-slate-400/20 flex gap-2"
              role="img"
            >
              {picPreview.slice(0, 4).map((_: any, idx: any) => (
                <img
                  className="rounded object-bottom w-full h-24 "
                  src={_}
                  alt="pics"
                />
              ))}
            </div>}
            <div className="grid grid-cols-[3fr_5fr] gap-1.5">
              {desc.map((_) => (
                <>
                  <p className="text-xs font-semibold">{Object.keys(_)}</p>
                  <p className="text-xs font-medium ">{Object.values(_)}</p>
                </>
              ))}
            </div>
          </TableWrapper>
        </Card>
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex items-center text-sm gap-2.5">
              <p>Your Listings</p>{" "}
              <Icon icon={"ri:arrow-right-s-line"} className="text-xs" />
              <p>{post.name}</p>
            </div>
            <div className="flex items-center justify-between my-4">
              <p className="font-semibold  mb-2.5">Review </p>
              {loading ? (
                <Loading />
              ) : (
                <YellowBtn type="submit" label={"Publish"} short />
              )}{" "}
            </div>
            <TableWrapper label="Product Info">
              <p className="text-sm font-semibold mb-2.5">Your Product</p>
              <div className="grid grid-cols-[3fr_5fr] gap-1.5">
                {review.map((_) => (
                  <>
                    <p className="text-xs font-semibold">{Object.keys(_)}</p>
                    <p className="text-xs font-medium ">{Object.values(_)}</p>
                  </>
                ))}
              </div>
            </TableWrapper>
            <TableWrapper label="Photos & Description">
                {post.condition === "NEW" ? null : <div
                  className="w-[100px] h-[100px] bg-zinc-100 rounded-lg border mt-1 mb-4 border-slate-400/20 flex gap-2"
                  role="img"
                >
                  {picPreview.slice(0, 4).map((_: any, idx: any) => (
                    <img
                      className="rounded object-bottom w-full h-24 "
                      src={_}
                      alt="pics"
                    />
                  ))}
                </div>}
              <div className="grid grid-cols-[3fr_5fr] gap-1.5">
                {desc.map((_) => (
                  <>
                    <p className="text-xs font-semibold">{Object.keys(_)}</p>
                    <p className="text-xs font-medium ">{Object.values(_)}</p>
                  </>
                ))}
              </div>
            </TableWrapper>
            <TableWrapper label="Shipping" className="space-y-1.5">
              <div className="grid grid-cols-[3fr_5fr] gap-1.5">
                <p className="text-xs font-semibold">
                  {" "}
                  Add Pre-Paid Shipping Label?
                </p>
                <p className="text-xs font-medium ">
                 Yes
                </p>
              </div>
              <div className="grid grid-cols-[3fr_5fr] gap-1.5">
                <p className="text-xs font-semibold">Continental U.S.</p>
                <p className="text-xs font-medium ">$4.00</p>
              </div>
            </TableWrapper>
            <div className="flex gap-5">
              <GreyBtn2
                className="w-32 my-8 text-sm"
                label={"Back"}
                onClick={() => setValue("Pricing")}
              />
              {loading ? (
                <Loader />
              ) : (
                <YellowBtn
                  type="submit"
                  className="px-8 my-8 w-fit text-sm"
                  label={"Publish"}
                />
              )}
            </div>
          </form>
        </Form>
      )}
    </>
  );
}

const yourGear = [
  { "Listing Title": "Fender Jazz Bass 2010" },
  { "Listing State": "Draft Listing" },
  { Brand: "Fender" },
  { Model: "Jazz Bass" },
  { Year: "2010" },
  { Category: "Keyboards and Synths" },
  { "Handmade?": "Yes" },
];

const photosSect = [
  { "Condition & Description": null },
  { Condition: "Mint" },
  { Description: "ZXZz" },
  { 'Sold "As-Described"?': "Yes" },
];
