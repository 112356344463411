import { OktaAuthOptions } from "@okta/okta-auth-js";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "0oagk58h061PhVQB35d7";
const ISSUER =
  process.env.REACT_APP_ISSUER ||
  "https://auth.pairrit.com/oauth2/default";
// "https://dev-54027024.okta.com/oauth2/default";
const REDIRECT_URI = `${window.location.origin}/login/callback`;

export const oidc: any = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  el: '#widget-container',
  useInteractionCodeFlow: false,
  useClassicEngine: true,
  pkce: true,
  scopes: ['openid', 'profile', 'email'],
  redirectUri: REDIRECT_URI
};
