import Card from "../../../common/Card";
import Switch from "../../../common/Switch";
import { z } from "zod";
import { useDispatch } from "react-redux";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem } from "../../../ui/form";
import { useAppSelector } from "../../../../redux/hooks";
import GradientBtn from "../../../buttons/GradientBtn";
import Loader from "../../../common/Loader/loader";
import { updateProfileProperties } from "../../../../redux/features/user/updateProfileSlice";

const formSchema = z.object({
  isSearchable: z.boolean().optional(),
});
const Account = () => {
  const { profileData, loading } = useAppSelector((state) => state.userProfile);
    const dispatch = useDispatch();
  const form = useForm({
    resolver: zodResolver(formSchema),
  });
    function onSubmit(values: any) {
      dispatch(updateProfileProperties(values));
    }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {loading ? (
          <Loader />
        ) : (
          <Card className="p-6 bg-white">
            <div className="flex justify-between">
              <h5 className="text-sm text-accentGray-700">SAFETY & PRIVACY</h5>
              <GradientBtn type="submit" label="Save Changes" icon="" />
            </div>
            <hr className="v2 my-6" />
            <div className="">
              <div>
                <h5 className="text-sm text-accentGray-700">PRIVACY</h5>
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-11 mt-3">
                  <div>
                    <h4 className="text-semibold text-[17px]">
                      Show up in search results
                    </h4>
                    <h5 className="text-xs text-accentGray-700">
                      Allow search engines like Google to link to your profile
                      in their search results.
                    </h5>
                  </div>
                  <FormField
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Switch
                            value={profileData.isShowsUpInSearchResults}
                            onOptionSelect={field.onChange}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                    name={"isSearchable"}
                    key={"isSearchable"}
                    control={form.control}
                  />
                </div>
              </div>
            </div>
            <hr className="v2 my-6" />
          </Card>
        )}
      </form>
    </Form>
  );
};

export default Account;
