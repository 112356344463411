import { Icon } from '@iconify/react';
import React from 'react'
import { Link } from 'react-router-dom';
import Flags from '../Home/Flags';
import Flags2 from '../Home/Flags/Flags2';
import FlagsBanner from '../Home/Flags/FlagsBanner';
import ShopFlags from '../Home/Flags/ShopFlags';
import Footer from '../Home/Footer';
import { PayoutBox } from '../How-To/Payout';
import WhyUs from '../Protection/WhyUs';
import GearList from '../Single-Page/New-Gear/gear-list';
import CardContainer from './CardContainer';
import ProductList from './product-list';

const MarketHome = () => {
  return (
    <div className="mb-[8em] ">
      {/* <MarketCategories value={category1} select={1} /> */}
      <Flags
        m1="Essence In Every Bottle"
        m2="All Sellers are Verified"
        m3="Shop Here"
        m4={3}
        m5=""
      />
      <Flags2
        m1="Essence In Every Bottle"
        m2="All Sellers are Verified"
        m3="Shop Here"
        m4={3}
        m5=""
      />
      {/* <MarketCategories value={category1} select={2} /> */}
      <div className="mt-4">
        <Link to={"/market"}>
          <p className="text-sm md:text-base font-bold flex mb-3">
            Find Your Fragrance
            <Icon
              icon="ph:arrow-right-duotone"
              className="my-auto ml-1 text-xl"
            />
          </p>
        </Link>
        <div className="h-0 border-t border-slate-400/20" />
        <ShopFlags />
      </div>
      <div className="lg:mt-10 mt-4">
        <Link to={"/market"}>
          <p className="text-sm md:text-base font-bold flex">
            Recommended For You{" "}
            <Icon
              icon="ph:arrow-right-duotone"
              className="my-auto ml-1 text-xl"
            />
          </p>
        </Link>
        <div className="h-0  border-t border-slate-400/20" />
        <GearList
          value={list}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>
      <WhyUs />
      <div className="lg:mt-10 mt-4">
        <Link to={"/market"}>
          <p className="text-sm md:text-base font-bold flex">
            Original Scents - Perfume Oils{" "}
            <Icon
              icon="ph:arrow-right-duotone"
              className="my-auto ml-1 text-xl"
            />
          </p>
        </Link>
        <div className="h-0  border-t border-slate-400/20" />
        <GearList
          value={listO}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>
      <img
        className="w-[2/3] h-20 mx-auto object-cover mt-8 rounded-lg"
        src="/assets/imgs/home/banner-2.png"
        alt="ad"
      />
      <div className="lg:mt-20 mt-4">
        <Link to={"/market"}>
          <p className="text-sm md:text-base font-bold flex mb-1">
            Shop Top Brands{" "}
            <Icon
              icon="ph:arrow-right-duotone"
              className="my-auto ml-1 text-xl"
            />
          </p>
        </Link>

        <div className="h-0 mb-5  border-t border-slate-400/20" />
        <CardContainer />
      </div>
      <div className="lg:mt-20 mt-4">
        <p className="text-sm md:text-base font-bold flex ">
          Most Popular Scents
          <Icon
            icon="ph:arrow-right-duotone"
            className="my-auto ml-1 text-xl"
          />
        </p>
        <div className="h-0  border-t border-slate-400/20" />
        <GearList
          value={prodList}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>
      <img
        className="w-[75%] h-20  mx-auto object-cover my-10 rounded-lg"
        src="/assets/imgs/home/banner-3.png"
        alt="ad"
      />
      <div className="lg:mt-18 mt-4">
        <p className="text-sm md:text-base font-bold flex">
          Top Women{" "}
          <Icon
            icon="ph:arrow-right-duotone"
            className="my-auto ml-1 text-xl"
          />
        </p>
        <div className="h-0  border-t border-slate-400/20" />
        <GearList
          value={list2}
          className="md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 "
        />
      </div>
      <img
        className="w-full h-full mx-auto object-cover mt-12 rounded-lg"
        src="/assets/imgs/home/banner-8.png"
        alt="ad"
      />

      <PayoutBox />
      <hr></hr>
      {/* <Footer/> */}
    </div>
  );
}

export default MarketHome



export const prodList = [
  {
    id: "FRG100004",
    brandName: "Christian Dior",
    brandAbr: "DIOR",
    model: "J'adore Dior",
    img: "https://fimgs.net/mdimg/perfume/375x500.210.jpg",
    fragUrl: "https://www.fragrantica.com/perfume/Dior/J-adore-210.html",
    formula: "EDP",
    year: 1999,
    category: "FLORAL",
    popularRate: 9.8,
    status: "OG",
    gender: "FEMALE",
    rate: 4.01,
    startPrice: 11.99,
    votes: 25802,
    createdDateTime: "2024-09-25T01:02:14.495",
  },
  {
    id: "FRG100022",
    brandName: "Yves Saint Laurent",
    brandAbr: "YSL",
    model: "Black Opium",
    img: "https://fimgs.net/mdimg/perfume/375x500.25324.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Yves-Saint-Laurent/Black-Opium-25324.html",
    formula: "EDP",
    year: 2014,
    category: "ORIENTAL",
    popularRate: 9.75,
    status: "active",
    gender: "FEMALE",
    rate: 4.15,
    startPrice: 9.99,
    votes: 25906,
    createdDateTime: "2024-09-25T00:50:37.41",
  },
  {
    id: "FRG100055",
    brandName: "Dolce & Gabbana",
    brandAbr: "D&G",
    model: "Light Blue",
    img: "https://fimgs.net/mdimg/perfume/375x500.485.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Dolce-Gabbana/Light-Blue-485.html",
    formula: "EDT",
    year: 2001,
    category: "FRESH",
    popularRate: 9.55,
    status: "CLASSIC",
    gender: "UNISEX",
    rate: 4,
    startPrice: 7.99,
    votes: 33792,
    createdDateTime: "2024-10-22T11:13:02.514",
  },
  {
    id: "FRG100086",
    brandName: "Chanel",
    brandAbr: "CHANEL",
    model: "Bleu de Chanel EDP",
    img: "https://fimgs.net/mdimg/perfume/375x500.25967.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Chanel/Bleu-de-Chanel-Eau-de-Parfum-25967.html",
    formula: "EDP",
    year: 2014,
    category: "FRESH",
    popularRate: 9.3,
    status: "OG",
    gender: "MALE",
    rate: 4.64,
    startPrice: 12.99,
    votes: 18505,
    createdDateTime: "2024-10-23T14:05:20.999",
  },
  {
    id: "FRG100076",
    brandName: "Carolina Herrera",
    brandAbr: "CH",
    model: "Good Girl",
    img: "https://fimgs.net/mdimg/perfume/375x500.39681.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Carolina-Herrera/Good-Girl-39681.html",
    formula: "EDP",
    year: 2016,
    category: "ORIENTAL",
    popularRate: 9.15,
    status: "CLASSIC",
    gender: "FEMALE",
    rate: 4.09,
    startPrice: 10.99,
    votes: 19405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100039",
    brandName: "Maison Francis Kurkdjian",
    brandAbr: "MFK",
    model: "Baccarat Rouge 540",
    img: "https://fimgs.net/mdimg/perfume/375x500.33519.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Carolina-Herrera/Good-Girl-39681.html",
    formula: "EDP",
    year: 2016,
    category: "ORIENTAL",
    popularRate: 9.15,
    status: "CLASSIC",
    gender: "UNISEX",
    rate: 4.09,
    startPrice: 11.99,
    votes: 19405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  
];



export const listO = [
  {
    id: "FRG100455",
    brandName: "PAIRRIT",
    brandAbr: "Layon + BDC",
    model: "Connecticut",
    img: "/assets/imgs/home/states/CT.png",
    formula: "OIL",
    year: 2015,
    category: "ORIENTAL",
    popularRate: 9.75,
    status: "BESTSELLER",
    gender: "UNISEX",
    rate: 4.85,
    startPrice: 18.99,
    votes: 5906,
    createdDateTime: "2024-09-25T00:50:37.41",
  },
  {
    id: "FRG100456",
    brandName: "PAIRRIT",
    brandAbr: "Tendre + Libre",
    model: "Florida",
    img: "/assets/imgs/home/states/FL.png",
    formula: "OIL",
    year: 2025,
    category: "WOODY",
    popularRate: 9.45,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.75,
    startPrice: 14.99,
    votes: 2792,
    createdDateTime: "2024-10-22T11:13:02.514",
  },
  {
    id: "FRG100457",
    brandName: "PAIRRIT",
    brandAbr: "Sauvage + Allure",
    model: "Arizona",
    img: "/assets/imgs/home/states/AZ.png",
    formula: "OIL",
    year: 2025,
    category: "FLORAL",
    popularRate: 9.3,
    status: "GOLD",
    gender: "UNISEX",
    rate: 4.94,
    startPrice: 15.99,
    votes: 4505,
    createdDateTime: "2024-10-23T14:05:20.999",
  },
  {
    id: "FRG100458",
    brandName: "PAIRRIT",
    brandAbr: "Baccarat + Aventus",
    model: "Georgia",
    img: "/assets/imgs/home/states/GA.png",
    formula: "OIL",
    year: 2025,
    category: "FLORAL",
    popularRate: 9.85,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.65,
    startPrice: 13.99,
    votes: 5405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100459",
    brandName: "PAIRRIT",
    brandAbr: "Baccarat Extrait + J'Adore",
    model: "California",
    img: "/assets/imgs/home/states/CA.png",
    formula: "OIL",
    year: 2025,
    category: "ORIENTAL",
    popularRate: 9.05,
    status: "GOLD",
    gender: "UNISEX",
    rate: 4.89,
    startPrice: 15.99,
    votes: 9405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100460",
    brandName: "PAIRRIT",
    brandAbr: "Hypnotic + BLK Opium",
    model: "New Jersey",
    img: "/assets/imgs/home/states/NJ.png",
    gender: "UNISEX",
    startPrice: 16.99,
    rate: 4.62,
    status: "GOLD",
    year: 2025,
    category: "ORIENTAL",
    formula: "OIL",
    popularRate: 9.1,
  },
  {
    id: "FRG100461",
    brandName: "PAIRRIT",
    brandAbr: "NY Nights + Tobacco Vanille",
    model: "Colorado",
    img: "/assets/imgs/home/states/CO.png",
    gender: "UNISEX",
    startPrice: 18.99,
    rate: 4.81,
    status: "GOLD",
    year: 2007,
    category: "ORIENTAL",
    formula: "OIL",
    popularRate: 9.5,
  },
  {
    id: "FRG100462",
    brandName: "PAIRRIT",
    brandAbr: "Spicebomb Ex + Portrait",
    model: "Alabama",
    img: "/assets/imgs/home/states/AL.png",
    gender: "UNISEX",
    startPrice: 13.99,
    rate: 4.71,
    status: "TREND",
    year: 2005,
    category: "ORIENTAL",
    formula: "OIL",
    popularRate: 9.7,
  },
  {
    id: "FRG100463",
    brandName: "PAIRRIT",
    brandAbr: "Grand Soir + Homme Intense",
    model: "Michigan",
    img: "/assets/imgs/home/states/MI.png",
    gender: "UNISEX",
    startPrice: 15.99,
    rate: 4.82,
    status: "NEW",
    year: 2006,
    category: "ORIENTAL",
    formula: "OIL",
    votes: 2405,
    popularRate: 9.65,
  },
  {
    id: "FRG100464",
    brandName: "PAIRRIT",
    brandAbr: "Greenwich + Born in Roma",
    model: "Louisiana",
    img: "/assets/imgs/home/states/LA.png",
    formula: "OIL",
    year: 2012,
    category: "WOODY",
    popularRate: 9.15,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.95,
    startPrice: 15.99,
    votes: 2405,
  },
  {
    id: "FRG100465",
    brandName: "PAIRRIT",
    brandAbr: "Irish Tweed + Le Male PAR",
    model: "Texas",
    img: "/assets/imgs/home/states/TX.png",
    formula: "OIL",
    year: 2020,
    category: "FRESH",
    popularRate: 9.05,
    status: "BESTSELLER",
    gender: "UNISEX",
    rate: 4.89,
    startPrice: 14.99,
    votes: 4405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100466",
    brandName: "PAIRRIT",
    brandAbr: "Orchid + Good Girl",
    model: "Virgina",
    img: "/assets/imgs/home/states/VA.png",
    formula: "OIL",
    year: 2011,
    category: "FLORAL",
    popularRate: 8.85,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.79,
    startPrice: 13.99,
    votes: 1405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
];

const list = [
  {
    id: "FRG100031",
    brandName: "Yves Saint Laurent",
    brandAbr: "YSL",
    model: "Tuxedo YSL",
    img: "https://fimgs.net/mdimg/perfume/375x500.32269.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Yves-Saint-Laurent/Tuxedo-32269.html",
    formula: "EDP",
    year: 2015,
    category: "ORIENTAL",
    popularRate: 9.75,
    status: "TREND",
    gender: "UNISEX",
    rate: 4.65,
    startPrice: 18.99,
    votes: 15906,
    createdDateTime: "2024-09-25T00:50:37.41",
  },
  {
    id: "FRG100270",
    brandName: "Creed",
    brandAbr: "CREED",
    model: "Green Irish Tweed",
    img: "https://fimgs.net/mdimg/perfume/375x500.474.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Creed/Green-Irish-Tweed-474.html",
    formula: "EDP",
    year: 1985,
    category: "WOODY",
    popularRate: 9.05,
    status: "OG",
    gender: "MALE",
    rate: 4.57,
    startPrice: 17.99,
    votes: 12792,
    createdDateTime: "2024-10-22T11:13:02.514",
  },
  {
    id: "FRG100098",
    brandName: "Chanel",
    brandAbr: "CHANEL",
    model: "Coco Mademoiselle",
    img: "https://fimgs.net/mdimg/perfume/375x500.611.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Chanel/Coco-Mademoiselle-611.html",
    formula: "EDP",
    year: 2001,
    category: "FLORAL",
    popularRate: 9.3,
    status: "CLASSIC",
    gender: "FEMALE",
    rate: 4.34,
    startPrice: 12.99,
    votes: 8505,
    createdDateTime: "2024-10-23T14:05:20.999",
  },
  {
    id: "FRG100069",
    brandName: "Parfums De Marly",
    brandAbr: "PDM",
    model: "Delina",
    img: "https://fimgs.net/mdimg/perfume/375x500.43871.jpg",
    fragUrl:
      "https://fragrantica.com/perfume/Parfums-de-Marly/Delina-43871.html",
    formula: "EDP",
    year: 2017,
    category: "FLORAL",
    popularRate: 8.85,
    status: "TREND",
    gender: "FEMALE",
    rate: 4.25,
    startPrice: 13.99,
    votes: 11405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100037",
    brandName: "Maison Francis Kurkdjian",
    brandAbr: "MFK",
    model: "Grand Soir",
    img: "https://fimgs.net/mdimg/perfume/375x500.40816.jpg",
    fragUrl:
      "https://fragrantica.com/perfume/Maison-Francis-Kurkdjian/Grand-Soir-40816.html",
    formula: "EDP",
    year: 2016,
    category: "ORIENTAL",
    popularRate: 9.05,
    status: "GOLD",
    gender: "UNISEX",
    rate: 4.69,
    startPrice: 15.99,
    votes: 9405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100403",
    brandName: "Maison Margiela",
    brandAbr: "INITIO",
    model: "By the Fireplace",
    img: "https://fimgs.net/mdimg/perfume/375x500.53641.jpg",
    gender: "UNISEX",
    startPrice: 16.99,
    rate: 4.42,
    status: "GOLD",
    year: 2018,
    category: "ORIENTAL",
    formula: "PAR",
    popularRate: 9.1,
  },
  {
    id: "FRG100178",
    brandName: "Tom Ford",
    brandAbr: "TF",
    model: "Tobacco Vanille",
    img: "https://fimgs.net/mdimg/perfume/375x500.1825.jpg",
    gender: "UNISEX",
    startPrice: 18.99,
    rate: 4.31,
    status: "GOLD",
    year: 2007,
    category: "ORIENTAL",
    formula: "EDP",
    popularRate: 9.5,
  },
  {
    id: "FRG100206",
    brandName: "Theirry Mugler",
    brandAbr: "MUGLER",
    model: "Alien",
    img: "https://fimgs.net/mdimg/perfume/375x500.707.jpg",
    gender: "FEMALE",
    startPrice: 13.99,
    rate: 4.1,
    status: "TREND",
    year: 2005,
    category: "ORIENTAL",
    formula: "EDP",
    popularRate: 9.7,
  },
  {
    id: "FRG100184",
    brandName: "Tom Ford",
    brandAbr: "TF",
    model: "Black Orchid",
    img: "https://fimgs.net/mdimg/perfume/375x500.1018.jpg",
    gender: "FEMALE",
    startPrice: 13.99,
    rate: 4,
    status: "active",
    year: 2006,
    category: "ORIENTAL",
    formula: "EDP",
    popularRate: 9.65,
  },
  {
    id: "FRG100068",
    brandName: "Parfums De Marly",
    brandAbr: "PDM",
    model: "Herod",
    img: "https://fimgs.net/mdimg/perfume/375x500.16939.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Parfums-de-Marly/Herod-16939.html",
    formula: "EDP",
    year: 2012,
    category: "WOODY",
    popularRate: 9.15,
    status: "TREND",
    gender: "MALE",
    rate: 4.55,
    startPrice: 15.99,
    votes: 9405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100414",
    brandName: "Maison Margiela",
    brandAbr: "MM",
    model: "Bubble Bath",
    img: "https://fimgs.net/mdimg/perfume/375x500.60105.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Maison-Martin-Margiela/Bubble-Bath-60105.html",
    formula: "EDT",
    year: 2020,
    category: "FRESH",
    popularRate: 9.05,
    status: "active",
    gender: "UNISEX",
    rate: 4.09,
    startPrice: 10.99,
    votes: 9405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
  {
    id: "FRG100156",
    brandName: "Lancome",
    brandAbr: "LC",
    model: "Trésor Midnight Rose",
    img: "https://fimgs.net/mdimg/perfume/375x500.11721.jpg",
    fragUrl:
      "https://www.fragrantica.com/perfume/Lancome/Tresor-Midnight-Rose-11721.html",
    formula: "EDP",
    year: 2011,
    category: "FLORAL",
    popularRate: 8.85,
    status: "TREND",
    gender: "FEMALE",
    rate: 4.19,
    startPrice: 10.99,
    votes: 11405,
    createdDateTime: "2024-10-22T20:07:48.332",
  },
];

export const list2 = [
  {
    id: "FRG100315",
    brandName: "Xerjoff",
    brandAbr: "XERJOFF",
    status: "TREND",
    gender: "UNISEX",
    rate: 4.19,
    model: "Xerjoff Erba Pura EDP",
    startPrice: 14.99,
    formula: "EDP",
    img: "https://fimgs.net/mdimg/perfume/375x500.55157.jpg",
  },
  {
    id: "FRG100275",
    brandName: "Creed",
    brandAbr: "CREED",
    status: "NEW",
    gender: "FEMALE",
    rate: 4.08,
    model: "Carmina",
    startPrice: 15.99,
    formula: "EDP",
    img: "https://fimgs.net/mdimg/perfume/375x500.84206.jpg",
  },
  {
    id: "FRG100177",
    brandName: "Tom Ford",
    brandAbr: "TF",
    status: "TREND",
    gender: "FEMALE",
    rate: 4.31,
    model: "Lost Cherry",
    startPrice: 14.99,
    formula: "PAR",
    img: "https://fimgs.net/mdimg/perfume/375x500.51411.jpg",
  },
  {
    id: "FRG100033",
    brandName: "Christian Dior",
    brandAbr: "DIOR",
    status: "CLASSIC",
    gender: "FEMALE",
    rate: 4.27,
    model: "Dior Addict EDP",
    startPrice: 12.99,
    formula: "EDP",
    img: "https://fimgs.net/mdimg/perfume/375x500.15270.jpg",
  },
  {
    id: "FRG100069",
    brandName: "Parfums De Marly",
    brandAbr: "PDM",
    status: "TREND",
    gender: "FEMALE",
    rate: 4.39,
    model: "DELINA",
    startPrice: 13.99,
    formula: "EDP",
    img: "https://fimgs.net/mdimg/perfume/375x500.43871.jpg",
  },
  {
    id: "FRG100335",
    brandName: "Montale",
    brandAbr: "MONTALE",
    status: "active",
    gender: "UNISEX",
    rate: 4.16,
    model: "Chocolate Greedy",
    startPrice: 12.99,
    formula: "EDP",
    img: "https://fimgs.net/mdimg/perfume/375x500.2186.jpg",
  },
  {
    id: "FRG100095",
    brandName: "Chanel",
    brandAbr: "CHANEL",
    status: "OG",
    gender: "FEMALE",
    rate: 4.09,
    category: "FLORAL",
    model: "Chanel No 5 EDP",
    startPrice: 11.99,
    img: "https://fimgs.net/mdimg/perfume/375x500.40069.jpg",
    formula: "EDP",
  },
  {
    id: "FRG100388",
    brandName: "Armani",
    brandAbr: "ARMANI",
    status: "CLASSIC",
    gender: "FEMALE",
    rate: 4.2,
    category: "TREND",
    model: "My Way Intense",
    startPrice: 12.99,
    img: "https://fimgs.net/mdimg/perfume/375x500.68596.jpg",
    formula: "EDP",
  },
  {
    id: "FRG100019",
    brandName: "Christian Dior",
    brandAbr: "DIOR",
    status: "NEW",
    gender: "FEMALE",
    rate: 4.14,
    category: "FLORAL",
    model: "J'adore L'Or (2023)",
    startPrice: 10.99,
    img: "https://fimgs.net/mdimg/perfume/375x500.84202.jpg",
    formula: "EDP",
  },
  {
    id: "FRG100137",
    brandName: "Estee Lauder",
    brandAbr: "Estée",
    status: "CLASSIC",
    gender: "FEMALE",
    rate: 4.04,
    category: "FLORAL",
    model: "Pleasures",
    startPrice: 11.99,
    img: "https://fimgs.net/mdimg/perfume/375x500.536.jpg",
    formula: "EDP",
  },
  {
    id: "FRG100220",
    brandName: "Byredo",
    brandAbr: "BYREDO",
    status: "active",
    gender: "FEMALE",
    rate: 4.24,
    category: "FLORAL",
    model: "La Tulipe",
    startPrice: 12.99,
    img: "https://fimgs.net/mdimg/perfume/375x500.8441.jpg",
    formula: "EDP",
  },
  {
    id: "FRG100058",
    brandName: "Dolce Gabbana",
    brandAbr: "D&G",
    status: "NEW",
    gender: "FEMALE",
    rate: 4.41,
    category: "FLORAL",
    model: "The One Gold",
    startPrice: 8.99,
    img: "https://fimgs.net/mdimg/perfume/375x500.69001.jpg",
    formula: "EDP",
  },
];