import { configureStore } from "@reduxjs/toolkit";
import authSignupReducer from "./features/auth/authSignupSlice";
import homeFeedReducer from "./features/homeFeed/homeFeedSlice";
import reportPostReducer from "./features/homeFeed/reportSlice";
import notifyReducer from "./features/notify/notifySlice";
import communitiesReducer from "./features/communties/communtiesSlice";
import communityReducer from "./features/communityFeed/communitySlice";
import commMarketReducer from "./features/communityFeed/commMarketSlice";
import marketplaceReducer from "./features/market/marketplaceSlice";
import userCollectionReducer from "./features/userCollection/userCollectionSlice";
import userProfileReducer from "./features/user/userProfileSlice";
import updateProfileReducer from "./features/user/updateProfileSlice";
import userStoreReducer from "./features/userStore/userStoreSlice";
import { postReducer } from "./features/post/postSlice";
import userThreadsReducer from "./features/userThreads/userThreadsSlice";
import userCommunitiesReducer from "./features/userCommunites/userCommunitiesSlice";
import wishlistReducer from "./features/userWishlist/wishlistSlice";
import alertsReducer from "./features/userAlerts/alertsSlice";
import newGearReducer from "./features/singlePages/newGear/newGearSlice";
import newListingReducer from "./features/singlePages/newListings/newListingSlice";
import newTopicReducer from "./features/singlePages/newTopics/newTopicSlice";
import newPollReducer from "./features/singlePages/newPolls/newPollSlice";
import messagesReducer from "./features/messages/messagesSlice";
import userPayoutReducer from "./features/userPayout/userPayoutSlice";
import userTransactionsReducer from "./features/userTransactions/userTransactionsSlice";
import userPurchasesReducer from "./features/userTransactions/purchases/userPurchasesSlice";
import userOrdersReducer from "./features/userTransactions/orders/userOrdersSlice";
import userOffersReducer from "./features/userTransactions/offers/userOffersSlice";
import cartReducer from "./features/cart/cartSlice";
import modSettingsReducer from "./features/communityMod/settings/modSettingsSlice";
import checkoutReducer from "./features/checkout/checkoutSlice";
import searchReducer from "./features/search/searchSlice";
import { gearReducer } from "./features/post/gearSlice";
import  imagesReducer  from "./features/singlePages/getImgs/getImgsSlice";
import { topicReducer } from "./features/post/topicSlice";
import { pollReducer } from "./features/post/pollSlice";
import { commentReducer } from "./features/post/commentsSlice";
import { inviteReducer } from "./features/post/inviteSlice";

export const store = configureStore({
  reducer: {
    authSignup: authSignupReducer,
    homeFeed: homeFeedReducer,
    reportPost: reportPostReducer,
    notify: notifyReducer,
    marketplace: marketplaceReducer,
    messages: messagesReducer,
    community: communityReducer,
    commMarket: commMarketReducer,
    communities: communitiesReducer,
    modSettings: modSettingsReducer,
    newGearPost: newGearReducer,
    listingPost: newListingReducer,
    topicPost: newTopicReducer,
    pollPost: newPollReducer,
    post: postReducer,
    comments: commentReducer,
    postPoll: pollReducer,
    postTopic: topicReducer,
    inviteUser: inviteReducer,
    postGear: gearReducer,
    getImgs:imagesReducer ,
    search: searchReducer,
    userAlerts: alertsReducer,
    userProfile: userProfileReducer,
    updateProfile: updateProfileReducer,
    userCollection: userCollectionReducer,
    userStore: userStoreReducer,
    userThreads: userThreadsReducer,
    userCommunities: userCommunitiesReducer,
    userWishlist: wishlistReducer,
    userMessages: messagesReducer,
    userPayout: userPayoutReducer,
    userTransactions: userTransactionsReducer,
    userPurchases: userPurchasesReducer,
    userOrders: userOrdersReducer,
    userOffers: userOffersReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
