import { Icon } from "@iconify/react";
import { HTMLAttributes, useState } from "react";
import { cn } from "../../../../helpers/utils";
import {
  likeAPost,
  removeLike,
} from "../../../../redux/features/homeFeed/homeFeedSlice";
import { notifySelect } from "../../../../redux/features/notify/notifySlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useOktaAuth } from "@okta/okta-react";
import useAuthUser from "../../../../hooks/getAuthUser";
import mixpanel from "mixpanel-browser";

interface VoteProps extends HTMLAttributes<HTMLDivElement> {
  likes?: any;
  isLiked?: boolean;
  postId: any;
}

export default function Vote({
  likes,
  isLiked,
  postId,
  className,
  ...props
}: VoteProps) {
  const [value, setValue] = useState(likes);
  const { authState } = useOktaAuth();
  const user = useAuthUser();
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.userProfile);
  const [likePressed, setLikePressed] = useState(isLiked);
  const [dislikePressed, setDislikePressed] = useState(false);

  const onLikeTap = () => {
    if (authState?.isAuthenticated) {
      setLikePressed(!likePressed);
      setDislikePressed(false);
      setValue(value + 1);
      const data = { postId: postId, userId: userId };
      dispatch(likeAPost(data));
      mixpanel.track("Post liked", {
        post_id: postId,
        email: user ? user["email"] : "",
      });
    } else {
      dispatch(
        notifySelect({
          title: "Please Signin",
          type: 1,
          open: true,
        })
      );
    }
  };

  const onDisLikeTap = () => {
    setDislikePressed(!dislikePressed);
    setLikePressed(false);
    setValue(value - 1);
    const data = { postId: postId, userId: userId };
    dispatch(removeLike(data));
  };
  return (
    <div className="min-w-[50px] my-1 ml-1">
      <div className="flex flex-col items-center p-1 font-semibold border rounded h-fit w-9 text-slate-800 bg-white/80 border-accentYellow-500">
        {likePressed ? (
          <Icon
            icon="icon-park-twotone:up-two"
            className="text-xl text-red-500"
            onClick={onDisLikeTap}
          />
        ) : (
          <Icon
            icon="icon-park-outline:up-two"
            className="text-xl hover:text-orange-700"
            onClick={onLikeTap}
          />
        )}
        <span className="text-sm">{value}</span>
        {/* {dislikePressed ? (
          <Icon
            icon="icon-park-twotone:down-two"
            className="text-lg text-red-500"
          />
        ) : (
          <Icon
            icon="icon-park-outline:down-two"
            className="text-lg  hover:text-orange-700"
            onClick={onDisLikeTap}
          />
        )} */}
      </div>
    </div>
  );
}
