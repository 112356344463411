import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import axios from "axios";

const Earnings = () => {
  const { profileData, loading } = useAppSelector((state) => state.userProfile);

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await axios.post(
        `https://webservice.pairrit.com/pairrit-ws/api/payment/getSession/${profileData.stripeId}`
      );

      const clientSecret = await response.data;
      return clientSecret;
    };
    return loadConnectAndInitialize({
      publishableKey: `pk_live_51OUNPXEmm19RxbbmY01wHSuAqL1Y2eQppKIk71QvthJ3fGnahVrl0CTHmLVVchUtWCL79vwIC9UUxb9CWEz5IXim00iDVN9ttU`,
      fetchClientSecret: fetchClientSecret,
    });
  });
  return (
    <div className="bg-white p-3 mt-5">
      <Link to={"/settings/payment"}>
        <div className="flex justify-start text-[#212121] underline text-xs">
          View Payout Method
        </div>
      </Link>
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <ConnectPayments />
      </ConnectComponentsProvider>
    </div>
  );
};

export default Earnings;
