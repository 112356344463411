import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import axios from "axios";

// Define a type for the slice state
interface InitialStateType {
  loading: boolean;
  error: string | null;
  url: any;
  status: boolean;
  name: string;
  lastFour: number;
}

// Define the initial state using that type
const initialState: InitialStateType = {
  status: false,
  name: "Sam Smith",
  lastFour: 2121,
  loading: false,
  error: null,
  url: null,
};

export const onBoardUser = createAsyncThunk(
  "payment/onboard",
  async (id: string) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/payment/getAccountLink/${id}`
    );
    return response.status;
  }
);

export const updateUserPay = createAsyncThunk(
  "payment/update",
  async (id: string) => {
    const response = await axios.post(
      `https://webservice.pairrit.com/pairrit-ws/api/payment/getUpdateLink/${id}`
    );
    return response.status;
  }
);

export const userPayoutSlice = createSlice({
  name: "userPayout",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addPayment: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(onBoardUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(onBoardUser.fulfilled, (state, action) => {
        state.loading = false;
        state.url = action.payload;
      })
      .addCase(onBoardUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(updateUserPay.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserPay.fulfilled, (state, action) => {
        state.loading = false;
        state.url = action.payload;
      })
      .addCase(updateUserPay.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const {} = userPayoutSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default userPayoutSlice.reducer;
