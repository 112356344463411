import { Icon } from "@iconify/react";
import mixpanel from "mixpanel-browser";
import useAuthUser from "../../../../hooks/getAuthUser";

const OnBoardingIntro = ({
  setValue,
}: {
  setValue: (value: string) => void;
  }) => {
  const user = useAuthUser();
  return (
    <div>
      <img src="/assets/imgs/common/logo.png" className="w-16 mx-auto" alt="" />
      <p className="text-center text-4xl font-extrabold text-gray-100">
        Welcome
      </p>
      <p className="text-center text-xs font-medium text-[#FE5401]">
        Largest growing fragrance Community
      </p>
      <hr className=" my-3" />
      <p className="text-center text-lg font-extrabold text-white mt-3">
        Start to help us setup your account
      </p>
      <footer className="my-5 flex justify-center">
          <button
            className="flex items-baseline gap-2 rounded-lg bg-gradient-to-l from-red-500  to-yellow-500  px-4 py-2.5 text-xl font-bold text-white hover:opacity-75 border-2 border-white"
          onClick={() => { setValue("Second");  mixpanel.track("Onboarded started (Step 1 Complete)", {
            email: user ? user["email"] : "",
          });}}
          >
            <span>
              Start
            </span>
            <Icon
              icon="solar:play-bold-duotone"
              className="text-xl text-white h-4"
            />
          </button>
      </footer>
    </div>
  );
};

export default OnBoardingIntro